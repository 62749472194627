import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { HttpErrorResponse } from '@angular/common/http';
import {
  KpiDto,
  LagCardPojo,
  LagControllerService,
  LagKpiPojo
} from '../../../../../../../sdk/ecadence-sdk';
import { NumberOnly } from '../../../../validators/number-only';
import { XAlertMessage } from '../../../alert-message/alert-message.component';
import { HelperService } from '../../../../../services/helper.service';

@Component({
  selector: 'edit-kpi-modal',
  templateUrl: './edit-kpi-modal.component.html',
  styleUrls: ['./edit-kpi-modal.component.css']
})
export class EditKpiModalComponent {
  constructor(
    private modalRef: BsModalRef,
    private lagController: LagControllerService,
    private helperService: HelperService
  ) {}

  _kpi: LagKpiPojo;
  _form: FormGroup;
  _isLoading = false;
  _isDisabled = false;

  @Input()
  lag: LagCardPojo;

  xAlertMessage: XAlertMessage = {
    messageType: 'info',
    message: undefined
  };

  @Output()
  closedEvent: EventEmitter<any> = new EventEmitter<any>();

  datePickerConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-dark-blue',
    showWeekNumbers: false,
    dateInputFormat: 'YYYY-MM-DD'
  };

  get kpi(): LagKpiPojo {
    return this._kpi;
  }

  @Input()
  set kpi(value: LagKpiPojo) {
    this._kpi = value;
    this.patchForm();
  }

  get form(): FormGroup {
    return this._form;
  }

  @Input()
  set form(value: FormGroup) {
    this._form = value;
    this.patchForm();
  }

  closeModal(): void {
    this.closedEvent.emit();
    this.modalRef.hide();
  }

  patchForm(): void {
    if (this.kpi && this.form) {
      this.form.patchValue({ ...this.kpi });
      if (this.kpi.completed) {
        this._form.get('description').disable();
        this._form.get('weight').disable();
        this._form.get('dueDate').disable();
        this.showMessage('You cannot edit a completed KPI', 'info');
        this._isDisabled = true;
      }
    }
  }

  get today(): Date {
    return new Date();
  }

  protected readonly NumberOnly = NumberOnly;

  updateKpi(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const formInfo: KpiDto = this.form.value as KpiDto;
    if (
      formInfo.description == this.kpi.description &&
      formInfo.weight == this.kpi.weight &&
      formInfo.dueDate == this.kpi.dueDate
    ) {
      this.showMessage('Please, make edit the form to proceed', 'info');
      return;
    }

    this._isLoading = true;
    this.lagController
      .updateKPI({
        lagId: this.lag.id,
        kpiId: this.kpi.id,
        kpiDto: this._form.value
      })
      .subscribe({
        next: (vv) => {
          this.kpi = vv;
          this.showMessage('KPI updated successfully', 'success');
        },
        error: (err: unknown) => {
          this.showMessage(
            err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
              ? err.error.message
              : 'Unable to update KPIs',
            'danger'
          );
        }
      })
      .add(() => {
        this._isLoading = false;
      });
  }

  showMessage(error: any, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
    this.xAlertMessage.messageType = type;
    this.xAlertMessage.message = error;
    setTimeout(() => {
      this.xAlertMessage.message = undefined;
    }, 4000);
  }
}
