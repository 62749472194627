<app-full-page-loader *ngIf="showLoading" [customText]="'loading'"></app-full-page-loader>
<app-header
  *ngIf="!isExtranet && !hideLayout && !showLoading"
  [user]="user"
  [showWelcome]="showWelcome"
/>
<main>
  <app-cadence-modal *ngIf="schedules && _show" (hideEvent)="hide($event)" [modalData]="schedules"></app-cadence-modal>
  <router-outlet></router-outlet>
</main>
