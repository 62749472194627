/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WigKpiPojo { 
    name?: string;
    completionStatus?: WigKpiPojo.CompletionStatusEnum;
    lagId?: number;
    actualScore?: number;
    duaDateTime?: string;
}
export namespace WigKpiPojo {
    export type CompletionStatusEnum = 'COMPLETED' | 'IN_REVIEW' | 'NOT_COMPLETED';
    export const CompletionStatusEnum = {
        COMPLETED: 'COMPLETED' as CompletionStatusEnum,
        IN_REVIEW: 'IN_REVIEW' as CompletionStatusEnum,
        NOT_COMPLETED: 'NOT_COMPLETED' as CompletionStatusEnum
    };
}


