import { Component, EventEmitter, Input, Output } from '@angular/core';
import { base64ToFile, ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.css']
})
export class ImageCropperComponent {
  imageChangedEvent = '';
  croppedImage = '';
  // eslint-disable-next-line
  /**
   * @deprecated use the image cropped event instead
   */
  onCroppedImage: (base64: string) => void;
  @Output()
  imageCropped = new EventEmitter<string>();
  @Output()
  imageCroppedAsBlob = new EventEmitter<Blob>();
  @Input()
  imageFile!: File;
  @Input()
  maxWidth = 500;
  @Input()
  maxHeight = 500;
  @Input()
  maintainAspectRatio = true;
  @Input()
  aspectRatio = this.maxWidth / this.maxHeight;

  constructor(private bsModalRef: BsModalRef) {}

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  onImageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  imageLoaded(image: LoadedImage): void {
    console.log(this.aspectRatio);
    // show cropper
  }

  cropperReady(): void {
    // cropper ready
  }

  loadImageFailed(): void {
    // show message
  }

  save(): void {
    if (this.onCroppedImage) {
      this.onCroppedImage(this.croppedImage);
    }
    this.imageCropped.emit(this.croppedImage);
    this.imageCroppedAsBlob.emit(base64ToFile(this.croppedImage));
  }

  close(): void {
    this.bsModalRef.hide();
  }

  confirm(): void {
    this.save();
    this.bsModalRef.hide();
  }
}
