<div>
  <form [formGroup]="form">
    <textarea
      [ngClass]="styleClass"
      [id]="controlName"
      [formControlName]="controlName"
      [maxlength]="maximumTextLength"
      class="form-control"
      style="min-height: 130px; resize: unset"
      [placeholder]="placeholder"
    ></textarea>
    <div class="d-flex justify-content-between">
      <error-message
        [controlName]="controlName.toString()"
        [form]="form"
        [label]="label"
        [fontSize]="10"
        [singularError]="true"
      >
      </error-message>
      <div
        class="d-flex justify-content-end"
        [ngClass]="{
          'text-danger': formValue?.length / maximumTextLength === 1,
          'text-success': formValue?.length / maximumTextLength < 1
        }"
      >
        {{ (formValue?.length || 0) + '/' + maximumTextLength }}
      </div>
    </div>
  </form>
</div>
