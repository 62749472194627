<div class="modal-header">
  <h4 class="modal-title">File Preview</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="isPdfFile; else imagePreview">
    <pdf-viewer
      style="width: 100%; height: 700px"
      [src]="fileUrl"
      [render-text]="true"
      [original-size]="false"
      [fit-to-page]="true"
      [autoresize]="true"
    ></pdf-viewer>
  </ng-container>
  <ng-template #imagePreview>
    <img [src]="fileUrl" class="img-fluid" alt="" />
  </ng-template>
</div>
