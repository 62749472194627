<div *ngIf="!previewOpen" #updateLeadMeasureScoreTop>
  <div
    class="offcanvas offcanvas-end show overflow-y-auto"
    tabindex="-1"
    id="leadScore"
    aria-labelledby="leadScoreLabel"
  >
    <div class="offcanvas-header pt-4 pb-3">
      <h5 id="leadScoreLabel">Update Lead Measure Score</h5>
      <button
        (click)="closeModal()"
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <form [formGroup]="form">
      <div class="offcanvas-body">
        <app-alert-message
          *ngIf="xAlertMessage"
          [messageType]="xAlertMessage!.messageType"
          [message]="xAlertMessage!.message"
        >
        </app-alert-message>
        <div class="mb-4 form-group">
          <custom-select-field
            [showRequiredRedIcon]="true"
            [form]="form"
            [controlName]="'wig'"
            [isLoading]="fetchingWigs"
            [name]="'Wig'"
            [rawData]="playerWigsPojo"
            [keyField]="'id'"
            [valueField]="'name'"
          >
          </custom-select-field>
        </div>

        <div class="mb-4 form-group">
          <custom-select-field
            [showRequiredRedIcon]="true"
            [form]="form"
            [controlName]="'lag'"
            [isLoading]="fetchingLagsAndItsMeasures"
            [name]="'Lag'"
            [rawData]="lagUI"
            [keyField]="'id'"
            [valueField]="'name'"
          >
          </custom-select-field>
        </div>

        <div class="mb-4 form-group">
          <custom-select-field
            [showRequiredRedIcon]="true"
            [form]="form"
            [controlName]="'lagMeasure'"
            [isLoading]="fetchingLagsAndItsMeasures"
            [name]="'Lag Measure'"
            [rawData]="lagMeasureOnly"
            [keyField]="'id'"
            [valueField]="'name'"
          >
          </custom-select-field>
        </div>

        <ng-container *ngIf="selectedLagMeasure; else selectALagMeasure">
          <div class="mb-4">
            <div class="fw-bold">
              Current Lag Measure Score:
              <span class="fw-normal">
                {{
                  selectedLagMeasure?.currentValue / selectedLagMeasure?.maxValue || 0 | percent
                }}</span
              >
            </div>
            <div class="mt-1 fs-13" style="color: #3285f8">
              Last updated: {{ selectedLagMeasure?.lastUpdated | shortestDate }}
            </div>
          </div>

          <div class="mb-4">
            <div class="display-box">
              <ng-container *ngIf="nextLagMeasureBenchmark; else NoLagMeasureBenchMark">
                <div class="fs-13 fw-bold mb-3">Next lag measure benchmark to meet</div>
                <div class="row gy-3">
                  <div class="col-md-6">
                    <div class="fs-12 fw-medium">Due Date</div>
                    <div class="fs-12">
                      {{ nextLagMeasureBenchmark?.dueDate | date : 'longDate' }}
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="fs-12 fw-medium">Score</div>
                    <div class="fs-12">
                      {{
                        nextLagMeasureBenchmark?.targetValue / selectedLagMeasure?.maxValue || 0
                          | percent
                      }}
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-template #NoLagMeasureBenchMark>
                <div class="fs-13 fw-bold mb-3">No Benchmark available</div>
              </ng-template>
            </div>
          </div>

          <ng-container
            *ngIf="
              leadMeasureAndCommitments && leadMeasureAndCommitments.length > 0;
              else noCommitments
            "
          >
            <div class="mb-4">
              <div class="fw-bold mb-1">Lead Measures</div>
              <div class="fs-14">Hi, enter your new player score</div>
            </div>

            <small class="text-danger fs-10" style="color: red" *ngIf="submissionError">
              Seems you have a score validation error, ensure that your updated score is not less
              than your current
            </small>

            <div class="mb-4">
              <ng-container *ngFor="let item of leadMeasureAndCommitments">
                <div class="display-box-dotted" style="">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="mb-3">
                        <div class="form-label fs-15 mb-1">{{ item.commitmentDto.uom }}</div>
                        <div class="fs-12 fw-medium">
                          Current Lead Measure Value:
                          <span class="fw-normal"> {{ item.commitmentDto.currentValue }}</span>
                        </div>
                        <div class="fs-12 fw-medium">
                          Current Player Score:
                          <span class="fw-normal"> {{ item.commitmentDto.playerScore }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="input-group">
                        <label class="form-label fs-15">Score:</label>
                        <div class="input-group mb-3">
                          <input
                            [(ngModel)]="item.newPlayerScore"
                            [ngModelOptions]="{ standalone: true }"
                            type="text"
                            class="form-control p-2"
                            placeholder="20"
                          />
                          <small
                            class="text-danger fs-10 fw-medium"
                            style="color: red"
                            *ngIf="item.commitmentDto.playerScore > item.newPlayerScore"
                          >
                            Your new score cannot be less than you current
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-2 mt-2 d-flex justify-content-end pointer">
                    <div (click)="showLeadMeasureActions(item)" class="hyper-blue-button">
                      <span class="iconify mx-1 fs-13" data-icon="carbon:list"></span>
                      View Lead Measure Actions
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="display-blue-box mb-4" style="word-break: break-word">
              <div class="fw-bold mb-2">Lead Measure Actions</div>
              <ng-container *ngIf="selectedLeadMeasure; else selectLeadMeasure">
                <div
                  class="mb-3 lead-action-card display-action"
                  style="max-height: 400px; overflow-y: scroll; overscroll: none"
                >
                  <ng-container
                    *ngFor="let ii of selectedLeadMeasure.commitmentDto?.commitmentDtos"
                  >
                    <div class="mb-1 mt-2 fw-medium fs-15">{{ ii.description }}</div>
                    <div class="fs-12 mb-1 fw-medium" style="color: #6f6f6f">
                      Target Score Movement:
                      <span class="fw-normal"> {{ ii.targetScoreMovement }}</span>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-6">
                        <div
                          class="mb-1 fs-11 fw-medium"
                          [ngClass]="{
                            'text-danger': ii.completionStatus === 'NOT_COMPLETED',
                            'text-success': ii.completionStatus === 'COMPLETED'
                          }"
                        >
                          {{ ii.completionStatus | underscoreToSpace }}
                        </div>
                      </div>
                      <div class="col-md-6">
                        <button
                          (click)="toggleMarkAs(ii)"
                          class="px-3 py-1 float-end fs-11 fw-medium pointer"
                          [ngClass]="{
                            'text-success': ii.completionStatus === 'NOT_COMPLETED',
                            'text-danger': ii.completionStatus === 'COMPLETED'
                          }"
                          style="background-color: #e7f4e2; border: none; border-radius: 12px"
                          [disabled]="markingCommitment"
                          [ngStyle]="{
                            'background-color':
                              ii.completionStatus === 'NOT_COMPLETED' ? '#e7f4e2' : 'white'
                          }"
                        >
                          <div class="d-flex">
                            <div *ngIf="ii.completionStatus === 'NOT_COMPLETED'">
                              <span
                                class="iconify pointer"
                                data-icon="solar:check-read-outline"
                              ></span>
                            </div>

                            <div *ngIf="ii.completionStatus === 'COMPLETED'">
                              <span
                                class="iconify"
                                data-icon="material-symbols:cancel"
                                style="color: red"
                              ></span>
                            </div>
                            <span class="ms-2">
                              Mark as {{ ii.completionStatus === 'COMPLETED' ? 'undone' : 'done' }}
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              <ng-template #selectLeadMeasure>
                <p class="mt-1 fs-14">Update Score</p>
              </ng-template>
            </div>

            <ng-container *ngIf="xAlertMessage.message">
              <small class="fs-10 text-danger" style="color: red">
                {{ xAlertMessage.message }}
              </small>
            </ng-container>
            <button
              (click)="preview(); scrollUp(updateLeadMeasureScoreTop)"
              class="btn fs-16 btn-primary w-100 mt-4"
            >
              Preview Update
            </button>
          </ng-container>

          <ng-template #noCommitments>
            <lottie-in-full-table
              [path]="'assets/lottie/no-data.json'"
              [message]="'You have no commitment for this lag measure'"
              [classForText]="'text-danger'"
            >
            </lottie-in-full-table>
          </ng-template>
        </ng-container>

        <ng-template #selectALagMeasure>
          <lottie-in-full-table
            [path]="'assets/lottie/select.json'"
            [message]="'Select WIG, Lag and Lag Measure'"
          >
          </lottie-in-full-table>
        </ng-template>
      </div>
    </form>
  </div>
</div>
