import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { XAlertMessage } from '../../../../shared/components/alert-message/alert-message.component';
import { LagMeasurePojo, LeadMeasureCommitmentDto } from '../../../../../../sdk/ecadence-sdk';
import { UpdateLeadMeasureScorePreviewDto } from '../update-lead-measure/update-lead-measure-score.component';
import { UpdateLeadMeasureScorePreviewComponent } from '../update-lead-measure-score-preview/update-lead-measure-score-preview.component';

@Component({
  selector: 'update-lag-measure-score',
  templateUrl: './update-lag-measure-score.component.html',
  styleUrls: ['./update-lag-measure-score.component.css']
})
export class UpdateLagMeasureScoreComponent implements OnInit {
  xAlertMessage: XAlertMessage = {
    messageType: 'info',
    message: undefined
  };

  previewOpen = false;

  @Input()
  lagMeasure: LagMeasurePojo;

  @Input()
  dto: UpdateLeadMeasureScorePreviewDto;

  @Output()
  updateEvent: EventEmitter<number> = new EventEmitter<number>();

  _lagMeasure: XLagMeasurePojo;
  touched = false;

  constructor(private modalService: BsModalService, private modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.getModeler();
  }

  getModeler(): void {
    if (this.lagMeasure) this._lagMeasure = { newValue: this.lagMeasure.currentValue };
    Object.assign(this._lagMeasure, this.lagMeasure);
  }

  preview(): void {
    this.previewOpen = true;
    const ref = this.modalService.show(UpdateLeadMeasureScorePreviewComponent, {
      initialState: {
        dto: this.dto,
        updatedLagMeasure: this._lagMeasure
      },
      class: 'offcanvas offcanvas-end show overflow-y-auto',
      backdrop: 'static'
    });

    ref.content.updateEvent.subscribe((v) => {
      this.updateEvent.emit(v);
      this.modalRef.hide();
    });
  }

  close(): void {
    this.modalRef.hide();
  }

  formTouched(): void {
    this.touched = true;
  }
}

export interface XLagMeasurePojo extends LagMeasurePojo {
  newValue?: number;
}
