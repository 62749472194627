<div class="cadence-time card p-3">
  <p *ngIf="overdue" class="text-danger fw-bold">Cadence overdue</p>
  <div class="text-center">
    <p class="text-warning fw-bold">Cadence Ends in:</p>
  </div>

  <div>
    <div class="d-flex justify-content-center align-items-center my-3">
      <div>
        <h1 class="fw-bold text-light mb-0">{{ minutes }}</h1>
        <p class="text-light fs-12">Min</p>
      </div>

      <div class="mx-4">
        <span class="iconify text-light" data-icon="material-symbols:hourglass-full"></span>
      </div>

      <div>
        <h1 class="fw-bold text-light mb-0">{{ seconds | number : '2.0' }}</h1>
        <p class="text-light fs-12">Seconds</p>
      </div>
    </div>
  </div>
</div>
