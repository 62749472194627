<div class="graphik-font">
  <header>
    <div>
      <nav class="navbar navbar-expand-lg fixed-top" [ngClass]="{ 'navbar-scrolled': isScrolled }">
        <div class="container">
          <a class="navbar-brand" href="#"
          ><img [src]="logoSrc" class="img-fluid" alt="" width="130"
          /></a>
          <button
            class="navbar-toggler text-light border-light"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="iconify fs-3" data-icon="ci:menu-alt-01"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarScroll">
            <ul
              class="navbar-nav mx-auto my-2 my-lg-0 navbar-nav-scroll"
              style="--bs-scroll-height: 100px"
            >
              <li class="nav-item">
                <a
                  class="nav-link px-5 py-1 fw-normal font-family-secondary"
                  aria-current="page"
                  href="/"
                >Home</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link px-5 py-1 fw-normal font-family-secondary" href="#about"
                >About Us</a
                >
              </li>
              <!--            <li class="nav-item">-->
              <!--              <a class="nav-link px-5 py-1 fw-normal font-family-secondary" href="#subscription"-->
              <!--                >Subscription-->
              <!--              </a>-->
              <!--            </li>-->
              <li class="nav-item">
                <a class="nav-link active-extranet px-5 py-1 fw-normal font-family-secondary" href="#"
                >Contact Us
                </a>
              </li>
            </ul>
            <div class="d-flex">
              <div class="dropdown">
                <button
                  class="btn btn-outline-light bg-transparent me-2 border-0 dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="iconify" data-icon="mdi:web"></span> EN
                </button>
                <ul class="dropdown-menu pt-0">
                  <li><a class="dropdown-item" href="#">EN</a></li>
                  <li><a class="dropdown-item" href="#">FR</a></li>
                  <li><a class="dropdown-item" href="#">ES</a></li>
                </ul>
              </div>

              <!-- <button class="btn btn-outline-light px-4 me-2">Sign In</button> -->
              <button class="btn btn-primary px-4" (click)="login()">Sign In</button>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <div class="contactBg">
      <div class="container">
        <div class="row pt-5">
          <div class="pt-5 formCard">
            <div class="mt-5 d-md-flex justify-content-between">
              <div class="col-md-6 col-12">
                <p class="my-3 small text-light">Contact Us</p>
                <h1 class="text-light" style="font-size: 3rem">Get In Touch</h1>

                <p class="text-light paragraph">
                  Feel free to reach out to us with any questions, inquiries, or feedback you may
                  have. Our dedicated team is ready to assist you and provide the support you need. We
                  strive to respond to all inquiries in a timely manner and ensure a positive
                  experience for our valued users.
                </p>

                <div class="mt-5">
                  <div class="d-flex mb-5">
                    <span class="iconify fs-3 text-light me-2" data-icon="carbon:email"></span>
                    <div>
                      <p class="text-light fw-bold">Email</p>
                      <p class="fs-14 text-light opacity-50">info@ecadence.ng</p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <span class="iconify fs-3 text-light me-2" data-icon="ph:phone"></span>
                    <div>
                      <p class="text-light fw-bold">Phone</p>
                      <p class="fs-14 text-light opacity-50">+234 909 425 7562</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12 px-md-4 mt-5 mt-md-0">
                <div class="card p-lg-5 p-3 rounded-0">
                  <p class="fw-bold">Get In Touch With Us</p>
                  <div class="mt-5">
                    <form>
                      <div class="row">
                        <div class="col">
                          <label for="" class="form-label">Name *</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Name"
                            aria-label="Name"
                          />
                        </div>
                        <div class="col">
                          <label for="" class="form-label">Email *</label>

                          <input
                            type="text"
                            class="form-control"
                            placeholder="Email"
                            aria-label=" Email"
                          />

                        </div>
                      </div>
                      <div class="col mt-5">
                        <label for="" class="form-label">How can we help? </label>
                        <textarea
                          type="text"
                          class="form-control"
                          rows="5"
                          placeholder="Your Message"
                        >
                        </textarea>
                      </div>
                      <div class="mt-5">
                        <button  class="btn btn-primary px-5">Send Message</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <section class="formCard faq">
    <div class="container">
      <div class="d-md-flex justify-content-between">
        <div class="col-12 col-md-6 text-center">
          <img src="/assets/img/contact.webp" class="img-fluid" alt=""/>
        </div>

        <div class="col-12 col-md-6 text-md-start mt-4 mt-md-0">
          <div class="mt-5">
            <p class="my-3 small">Frequently Asked Questions</p>
            <h1 class="" style="font-size: 3rem">Discover The Most Common Questions</h1>
          </div>

          <div class="col-md-10">
            <div class="accordion accordion-flush mt-5" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button text-primary collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Is eCadence suitable for individuals or teams?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse show"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body fs-14">
                    Yes, eCadence is designed to cater to both individuals and teams. It offers
                    features for personal goal tracking as well as collaboration and coordination
                    among team members.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button text-primary collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Can I upgrade my subscription plan?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse show"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Yes, you can upgrade your subscription plan at any time. eCadence provides
                    flexible options to accommodate your evolving needs. Simply visit your account
                    settings to explore the available upgrade options.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button text-primary collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Can I cancel my subscription at any time?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse show"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Absolutely! You have the freedom to cancel your subscription to eCadence at any
                    time. Please note that refunds may vary depending on the terms and conditions of
                    your subscription plan.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button text-primary collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Is my data secure with eCadence?
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  class="accordion-collapse collapse show"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Yes, eCadence takes data security and privacy seriously. It employs
                    industry-standard encryption protocols and follows best practices to protect your
                    data. Your information is stored securely and treated with the utmost
                    confidentiality.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button text-primary collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    What kind of customer support is available?
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  class="accordion-collapse collapse show"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    eCadence offers customer support to assist you with any queries or issues you may
                    encounter. Support is available via email for Basic eCadence subscribers, while
                    Pro and Super eCadence subscribers receive priority support with faster response
                    times and access to a dedicated customer success manager.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="my-5 py-xxl-4">
    <div class="container page_supp">
      <div class="row p-lg-5 py-5 py-md-0">
        <div class="col-lg-7">
          <h4 class="text-light my-3">
            What can we do to support your growth and enable continuous improvement?
          </h4>
          <p class="my-5 col-xl-10 text-light paragraph">
            We empower our clients to revolutionise their business practices, optimise their human
            capital, and elevate their operations, resulting in unparalleled levels of success.
          </p>

          <div class="d-flex flex-column flex-md-row justify-content-md-start">
            <button class="btn btn-light px-5 me-md-3" (click)="login()">Try e-Cadence</button>
            <button class="btn btn-outline-light px-5 mt-3 mt-md-0">Contact Us</button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <footer class="mt-5 dark_brand_color">
    <div class="container">
      <div class="row mb-5 pt-5">
        <div class="d-flex flex-column flex-md-row justify-content-center">
          <div class="col-12 col-md-3">
            <img src="/assets/img/logo_secondary.webp" width="150" class="img-fluid" alt=""/>
            <p class="my-3 fw-bold text-warning">✨ Automating Execution</p>
          </div>

          <div class="col-12 col-md my-4 mt-md-0">
            <div class="d-flex justify-content-between">
              <div class="col-lg-6">
                <p class="fw-bold opacity-50 mb-2">Company</p>
                <ul class="nav flex-column">
                  <li class="nav-item ps-1">
                    <a class="nav-link ps-1" aria-current="page" href="#about">About Us</a>
                  </li>
                  <!--                <li class="nav-item ps-1">-->
                  <!--                  <a class="nav-link ps-1" href="#subscription">Subscription</a>-->
                  <!--                </li>-->
                  <li class="nav-item ps-1">
                    <a class="nav-link ps-1" href="#">Contact Us</a>
                  </li>
                </ul>
              </div>
              <!--            <div class="col-lg-6">-->
              <!--              <p class="fw-bold opacity-50 mb-2">Industries</p>-->
              <!--              <ul class="nav flex-column">-->
              <!--                <li class="nav-item ps-1">-->
              <!--                  <a class="nav-link ps-1" aria-current="page" href="#">Retail</a>-->
              <!--                </li>-->
              <!--                <li class="nav-item ps-1">-->
              <!--                  <a class="nav-link ps-1" href="#">Technology</a>-->
              <!--                </li>-->
              <!--                <li class="nav-item ps-1">-->
              <!--                  <a class="nav-link ps-1" href="#">Finance & Insurance</a>-->
              <!--                </li>-->
              <!--              </ul>-->
              <!--            </div>-->
            </div>
          </div>

          <div class="col-12 col-md-3 mt-4 mt-md-0">


            <p class="text-warning fs-12 my-2 fw-bold"> Coming soon...</p>
            <div class="d-flex justify-content-between flex-md-column flex-row">
              <a href="" class="mb-4 opacity-50">
                <img src="/assets/img/apple.webp" class="img-fluid" alt=""/>
              </a>
              <a href="" class="opacity-50">
                <img src="/assets/img/google.webp" class="img-fluid" alt=""/>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!--    <div class="mt-5">-->
      <!--      <hr class="opacity-25 m-0" />-->
      <!--      <div class="row py-3">-->
      <!--        <div class="d-md-flex justify-content-md-between">-->
      <!--          <div class="d-flex justify-content-md-start justify-content-center flex-wrap">-->
      <!--            <a class="fw-bold mb-0 fs-13 text-decoration-none text-light" aria-current="page">-->
      <!--              <span class="iconify me- fw-normal" data-icon="mdi:web"></span>-->
      <!--              English-->
      <!--            </a>-->
      <!--            <a class="fw-normal mx-3 mb-0 fs-13 text-decoration-none text-light opacity-75"-->
      <!--              >Privacy Policy</a-->
      <!--            >-->
      <!--            <a class="fw-normal mx-3 mb-0 fs-13 text-decoration-none text-light opacity-75"-->
      <!--              >Cookies</a-->
      <!--            >-->

      <!--            <a class="fw-normal mx-3 mb-0 fs-13 text-decoration-none text-light opacity-75"-->
      <!--              >Term of Use</a-->
      <!--            >-->
      <!--          </div>-->

      <!--          <div class="d-flex justify-content-center mt-3 my-md-0">-->
      <!--            <div class="d-flex align-items-center">-->
      <!--              <a href="" class="text-light">-->
      <!--                <span class="iconify me-3 fs-4 opacity-25" data-icon="ic:baseline-facebook"> </span>-->
      <!--              </a>-->
      <!--              <a href="" class="text-light">-->
      <!--                <span class="iconify fs-4 opacity-25" data-icon="mdi:linkedin"> </span>-->
      <!--              </a>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </div>-->

      <hr class="opacity-25 m-0"/>

      <div class=" d-flex justify-content-center justify-content-md-start">
        <div class="d-flex align-items-center pb-3 pt-3">
          <img src="/assets/img/footer_brand_icon.png" class="me-3 img-fluid" width="32" alt=""/>
          <p class="opacity-50 fs-14 fw-medium">© 2023 e-Cadence. All rights reserved</p>
        </div>
      </div>
    </div>
  </footer>


</div>
