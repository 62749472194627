/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CadenceScheduleDto { 
    wigID: number;
    title: string;
    startTime: string;
    endTime: string;
    cycle?: CadenceScheduleDto.CycleEnum;
    cadenceTypeName?: string;
    dayOfTheWeek?: Array<CadenceScheduleDto.DayOfTheWeekEnum>;
    guests?: Array<string>;
    repeatEvery?: number;
    tillEndOfWig?: boolean;
    excludeWeekends?: boolean;
    maxRepeat?: number;
    dayOfMonth?: number;
}
export namespace CadenceScheduleDto {
    export type CycleEnum = 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'YEARLY';
    export const CycleEnum = {
        DAILY: 'DAILY' as CycleEnum,
        WEEKLY: 'WEEKLY' as CycleEnum,
        MONTHLY: 'MONTHLY' as CycleEnum,
        YEARLY: 'YEARLY' as CycleEnum
    };
    export type DayOfTheWeekEnum = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
    export const DayOfTheWeekEnum = {
        MONDAY: 'MONDAY' as DayOfTheWeekEnum,
        TUESDAY: 'TUESDAY' as DayOfTheWeekEnum,
        WEDNESDAY: 'WEDNESDAY' as DayOfTheWeekEnum,
        THURSDAY: 'THURSDAY' as DayOfTheWeekEnum,
        FRIDAY: 'FRIDAY' as DayOfTheWeekEnum,
        SATURDAY: 'SATURDAY' as DayOfTheWeekEnum,
        SUNDAY: 'SUNDAY' as DayOfTheWeekEnum
    };
}


