import { FormControl } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';
import {
  CountryCode,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength
} from 'libphonenumber-js';

/**
 *
 */
export function NgxTelPhoneNumberValidator() {
  return (control: FormControl): { [key: string]: any } | null => {
    const ngxPhoneNumber: NgxPhoneNumber = control.value;

    const e164Regex = '^\\+?[1-9]\\d{6,14}$';

    if (
      ngxPhoneNumber == null ||
      ngxPhoneNumber?.number == null ||
      ngxPhoneNumber?.number?.length < 1
    ) {
      return { validE164: true };
    }
    const phoneNumberUtil = PhoneNumberUtil.getInstance();

    const isValid: boolean =
      isPossiblePhoneNumber(ngxPhoneNumber.number, ngxPhoneNumber.countryCode) &&
      isValidPhoneNumber(ngxPhoneNumber.number, ngxPhoneNumber.countryCode) &&
      validatePhoneNumberLength(ngxPhoneNumber.number, ngxPhoneNumber.countryCode) === undefined &&
      phoneNumberUtil.isValidNumber(phoneNumberUtil.parse(ngxPhoneNumber.e164Number)) &&
      ngxPhoneNumber?.e164Number.match(e164Regex) &&
      (ngxPhoneNumber?.countryCode == 'NG' ? ngxPhoneNumber?.number.length > 9 : true);

    if (isValid) {
      return null;
    } else {
      return { validE164: true };
    }
  };
}

export interface NgxPhoneNumber {
  countryCode: CountryCode;
  dialCode: string;
  e164Number: string;
  internationalNumber: string;
  nationalNumber: string;
  number: string;
}
