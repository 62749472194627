<div
  class="p-2 position-relative"
  type="button"
  data-bs-toggle="dropdown"
  aria-expanded="false"
  style="background: #3285f834; border-radius: 3.28px"
>
  <span class="iconify fs-22 text-primary" data-icon="fluent:code-block-16-filled"></span>

  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
    {{ blockers?.length }}
    <span class="visually-hidden">unread messages</span>
  </span>

  <span class="dropdown-menu">
    <div class="card-header p-0 border-bottom-0 fw-bold  px-2">Unassigned Blocker</div>
    <div *ngIf="blockers.length > 0; else noData">
      <ng-container *ngFor="let blocker of blockers; let i = index">
        <ng-container *ngIf="i < _showLimit">
          <div (click)="showBlocker(blocker)" class="card p-3" style="width: 18rem">
<!--            <div class="card-header p-0 border-bottom-0 fw-bold">Blocker</div>-->
            <div class="card rounded-3 border-1">
              <div class="card-header d-flex justify-content-between p-2 fs-12">
                <p>{{ blocker?.raisedBy }}</p>
                <p class="text-muted fs-10">{{ blocker?.createdAt | shortestDate }}</p>
              </div>

              <span class="list-group list-group-flush px-2">
                <span class="fs-12 text-muted my-2">
                  {{ blocker?.description }}
                </span>

                <span
                  class="fw-bold fs-10"
                  [ngClass]="{'text-danger': blocker.resolutionStatus === 'PENDING',
            'text-warning': blocker.resolutionStatus === 'IN_PROGRESS',
            'text-success': blocker.resolutionStatus === 'RESOLVED',}"
                  >{{ blocker.resolutionStatus | underscoreToSpace }}</span
                >
              </span>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="blockers.length > _showLimit">
        <button (click)="goToBlockerSearch()" class="btn btn-primary float-end mt-3">Show All ({{ blockers.length }}) </button>
      </ng-container>
    </div>
    <ng-template #noData>
      <lottie-in-full-table [path]="'assets/lottie/no-data.json'" [message]="'No Unassigned Blocker'">
      </lottie-in-full-table>
    </ng-template>
  </span>
</div>
