import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TruncatePipe } from '../../pipes/truncate.pipe';

@Component({
  selector: 'custom-select-field',
  templateUrl: './custom-select-field.component.html',
  styleUrls: ['./custom-select-field.component.css'],
  providers: [TruncatePipe]
})
export class CustomSelectFieldComponent<T> implements OnChanges {
  constructor(private truncatePipe: TruncatePipe) {}
  @Input()
  set showRequiredRedIcon(value: boolean) {
    this._showRequiredRedIcon = value;
  }
  /**
   * @author Joshua Oyadokun
   * June 20, 2023 :: 21:31pm
   */

  /**
   * How it works:
   *
   * The CustomSelectFieldComponent component is a custom select field component that allows you to display a list of options in a select field.
   * The component takes in a list of data as input, and it then converts the data into a list of key-value pairs.
   * The key-value pairs are then used to populate the select field.
   *
   * The component also has a few other features, such as the ability to show a loading indicator while the data is loading,
   * and the ability to show a message if there are no options available.
   *
   * @param changes
   */

  /**
   * How to Use:
   *
   * Import the CustomSelectFieldComponent in the module where you want to use it.
   
   * import { CustomSelectFieldComponent } from 'path/to/custom-select-field.component';
   * Add the CustomSelectFieldComponent to the declarations array of your module.
   
   * @NgModule({
   *   declarations: [
   *     // ...
   *     CustomSelectFieldComponent,
   *     // ...
   *   ],
   *   // ...
   * })
   * export class YourModule { }
   * In your component's template, use the custom-select-field selector to include the custom select field.
   
   * <custom-select-field
   *   [form]="yourForm"
   *   [controlName]="lag"
   *   [name]="Your Field Name"
   *   [nameKey]="'name'"
   *   [valueKey]="'value'"
   *   [rawData]="yourDataArray"
   *   [isLoading]="isLoadingData"
   * ></custom-select-field>
   * Replace the following placeholders:
   *
   * yourForm: The instance of your Angular form group.
   * lag: The name of the form control associated with the select field.
   * YourFieldName: The display name for the select field.
   * yourDataArray: An array of objects containing the data for the select field options.
   * name: The property name in the objects of yourDataArray that represents the display name for each option.
   * value: The property name in the objects of yourDataArray that represents the value for each option.
   * isLoadingData: A boolean value indicating whether the data is currently being loaded.
   * That's it! You have now integrated the CustomSelectFieldComponent into your Angular application.
   * The component will handle rendering the select field and updating the options based on the provided data.
   * @param changes
   */

  ngOnChanges(changes: SimpleChanges): void {
    this.loadRefinedList();
  }
  _isLoading = false;
  _refinedData: KeyValuePojo[];
  _keyField: string;
  _valueField: string;
  _rawData: T[];

  @Input()
  form: FormGroup;

  @Input()
  controlName: string;

  @Input()
  name: string;

  @Input()
  truncateLength = true;

  @Input()
  maxLength = 35;

  _labelClass: string;

  _showRequiredRedIcon = true;

  @Input()
  set labelClass(value: string) {
    this._labelClass = value;
  }

  get keyField(): string {
    return this._keyField;
  }

  @Input()
  set keyField(value: string) {
    this._keyField = value;
  }

  get valueField(): string {
    return this._valueField;
  }

  @Input()
  set valueField(value: string) {
    this._valueField = value;
  }

  get rawData(): T[] {
    return this._rawData;
  }

  @Input()
  set rawData(value: T[]) {
    // console.log('Called set raw ', value);
    this._rawData = value;
    this.loadRefinedList();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  @Input()
  set isLoading(value: boolean) {
    this._isLoading = value;
  }

  private loadRefinedList(): void {
    const inputData = this.rawData;
    if (inputData) {
      this._refinedData = [];
      inputData.forEach((option) => {
        let key, value;
        if (typeof option === 'string') {
          key = option;
          value = option;
        } else if (typeof option === 'object') {
          const map = new Map(Object.entries(option));
          key = map.get(this.keyField);
          value = map.get(this.valueField);
        } else {
          throw new Error("Object type hasn't been implemented");
        }
        this._refinedData.push({ key: key, value: value });
      });
    }
  }

  getTruncatedValue(value: string): string {
    return this.truncatePipe.transform(value, this.maxLength);
  }
}

interface KeyValuePojo {
  key: string;
  value: string;
}
