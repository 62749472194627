import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { PageManager } from '../../services/page-manager';
import { AuthenticationService } from '../../services/authentication.service';
import { AccountMembershipPojo } from '../../../../sdk/ecadence-sdk';
import PermissionsEnum = AccountMembershipPojo.PermissionsEnum;

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private pageManager: PageManager,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authenticationService
      .getUser()
      .pipe(
        map((user) => {
          if (user == null) {
            return true;
          }
          const wigId = next.paramMap.get('id');
          if (wigId != null) {
            if (this.authenticationService.hasPermission(PermissionsEnum.VIEW_SCOREBOARD)) {
              return true;
            } else {
              this.router.navigate(['/dashboard']);
              return false;
            }
          }
          const allowedPermissions: string[] = next.data['allowedPermissions'];
          if (this.authenticationService.hasAnyPermission(allowedPermissions)) {
            return true;
          }
          this.router.navigate(['/forbidden']);
          return false;
        })
      )
      .pipe(
        catchError((err: unknown, caught: Observable<any>) => {
          return of(false);
        })
      );
  }
}
