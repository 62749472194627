import { commonEnv } from './environment.common';

const env: Partial<typeof commonEnv> = {
  production: true,
  keycloakConfig: {
    ...commonEnv.keycloakConfig,
    url: 'https://ecadence.ng/auth'
  }
};

export const environment = {
  ...commonEnv,
  ...env
};
