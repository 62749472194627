<div class="dash-new-container p-md-3">
  <div class="dash-new-banner mt-4 mb-5 py-md-5 py-4 ps-md-5 ps-3">
    <div class="pt-md-4 pt-3 pb-2">
      <h1 class="text-white">Good evening, Uche</h1>
    </div>
    <div class="text-white">Embrace success with eCadence right away!</div>
  </div>

  <div class="mb-5 d-flex justify-content-between align-items-center">
    <div class="col-md-6">
      <div class="row gy-3">
        <div class="col-md-6">
          <div
            class="p-4"
            style="border-radius: 8px; border-left: 2.5px solid #bb7ee3; background: #f3eafa"
          >
            <h5>Lead Measure Entry</h5>
            <div class="mb-3">Create commitments under WIGs</div>
            <div class="d-flex text-end ms-auto justify-content-end">
              <div class="p-1 me-1 rounded-5 lead-card">
                <span class="iconify fs-3" data-icon="fluent:chevron-right-16-regular"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="p-4"
            style="border-radius: 8px; border-left: 2.5px solid #e487af; background: #f8ecf1"
          >
            <h5>Help</h5>
            <div class="mb-3">Know more about eCadence</div>
            <div class="d-flex text-end ms-auto justify-content-end">
              <div class="p-1 me-1 rounded-5 lead-card-2">
                <span class="iconify fs-3" data-icon="fluent:chevron-right-16-regular"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- INSERT MEMBERS DIVS -->
    <div class="d-none d-md-block">Members</div>
  </div>

  <div class="mt-5">
    <div class="d-flex justify-content-between">
      <h5 style="color: #3644d0">WIGs Overview</h5>
      <a class="text-primary pointer">View all</a>
    </div>

    <div class="row mt-md-4 mt-3 gy-3">
      <div class="col-md-4">
        <a
          class="pointer"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasBottom"
          aria-controls="offcanvasBottom"
        >
          <div class="card overview-card">
            <div class="text-end mb-3">
              <span
                class="iconify fs-4 text-danger"
                data-icon="fluent:arrow-sync-dismiss-24-regular"
              ></span>
            </div>
            <h6>HA-NVS Release on the Minimum Required...</h6>

            <div class="row gy-1 my-2 pe-3 text-danger">
              <div class="col-md-5">
                <div class="fs-13 fw-medium">
                  Due by: <span class="fw-normal time-stamp-dashboard ms-1"> 23-09-2023</span>
                </div>
              </div>
              <div class="col-md-7 ps-md-5 pe-md-0">
                <div class="d-flex align-items-start">
                  <div class="fs-13 fw-medium">Time Left:</div>
                  <div class="ms-md-3 mt-3 mt-md-0">
                    <div class="d-flex align-items-center mb-3 ms-2">
                      <div>
                        <div class="time-stamp-dashboard mb-1">45</div>
                        <div style="text-align: right; font-size: 10px">Days</div>
                      </div>
                      <div class="ms-4">
                        <div class="time-stamp-dashboard mb-1">56</div>
                        <div style="text-align: right; font-size: 10px">Hr</div>
                      </div>
                      <div class="time-stamp-dashboard ms-2">:</div>
                      <div class="ms-4">
                        <div class="time-stamp-dashboard mb-1">00</div>
                        <div style="text-align: right; font-size: 10px">Min</div>
                      </div>
                      <div class="time-stamp-dashboard ms-2">:</div>
                      <div class="ms-4">
                        <div class="time-stamp-dashboard mb-1">00</div>
                        <div style="text-align: right; font-size: 10px">Secs</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-2 mb-3 d-flex align-items-center justify-content-center">
              <div class="d-flex align-items-center">
                <div class="d-flex">
                  <div class="p-1 rounded-5 miles-completed">
                    <span
                      class="iconify fs-3"
                      data-icon="fluent:arrow-sync-checkmark-24-regular"
                    ></span>
                  </div>
                </div>
                <div class="horizontal-miles-ongoing"></div>
              </div>
              <div class="d-flex align-items-center">
                <div class="d-flex">
                  <div class="p-1 rounded-5 miles-ongoing">
                    <span
                      class="iconify fs-3"
                      data-icon="fluent:arrow-sync-checkmark-24-regular"
                    ></span>
                  </div>
                </div>
                <div class="horizontal-miles-error"></div>
              </div>
              <div class="d-flex align-items-center">
                <div class="d-flex">
                  <div class="p-1 rounded-5 miles-error">
                    <span
                      class="iconify fs-3"
                      data-icon="fluent:arrow-sync-checkmark-24-regular"
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="py-3 border-top" style="border-top: 0.7px dashed #c4d8cc !important">
              <div class="progress" style="height: 6px">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="width: 25%; background-color: #d62b2b"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="fs-11 mt-2">WIG Progress and Status</div>
            </div>

            <!-- <div style="background-color: #e5e4e4; color: #4E3AA4; border-radius: 9px;" class="fs-11 px-2 pt-1 d-flex">
                        Add blocker
                    </div> -->

            <div class="text-end">
              <button
                style="background-color: #eeeeee; color: #4e3aa4; border-radius: 36px; border: none"
                class="fs-11 px-3 fw-medium"
              >
                Add blocker
              </button>
              <button
                style="background-color: #eee; color: #4e3aa4; border-radius: 36px; border: none"
                class="fs-11 fw-medium px-3 ms-2"
              >
                Suggest Lead Measure
              </button>
            </div>
          </div>
        </a>
      </div>
      <div class="col-md-4">
        <a class="pointer">
          <div class="card overview-card">
            <div class="text-end mb-3">
              <span
                class="iconify fs-4 text-success"
                data-icon="fluent:arrow-sync-checkmark-24-regular"
              ></span>
            </div>
            <h6>HA-NVS Release on the Minimum Required Lorem Ipsum Lorem Ipsum...</h6>

            <div class="row gy-1 my-2 pe-3">
              <div class="col-md-5 text-success">
                <div class="fs-13 fw-medium">
                  Completed: <span class="fw-normal time-stamp-dashboard ms-1"> 23-09-2023</span>
                </div>
              </div>
              <div class="col-md-7 ps-md-5 pe-md-0" style="color: #3a3a3a">
                <div class="d-flex align-items-start">
                  <div class="fs-13 fw-medium">Time Left:</div>
                  <div class="ms-md-3 mt-3 mt-md-0">
                    <div class="d-flex align-items-center mb-3 ms-2">
                      <div>
                        <div class="time-stamp-dashboard mb-1">00</div>
                        <div style="text-align: right; font-size: 10px">Days</div>
                      </div>
                      <div class="ms-4">
                        <div class="time-stamp-dashboard mb-1">00</div>
                        <div style="text-align: right; font-size: 10px">Hr</div>
                      </div>
                      <div class="time-stamp-dashboard ms-2">:</div>
                      <div class="ms-4">
                        <div class="time-stamp-dashboard mb-1">00</div>
                        <div style="text-align: right; font-size: 10px">Min</div>
                      </div>
                      <div class="time-stamp-dashboard ms-2">:</div>
                      <div class="ms-4">
                        <div class="time-stamp-dashboard mb-1">00</div>
                        <div style="text-align: right; font-size: 10px">Secs</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-2 mb-3 d-flex align-items-center justify-content-center">
              <div class="d-flex align-items-center">
                <div class="d-flex">
                  <div class="p-1 rounded-5 miles-completed">
                    <span
                      class="iconify fs-3"
                      data-icon="fluent:arrow-sync-checkmark-24-regular"
                    ></span>
                  </div>
                </div>
                <div class="horizontal-miles-ongoing"></div>
              </div>
              <div class="d-flex align-items-center">
                <div class="d-flex">
                  <div class="p-1 rounded-5 miles-ongoing">
                    <span
                      class="iconify fs-3"
                      data-icon="fluent:arrow-sync-checkmark-24-regular"
                    ></span>
                  </div>
                </div>
                <div class="horizontal-miles-error"></div>
              </div>
              <div class="d-flex align-items-center">
                <div class="d-flex">
                  <div class="p-1 rounded-5 miles-error">
                    <span
                      class="iconify fs-3"
                      data-icon="fluent:arrow-sync-checkmark-24-regular"
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="py-3 border-top" style="border-top: 0.7px dashed #c4d8cc !important">
              <div class="progress" style="height: 6px">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  style="width: 100%"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="fs-11 mt-2">WIG Progress and Status</div>
            </div>

            <!-- <div style="background-color: #e5e4e4; color: #4E3AA4; border-radius: 9px;" class="fs-11 px-2 pt-1 d-flex">
                        Add blocker
                    </div> -->

            <div class="text-end" style="opacity: 0.3; cursor: default">
              <button
                style="background-color: #eeeeee; color: #4e3aa4; border-radius: 36px; border: none"
                class="fs-11 px-3 fw-medium"
              >
                Add blocker
              </button>
              <button
                style="background-color: #eee; color: #4e3aa4; border-radius: 36px; border: none"
                class="fs-11 fw-medium px-3 ms-2"
              >
                Suggest Lead Measure
              </button>
            </div>
          </div>
        </a>
      </div>
      <div class="col-md-4">
        <a class="pointer">
          <div class="card overview-card">
            <div class="text-end mb-3">
              <span
                class="iconify fs-4 text-warning"
                data-icon="fluent:arrow-sync-checkmark-24-regular"
              ></span>
            </div>
            <h6>HA-NVS Release on the Minimum Required Lorem Ipsum Lorem Ipsum...</h6>

            <div class="row gy-1 my-2" style="color: #3a3a3a">
              <div class="col-md-5">
                <div class="fs-13 fw-medium">
                  Due by: <span class="fw-normal time-stamp-dashboard ms-1"> 23-09-2023</span>
                </div>
              </div>
              <div class="col-md-7 ps-md-5 pe-md-0">
                <div class="d-flex align-items-start">
                  <div class="fs-13 fw-medium">Time Left:</div>
                  <div class="ms-md-3 mt-3 mt-md-0">
                    <div class="d-flex align-items-center mb-3 ms-2">
                      <div>
                        <div class="time-stamp-dashboard text-primary mb-1">45</div>
                        <div style="text-align: right; font-size: 10px">Days</div>
                      </div>
                      <div class="ms-4">
                        <div class="time-stamp-dashboard mb-1">56</div>
                        <div style="text-align: right; font-size: 10px">Hr</div>
                      </div>
                      <div class="time-stamp-dashboard ms-2">:</div>
                      <div class="ms-4">
                        <div class="time-stamp-dashboard mb-1">00</div>
                        <div style="text-align: right; font-size: 10px">Min</div>
                      </div>
                      <div class="time-stamp-dashboard ms-2">:</div>
                      <div class="ms-4">
                        <div class="time-stamp-dashboard mb-1">00</div>
                        <div style="text-align: right; font-size: 10px">Secs</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-2 mb-3 d-flex align-items-center justify-content-center">
              <div class="d-flex align-items-center">
                <div class="d-flex">
                  <div class="p-1 rounded-5 miles-completed">
                    <span
                      class="iconify fs-3"
                      data-icon="fluent:arrow-sync-checkmark-24-regular"
                    ></span>
                  </div>
                </div>
                <div class="horizontal-miles-ongoing"></div>
              </div>
              <div class="d-flex align-items-center">
                <div class="d-flex">
                  <div class="p-1 rounded-5 miles-ongoing">
                    <span
                      class="iconify fs-3"
                      data-icon="fluent:arrow-sync-checkmark-24-regular"
                    ></span>
                  </div>
                </div>
                <div class="horizontal-miles-error"></div>
              </div>
              <div class="d-flex align-items-center">
                <div class="d-flex">
                  <div class="p-1 rounded-5 miles-error">
                    <span
                      class="iconify fs-3"
                      data-icon="fluent:arrow-sync-checkmark-24-regular"
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="py-3 border-top" style="border-top: 0.7px dashed #c4d8cc !important">
              <div class="progress" style="height: 6px">
                <div
                  class="progress-bar bg-warning"
                  role="progressbar"
                  style="width: 100%"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="fs-11 mt-2">WIG Progress and Status</div>
            </div>

            <!-- <div style="background-color: #e5e4e4; color: #4E3AA4; border-radius: 9px;" class="fs-11 px-2 pt-1 d-flex">
                        Add blocker
                    </div> -->

            <div class="text-end">
              <button
                style="background-color: #eeeeee; color: #4e3aa4; border-radius: 36px; border: none"
                class="fs-11 px-3 fw-medium"
              >
                Add blocker
              </button>
              <button
                style="background-color: #eee; color: #4e3aa4; border-radius: 36px; border: none"
                class="fs-11 fw-medium px-3 ms-2"
              >
                Suggest Lead Measure
              </button>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>

<!-- oFF-CANVAS FOR MILESTONE -->

<div
  class="offcanvas pt-2 ms-auto offcanvas-bottom"
  style="width: 500px; height: 630px"
  tabindex="-1"
  id="offcanvasBottom"
  aria-labelledby="offcanvasBottomLabel"
>
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasBottomLabel">WIG Milestones</h5>
    <button
      type="button"
      class="btn-close text-reset"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div class="offcanvas-body small">
    <div class="light-blue-bg">
      <h6 class="mb-2">HA-NVS Release Lorem Ipsum Lorem Ipsum</h6>
      <div class="col-md-4 mb-2">
        <div class="fs-13 fw-medium">
          Overall Score: <span class="fw-normal time-stamp-dashboard ms-1"> 43%</span>
        </div>
      </div>
      <div class="row gy-2">
        <div class="col-md-6">
          <div class="fs-13 fw-medium">
            Due Date: <span class="fw-normal time-stamp-dashboard ms-1">23-10-1990</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="d-flex align-items-center">
            <div class="fs-13 fw-medium">Customers:</div>
            <div class="ms-2">
              <div class="btn border-0 p-1 position-relative">
                <span class="iconify fs-5" data-icon="mdi:users"></span>
                <span
                  class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                >
                  2
                  <span class="visually-hidden">unread messages</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="mt-4">
        <div>
          <div class="d-flex align-items-center">
            <div class="p-1 me-1 rounded-5 miles-completed">
              <span class="iconify fs-3" data-icon="fluent:arrow-sync-checkmark-24-regular"></span>
            </div>
            <p class="mb-0 ms-2 fs-14 fw-normal">
              Lag 1 | <span class="text-success">Completed by : 23-10-1990</span>
            </p>
          </div>
        </div>
        <div class="vertical-miles-ongoing ms-3 pt-1" style="padding-left: 30px">
          <h6>Implement VRPS</h6>
        </div>
      </div>

      <div class="">
        <div>
          <div class="d-flex align-items-center">
            <div class="p-1 me-1 rounded-5 miles-ongoing">
              <span class="iconify fs-3" data-icon="fluent:arrow-sync-24-regular"></span>
            </div>
            <p class="mb-0 ms-2 fs-14 fw-normal">
              Lag 1 | <span class="text-warning">Due by : 23-10-1990</span>
            </p>
          </div>
        </div>
        <div class="vertical-miles-error ms-3 pt-1" style="padding-left: 30px">
          <h6>Implement MVRD</h6>
        </div>
      </div>

      <div class="">
        <div>
          <div class="d-flex align-items-center">
            <div class="p-1 me-1 rounded-5 miles-error">
              <span class="iconify fs-3" data-icon="fluent:arrow-sync-dismiss-24-regular"></span>
            </div>
            <p class="mb-0 ms-2 fs-14 fw-normal">
              Lag 1 | <span class="text-danger">Due Date : 23-10-1990</span>
            </p>
          </div>
        </div>
        <div class="vertical-miles-unstarted ms-3 pt-1" style="padding-left: 30px">
          <h6>Implement MVRD</h6>
        </div>
      </div>

      <div class="">
        <div>
          <div class="d-flex align-items-center">
            <div class="p-1 me-1 rounded-5 miles-unstarted">
              <span class="iconify fs-3" data-icon="fluent:dialpad-off-24-regular"></span>
            </div>
            <p class="mb-0 ms-2 fs-14 fw-normal">
              Lag 1 | <span style="color: #939393">Start Date : 23-10-1990</span>
            </p>
          </div>
        </div>
        <div class="ms-3 pt-1" style="padding-left: 32px">
          <h6>Implement MVRD</h6>
        </div>
      </div>
    </div>
  </div>
</div>
