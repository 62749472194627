import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { CountryISO } from '@capgo/ngx-intl-tel-input';
import {
  AccountMembershipPojo,
  AssignBlockerDto,
  BlockerControllerService,
  BlockerSearchDto,
  NameCodePojo,
  NameIdPojo,
  WigControllerService
} from '../../../../../../../sdk/ecadence-sdk';
import { XAlertMessage } from '../../../../../shared/components/alert-message/alert-message.component';
import { FormConstants, IsoNumber } from '../../../../../shared/constants/form-constants';
import { NgxTelPhoneNumberValidator } from '../../../../../shared/validators/ngx-tel-phone-number-validator';
import { NotBlankValidator } from '../../../../../shared/validators/not-blank.validator';
import { AuthenticationService } from '../../../../../services/authentication.service';

@Component({
  selector: 'assign-blocker-modal',
  templateUrl: './assign-blocker-modal.component.html',
  styleUrls: ['./assign-blocker-modal.component.css']
})
export class AssignBlockerModalComponent implements OnInit {
  get showNewInfluencerForm(): boolean {
    return this._showNewInfluencerForm;
  }

  _blockerDto: BlockerSearchDto;
  _showNewInfluencerForm = false;

  xAlertMessage: XAlertMessage = {
    message: undefined,
    messageType: 'info'
  };

  _blockerId: number;
  _loading = false;

  @Input()
  set blockerDto(value: BlockerSearchDto) {
    this.computeBlockerDto(value);
  }

  @Input()
  set blockerId(value: number) {
    this._blockerId = value;
    this._loading = true;
    this.blockerController
      .searchBlocker({ blockerSearchFilter: { blockerId: value } })
      .subscribe({
        next: (value) => {
          if (!value.empty && value.results?.length > 0) {
            this.computeBlockerDto(value.results[0]);
          }
        },
        error: (err: unknown) => {
          console.log('error');
        }
      })
      .add(() => {
        this._loading = false;
      });
  }

  _formGroup: FormGroup;
  _isFetchingUsers = false;
  _users: NameIdPojo[];

  _isFetchingExternalInfluencers = false;
  _externalInfluencers: NameIdPojo[];

  _isUser = true;

  countryAlpha = 'ng';
  selectedCountryISO: CountryISO = CountryISO.Nigeria;

  playerIdControl: AbstractControl;
  influencerIdControl: AbstractControl;
  nameControl: AbstractControl;
  emailAddressControl: AbstractControl;
  phoneNumberControl: AbstractControl;

  externalInfluencerControls: AbstractControl[];

  newInfluencerMap: Map<AbstractControl, ValidatorFn[]> = new Map<AbstractControl, ValidatorFn[]>();

  get isUser(): boolean {
    return this._isUser;
  }

  set isUser(value: boolean) {
    this._isUser = value;
    if (this._formGroup) {
      if (this.isUser) {
        this.playerIdControl.setValidators(Validators.required);
        this.playerIdControl.updateValueAndValidity();

        for (const control of this.externalInfluencerControls) {
          control.setValue('');
          control.clearValidators();
          control.updateValueAndValidity();
        }
        this._showNewInfluencerForm = false;
      } else {
        this.influencerIdControl.setValidators(Validators.required);
        this.influencerIdControl.updateValueAndValidity();

        this.playerIdControl.setValue('');
        this.playerIdControl.clearValidators();
        this.playerIdControl.updateValueAndValidity();
      }
    }
  }

  _isAssigning = false;
  _isResolving = false;

  get assignedTo(): string {
    return this._blockerDto?.assignedTo;
  }

  nameValidations: ValidatorFn[] = [
    Validators.required,
    NotBlankValidator.validate,
    Validators.minLength(FormConstants.NAME_MINIMUM_LENGTH),
    Validators.pattern(/^[^0-9!@#$%^&*()_+=[\]{};':"\\|,.<>/?~`]*$/),
    Validators.maxLength(FormConstants.NAME_MAXIMUM_LENGTH)
  ];

  emailValidation: ValidatorFn[] = [
    Validators.required,
    Validators.pattern(FormConstants.EMAIL_REGEX)
  ];

  phoneNumberValidation: ValidatorFn[] = [
    Validators.required,
    NgxTelPhoneNumberValidator(),
    NotBlankValidator.validate
  ];

  constructor(
    private formBuilder: FormBuilder,
    private wigController: WigControllerService,
    private blockerController: BlockerControllerService,
    private modalRef: BsModalRef,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this._formGroup = this.formBuilder.group({
      playerId: [''],
      influencerId: [''],
      name: ['', this.nameValidations],
      emailAddress: ['', this.emailValidation],
      phoneNumber: ['']
    });

    this.playerIdControl = this._formGroup.get('playerId');
    this.influencerIdControl = this._formGroup.get('influencerId');
    this.nameControl = this._formGroup.get('name');
    this.emailAddressControl = this._formGroup.get('emailAddress');
    this.phoneNumberControl = this._formGroup.get('influencerId');

    this.externalInfluencerControls = [
      this.influencerIdControl,
      this.nameControl,
      this.emailAddressControl,
      this.phoneNumberControl
    ];

    this.newInfluencerMap = new Map<AbstractControl, ValidatorFn[]>();
    this.newInfluencerMap.set(this.nameControl, this.nameValidations);
    this.newInfluencerMap.set(this.emailAddressControl, this.emailValidation);
    this.newInfluencerMap.set(this.phoneNumberControl, this.phoneNumberValidation);
  }

  fetchUser(widId: number): void {
    this._isFetchingUsers = true;
    this.wigController
      .getUsernameAndPlayerIdForWig({ id: widId })
      .subscribe({
        next: (players: NameIdPojo[]) => {
          this._users = players;
        }
      })
      .add(() => {
        this._isFetchingUsers = false;
      });
  }

  fetchExternalInfluencer(widId: number): void {
    this._isFetchingExternalInfluencers = true;
    this.wigController
      .getExternalInfluencer({ id: widId })
      .subscribe({
        next: (players: NameCodePojo[]) => {
          this._externalInfluencers = players;
        }
      })
      .add(() => {
        this._isFetchingExternalInfluencers = false;
      });
  }

  closeModal(): void {
    this.modalRef.hide();
  }

  assignBlocker(): void {
    this._formGroup.markAllAsTouched();
    if (this._formGroup.invalid) {
      return;
    }

    this._isAssigning = true;
    console.log(this._formGroup.value);
    const formObject: AssignBlockerDto = this._formGroup.value as AssignBlockerDto;
    console.log(formObject);
    let phonenumber;

    if (formObject.phoneNumber) {
      phonenumber = (formObject.phoneNumber as unknown as IsoNumber).e164Number;
    }
    this.blockerController
      .assignBlocker({
        id: this._blockerDto.id,
        assignBlockerDto: {
          ...this._formGroup.value,
          phoneNumber: phonenumber
        }
      })
      .subscribe({
        next: (v) => {
          this.xAlertMessage.messageType = 'success';
          let assignedName;

          if (formObject.playerId) {
            console.log(this._users);
            assignedName = this._users
              .filter((x) => x.id == formObject.playerId)
              .map((y) => y.name)[0];
          } else {
            assignedName = this._externalInfluencers
              .filter((x) => x.id == formObject.influencerId)
              .map((y) => y.name)[0];
          }

          const assignedTo: string = assignedName || formObject.name;

          this.xAlertMessage.message = `Blocker has been assigned to ${assignedTo} `;
          this._blockerDto.resolutionStatus = 'IN_PROGRESS';
          this._blockerDto.assignedTo = assignedTo;
        },
        error: (err: unknown) => {
          this.xAlertMessage.messageType = 'danger';
          this.xAlertMessage.message =
            err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
              ? err.error.message
              : 'Unable to assign blocker';
        }
      })
      .add(() => {
        this._isAssigning = false;
      });
  }

  markAsResolved(): void {
    this._isResolving = true;
    this.blockerController
      .markAsResolved({
        id: this._blockerDto.id,
        isResolved: true
      })
      .subscribe({
        next: (v) => {
          this.xAlertMessage.messageType = 'success';
          this.xAlertMessage.message = `Blocker has been marked as resolved`;
          this._blockerDto.resolutionStatus = 'RESOLVED';
        },
        error: (err: unknown) => {
          this.xAlertMessage.messageType = 'danger';
          this.xAlertMessage.message =
            err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
              ? err.error.message
              : 'Unable to mark blocker as resolved';
        }
      })
      .add(() => {
        this._isResolving = false;
      });
  }

  fixInfluencerFormValidations(): void {
    if (this.showNewInfluencerForm) {
      for (const keu of this.newInfluencerMap.keys()) {
        keu.setValidators(this.newInfluencerMap.get(keu));
        keu.updateValueAndValidity();
      }
      this.influencerIdControl.setValue('');
      this.influencerIdControl.clearValidators();
      this.influencerIdControl.updateValueAndValidity();
    } else {
      for (const keu of this.newInfluencerMap.keys()) {
        keu.setValue('');
        keu.clearValidators();
        keu.updateValueAndValidity();
      }
      this.phoneNumberControl.setValue('');
      this.phoneNumberControl.clearValidators();
      this.phoneNumberControl.updateValueAndValidity();

      this.influencerIdControl.setValidators(Validators.required);
      this.influencerIdControl.updateValueAndValidity();
    }
  }

  toggleNewInfluencerForm(): void {
    this._showNewInfluencerForm = !this._showNewInfluencerForm;
    this.fixInfluencerFormValidations();
  }

  get canAssignBlocker(): boolean {
    return this.authenticationService.hasPermission(
      AccountMembershipPojo.PermissionsEnum.ASSIGN_BLOCKER
    );
  }

  computeBlockerDto(value: BlockerSearchDto): void {
    this._blockerDto = value;
    this.fetchUser(this._blockerDto.wigId);
    this.fetchExternalInfluencer(this._blockerDto.wigId);
  }
}
