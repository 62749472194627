import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import moment from 'moment/moment';
import { XAlertMessage } from '../../../../shared/components/alert-message/alert-message.component';
import {
  CadenceControllerService,
  CommittmentDto,
  LagControllerService,
  LagMeasureBenchmarkPojo,
  LagMeasureControllerService,
  LagMeasurePojo,
  LeadMeasureBenchmarkPojo,
  LeadMeasurePojo,
  NameIdPojo,
  PlayerLeadMeasureScorePojo,
  WigControllerService
} from '../../../../../../sdk/ecadence-sdk';
import { AuthenticationService } from '../../../../services/authentication.service';
import { CustomValidators } from '../../../../shared/custom-validators';
import { SuccessDialogComponent } from '../../../../shared/components/success-dialog/success-dialog.component';
import { CadenceSchedulerService } from '../../../../services/cadence-scheduler.service';
import { removeUndefinedOrNullFields } from '../../../../shared/utils/utils';
import { FormConstants } from '../../../../shared/constants/form-constants';
import { CountryIsoMapper } from '../../../../shared/utils/country-iso-mapper';
import valueOf = CountryIsoMapper.valueOf;

@Component({
  selector: 'add-commitment',
  templateUrl: './add-commitment.component.html',
  styleUrls: ['./add-commitment.component.scss']
})
export class AddCommitmentComponent {
  datePickerConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-dark-blue',
    showWeekNumbers: false,
    dateInputFormat: 'YYYY-MM-DD'
  };
  xAlertMessage: XAlertMessage = {
    messageType: 'info',
    message: undefined
  };

  @Input()
  wigId;

  @Output()
  commitmentAddedEvent: EventEmitter<CommittmentDto[]> = new EventEmitter<CommittmentDto[]>();

  commitmentsList: CommittmentDto[] = [];

  lagMeasureUI: LagMeasurePojo[];
  leadMeasureUI: LeadMeasurePojo[];

  playerWigsPojo: NameIdPojo[];
  lagUI: NameIdPojo[];
  lagMeasureOnly: NameIdPojo[];

  form: FormGroup;
  selectedLagMeasure: LagMeasurePojo;
  selectedLeadMeasure: LeadMeasurePojo;

  fetchingLagMeasures = false;
  fetchingLeadMeasures = false;
  fetchingWigs = false;
  fetchingLagsAndItsMeasures = false;

  dueDate: string;
  errorMessage: string;
  playerScorePojo: PlayerLeadMeasureScorePojo;
  private cadenceEventId: number;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private cadenceControllerService: CadenceControllerService,
    private lagmeasureService: LagMeasureControllerService,
    private bsModalRef: BsModalRef,
    private cadenceService: CadenceSchedulerService,
    private wigService: WigControllerService,
    private lagService: LagControllerService
  ) {}

  ngOnInit(): void {
    this.subscribeToCadenceEvent();
    this.form = this.fb.group({
      commitmentText: [
        '',
        [
          Validators.required,
          CustomValidators.notBlank,
          Validators.maxLength(250),
          CustomValidators.minLength(3)
        ]
      ],
      playerScore: [''],
      lag: [''],
      lagMeasure: [''],
      leadMeasure: [''],
      dueTime: [''],
      dueDate: [''],
      jiraId: ['']
    });

    if (this.wigId !== null) {
      this.getLagsForWig(this.wigId);
      // this.cadenceControllerService
      //   .getLagMeasures({ wigId: this.wigId })
      //   .subscribe(
      //     (value) => {
      //       this.fetchingLagMeasures = false;
      //       this.lagMeasureUI = value;
      //     },
      //     (error: unknown) => {
      //       this.showMessage(
      //         error instanceof HttpErrorResponse && error.error?.message && error.error.code !== 500
      //           ? error.error.message
      //           : 'Unable to fetch lag measures',
      //         'danger'
      //       );
      //     }
      //   )
      //   .add(() => (this.fetchingLagMeasures = false));
    } else {
      this.cadenceControllerService
        .getPlayerWigs()
        .subscribe(
          (value) => {
            this.fetchingWigs = false;
            this.playerWigsPojo = value;
          },
          (error: unknown) => {
            this.showMessage(
              error instanceof HttpErrorResponse && error.error?.message && error.error.code !== 500
                ? error.error.message
                : 'Could not fetch categories.',
              'danger'
            );
          }
        )
        .add(() => (this.fetchingWigs = false));
    }

    this.form.get('lag').valueChanges.subscribe((v) => {
      if (v) {
        this.getLagMeasuresForLagWithBenchmacks(v);
        console.log('value og lag is: ', v);
      }
    });

    // this.form.get('lagMeasure').valueChanges.subscribe((v) => {
    //   if (v) {
    //     const selectedLag = this.lagMeasureUI.find(
    //       (item) => item.lagMeasureId === Number(v)
    //     );
    //     this.selectedLagMeasure = selectedLag;
    //     console.log('value og lag is: ', selectedLag);
    //   }
    // });
  }

  subscribeToCadenceEvent(): void {
    this.cadenceService.cadenceEventId.subscribe((v) => {
      this.cadenceEventId = v;
    });
  }

  fetchleadmeasures(lagId: number): void {
    this.fetchingLeadMeasures = true;
    this.cadenceControllerService
      .getLeadMeasuresForPlayer({ lagId: lagId })
      .subscribe(
        (value) => {
          this.fetchingLeadMeasures = false;
          this.leadMeasureUI = value;
        },
        (error: unknown) => {
          this.showMessage(
            error instanceof HttpErrorResponse && error.error?.message && error.error.code !== 500
              ? error.error.message
              : 'Could not fetch sub categories.',
            'danger'
          );
        }
      )
      .add(() => (this.fetchingLeadMeasures = false));
  }

  fetchPlayerScore(leadMeasureId: number): void {
    this.cadenceControllerService
      .getPlayerLeadMeasureScore({ leadMeasureId: leadMeasureId })
      .subscribe(
        (value) => {
          this.playerScorePojo = value;
        },
        (error: unknown) => {
          this.showMessage(
            error instanceof HttpErrorResponse && error.error?.message && error.error.code !== 500
              ? error.error.message
              : 'Could not fetch sub categories.',
            'danger'
          );
        }
      );
  }

  saveCommittments(): void {
    if (this.commitmentsList.length == 0) {
      return;
    }
    this.cadenceControllerService
      .createCommittments({ id: this.wigId, committmentDto: this.commitmentsList })
      .subscribe(
        (value) => {
          this.modalService.show(SuccessDialogComponent, {
            initialState: {
              onClose: (): void => this.onSuccessClose(this.commitmentsList),
              message: 'Successfully made committments',
              title: 'Commitments'
            },
            ignoreBackdropClick: true,
            class: 'modal-sm modal-dialog-centered'
          });
          // this.commitmentsList.splice(0, this.commitmentsList.length);
        },
        (error: unknown) => {
          this.showMessage(
            error instanceof HttpErrorResponse && error.error?.message && error.error.code !== 500
              ? error.error.message
              : 'Something went wrong. Please try again later.',
            'danger'
          );
        }
      );
  }

  onSuccessClose(committments: CommittmentDto[]): void {
    this.bsModalRef.hide();
    this.commitmentAddedEvent.emit(committments);
  }

  addCommitmentsToList(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    console.log('jira is is: ', this.form.get('jiraId').value);

    const selectedLag = this.lagMeasureUI.find(
      (x) => x.lagMeasureId === Number(this.form.get('lagMeasure')?.value)
    );
    const selectedLead = this.leadMeasureUI.find(
      (x) => x.leadMeasureId === Number(this.form.get('leadMeasure')?.value)
    );

    const due = moment(
      this.formatDateAndTime(this.form.get('dueDate').value, this.form.get('dueTime').value),
      'MMMM DD, YYYY h:mm A'
    ).format('YYYY-MM-DDTHH:mm');
    const item = {
      lagMeasureName: selectedLag.lagMeasureName,
      leadmeasureName: selectedLead.unit,
      commitments: this.form.get('commitmentText').value,
      playerScore: this.form.get('playerScore').value,
      jiraId: this.form.get('jiraId').value,
      leadMeasureScore: selectedLead.currentValue,
      dueAt: due,
      leadMeasureId: selectedLead.leadMeasureId,
      lagMeasureId: selectedLag.lagMeasureId,
      cadenceEventId: this.cadenceEventId ?? ''
    } as CommittmentDto;
    removeUndefinedOrNullFields(item);
    this.commitmentsList.push(item);
    this.form.reset({
      lagMeasureName: '',
      leadmeasureName: '',
      commitments: '',
      playerScore: '',
      jiraId: ''
    });
  }

  removeCommitment($event: CommittmentDto): void {
    this.commitmentsList.splice(this.commitmentsList.indexOf($event), 1);
  }

  handleSelection($event: any): void {
    this.selectedLeadMeasure = null;
    this.form.get('leadMeasure').patchValue('');
    this.leadMeasureUI = null;

    if ($event.target.value) {
      const selectedLag = this.lagMeasureUI.find(
        (item) => item.lagMeasureId === Number($event.target.value)
      );
      this.selectedLagMeasure = selectedLag;
      this.fetchleadmeasures($event.target.value);
    }
  }

  playerScore($event: any): void {
    if ($event) {
      this.selectedLeadMeasure = this.leadMeasureUI.find(
        (item) => item.leadMeasureId === Number($event.target.value)
      );
      this.fetchPlayerScore($event.target.value);
    }
  }

  getFormControlName(tag: HTMLLabelElement): string {
    return tag.control.attributes.getNamedItem('formcontrolname').nodeValue;
  }

  getFormattedDate(date: Date): string {
    return date.toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    });
  }

  getFormattedTime(date: Date): string {
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  }

  formatDateAndTime(date: Date, time: Date): string {
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    const timeFormatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(time);

    return `${formattedDate} ${formattedTime}`;
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

  getMaxCommitmentScore(): number {
    return Number.MAX_VALUE;
    /*(
      this.selectedLeadMeasure?.leadMeasureBenchmark[
        this.selectedLeadMeasure?.leadMeasureBenchmark.length - 1
      ]?.targetValue ?? 0
    );*/
  }

  getLeadBenchmarkToMeet(): LeadMeasureBenchmarkPojo {
    const benchmarksAboveScore = this.selectedLeadMeasure.leadMeasureBenchmark.filter(
      (benchmark) =>
        benchmark.targetValue !== undefined &&
        benchmark.targetValue > this.selectedLeadMeasure.currentValue
    );

    if (benchmarksAboveScore.length === 0) {
      return undefined; // No benchmark above the lead measure score
    }
    benchmarksAboveScore.sort((a, b) => a.targetValue! - b.targetValue!);
    return benchmarksAboveScore[0];
  }

  getLagBenchmarkToMeet(): LagMeasureBenchmarkPojo {
    const benchmarksAboveScore = this.selectedLagMeasure.lagMeasureBenchmark.filter(
      (benchmark) =>
        benchmark.targetValue !== undefined &&
        benchmark.targetValue > this.selectedLagMeasure.currentValue
    );

    if (benchmarksAboveScore.length === 0) {
      return undefined; // No benchmark above the lead measure score
    }
    benchmarksAboveScore.sort((a, b) => a.targetValue! - b.targetValue!);
    return benchmarksAboveScore[0];
  }

  protected readonly FormConstants = FormConstants;

  showMessage(error: any, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
    this.xAlertMessage.messageType = type;
    this.xAlertMessage.message = error;
    setTimeout(() => {
      this.xAlertMessage.message = undefined;
    }, 4000);
  }

  getWigLagMeasures(wigId: number): void {
    //this.fetchingLagsAndItsMeasures = true;
    this.cadenceControllerService
      .getLagMeasures({ wigId })
      .subscribe(
        (value) => {
          this.lagMeasureUI = value;
          const uniqueLagIds = new Set();
          this.lagUI = [];
          value.forEach((x: LagMeasurePojo) => {
            if (!uniqueLagIds.has(x.lagId)) {
              console.log('new lag id of ', x.lagId);
              const lag: NameIdPojo = {
                id: x.lagId,
                name: x.lagName
              };
              uniqueLagIds.add(x.lagId);
              console.log(uniqueLagIds);
              this.lagUI.push(lag);
            } else {
              console.log('it has ', x.lagId);
            }
          });
          console.log(this.lagUI);
          this.lagMeasureOnly = value.map((x: LagMeasurePojo) => {
            const lagMeasure: NameIdPojo = {
              id: x.lagMeasureId,
              name: x.lagMeasureName
            };
            return lagMeasure;
          });
        },
        (error: unknown) => {
          if (
            error instanceof HttpErrorResponse &&
            error.error &&
            typeof error.error == 'object' &&
            error.error.message
          ) {
            this.xAlertMessage = error.error.message;
            return;
          }
          this.errorMessage = 'Could not fetch categories.';
        }
      )
      .add(() => {
        this.fetchingLagsAndItsMeasures = false;
      });
  }

  getLagsForWig(wigId: number): void {
    this.wigService.getLagsForWig({ id: wigId }).subscribe(
      (value) => {
        this.lagUI = value;
      },
      (error: unknown) => {
        if (
          error instanceof HttpErrorResponse &&
          error.error &&
          typeof error.error == 'object' &&
          error.error.message
        ) {
          this.xAlertMessage = error.error.message;
        }
      }
    );
  }

  getLagMeasuresForLag(lagId: number): void {
    this.lagService.getMeasuresForLag({ id: lagId }).subscribe(
      (value) => {
        this.lagMeasureOnly = value;
      },
      (error: unknown) => {
        if (
          error instanceof HttpErrorResponse &&
          error.error &&
          typeof error.error == 'object' &&
          error.error.message
        ) {
          this.xAlertMessage = error.error.message;
        }
      }
    );
  }

  getLagMeasuresForLagWithBenchmacks(lagId: number): void {
    this.lagmeasureService.getMeasuresAndBenchmarksForLag({ lagId: lagId }).subscribe(
      (value) => {
        this.lagMeasureUI = value;
      },
      (error: unknown) => {
        if (
          error instanceof HttpErrorResponse &&
          error.error &&
          typeof error.error == 'object' &&
          error.error.message
        ) {
          this.xAlertMessage = error.error.message;
        }
      }
    );
  }
}
