<div
>
<!--  <span class="iconify fs-22 text-primary" data-icon="fluent:code-block-16-filled"></span>-->



  <span class="dropdown-menu dropdown-menu-end px-2 overflow-y-scroll" style="max-height: 300px">
    <div  *ngIf="usersWithoutWig?.length > 0; else empty">
      <div class="card p-2 overflow-y-auto mb-3" style="width: 18rem" *ngFor="let user of usersWithoutWig">
<!--        <div class="card-header p-0 border-bottom-0 fw-bold">User</div>-->

        <div class=" rounded-3 border-1">
          <div class=" d-flex flex-column justify-content-between p-2 fs-12">
            <p class="mb-0">Name: {{ user.firstName }} {{user.lastName}}</p>
            <p class="text-muted mb-0 fs-10">Email Address: {{ user.emailAddress }}</p>
          </div>

<!--          <span class="list-group list-group-flush px-2">-->
<!--&lt;!&ndash;            <span class="fs-12 text-muted my-2">&ndash;&gt;-->
<!--&lt;!&ndash;              {{ blocker?.description }}&ndash;&gt;-->
<!--&lt;!&ndash;            </span>&ndash;&gt;-->

<!--            <span class="fw-bold text-danger fs-10" *ngIf="blocker.resolutionStatus !== 'RESOLVED'"-->
<!--            >Not Resolved</span-->
<!--            >-->
<!--            <span class="fw-bold text-success fs-10" *ngIf="blocker.resolutionStatus === 'RESOLVED'"-->
<!--            >Resolved</span-->
<!--            >-->
<!--          </span>-->
        </div>
      </div>
    </div>
    <ng-template #empty>
      <span class="text-center fs-12 text-muted">No users without assigned wig</span>
    </ng-template>
  </span>
</div>

