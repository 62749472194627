import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TimerObject } from '../../pages/intranet/wig-mgt/wig-profile/wig-profile.component';
import { TimerService } from '../../services/timer.service';

@Pipe({
  name: 'arrayreload',
  pure: false
})
export class ObjectReloadPipe implements PipeTransform {
  constructor(private timerService: TimerService) {}

  transform(value: Array<TimerObject>, index: number, ...args: unknown[]): TimerObject {
    return value[index];
  }
}
