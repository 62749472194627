<nav class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <a class="navbar-brand pointer" (click)="toDashboard()"
      ><img src="/assets/img/logo.svg" class="img-fluid" alt="" style="width: 130px"
    /></a>
    <button
      class="navbar-toggler bg-light"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item">
          <a (click)="toDashboard()" class="nav-link active p-1 pointer" aria-current="page">
            <span class="iconify" data-icon="basil:apps-outline"></span>
            Dashboard</a
          >
        </li>
        <li class="nav-item" *ngIf="canViewUsers()">
          <a class="nav-link p-1 pointer" (click)="toUsers()"
            ><span class="iconify" data-icon="basil:contacts-outline"></span> Users</a
          >
        </li>
        <!--        <li class="nav-item">-->
        <!--          <a class="nav-link p-1">-->
        <!--            <span class="iconify" data-icon="tabler:steam"></span>-->
        <!--            Teams</a-->
        <!--          >-->
        <!--        </li>-->
        <li class="nav-item" *ngIf="canViewWig">
          <a class="nav-link p-1 pointer" (click)="toWig()">
            <span class="iconify" data-icon="octicon:goal-24"></span> WIG Management</a
          >
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle p-1"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span class="iconify" data-icon="gg:more-o"></span>
            More
          </a>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item pointer" (click)="toMasterRecord()">Master Record</a>
            </li>
            <li (click)="toManageBlocker()">
              <a class="dropdown-item pointer" (click)="toManageBlocker()">Manage Blockers</a>
            </li>
            <li (click)="toPlayerDailyProfile()">
              <a class="dropdown-item pointer" (click)="toPlayerDailyProfile()">Analytics</a>
            </li>
          </ul>
        </li>
      </ul>
      <div class="d-flex mt-4 mt-md-0 align-items-center text-light px-md-4">
        <div class="me-4 dropdown-center">
          <button
            class="btn p-0 position-relative pointer"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span class="iconify fs-3 text-light" data-icon="basil:notification-solid"></span>
            <span
              *ngIf="this.notificationCount > 0"
              class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
            >
              {{ this.notificationCount }}
              <span class="visually-hidden">unread messages</span>
            </span>
          </button>

          <ul
            class="dropdown-menu dropdown-menu-end bg-transparent border-0 py-0 overflow-y-auto shadow-none mt-0"
            style="border-bottom: 3px solid #00f !important"
            [ngClass]="{ 'notification-style': notifications?.length > 0 }"
          >
            <!--            <p class="fw-bold mb-2">Notifications</p>-->
            <ng-container *ngIf="notifications?.length > 0; else noPendingNotifications">
              <li
                class="pb-2 px-4 pt-1 my-2 rounded-1 bg-light"
                style="border: 1px solid #4d4d4d33"
                *ngFor="let pendingNotification of notifications"
              >
                <a class="text-decoration-none pointer">
                  <!--                  </a></li>-->
                  <div class="card-header border-bottom justify-content-between d-flex mt-2">
                    <div
                      class="mb-0 fs-13 d-flex"
                      (click)="markNotificationAsRead(pendingNotification)"
                    >
                      <span class="fs-10 me-1" [popover]="'Mark as read'">
                        <span class="iconify" data-icon="material-symbols:mark-as-unread"></span>
                      </span>
                      <strong class="me-auto text-muted">{{ pendingNotification.title }}</strong>
                    </div>

                    <p class="mb-0 fs-12 text-muted">
                      <small>{{ pendingNotification.createdAt | shortestDate }}</small>
                    </p>
                  </div>
                  <div class="card-body py-2" (click)="viewMore(pendingNotification)">
                    <p class="card-text mb-0 text-muted fw-normal text-truncate fs-13">
                      {{ pendingNotification.body || '' }}
                    </p>
                    <p class="card-text mb-0 small text-muted fw-normal"></p>
                  </div>
                </a>
              </li>
            </ng-container>
            <ng-template #noPendingNotifications>
              <div class="text-dark my-2 mt-3">
                <p class="card-text mb-0 small text-muted fw-normal text-center">
                  No new notifications
                </p>
              </div>
            </ng-template>
          </ul>
        </div>
        <p class="mb-0 me-2">My Profile</p>
        <button
          class="btn nav-drop p-1"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span class="iconify text-light" data-icon="fluent:caret-down-12-filled"></span>
        </button>

        <ul class="dropdown-menu dropdown-menu-end account-dropdown user-dropdown-menu">
          <div class="">
            <div class="mx-auto img-thumbnail-circle mx-1">
              <ng-container *ngIf="!_loadingImage; else spinner">
                <ng-container *ngIf="_profilePicture; else initialPlaceHolder">
                  <img
                    [src]="_profilePicture"
                    alt=""
                    class="rounded-circle img-fluid"
                    style="
                      height: 75px;
                      width: 75px;
                      position: absolute;
                      left: 50%;
                      top: 50%;
                      transform: translate(-50%, -50%);
                    "
                  />
                </ng-container>
              </ng-container>

              <ng-template #initialPlaceHolder>
                <div *ngIf="!_profilePicture">
                  <span
                    style="
                      position: absolute;
                      left: 50%;
                      top: 50%;
                      transform: translate(-50%, -50%);
                      font-weight: 500;
                    "
                    >{{
                      user?.firstName.charAt(0).toUpperCase() +
                        user?.lastName.charAt(0).toUpperCase()
                    }}</span
                  >
                </div>
              </ng-template>
            </div>
            <div class="text-center">
              <div class="mt-3 fs-18" style="font-weight: 500; color: #313131">
                {{ user?.firstName + ' ' + user?.lastName }}
              </div>
              <div class="mt-1 fs-14" style="color: #555">{{ user?.email }}</div>
              <div style="color: #7608e4; font-size: 17px; font-weight: 500" class="mt-2 mb-3">
                {{ currentAccount?.accountName | titlecase }}
              </div>
            </div>
            <div>
              <div
                *ngIf="userAccounts.length > 1"
                class="py-3 px-3"
                style="
                  border-top: 0.6px solid rgba(55, 60, 190, 0.6);
                  border-bottom: 0.6px solid #373cbe;
                "
              >
                <div style="color: #888; font-size: 12px" class="mb-3">Other accounts</div>
                <ng-container *ngFor="let account of userAccounts">
                  <div
                    *ngIf="account.code !== currentAccount.accountCode"
                    class="d-flex justify-content-between pb-3 dropdown-item pointer"
                  >
                    <div style="color: #28014f; font-weight: 500">
                      {{ account.name | titlecase }}
                    </div>
                    <div><span class="iconify" data-icon="mdi-light:chevron-right"></span></div>
                  </div>
                </ng-container>
                <!--<div class="d-flex justify-content-between pb-3 dropdown-item pointer">
                  <div style="color: #28014F; font-weight: 500;">Super Admin</div>
                  <div><span class="iconify" data-icon="mdi-light:chevron-right"></span></div>
                 </div> -->
                <div class="d-flex justify-content-between pb-3 dropdown-item pointer">
                  <div style="color: #28014f; font-weight: 500">Super Admin</div>
                  <div><span class="iconify" data-icon="mdi-light:chevron-right"></span></div>
                </div>
                <div class="d-flex justify-content-between pb-3 dropdown-item pointer">
                  <div style="color: #28014f; font-weight: 500">Super Admin</div>
                  <div><span class="iconify" data-icon="mdi-light:chevron-right"></span></div>
                </div>
              </div>
            </div>
            <div>
              <div
                class="py-3 px-3"
                style="
                  border-top: 0.6px solid rgba(55, 60, 190, 0.6);
                  border-bottom: 0.6px solid #373cbe;
                "
              >
                <div style="color: #888; font-size: 12px">Settings</div>
                <div
                  routerLink="/profile"
                  class="d-flex justify-content-between pb-3 dropdown-item pointer"
                >
                  <div style="color: #28014f; font-weight: 500">Profile</div>
                  <div><span class="iconify" data-icon="mdi-light:chevron-right"></span></div>
                </div>
              </div>
            </div>
            <div
              class="text-center py-2 logout"
              style="border-top: 0.6px solid rgba(55, 60, 190, 0.6)"
            >
              <a class="text-danger pointer fs-15 mt-1 pointer" (click)="logout()">Logout</a>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
</nav>

<ng-template #spinner>
  <div
    class="d-flex justify-content-center align-items-center"
    *ngIf="_loadingImage"
    style="
      height: 168px;
      width: 168px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    "
  >
    <span
      class="iconify"
      data-icon="eos-icons:loading"
      style="color: #6610ff"
      data-rotate="90deg"
    ></span>
  </div>
</ng-template>
