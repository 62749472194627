<div class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="modal-header pt-4 pb-3">
      <h6 class="modal-title" id="previewLeadScoreLabel">Preview Update</h6>
      <button
        (click)="close()"
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <div class="row gy-5">
        <div class="col-12">
          <div>
            <div class="fs-15 fw-bold text-dark mb-2">WIG</div>
            <div>{{ dto?.wigName || 'Testing and implementing all the apps by 30th May' }}</div>
          </div>
        </div>
        <div class="col-12">
          <div>
            <div class="fs-15 text-dark fw-bold mb-2">Lag</div>
            <div>{{ dto?.lagName || 'Testing and implementing all the apps by 30th May' }}</div>
          </div>
        </div>
        <div class="col-12">
          <div>
            <div class="fs-15 text-dark fw-bold mb-2">Lag Measure (UOM)</div>
            <div>{{ dto?.lagMeasure ? '(' + dto.lagMeasure + ') ' : '20 views signed off' }}</div>
          </div>
        </div>

        <div class="col-12">
          <div>
            <div class="fs-15 text-dark fw-bold mb-2">Lead Measure (UOM)</div>
            <div>
              <div *ngFor="let lead of dto.leadMeasureScores" class="row gy-3">
                <div class="col-md-6 fs-12">
                  {{ lead.currentScore }} {{ '(' + lead.name + ')' }}
                </div>
                <div class="col-md-6 fw-medium fs-12 float-end">
                  Player score: <span class="fw-normal">{{ lead.newPlayerScore }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row gy-3 mt-4">
          <div class="col-12">
            <div class="mb-5">
              <div class="blue-div py-1 px-2" *ngIf="false">
                <div>
                  <div class="d-flex align-items-center">
                    <div>
                      <span
                        class="iconify"
                        style="font-size: 14px"
                        data-icon="emojione:light-bulb"
                      ></span>
                    </div>
                    <div class="ms-1" style="font-size: 14px">
                      This lead measure update predicts that
                    </div>
                  </div>
                </div>
              </div>
              <div class="profile-bottom-div fs-14 p-3">The lag predicts..</div>
            </div>

            <div>
              <app-bar-chart [maxYValue]="_maxY" [barChartData]="_barChartData"> </app-bar-chart>
            </div>
          </div>
          <div class="col-12">
            <div class="purple-div py-1 px-2">
              <div>
                <div class="d-flex align-items-center">
                  <div>
                    <span
                      class="iconify"
                      style="font-size: 14px"
                      data-icon="emojione:basketball"
                    ></span>
                  </div>
                  <div class="ms-1" style="font-size: 14px">Summary of Score Update</div>
                </div>
              </div>
            </div>
            <div class="profile-bottom-div fs-14 p-3">
              <div class="mb-3">Your lead measure scores will be saved as listed above.</div>
              <div>
                <div class="mb-2">
                  Your lag measure score is {{ dto.lagMeasureCurrentValue }} and the next benchmark
                  score should {{ benchmarkPassed() ? 'have been' : 'be' }}
                  {{ dto.nextBenchmark.targetValue }} by {{ dto.nextBenchmark.dueDate | dateOnly }}
                </div>
              </div>

              <div class="light-blue-bg" style="border-radius: 0px !important">
                <div class="d-flex align-items-center justify-content-center">
                  <div>
                    <span class="iconify" style="font-size: 14px" data-icon="emojione:memo"></span>
                  </div>
                  <div class="ms-1" style="font-size: 14px">Have a productive day!</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button (click)="update()" type="button" class="btn px-5 btn-primary">Update Score</button>
    </div>
  </div>
</div>
