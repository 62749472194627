<ng-container >
  <div class="modal-content rounded rounded-3 text-dark" *ngIf="_showReview">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Lead Measure Suggestion</h1>
        <button
          (click)="closeModal()"
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>

      <div class="modal-body pt-0">
        <app-alert-message
          *ngIf="xAlertMessage"
          [messageType]="xAlertMessage!.messageType"
          [message]="xAlertMessage!.message"
        >
        </app-alert-message>

        <ng-container *ngIf="!_loading; else loading">
          <ng-container *ngIf="_leadMeasureSearchPojo; else notFound">
            <div class="row">
              <div
                class="alert mt-1 px-3 rounded-0"
                style="
                color: #0b4eab;
                background-color: rgba(163.62, 202.24, 255, 0.3);
                border-left: #0b4eab 2px solid;
              "
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <span class="fw-bold"> {{ _leadMeasureSearchPojo.createdBy }} </span> suggested
                    this lead measure.
                  </div>
                  <div class="me-5">
                    Status:
                    <span
                      [ngClass]="{
                      'text-success': _leadMeasureSearchPojo.reviewStatus === 'APPROVED',
                      'text-danger': _leadMeasureSearchPojo.reviewStatus === 'DISAPPROVED',
                      'text-warning': _leadMeasureSearchPojo.reviewStatus === 'PENDING'
                    }"
                    >
                    {{ _leadMeasureSearchPojo.reviewStatus }}
                  </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-0 row-cols-2 g-3">
              <div class="col">
                <div class="d-flex my-2">
                  <span class="iconify fs-5 me-3" data-icon="emojione:antenna-bars"></span>
                  <div>
                    <p class="fw-bold">Lead UOM:</p>
                    <p class="small text-muted">{{ _leadMeasureSearchPojo.measurementUnit }}</p>
                  </div>
                </div>

                <div class="card p-0 rounded-0 border-0 my-4 col-lg-12">
                  <div class="card-header border rounded-0 p-2 text-primary border-1 border-primary">
                    <span class="iconify fs-5 me-2" data-icon="emojione:light-bulb"></span>
                    <span>This lead measure predicts that </span>
                  </div>
                  <div class="card-body border">
                    <p class="fw-normal text-muted small">
                      {{ _leadMeasureSearchPojo.predictionNote }}
                    </p>
                  </div>
                </div>

                <div class="card p-0 rounded-0 border-0 my-3 col-lg-12">
                  <div
                    class="card-header bg-primary border rounded-0 text-light p-2 border-1 border-primary"
                  >
                    <span class="iconify fs-5 me-2" data-icon="entypo:user"></span>
                    <span>Lead measure influencer </span>
                  </div>
                  <div class="card-body border">
                    <ng-container *ngFor="let x of _leadMeasureSearchPojo.assignedUsers">
                      <p class="fw-normal text-muted small">
                        {{ x.name }}
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="d-flex my-2">
                  <div>
                    <p class="fw-bold">WIG</p>
                    <p class="small text-muted">{{ _leadMeasureSearchPojo.wigName }}</p>
                  </div>
                </div>
                <div class="d-flex my-4">
                  <div>
                    <p class="fw-bold">LAG</p>
                    <p class="small text-muted">{{ _leadMeasureSearchPojo.lagName }}</p>
                  </div>
                </div>

                <div class="d-flex my-4">
                  <div>
                    <p class="fw-bold">LAG Measure</p>
                    <p class="small text-muted">{{ _leadMeasureSearchPojo.lagMeasureName }}</p>
                  </div>
                </div>

                <!--              <div class="d-flex my-4">-->
                <!--                <div>-->
                <!--                  <p class="fw-bold">Status</p>-->
                <!--                  <div-->
                <!--                    class="small"-->
                <!--                    [ngClass]="{-->
                <!--                      'text-success': _leadMeasureSearchPojo.reviewStatus === 'APPROVED',-->
                <!--                      'text-danger': _leadMeasureSearchPojo.reviewStatus === 'DISAPPROVED',-->
                <!--                      'text-warning': _leadMeasureSearchPojo.reviewStatus === 'PENDING'-->
                <!--                    }"-->
                <!--                  >-->
                <!--                    {{ _leadMeasureSearchPojo.reviewStatus }}-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--              </div>-->
                <ng-container *ngIf="_leadMeasureSearchPojo.reviewedBy">
                  <div class="d-flex my-4">
                    <div>
                      <p class="fw-bold">Reviewed By</p>
                      <p class="small text-muted">
                        {{ _leadMeasureSearchPojo.reviewedBy | uppercase }}
                      </p>
                    </div>
                  </div>
                </ng-container>

                <div
                  class="card p-0 rounded-0 border-0 my-4 col-lg-12"
                  *ngIf="
                  _leadMeasureSearchPojo.reviewStatus === 'PENDING' ||
                  _leadMeasureSearchPojo.reviewStatus === 'APPROVED' ||
                  _leadMeasureSearchPojo.reviewNote
                "
                >
                  <div class="border rounded-0 p-2 text-danger border-1 border-danger">
                    <span class="iconify me-1" data-icon="line-md:cancel"></span>
                    <span>Disapproval Note </span>
                  </div>
                  <div class="card-body border border-danger">
                    <ng-container
                      *ngIf="
                      _leadMeasureSearchPojo.reviewStatus === 'PENDING' ||
                        _leadMeasureSearchPojo.reviewStatus === 'APPROVED';
                      else reviewNote
                    "
                    >
                      <form [formGroup]="_form">
                        <custom-text-area
                          [maximumTextLength]="FormConstants.SHORT_NOTE_MAXIMUM_LENGTH"
                          [placeholder]="'Reason for Disapproval'"
                          [form]="_form"
                          [controlName]="'reason'"
                        >
                        </custom-text-area>
                      </form>
                      <div class="d-flex justify-content-end">
                        <app-button-with-loader
                          [ngClass]="{ 'grey-out-div': _isApproving }"
                          [buttonType]="'submit'"
                          [classText]="'btn btn-outline-danger me-3 px-5 mt-3'"
                          [idleStateText]="'Disapprove'"
                          [isProcessing]="_isDisapproving"
                          [workingStateText]="'Disapproving...'"
                          (buttonClick)="disapprove()"
                        >
                        </app-button-with-loader>
                      </div>
                    </ng-container>
                    <ng-template #reviewNote>
                      {{ _leadMeasureSearchPojo.reviewNote }}
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="_leadMeasureSearchPojo.reviewStatus === 'PENDING'" class="text-end">
              <app-button-with-loader
                [ngClass]="{ 'grey-out-div': _isDisapproving }"
                [buttonType]="'submit'"
                [classText]="'btn btn-primary px-5'"
                [idleStateText]="'Approve'"
                [isProcessing]="_isApproving"
                [workingStateText]="'Approving...'"
                (buttonClick)="approve()"
              >
              </app-button-with-loader>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>


<ng-template #loading>
  <lottie-in-full-table [path]="'assets/lottie/loading.json'" [message]="'Searching...'">
  </lottie-in-full-table>
</ng-template>
<ng-template #notFound>
  <lottie-in-full-table
    [path]="'assets/lottie/no-data.json'"
    [message]="'Could not find Lead Measure'"
  >
  </lottie-in-full-table>
</ng-template>
