export class ErrorMessages {
  static downTime = 'downTime';

  static messages(label = 'This field'): { type: string; message: string }[] {
    return [
      {
        type: 'required',
        message: `${label} is required `
      },
      {
        type: 'unique',
        message: `${label} already in use `
      },
      {
        type: 'notBlank',
        message: `${label} cannot be blank  `
      },
      {
        type: 'email',
        message: `${label} must be a valid email `
      },
      {
        type: 'taken',
        message: `${label} has been used `
      },
      {
        type: 'leadMeasureForLagMeasure',
        message: `Measurement Unit already created for this lag measure `
      },
      {
        type: 'notDeliverable',
        message: `${label} is not valid `
      },
      {
        type: 'noSpace',
        message: `Blank space not allowed`
      },
      {
        type: 'minlength',
        message: `Too short`
      },
      {
        type: 'maxlength',
        message: `Too long`
      },
      {
        type: 'invalidPhoneNumber',
        message: `${label} is not valid`
      },
      {
        type: 'validatePhoneNumber',
        message: ``
      },
      {
        type: 'downTime',
        message: `Something went wrong. Try again later`
      },
      {
        type: 'max',
        message: `${label} is too large`
      },
      {
        type: 'min',
        message: `${label} is too small`
      }
    ];
  }
}
