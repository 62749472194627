<!-- ------------------------------- -->

<div class="m-0">
  <div class="d-flex justify-content-between align-items-center pe-2">
    <div class="d-flex justify-content-start align-items-center modal-header border-bottom border-dark ">
      <h6 class=" mb-0 fs-16 fw-medium" id="modal-title">{{ header }}</h6>
    </div>
    <button (click)="close()" class="btn p-1 " type="button" aria-label="Close">
      <span class="iconify fs-5" data-icon="iconamoon:close"></span>
    </button>
  </div>
  <div class="modal-body my-2">
    <div class="">
      <div class="mb-3 text-center">
       <span class="iconify" style="font-size: 3rem" data-icon="fluent-emoji-flat:warning"></span>
      </div>
      <div class="mt-3 text-center ">
       <p class="fs-14"> {{ body }}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer mt-4 ">
    <button (click)="close()" class="btn btn-outline-danger border-0 " type="button">
      Cancel
    </button>
    <button
      (click)="confirmButtonClicked()"
      [disabled]="loading"
      class=" btn btn-primary"
      type="button"
    >
      {{ positiveButtonText }}
    </button>
  </div>
</div>
