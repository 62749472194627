/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { Blocker } from '../model/models';
import { LagMeasureScorePojo } from '../model/models';
import { QueryResultsPlayerLagMeasurePojo } from '../model/models';
import { QueryResultsPlayerLeadMeasurePojo } from '../model/models';
import { QueryResultsScoreboardPlayerSummaryPojo } from '../model/models';
import { ScoreBoardBuilderPojo } from '../model/models';
import { ScoreboardStatusPojo } from '../model/models';
import { TeamWigCommitmentPojo } from '../model/models';
import { UserWithoutWigPojo } from '../model/models';
import { WigScoreStatisticsPojo } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface AffectedLagsRequestParams {
    lagId: number;
}

export interface GetLagMeasureScoreRequestParams {
    id: number;
}

export interface GetPlayerLagMeasuresRequestParams {
    limit: number;
    playerId: number;
    offset: number;
    wigId: number;
}

export interface GetPlayerLeadMeasuresRequestParams {
    limit: number;
    playerId: number;
    offset: number;
    wigId: number;
}

export interface GetPlayersForAWigRequestParams {
    wigId: number;
    limit: number;
    offset: number;
}

export interface GetScoreboardStatsRequestParams {
    wigId: number;
    scoreboardType: 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'YEARLY' | 'QUARTERLY';
    blockerLimit?: number;
    blockerOffset?: number;
    playerLimit?: number;
    playerOffset?: number;
    lagLimit?: number;
    lagOffset?: number;
}

export interface GetTeamWigCommitmentsRequestParams {
    wigId: number;
}

export interface GetWigScoreStatisticsRequestParams {
    wigId: number;
}

export interface ScoreboardStatusRequestParams {
    wigId: number;
}

export interface UpdateLagMeasureScoreRequestParams {
    id: number;
    score: number;
}


@Injectable({
  providedIn: 'root'
})
export class ScoreboardControllerService {

    protected basePath = 'http://ecadence.ng/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public affectedLags(requestParameters: AffectedLagsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<Blocker>>;
    public affectedLags(requestParameters: AffectedLagsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<Blocker>>>;
    public affectedLags(requestParameters: AffectedLagsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<Blocker>>>;
    public affectedLags(requestParameters: AffectedLagsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const lagId = requestParameters.lagId;
        if (lagId === null || lagId === undefined) {
            throw new Error('Required parameter lagId was null or undefined when calling affectedLags.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Blocker>>(`${this.configuration.basePath}/scoreboard/affected-lag/${encodeURIComponent(String(lagId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLagMeasureScore(requestParameters: GetLagMeasureScoreRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<LagMeasureScorePojo>;
    public getLagMeasureScore(requestParameters: GetLagMeasureScoreRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<LagMeasureScorePojo>>;
    public getLagMeasureScore(requestParameters: GetLagMeasureScoreRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<LagMeasureScorePojo>>;
    public getLagMeasureScore(requestParameters: GetLagMeasureScoreRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getLagMeasureScore.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<LagMeasureScorePojo>(`${this.configuration.basePath}/scoreboard/lag-Measures/${encodeURIComponent(String(id))}/score`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlayerLagMeasures(requestParameters: GetPlayerLagMeasuresRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsPlayerLagMeasurePojo>;
    public getPlayerLagMeasures(requestParameters: GetPlayerLagMeasuresRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsPlayerLagMeasurePojo>>;
    public getPlayerLagMeasures(requestParameters: GetPlayerLagMeasuresRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsPlayerLagMeasurePojo>>;
    public getPlayerLagMeasures(requestParameters: GetPlayerLagMeasuresRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling getPlayerLagMeasures.');
        }
        const playerId = requestParameters.playerId;
        if (playerId === null || playerId === undefined) {
            throw new Error('Required parameter playerId was null or undefined when calling getPlayerLagMeasures.');
        }
        const offset = requestParameters.offset;
        if (offset === null || offset === undefined) {
            throw new Error('Required parameter offset was null or undefined when calling getPlayerLagMeasures.');
        }
        const wigId = requestParameters.wigId;
        if (wigId === null || wigId === undefined) {
            throw new Error('Required parameter wigId was null or undefined when calling getPlayerLagMeasures.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (wigId !== undefined && wigId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>wigId, 'wigId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsPlayerLagMeasurePojo>(`${this.configuration.basePath}/scoreboard/player/${encodeURIComponent(String(playerId))}/lag-measures`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlayerLeadMeasures(requestParameters: GetPlayerLeadMeasuresRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsPlayerLeadMeasurePojo>;
    public getPlayerLeadMeasures(requestParameters: GetPlayerLeadMeasuresRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsPlayerLeadMeasurePojo>>;
    public getPlayerLeadMeasures(requestParameters: GetPlayerLeadMeasuresRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsPlayerLeadMeasurePojo>>;
    public getPlayerLeadMeasures(requestParameters: GetPlayerLeadMeasuresRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling getPlayerLeadMeasures.');
        }
        const playerId = requestParameters.playerId;
        if (playerId === null || playerId === undefined) {
            throw new Error('Required parameter playerId was null or undefined when calling getPlayerLeadMeasures.');
        }
        const offset = requestParameters.offset;
        if (offset === null || offset === undefined) {
            throw new Error('Required parameter offset was null or undefined when calling getPlayerLeadMeasures.');
        }
        const wigId = requestParameters.wigId;
        if (wigId === null || wigId === undefined) {
            throw new Error('Required parameter wigId was null or undefined when calling getPlayerLeadMeasures.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (wigId !== undefined && wigId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>wigId, 'wigId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsPlayerLeadMeasurePojo>(`${this.configuration.basePath}/scoreboard/player/${encodeURIComponent(String(playerId))}/lead-measures`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlayersForAWig(requestParameters: GetPlayersForAWigRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsScoreboardPlayerSummaryPojo>;
    public getPlayersForAWig(requestParameters: GetPlayersForAWigRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsScoreboardPlayerSummaryPojo>>;
    public getPlayersForAWig(requestParameters: GetPlayersForAWigRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsScoreboardPlayerSummaryPojo>>;
    public getPlayersForAWig(requestParameters: GetPlayersForAWigRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const wigId = requestParameters.wigId;
        if (wigId === null || wigId === undefined) {
            throw new Error('Required parameter wigId was null or undefined when calling getPlayersForAWig.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling getPlayersForAWig.');
        }
        const offset = requestParameters.offset;
        if (offset === null || offset === undefined) {
            throw new Error('Required parameter offset was null or undefined when calling getPlayersForAWig.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsScoreboardPlayerSummaryPojo>(`${this.configuration.basePath}/scoreboard/players/${encodeURIComponent(String(wigId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getScoreboardStats(requestParameters: GetScoreboardStatsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ScoreBoardBuilderPojo>;
    public getScoreboardStats(requestParameters: GetScoreboardStatsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ScoreBoardBuilderPojo>>;
    public getScoreboardStats(requestParameters: GetScoreboardStatsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ScoreBoardBuilderPojo>>;
    public getScoreboardStats(requestParameters: GetScoreboardStatsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const wigId = requestParameters.wigId;
        if (wigId === null || wigId === undefined) {
            throw new Error('Required parameter wigId was null or undefined when calling getScoreboardStats.');
        }
        const scoreboardType = requestParameters.scoreboardType;
        if (scoreboardType === null || scoreboardType === undefined) {
            throw new Error('Required parameter scoreboardType was null or undefined when calling getScoreboardStats.');
        }
        const blockerLimit = requestParameters.blockerLimit;
        const blockerOffset = requestParameters.blockerOffset;
        const playerLimit = requestParameters.playerLimit;
        const playerOffset = requestParameters.playerOffset;
        const lagLimit = requestParameters.lagLimit;
        const lagOffset = requestParameters.lagOffset;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (scoreboardType !== undefined && scoreboardType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>scoreboardType, 'scoreboardType');
        }
        if (blockerLimit !== undefined && blockerLimit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>blockerLimit, 'blockerLimit');
        }
        if (blockerOffset !== undefined && blockerOffset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>blockerOffset, 'blockerOffset');
        }
        if (playerLimit !== undefined && playerLimit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerLimit, 'playerLimit');
        }
        if (playerOffset !== undefined && playerOffset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerOffset, 'playerOffset');
        }
        if (lagLimit !== undefined && lagLimit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lagLimit, 'lagLimit');
        }
        if (lagOffset !== undefined && lagOffset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lagOffset, 'lagOffset');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ScoreBoardBuilderPojo>(`${this.configuration.basePath}/scoreboard/${encodeURIComponent(String(wigId))}/scoreboard-stats`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTeamWigCommitments(requestParameters: GetTeamWigCommitmentsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<TeamWigCommitmentPojo>>;
    public getTeamWigCommitments(requestParameters: GetTeamWigCommitmentsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<TeamWigCommitmentPojo>>>;
    public getTeamWigCommitments(requestParameters: GetTeamWigCommitmentsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<TeamWigCommitmentPojo>>>;
    public getTeamWigCommitments(requestParameters: GetTeamWigCommitmentsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const wigId = requestParameters.wigId;
        if (wigId === null || wigId === undefined) {
            throw new Error('Required parameter wigId was null or undefined when calling getTeamWigCommitments.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<TeamWigCommitmentPojo>>(`${this.configuration.basePath}/scoreboard/wig/${encodeURIComponent(String(wigId))}/team-commitments`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersWithoutAssignedWig(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<UserWithoutWigPojo>>;
    public getUsersWithoutAssignedWig(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<UserWithoutWigPojo>>>;
    public getUsersWithoutAssignedWig(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<UserWithoutWigPojo>>>;
    public getUsersWithoutAssignedWig(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<UserWithoutWigPojo>>(`${this.configuration.basePath}/scoreboard/organization/players-without-wig`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWigScoreStatistics(requestParameters: GetWigScoreStatisticsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<WigScoreStatisticsPojo>;
    public getWigScoreStatistics(requestParameters: GetWigScoreStatisticsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<WigScoreStatisticsPojo>>;
    public getWigScoreStatistics(requestParameters: GetWigScoreStatisticsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<WigScoreStatisticsPojo>>;
    public getWigScoreStatistics(requestParameters: GetWigScoreStatisticsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const wigId = requestParameters.wigId;
        if (wigId === null || wigId === undefined) {
            throw new Error('Required parameter wigId was null or undefined when calling getWigScoreStatistics.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (wigId !== undefined && wigId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>wigId, 'wigId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<WigScoreStatisticsPojo>(`${this.configuration.basePath}/scoreboard/wig-score-statistics/`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scoreboardStatus(requestParameters: ScoreboardStatusRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ScoreboardStatusPojo>;
    public scoreboardStatus(requestParameters: ScoreboardStatusRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ScoreboardStatusPojo>>;
    public scoreboardStatus(requestParameters: ScoreboardStatusRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ScoreboardStatusPojo>>;
    public scoreboardStatus(requestParameters: ScoreboardStatusRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const wigId = requestParameters.wigId;
        if (wigId === null || wigId === undefined) {
            throw new Error('Required parameter wigId was null or undefined when calling scoreboardStatus.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ScoreboardStatusPojo>(`${this.configuration.basePath}/scoreboard/${encodeURIComponent(String(wigId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateLagMeasureScore(requestParameters: UpdateLagMeasureScoreRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public updateLagMeasureScore(requestParameters: UpdateLagMeasureScoreRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public updateLagMeasureScore(requestParameters: UpdateLagMeasureScoreRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public updateLagMeasureScore(requestParameters: UpdateLagMeasureScoreRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateLagMeasureScore.');
        }
        const score = requestParameters.score;
        if (score === null || score === undefined) {
            throw new Error('Required parameter score was null or undefined when calling updateLagMeasureScore.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (score !== undefined && score !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>score, 'score');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/scoreboard/lag-Measures/${encodeURIComponent(String(id))}/score`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
