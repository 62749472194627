import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProgressbarType } from 'ngx-bootstrap/progressbar';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { WigSummaryDto } from '../../../../../../sdk/ecadence-sdk';
import { RaiseBlockerComponent } from '../../../../pages/intranet/leverage-mgt/modals/raise-blocker/raise-blocker.component';
import { TimerObject } from '../../../../pages/intranet/wig-mgt/wig-profile/wig-profile.component';
import { CreateLeadMeasureModalComponent } from '../../../../pages/intranet/leverage-mgt/modals/create-lead-measure-modal/create-lead-measure-modal.component';
import { HelperService } from '../../../../services/helper.service';
import { AuthenticationService } from '../../../../services/authentication.service';

@Component({
  selector: 'wig-overview',
  templateUrl: './wig-overview.component.html',
  styleUrls: ['./wig-overview.component.css']
})
export class WigOverviewComponent implements OnInit {
  _wigSummaryDto: WigSummaryDto;

  _timerObject: TimerObject;
  _intervalObject: any;
  isNegative: boolean;

  @Output()
  milestoneEvent: EventEmitter<WigSummaryDto> = new EventEmitter<WigSummaryDto>();

  isCompleted: boolean;
  isOverdue: boolean;
  isInprogress: boolean;
  completedInfo: string;
  overdueInfo: string;

  lag1Info: string;
  lag1Exists: boolean;
  lag1IsOverdue: boolean;
  lag1IsinProgress: boolean;
  lag1IsCompleted: boolean;

  lag2Info: string;
  lag2Exists: boolean;
  lag2IsOverdue: boolean;
  lag2IsinProgress: boolean;
  lag2IsCompleted: boolean;

  lag3Info: string;
  lag3Exists: boolean;
  lag3IsOverdue: boolean;
  lag3IsinProgress: boolean;
  lag3IsCompleted: boolean;

  inprogressInfo: string;

  constructor(
    private modalService: BsModalService,
    private helperService: HelperService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.lagsCompletedInfo();
    this.lagsOverdueInfo();
    this.lagsInprogressInfo();
    this.setupFirstThreeLags();
  }

  @Input()
  set wigSummaryDto(value: WigSummaryDto) {
    this._wigSummaryDto = value;
    this.countdown();
  }

  completedOnTime(): boolean {
    return new Date(this._wigSummaryDto.completedAt) <= new Date(this._wigSummaryDto.dueAt);
  }

  getColor(i: number): string {
    if (i === 0) {
      return 'deepskyblue';
    }
    if (i === 1) {
      return 'deeppink';
    } else {
      return 'deepskyblue';
    }
  }

  getMarginLeft(i: number): string {
    if (i === 0) {
      return '0';
    }
    return '-1rem';
  }

  suggestLeadMeasure(): void {
    const createLeadMeasureModal: BsModalRef<CreateLeadMeasureModalComponent> =
      this.modalService.show(CreateLeadMeasureModalComponent, {
        initialState: {
          wigPojo: {
            id: this._wigSummaryDto.wigId
          },
          isSuggestion: true
        },
        keyboard: false,
        backdrop: 'static',
        class: 'offcanvas offcanvas-end show'
      });
    setTimeout(() => {
      this.helperService.removeModalDialogClass();
    }, 100);

    createLeadMeasureModal.content.doneEvent.subscribe({
      next: (ignore) => {
        createLeadMeasureModal?.hide();
      }
    });
  }

  addBlocker(): void {
    this.modalService.show(RaiseBlockerComponent, {
      initialState: {
        wigPojo: {
          id: this._wigSummaryDto.wigId,
          name: this._wigSummaryDto.wigName
        }
      },
      keyboard: false,
      backdrop: 'static',
      class: ''
    });
  }

  countdown(): void {
    const targetTime = new Date(this._wigSummaryDto.dueAt).getTime();

    this._intervalObject = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeRemaining = targetTime - currentTime;

      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      this.isNegative = days < 1;

      this._timerObject = {
        days,
        hours,
        minutes,
        seconds
      };
    }, 1000);
  }

  getProgressColour(): ProgressbarType {
    if (this._wigSummaryDto.progress === 1) {
      return 'success';
    } else if (this._wigSummaryDto.progress < 0.4) {
      return 'danger';
    } else {
      return 'warning';
    }
  }

  duePassedToday(): boolean {
    return new Date(this._wigSummaryDto.dueAt) > new Date();
  }

  protected readonly Math = Math;

  showMilestone(): void {
    this.milestoneEvent.emit(this._wigSummaryDto);
  }

  lagsCompletedInfo(): void {
    const completedLags = this._wigSummaryDto.lagPojos.find(
      (item) =>
        new Date(item['completedAt']).getDate() < new Date().getDate() ||
        new Date(item['startAt']).getDate() < new Date(item['dueAt']).getDate()
    );
    this.isCompleted = !!completedLags;

    if (this.isCompleted) {
      this.completedInfo = `Lag Name: ${completedLags['name']},
       Date Completed: ${new Date(completedLags['completedAt']).toDateString()},
       Due Date: ${new Date(completedLags['dueDate']).toDateString()},
       Players: ${completedLags['players']?.length},
       Blockers: ${completedLags['blockers']?.length || 0}
      `;
    }
  }

  lagsOverdueInfo(): void {
    const overdueLags = this._wigSummaryDto?.lagPojos?.find(
      (item) =>
        item['createdAt']?.getDate() < new Date()?.getDate() ||
        new Date()?.getDate() > new Date(item['dueAt'])?.getDate() ||
        item['overdueBenchmark'].length > 0
    );

    this.isOverdue = !!overdueLags;
    if (this.isOverdue) {
      this.overdueInfo = `Lag Name: ${overdueLags['name']},
      Due Date: ${new Date(overdueLags['dueDate']).toDateString()},
      Players: ${overdueLags['players']?.length || 0},
      Blockers: ${overdueLags['blockers']?.length || 0}
      `;
    }
  }

  lagsInprogressInfo(): void {
    const inprogress = this._wigSummaryDto?.lagPojos?.find(
      (item) =>
        item['players'].length > 0 &&
        new Date(item['startDate'])?.getDate() < new Date(item['dueDate'])?.getDate()
    );

    this.isInprogress = !!inprogress;
    if (this.isInprogress) {
      this.inprogressInfo = `Lag Name: ${inprogress['name']},
      Due Date: ${new Date(inprogress['dueDate']).toDateString()},
      Players: ${inprogress['players']?.length || 0},
      Blockers: ${inprogress['blockers']?.length || 0}`;
    }
  }

  setupFirstThreeLags(): void {
    const lag1 =
      this._wigSummaryDto?.lagPojos?.length > 0 ? this._wigSummaryDto?.lagPojos?.at(0) : null;
    const lag2 =
      this._wigSummaryDto?.lagPojos?.length > 1 ? this._wigSummaryDto?.lagPojos?.at(1) : null;
    const lag3 =
      this._wigSummaryDto?.lagPojos?.length > 2 ? this._wigSummaryDto?.lagPojos?.at(2) : null;

    if (lag1 != null) {
      this.lag1Exists = true;

      const overdueLag1 = this._wigSummaryDto?.lagPojos?.find(
        (item, index) =>
          index == 0 &&
          item['createdAt']?.getDate() < new Date()?.getDate() &&
          new Date()?.getDate() > new Date(item['dueAt'])?.getDate() &&
          item['overdueBenchmark'].length > 0
      );
      this.lag1IsOverdue = overdueLag1 != null;

      const inProgressLag1 = this._wigSummaryDto?.lagPojos?.find(
        (item, index) =>
          index == 0 &&
          item['players'].length > 0 &&
          new Date(item['startDate'])?.getDate() < new Date()?.getDate()
      );
      this.lag1IsinProgress = inProgressLag1 != null;

      const completedLag1 = this._wigSummaryDto?.lagPojos?.find(
        (item, index) =>
          (index == 0 && new Date(item['completedAt']).getDate() < new Date().getDate()) ||
          new Date(item['startAt']).getDate() < new Date().getDate()
      );
      this.lag1IsCompleted = completedLag1 != null;

      this.lag1Info = `Lag Name: ${lag1['name']},
      Due Date: ${new Date(lag1['dueDate']).toDateString()},
      Players: ${lag1['players']?.length || 0},
      Blockers: ${lag1['blockers']?.length || 0}
      `;
    }

    //Lag2
    if (lag2 != null) {
      this.lag2Exists = true;

      const overdueLag2 = this._wigSummaryDto?.lagPojos?.find(
        (item, index) =>
          index == 1 &&
          item['createdAt']?.getDate() < new Date()?.getDate() &&
          new Date()?.getDate() > new Date(item['dueAt'])?.getDate() &&
          item['overdueBenchmark'].length > 0
      );
      this.lag2IsOverdue = overdueLag2 != null;

      const inProgressLag2 = this._wigSummaryDto?.lagPojos?.find(
        (item, index) =>
          index == 1 &&
          item['players'].length > 0 &&
          new Date(item['startDate'])?.getDate() < new Date()?.getDate()
      );
      this.lag2IsinProgress = inProgressLag2 != null;

      const completedLag2 = this._wigSummaryDto?.lagPojos?.find(
        (item, index) =>
          (index == 1 && new Date(item['completedAt']).getDate() < new Date().getDate()) ||
          new Date(item['startAt']).getDate() < new Date().getDate()
      );
      this.lag2IsCompleted = completedLag2 != null;

      this.lag2Info = `Lag Name: ${lag2['name']},
      Due Date: ${new Date(lag2['dueDate']).toDateString()},
      Players: ${lag2['players']?.length || 0},
      Blockers: ${lag2['blockers']?.length || 0}
      `;
    }

    //Lag3
    if (lag3 != null) {
      this.lag3Exists = true;

      const overdueLag3 = this._wigSummaryDto?.lagPojos?.find(
        (item, index) =>
          index == 2 &&
          item['createdAt']?.getDate() < new Date()?.getDate() &&
          new Date()?.getDate() > new Date(item['dueAt'])?.getDate() &&
          item['overdueBenchmark'].length > 0
      );
      this.lag3IsOverdue = overdueLag3 != null;

      const inProgressLag3 = this._wigSummaryDto?.lagPojos?.find(
        (item, index) =>
          index == 2 &&
          item['players'].length > 0 &&
          new Date(item['startDate'])?.getDate() < new Date()?.getDate()
      );
      this.lag3IsinProgress = inProgressLag3 != null;

      const completedLag3 = this._wigSummaryDto?.lagPojos?.find(
        (item, index) =>
          (index == 2 && new Date(item['completedAt']).getDate() < new Date().getDate()) ||
          new Date(item['startAt']).getDate() < new Date().getDate()
      );
      this.lag3IsCompleted = completedLag3 != null;

      this.lag3Info = `Lag Name: ${lag3['name']},
      Due Date: ${new Date(lag3['dueDate']).toDateString()},
      Players: ${lag3['players']?.length || 0},
      Blockers: ${lag3['blockers']?.length || 0}
      `;
    }
  }

  chechUserPermissions(permission: string): boolean {
    return this.authenticationService.hasPermission(permission);
  }

  viewBlockers(): void {
    this.router.navigate(['/leverages/blockers'], {
      queryParams: { wigId: this._wigSummaryDto.wigId }
    });
  }

  selectedWig(wigId: number): void {
    this.router.navigate(['/wig/' + wigId + '/profile']);
  }
}
