import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../models/user/user.model';

@Component({
  selector: 'forbidden-page',
  templateUrl: './forbidden-page.component.html',
  styleUrls: ['./forbidden-page.component.css']
})
export class ForbiddenPageComponent implements OnDestroy {
  user: User;
  stop$ = new Subject();
  code = '403';

  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  ngOnInit(): void {
    if (history?.state?.code) this.code = history.state.code;

    this.authenticationService
      .getUser()
      .pipe(takeUntil(this.stop$))
      .subscribe((value) => {
        this.user = value;
      });
  }

  takeMeBack(): void {
    if (this.user) {
      this.router.navigate(['/dashboard']);
    } else {
      this.authenticationService.login({ prompt: 'login' });
    }
  }

  ngOnDestroy(): void {
    this.stop$.next(null);
    this.stop$.complete();
  }
}
