import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../../services/authentication.service';
import {
  AccountMembershipPojo,
  CadenceControllerService,
  CommitmentDto,
  CommittmentDto,
  IdDoublePojo,
  LagMeasureBenchmarkPojo,
  LagMeasureControllerService,
  LagMeasurePojo,
  LeadMeasureCommitmentDto,
  LeadMeasurePojo,
  LeverageControllerService,
  NameIdPojo,
  PlayerLeadMeasureScorePojo
} from '../../../../../../sdk/ecadence-sdk';
import { XAlertMessage } from '../../../../shared/components/alert-message/alert-message.component';
import { SuccessDialogComponent } from '../../../../shared/components/success-dialog/success-dialog.component';
import { UpdateLeadMeasureScorePreviewComponent } from '../update-lead-measure-score-preview/update-lead-measure-score-preview.component';
import { HelperService } from '../../../../services/helper.service';
import { UpdateLagMeasureScoreComponent } from '../update-lag-measure-score/update-lag-measure-score.component';
import PermissionsEnum = AccountMembershipPojo.PermissionsEnum;

@Component({
  selector: 'update-lead-measure',
  templateUrl: './update-lead-measure-score.component.html',
  styleUrls: ['./update-lead-measure-score.component.css']
})
export class UpdateLeadMeasureScoreComponent implements OnInit {
  xAlertMessage: XAlertMessage = {
    messageType: 'info',
    message: undefined
  };

  @Input() wigId = 25202;

  commitmentsList: CommittmentDto[] = [];

  lagUI: NameIdPojo[];
  lagMeasureOnly: NameIdPojo[];
  lagMeasureUI: LagMeasurePojo[];
  leadMeasureUI: LeadMeasurePojo[];

  playerWigsPojo: NameIdPojo[];
  form: FormGroup;
  errorMessage: string;

  _selectedLeadMeasure: XLeadMeasureCommitmentDto;

  get selectedLeadMeasure(): XLeadMeasureCommitmentDto {
    return this._selectedLeadMeasure;
  }

  selectedLagMeasure: LagMeasurePojo;
  nextLagMeasureBenchmark: LagMeasureBenchmarkPojo;

  fetchingLagsAndItsMeasures = false;
  fetchingLeadMeasures = false;
  fetchingWigs = false;
  dueDate: string;
  playerScorePojo: PlayerLeadMeasureScorePojo;

  fetchingLeadMeasure = false;
  _leadMeasureAndCommitments: XLeadMeasureCommitmentDto[];

  _updatedLeadMeasureIdAndNewScore: Array<IdDoublePojo>;

  submissionError = false;

  get leadMeasureAndCommitments(): XLeadMeasureCommitmentDto[] {
    return this._leadMeasureAndCommitments;
  }

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private cadenceControllerService: CadenceControllerService,
    private lagmeasureService: LagMeasureControllerService,
    private bsModalRef: BsModalRef,
    private leadController: LeverageControllerService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.addFormEventHandlers();
    this.getPlayersWigs();
  }

  initForm(): void {
    this.form = this.fb.group({
      score: [''],
      lagMeasure: [''],
      leadMeasure: [''],
      wig: [''],
      lag: ['']
    });
  }

  updatingPlayerLeadMeasureScore = false;

  updatePlayerLeadMeasureScore(v: number): void {
    this.updatingPlayerLeadMeasureScore = true;
    this.leadController
      .updatePlayerScore({
        updatePlayerCommitmentPojo: {
          lagMeasureId: this.selectedLagMeasure.lagMeasureId,
          leadMeasureIdAndNewScore: this._updatedLeadMeasureIdAndNewScore,
          newScore: v
        }
      })
      .subscribe({
        next: (val: unknown) => {
          //   todo do success modal here
          this.bsModalRef.hide();
          const ref = this.modalService.show(SuccessDialogComponent, {
            initialState: {
              message: `Your lead measure score have been updated successfully`
            },
            keyboard: false,
            backdrop: 'static',
            class: 'modal-dialog-centered modal-sm'
          });
        },
        error: (err: unknown) => {
          this.xAlertMessage.messageType = 'danger';
          this.xAlertMessage.message =
            err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
              ? err.error.message
              : "'Unable to update player's  score";
        }
      })
      .add(() => {
        this.updatingPlayerLeadMeasureScore = true;
      });
  }

  getPlayersWigs(): void {
    this.cadenceControllerService.getPlayerWigs().subscribe(
      (value) => {
        this.fetchingWigs = false;
        this.playerWigsPojo = value;
      },
      (error: unknown) => {
        this.fetchingLagsAndItsMeasures = false;
        if (
          error instanceof HttpErrorResponse &&
          error.error &&
          typeof error.error == 'object' &&
          error.error.message
        ) {
          this.errorMessage = error.error.message;
          return;
        }
        this.errorMessage = 'Could not fetch categories.';
      }
    );
  }

  addFormEventHandlers(): void {
    this.form.get('wig').valueChanges.subscribe((v) => {
      this.resetContentForWigReset();
      if (v) {
        this.getWigLagMeasures(v);
      }
    });

    this.form.get('lag').valueChanges.subscribe((v) => {
      this.resetContentForLagReset();
      if (v) {
        this.filterLagMeasures(v);
      }
    });

    this.form.get('lagMeasure').valueChanges.subscribe((v) => {
      this.resetContentForLagMeasureReset();

      if (v) {
        this.fetchleadmeasures(v);
        this.computeSelectedLagMeasure();
      }
    });

    this.form.get('leadMeasure').valueChanges.subscribe((v) => {
      this.resetContentForLeadMeasureReset();
      if (v) this.fetchPlayerScore(v);
    });
  }

  resetContentForWigReset(): void {
    this.form.get('leadMeasure').patchValue('');
    this.form.get('lagMeasure').patchValue('');
    this.form.get('lag').patchValue('');
  }

  resetContentForLagReset(): void {
    this.form.get('leadMeasure').patchValue('');
    this.form.get('lagMeasure').patchValue('');
  }

  resetContentForLeadMeasureReset(): void {
    console.log('');
  }

  resetContentForLagMeasureReset(): void {
    console.log('');
  }

  getWigLagMeasures(wigId: number): void {
    this.fetchingLagsAndItsMeasures = true;
    this.cadenceControllerService
      .getLagMeasures({ wigId })
      .subscribe(
        (value) => {
          this.lagMeasureUI = value;
          const uniqueLagIds = new Set();
          this.lagUI = [];
          value.forEach((x: LagMeasurePojo) => {
            if (!uniqueLagIds.has(x.lagId)) {
              console.log('new lag id of ', x.lagId);
              const lag: NameIdPojo = {
                id: x.lagId,
                name: x.lagName
              };
              uniqueLagIds.add(x.lagId);
              console.log(uniqueLagIds);
              this.lagUI.push(lag);
            } else {
              console.log('it has ', x.lagId);
            }
          });
          console.log(this.lagUI);
          this.lagMeasureOnly = value.map((x: LagMeasurePojo) => {
            const lagMeasure: NameIdPojo = {
              id: x.lagMeasureId,
              name: x.lagMeasureName
            };
            return lagMeasure;
          });
        },
        (error: unknown) => {
          if (
            error instanceof HttpErrorResponse &&
            error.error &&
            typeof error.error == 'object' &&
            error.error.message
          ) {
            this.errorMessage = error.error.message;
            return;
          }
          this.errorMessage = 'Could not fetch categories.';
        }
      )
      .add(() => {
        this.fetchingLagsAndItsMeasures = false;
      });
  }

  fetchleadmeasures(lagId: number): void {
    this.fetchingLeadMeasures = true;
    this.cadenceControllerService.getLeadMeasuresForPlayer({ lagId: lagId }).subscribe(
      (value) => {
        this.fetchingLeadMeasures = false;
        this.leadMeasureUI = value;
      },
      (error: unknown) => {
        this.fetchingLeadMeasures = false;
        if (
          error instanceof HttpErrorResponse &&
          error.error &&
          typeof error.error == 'object' &&
          error.error.message
        ) {
          this.errorMessage = error.error.message;
          return;
        }
        this.errorMessage = 'Could not fetch sub categories.';
      }
    );
  }

  fetchPlayerScore(leadMeasureId: number): void {
    this.cadenceControllerService
      .getPlayerLeadMeasureScore({ leadMeasureId: leadMeasureId })
      .subscribe(
        (value) => {
          this.playerScorePojo = value;
        },
        (error: unknown) => {
          if (
            error instanceof HttpErrorResponse &&
            error.error &&
            typeof error.error == 'object' &&
            error.error.message
          ) {
            this.errorMessage = error.error.message;
            return;
          }
          this.errorMessage = 'Could not fetch sub categories.';
        }
      );
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

  computeSelectedLagMeasure(): void {
    this.selectedLagMeasure = this.lagMeasureUI.find(
      (lagMeasure) => lagMeasure.lagMeasureId == this.form.get('lagMeasure').value
    );
    if (this.selectedLagMeasure) {
      this.fetchNextLagMeasureBenchmark();
    }

    this.getAllLeadMeasures();
  }

  getAllLeadMeasures(): void {
    this.fetchingLeadMeasure = true;
    this.leadController
      .myCommitmentForLagMeasure({ id: this.selectedLagMeasure.lagMeasureId })
      .subscribe({
        next: (value) => {
          this._leadMeasureAndCommitments = value.map((x) => {
            const xtended: XLeadMeasureCommitmentDto = {
              newPlayerScore: x.playerScore,
              commitmentDto: { ...x }
            };
            return xtended;
          });
        }
      })
      .add(() => {
        this.fetchingLeadMeasure = false;
      });
  }

  fetchNextLagMeasureBenchmark(): void {
    let benchmarks: LagMeasureBenchmarkPojo[] = this.selectedLagMeasure.lagMeasureBenchmark;
    benchmarks = benchmarks.sort((a, b) => a.targetValue - b.targetValue);
    this.nextLagMeasureBenchmark = benchmarks.find(
      (x) => x.targetValue > this.selectedLagMeasure.currentValue
    );
  }

  filterLagMeasures(lagId: number): void {
    this.lagMeasureOnly = this.lagMeasureUI
      .filter((x) => x.lagId == lagId)
      .map((y) => {
        const lagM: NameIdPojo = {
          id: y.lagMeasureId,
          name: y.lagMeasureName
        };
        return lagM;
      });
  }

  showLeadMeasureActions(item: XLeadMeasureCommitmentDto): void {
    this._selectedLeadMeasure = item;
  }

  markingCommitment = false;
  previewOpen = false;

  toggleMarkAs(ii: CommitmentDto): void {
    let subscription: Observable<any>;
    if (ii.completionStatus === 'COMPLETED') {
      subscription = this.leadController.markCommitmentAsNotDone({ id: ii.id });
    } else {
      subscription = this.leadController.markCommitmentAsDone({ id: ii.id });
    }

    this.markingCommitment = true;
    subscription
      .subscribe({
        next: () => {
          if (ii.completionStatus === 'COMPLETED') {
            ii.completionStatus = 'NOT_COMPLETED';
          } else {
            ii.completionStatus = 'COMPLETED';
          }
        }
      })
      .add(() => {
        this.markingCommitment = false;
      });
  }

  scrollUp(el: HTMLElement): void {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  preview(): void {
    this.submissionError = false;

    this.submissionError = this.leadMeasureAndCommitments.some(
      (x) => x.newPlayerScore < x.commitmentDto.playerScore
    );

    if (this.submissionError) {
      return;
    }

    const previewPojo: UpdateLeadMeasureScorePreviewDto = {
      wigName: this.selectedLagMeasure.wigName,
      lagName: this.selectedLagMeasure.lagName,
      lagMeasure: this.selectedLagMeasure.lagMeasureName,
      lagMeasureUOM: this.selectedLagMeasure.unit,
      lagMeasureCurrentValue: this.selectedLagMeasure.currentValue,
      lagMeasureMaxValue: this.selectedLagMeasure.maxValue,
      leadMeasureScores: this.leadMeasureAndCommitments
        .filter((x) => x.newPlayerScore != x.commitmentDto.playerScore)
        .map((y) => {
          const ne: XLeadMeasureScoreUpdateDto = {
            id: y.commitmentDto.leadMeasureId,
            name: y.commitmentDto.uom,
            currentScore: y.commitmentDto.currentValue,
            newPlayerScore: y.newPlayerScore
          };
          return ne;
        }),
      nextBenchmark: this.nextLagMeasureBenchmark
    };
    console.log(previewPojo);

    if (previewPojo.leadMeasureScores.length < 1) {
      this.xAlertMessage.messageType = 'info';
      this.xAlertMessage.message = 'Please, make update to your scores to preview';
      return;
    }

    this.xAlertMessage.message = undefined;

    this._updatedLeadMeasureIdAndNewScore = previewPojo.leadMeasureScores.map((y) => {
      const yy: IdDoublePojo = {
        id: y.id,
        newScore: parseInt(y.newPlayerScore as unknown as string)
      };
      return yy;
    });

    this.previewOpen = true;

    let ref;
    const canUpdateLagScore = this.canUpdateLageScore();

    if (!canUpdateLagScore) {
      ref = this.modalService.show(UpdateLeadMeasureScorePreviewComponent, {
        initialState: {
          dto: previewPojo
        },
        class: 'offcanvas offcanvas-end show overflow-y-auto',
        backdrop: 'static'
      });
    } else {
      ref = this.modalService.show(UpdateLagMeasureScoreComponent, {
        initialState: {
          dto: previewPojo,
          lagMeasure: this.selectedLagMeasure
        },
        class: 'offcanvas offcanvas-end show overflow-y-auto',
        backdrop: 'static'
      });
    }

    ref.content.updateEvent.subscribe((v) => {
      this.updatePlayerLeadMeasureScore(v);
    });

    ref.onHide.subscribe((v) => (this.previewOpen = false));
  }

  canUpdateLageScore(): boolean {
    return this.authenticationService.hasPermission(PermissionsEnum.CREATE_USER);
  }
}

export interface XLeadMeasureCommitmentDto {
  newPlayerScore?: number;
  commitmentDto: LeadMeasureCommitmentDto;
}

export interface XLeadMeasureScoreUpdateDto {
  id: number;
  name: string;
  currentScore: number;
  newPlayerScore: number;
}

export interface UpdateLeadMeasureScorePreviewDto {
  wigName: string;
  lagName: string;
  lagMeasure: string;
  lagMeasureUOM: string;
  lagMeasureCurrentValue: number;
  lagMeasureMaxValue: number;
  leadMeasureScores: XLeadMeasureScoreUpdateDto[];
  nextBenchmark: LagMeasureBenchmarkPojo;
}
