import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import moment from 'moment';
import { CadenceControllerService } from '../../../../../sdk/ecadence-sdk';
import { CadenceTimerObject } from '../../../pages/intranet/scoreboard/scoreboard.component';

@Component({
  selector: 'app-cadence-timer',
  templateUrl: './cadence-timer.component.html',
  styleUrls: ['./cadence-timer.component.css']
})
export class CadenceTimerComponent implements OnInit {
  minutes: number;
  seconds: number;
  overdue = false;
  timerInterval: any;
  @Output()
  isCadence: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() cadenceEventId: number;
  cadenceTimer: number;
  cadenceExtraTime = -900000;
  @Input() wigId: number;

  constructor(private cadenceControllerService: CadenceControllerService) {}

  startTimer(initialSeconds: number): void {
    const targetTime = new Date().getTime() + initialSeconds * 1000; // Convert seconds to milliseconds

    const timerInterval = setInterval(() => {
      const currentTime = new Date().getTime();
      const remainingTime = targetTime - currentTime;

      this.minutes = Math.floor(remainingTime / (1000 * 60));
      this.seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

      // if (remainingTime <= 0) {
      //   // clearInterval(timerInterval);
      //   this.overdue = true;
      //   console.log('original time has elapsed');
      // }

      if (remainingTime <= 0) {
        // Handle endCadence logic
        clearInterval(timerInterval);
        this.isCadence.emit(false); // Set isCadence to false

        if (!this.cadenceEventId) {
          return;
        }
        this.cadenceControllerService
          .endCadenceNotification({ body: this.cadenceEventId })
          .subscribe(
            (value) => {
              console.log('end of cadence call was successful: ', value);
            },
            (error: unknown) => {
              console.log(error);
              if (
                error instanceof HttpErrorResponse &&
                error.error &&
                typeof error.error == 'object' &&
                error.error.message &&
                error.status == 400
              ) {
                console.log('An error occurred:', error);
              }
            }
          );
      }
    }, 1000); // Update every second (1000 milliseconds)
  }

  ngOnInit(): void {
    this.getOngoingCadence();
    console.log('cadence timer component', this.cadenceTimer);
  }

  getOngoingCadence(): boolean {
    let value = false;
    console.log('started here');
    this.cadenceControllerService.getOngoingCadence({ wigId: this.wigId }).subscribe(
      (data) => {
        if (data) {
          console.log('there is value', data.title, data.startTime);
          const currentTime = new Date(); // Get the current date and time
          const fomartedstartTime = moment(data.startTime, 'H:mm');
          const formatedEndTime = moment(data.endTime, 'H.mm');
          const formattedEndTime = moment(data.endTime, 'H:mm');
          const currentTimeInMonment = moment(); // Get the current time

          const diffSeconds = formattedEndTime.diff(currentTimeInMonment, 'seconds');
          console.log('time difference for schedule is: ', diffSeconds);
          this.cadenceTimer = diffSeconds;
          value = true;
          this.startTimer(this.cadenceTimer);
        } else {
          console.log('no value');
          value = false;
        }
      },
      (error: unknown) => {
        // Handle errors
        console.error(error);
        console.log('no value error');
        value = false;
      }
    );
    return value;
  }

  // ngOnDestroy() {
  //   if (this.timerInterval) {
  //     clearInterval(this.timerInterval);
  //   }
  // }
}
