/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentDto } from './attachment-dto';
import { LagMeasurePojo } from './lag-measure-pojo';


export interface LagPojo { 
    lagName: string;
    wigId: number;
    description: string;
    influencer?: string;
    startDate: string;
    endDate: string;
    desiredDirection?: LagPojo.DesiredDirectionEnum;
    lagMeasures?: Array<LagMeasurePojo>;
    customerName?: string;
    reviewer?: string;
    priorityOrder?: number;
    supportingDocument?: Array<AttachmentDto>;
}
export namespace LagPojo {
    export type DesiredDirectionEnum = 'UP' | 'DOWN';
    export const DesiredDirectionEnum = {
        UP: 'UP' as DesiredDirectionEnum,
        DOWN: 'DOWN' as DesiredDirectionEnum
    };
}


