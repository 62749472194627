import { Component, OnInit } from '@angular/core';
import { ActivationStart, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import moment from 'moment';
import { CadenceSchedulePojo } from '../../sdk/ecadence-sdk';
import { AuthenticationService } from './services/authentication.service';
import { User } from './models/user/user.model';
import { CadenceSchedulerService } from './services/cadence-scheduler.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  currentRoute: string;
  isExtranet = false;
  hideLayout = false;
  hideIntranetNavbar = false;
  userResponse = false;
  title = 'ecadence';
  user?: User;
  private loading: boolean;
  showWelcome = false;
  schedules: CadenceSchedulePojo;
  _show = false;
  countdown$: Observable<string>;
  private scheduleReminder: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    null
  );

  constructor(
    private router: Router,
    private cadenceScheduleService: CadenceSchedulerService,
    private authenticationService: AuthenticationService
  ) {
    this.countdown$ = this.scheduleReminder.asObservable();
    this.authenticationService.getUser().subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
    this.cadenceScheduleService.schedules$.subscribe((schedules: CadenceSchedulePojo) => {
      this.schedules = schedules;
      if (moment().diff(moment(schedules?.endTime, 'H:mm'), 'seconds')) {
        this.cadenceScheduleService.cadenceEventId.next(undefined);
      }
      this._show = true;
      this.cadenceScheduleService.saveCadenceData(schedules, 100); // Save the schedules data
    });
    const countDownFromStorage = localStorage.getItem('countdown');
    if (countDownFromStorage) {
      this.scheduleReminder.next(countDownFromStorage);
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe((data) => {
      if (data instanceof ActivationStart) {
        if (data) {
          this.isExtranet = data.snapshot.data['isExtranet'];
          this.hideLayout = data.snapshot.data['hideLayout'];
          this.hideIntranetNavbar = data.snapshot.data['hideIntranetNavbar'] || false;
          this.showWelcome = data.snapshot.url.join('/') === 'dashboard';
        }
      } else if (data instanceof NavigationStart) {
        this.loading = true;
      } else if (data instanceof NavigationEnd) {
        this.loading = false;
      }
    });

    this.router.url;
    this.authenticationService.fetchUser().subscribe(
      (user) => {
        this.user = user;
        this.userResponse = true;
      },
      (error: unknown) => {
        this.userResponse = true;
      }
    );
  }

  setLayout(hide: boolean): void {
    this.hideLayout = hide;
  }

  get showLoading(): boolean {
    return !this.userResponse || this.loading;
  }

  get showDashboardLayout(): boolean {
    return this.user && !this.isExtranet && !this.hideLayout && !this.showLoading;
  }

  get showContent(): boolean {
    return !this.showLoading;
  }

  hide(event: any): void {
    this._show = false;
  }
}
