/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LeadMeasureSearchFilter { 
    offset?: number;
    limit?: number;
    order?: LeadMeasureSearchFilter.OrderEnum;
    orderColumn?: string;
    leadMeasureId?: number;
    wigId?: number;
    lagId?: number;
    lagMeasureId?: number;
    desiredDirection?: LeadMeasureSearchFilter.DesiredDirectionEnum;
    completionStatus?: LeadMeasureSearchFilter.CompletionStatusEnum;
    uom?: string;
    reviewStatus?: LeadMeasureSearchFilter.ReviewStatusEnum;
    createdAfter?: string;
    createdBefore?: string;
}
export namespace LeadMeasureSearchFilter {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        ASC: 'ASC' as OrderEnum,
        DESC: 'DESC' as OrderEnum
    };
    export type DesiredDirectionEnum = 'UP' | 'DOWN';
    export const DesiredDirectionEnum = {
        UP: 'UP' as DesiredDirectionEnum,
        DOWN: 'DOWN' as DesiredDirectionEnum
    };
    export type CompletionStatusEnum = 'COMPLETED' | 'IN_REVIEW' | 'NOT_COMPLETED';
    export const CompletionStatusEnum = {
        COMPLETED: 'COMPLETED' as CompletionStatusEnum,
        IN_REVIEW: 'IN_REVIEW' as CompletionStatusEnum,
        NOT_COMPLETED: 'NOT_COMPLETED' as CompletionStatusEnum
    };
    export type ReviewStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ReviewStatusEnum = {
        APPROVED: 'APPROVED' as ReviewStatusEnum,
        PENDING: 'PENDING' as ReviewStatusEnum,
        DISAPPROVED: 'DISAPPROVED' as ReviewStatusEnum
    };
}


