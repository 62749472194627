import { Component, Input, OnInit } from '@angular/core';
import { ScoreboardControllerService, UserWithoutWigPojo } from '../../../../../sdk/ecadence-sdk';

@Component({
  selector: 'user-without-wig-card',
  templateUrl: './user-without-wig-card.component.html',
  styleUrls: ['./user-without-wig-card.component.css']
})
export class UserWithoutWigCardComponent implements OnInit {
  usersWithoutWig: UserWithoutWigPojo[];
  @Input() showUsers: boolean;
  showUsersWithoutWig: boolean;

  constructor(private scoreboardControllerService: ScoreboardControllerService) {}

  ngOnInit(): void {
    this.showUsersWithoutWig = this.showUsers;
    this.getUsersWithoutAssignedWigs();
  }

  getUsersWithoutAssignedWigs(): void {
    this.scoreboardControllerService.getUsersWithoutAssignedWig().subscribe(
      (res) => {
        this.usersWithoutWig = res;
      },
      (error: unknown) => {
        console.log('error ==>', error);
      }
    );
  }
}
