/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccountMembership } from './portal-account-membership';


export interface AccountMembershipPojo { 
    accountName?: string;
    accountCode?: string;
    accountId?: number;
    enableSubscriptions?: boolean;
    accountType?: AccountMembershipPojo.AccountTypeEnum;
    roles?: Set<string>;
    membership?: PortalAccountMembership;
    permissions?: Set<AccountMembershipPojo.PermissionsEnum>;
    accountLogoId?: number;
}
export namespace AccountMembershipPojo {
    export type AccountTypeEnum = 'SYSTEM' | 'ORGANIZATION';
    export const AccountTypeEnum = {
        SYSTEM: 'SYSTEM' as AccountTypeEnum,
        ORGANIZATION: 'ORGANIZATION' as AccountTypeEnum
    };
    export type PermissionsEnum = 'CREATE_USER' | 'UPDATE_USER' | 'VIEW_USER' | 'DEACTIVATE_USER' | 'DELETE_USER' | 'CREATE_COMMITMENT' | 'UPDATE_COMMITMENT' | 'UPDATE_SETTING' | 'VIEW_SETTING' | 'DELETE_SETTING' | 'VIEW_LOG' | 'CREATE_WIG' | 'UPDATE_WIG' | 'DEACTIVATE_WIG' | 'VIEW_WIG' | 'DELETE_WIG' | 'CREATE_LAG' | 'VIEW_LAG' | 'UPDATE_LAG' | 'DEACTIVATE_LAG' | 'DELETE_LAG' | 'CREATE_CUSTOMER' | 'UPDATE_CUSTOMER' | 'DELETE_CUSTOMER' | 'DEACTIVATE_CUSTOMER' | 'VIEW_CUSTOMER' | 'CREATE_TEAM' | 'UPDATE_TEAM' | 'VIEW_TEAM' | 'DELETE_TEAM' | 'DEACTIVATE_TEAM' | 'START_CADENCE' | 'VIEW_SCOREBOARD' | 'DIRECT_EDIT_LAG_MEASURE_SCORE' | 'CREATE_LEAD_MEASURE' | 'UPDATE_LEAD_MEASURE' | 'VIEW_LEAD_MEASURE' | 'DELETE_LEAD_MEASURE' | 'REQUEST_LEAD_MEASURE' | 'EXECUTE_LEAD_MEASURE' | 'CREATE_UNIT_OF_MEASURE' | 'UPDATE_UNIT_OF_MEASURE' | 'DELETE_UNIT_OF_MEASURE' | 'CREATE_EXTERNAL_INFLUENCER' | 'CREATE_BLOCKER' | 'UPDATE_BLOCKER' | 'DELETE_BLOCKER' | 'VIEW_BLOCKER' | 'UPDATE_BLOCKER_STATUS' | 'ASSIGN_BLOCKER' | 'VIEW_CHECK_REQUEST' | 'UPDATE_CHECK_REQUEST' | 'MARK_LAG_KPI';
    export const PermissionsEnum = {
        CREATE_USER: 'CREATE_USER' as PermissionsEnum,
        UPDATE_USER: 'UPDATE_USER' as PermissionsEnum,
        VIEW_USER: 'VIEW_USER' as PermissionsEnum,
        DEACTIVATE_USER: 'DEACTIVATE_USER' as PermissionsEnum,
        DELETE_USER: 'DELETE_USER' as PermissionsEnum,
        CREATE_COMMITMENT: 'CREATE_COMMITMENT' as PermissionsEnum,
        UPDATE_COMMITMENT: 'UPDATE_COMMITMENT' as PermissionsEnum,
        UPDATE_SETTING: 'UPDATE_SETTING' as PermissionsEnum,
        VIEW_SETTING: 'VIEW_SETTING' as PermissionsEnum,
        DELETE_SETTING: 'DELETE_SETTING' as PermissionsEnum,
        VIEW_LOG: 'VIEW_LOG' as PermissionsEnum,
        CREATE_WIG: 'CREATE_WIG' as PermissionsEnum,
        UPDATE_WIG: 'UPDATE_WIG' as PermissionsEnum,
        DEACTIVATE_WIG: 'DEACTIVATE_WIG' as PermissionsEnum,
        VIEW_WIG: 'VIEW_WIG' as PermissionsEnum,
        DELETE_WIG: 'DELETE_WIG' as PermissionsEnum,
        CREATE_LAG: 'CREATE_LAG' as PermissionsEnum,
        VIEW_LAG: 'VIEW_LAG' as PermissionsEnum,
        UPDATE_LAG: 'UPDATE_LAG' as PermissionsEnum,
        DEACTIVATE_LAG: 'DEACTIVATE_LAG' as PermissionsEnum,
        DELETE_LAG: 'DELETE_LAG' as PermissionsEnum,
        CREATE_CUSTOMER: 'CREATE_CUSTOMER' as PermissionsEnum,
        UPDATE_CUSTOMER: 'UPDATE_CUSTOMER' as PermissionsEnum,
        DELETE_CUSTOMER: 'DELETE_CUSTOMER' as PermissionsEnum,
        DEACTIVATE_CUSTOMER: 'DEACTIVATE_CUSTOMER' as PermissionsEnum,
        VIEW_CUSTOMER: 'VIEW_CUSTOMER' as PermissionsEnum,
        CREATE_TEAM: 'CREATE_TEAM' as PermissionsEnum,
        UPDATE_TEAM: 'UPDATE_TEAM' as PermissionsEnum,
        VIEW_TEAM: 'VIEW_TEAM' as PermissionsEnum,
        DELETE_TEAM: 'DELETE_TEAM' as PermissionsEnum,
        DEACTIVATE_TEAM: 'DEACTIVATE_TEAM' as PermissionsEnum,
        START_CADENCE: 'START_CADENCE' as PermissionsEnum,
        VIEW_SCOREBOARD: 'VIEW_SCOREBOARD' as PermissionsEnum,
        DIRECT_EDIT_LAG_MEASURE_SCORE: 'DIRECT_EDIT_LAG_MEASURE_SCORE' as PermissionsEnum,
        CREATE_LEAD_MEASURE: 'CREATE_LEAD_MEASURE' as PermissionsEnum,
        UPDATE_LEAD_MEASURE: 'UPDATE_LEAD_MEASURE' as PermissionsEnum,
        VIEW_LEAD_MEASURE: 'VIEW_LEAD_MEASURE' as PermissionsEnum,
        DELETE_LEAD_MEASURE: 'DELETE_LEAD_MEASURE' as PermissionsEnum,
        REQUEST_LEAD_MEASURE: 'REQUEST_LEAD_MEASURE' as PermissionsEnum,
        EXECUTE_LEAD_MEASURE: 'EXECUTE_LEAD_MEASURE' as PermissionsEnum,
        CREATE_UNIT_OF_MEASURE: 'CREATE_UNIT_OF_MEASURE' as PermissionsEnum,
        UPDATE_UNIT_OF_MEASURE: 'UPDATE_UNIT_OF_MEASURE' as PermissionsEnum,
        DELETE_UNIT_OF_MEASURE: 'DELETE_UNIT_OF_MEASURE' as PermissionsEnum,
        CREATE_EXTERNAL_INFLUENCER: 'CREATE_EXTERNAL_INFLUENCER' as PermissionsEnum,
        CREATE_BLOCKER: 'CREATE_BLOCKER' as PermissionsEnum,
        UPDATE_BLOCKER: 'UPDATE_BLOCKER' as PermissionsEnum,
        DELETE_BLOCKER: 'DELETE_BLOCKER' as PermissionsEnum,
        VIEW_BLOCKER: 'VIEW_BLOCKER' as PermissionsEnum,
        UPDATE_BLOCKER_STATUS: 'UPDATE_BLOCKER_STATUS' as PermissionsEnum,
        ASSIGN_BLOCKER: 'ASSIGN_BLOCKER' as PermissionsEnum,
        VIEW_CHECK_REQUEST: 'VIEW_CHECK_REQUEST' as PermissionsEnum,
        UPDATE_CHECK_REQUEST: 'UPDATE_CHECK_REQUEST' as PermissionsEnum,
        MARK_LAG_KPI: 'MARK_LAG_KPI' as PermissionsEnum
    };
}


