import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { HelperService } from '../../../../../services/helper.service';
import {
  CommitmentControllerService,
  LeverageControllerService,
  ScoreboardControllerService,
  TeamWigCommitmentPojo
} from '../../../../../../../sdk/ecadence-sdk';
import { SuccessDialogComponent } from '../../../../../shared/components/success-dialog/success-dialog.component';
import { ConfirmDialogComponent } from '../../../../../shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { DisapprovalDialogComponent } from '../../../../../shared/components/dialogs/disapproval-dialog/disapproval-dialog.component';

@Component({
  selector: 'commitment-card',
  templateUrl: './commitment-card.component.html',
  styleUrls: ['./commitment-card.component.css']
})
export class CommitmentCardComponent {
  @Input() commitment: TeamWigCommitmentPojo;

  constructor(
    public helperService: HelperService,
    private laverageControllerService: LeverageControllerService,
    private scoreboardControllerService: ScoreboardControllerService,
    private commitmentController: CommitmentControllerService,
    private bsModalService: BsModalService
  ) {}

  markCommitmentAsDone(commitmentId: number): void {
    this.laverageControllerService.markCommitmentAsDone({ id: commitmentId }).subscribe(
      (res) => {
        console.log('done');
      },
      (error: unknown) => {
        console.log('error', error);
      }
    );
  }

  markCommitmentAsNotdone(commitmentId: number): void {
    this.laverageControllerService.markCommitmentAsNotDone({ id: commitmentId }).subscribe(
      (res) => {
        console.log('done');
      },
      (error: unknown) => {
        console.log('error', error);
      }
    );
  }

  deleteCommitment(commitment: TeamWigCommitmentPojo): void {
    const reasonModal = this.bsModalService.show(DisapprovalDialogComponent, {
      initialState: {
        headerMessage: 'Delete Commitment',
        promptText: 'Kindly provide the reason for deleting this commitment',
        formLabel: 'Cancellation Reason',
        successMessage: 'Commitment deleted'
      },
      class: ''
    });

    reasonModal.content.confirmClicked.subscribe({
      next: (val: string) => {
        reasonModal.content.loading = true;
        this.commitmentController
          .deleteCommitment({
            id: commitment.commitmentId,
            reason: val
          })
          .subscribe({
            next: () => {
              commitment.cancelled = true;
              commitment.cancellationNote = val;
              reasonModal.content.showSuccess = true;
            },
            error: (err: unknown) => {
              reasonModal.content.xAlertMessage.messageType = 'danger';
              reasonModal.content.xAlertMessage.message =
                err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
                  ? err.error.message
                  : 'Unable to delete commitment';
            }
          })
          .add(() => {
            reasonModal.content.loading = false;
          });
      }
    });
  }

  // this.showSuccessModal("User's details updated successfully");

  showSuccessModal(message: string): void {
    const bsModalRef1 = this.bsModalService.show(SuccessDialogComponent, {
      initialState: {
        title: 'Success',
        message: message,
        onClose: () => {
          this.bsModalService.hide();
          // Reload the page
          location.reload();
        }
      },
      class: 'modal-sm modal-dialog-centered',
      keyboard: false,
      backdrop: 'static'
    });
    bsModalRef1.content.closeEvent.subscribe((result: any) => {
      console.log('done');
    });
  }

  actionConfirmationModal(message: string, action: string, commitmentId: number): void {
    const bsModalRef2 = this.bsModalService.show(ConfirmDialogComponent, {
      initialState: {
        body: message,
        positiveButtonText: 'Yes',
        negativeButtonText: 'No'
      },
      class: 'modal-sm modal-dialog-centered',
      keyboard: false,
      backdrop: 'static'
    });
    bsModalRef2.content.confirm.subscribe((result: any) => {
      bsModalRef2.hide();
      if (action === 'markCommitmentAsDone') {
        this.markCommitmentAsDone(commitmentId);
        this.showSuccessModal('Commitment marked as done');
        setTimeout(() => {
          // Reload the page
          location.reload();
        }, 1000);
        // location.reload();
      } else if (action === 'markCommitmentAsNotdone') {
        this.markCommitmentAsNotdone(commitmentId);
        this.showSuccessModal('Commitment marked as not done');
        // setTimeout(function() {
        //   // Reload the page
        //   location.reload();
        // }, 1000);
        // location.reload();
      }
    });
  }

  protected readonly JSON = JSON;
}
