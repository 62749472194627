<header>
  <div>
    <nav  class="navbar navbar-expand-lg fixed-top" [ngClass]="{ 'navbar-scrolled': isScrolled }" >
      <div class="container">
        <a class="navbar-brand" href="#"
          ><img [src]="logoSrc" class="img-fluid" alt="" width="130"
        /></a>
        <button
          class="navbar-toggler  "
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="iconify fs-3" data-icon="ci:menu-alt-01"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarScroll" >
          <ul
            class="navbar-nav mx-auto my-2 my-lg-0 navbar-nav-scroll"
            style="--bs-scroll-height: 100px"
          >
            <li class="nav-item">
              <a
                class="nav-link active-extranet px-5 py-1 fw-normal font-family-secondary"
                aria-current="page"
                href="#"
                >Home</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link px-5 py-1 fw-normal font-family-secondary" href="#about"
                >About Us</a
              >
            </li>
<!--            <li class="nav-item">-->
<!--              <a class="nav-link px-5 py-1 fw-normal font-family-secondary" href="#subscription"-->
<!--                >Subscription-->
<!--              </a>-->
<!--            </li>-->
            <li class="nav-item">
              <a class="nav-link px-5 py-1 fw-normal font-family-secondary" routerLink="contact-us">
                Contact Us</a
              >
            </li>
          </ul>
          <div class="d-flex">
<!--            <div class="dropdown">-->
<!--              <button-->
<!--                class="btn btn-outline-light bg-transparent me-2 border-0 dropdown-toggle"-->
<!--                type="button"-->
<!--                data-bs-toggle="dropdown"-->
<!--                aria-expanded="false"-->
<!--              >-->
<!--                <span class="iconify" data-icon="mdi:web"></span> EN-->
<!--              </button>-->
<!--              <ul class="dropdown-menu pt-0">-->
<!--                <li><a class="dropdown-item" href="#">EN</a></li>-->
<!--                <li><a class="dropdown-item" href="#">FR</a></li>-->
<!--                <li><a class="dropdown-item" href="#">ES</a></li>-->
<!--              </ul>-->
<!--            </div>-->

            <!-- <button class="btn btn-outline-light px-4 me-2">Sign In</button> -->
            <button class="btn btn-primary px-4" (click)="login()">Sign In</button>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <div id="headerCarousel" class="carousel slide">
    <div class="carousel-indicators justify-content-md-end justify-content-center py-md-5 py-2">
      <button
        type="button"
        data-bs-target="#headerCarousel"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#headerCarousel"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      ></button>
      <button
        type="button"
        data-bs-target="#headerCarousel"
        data-bs-slide-to="2"
        aria-label="Slide 3"
      ></button>
    </div>

    <div class="carousel-inner">
      <div class="carousel-item active">
        <picture>
          <source media="(max-width: 576px)" srcset="/assets/img/slide_1_small.webp" />
          <source media="(max-width: 768px)" srcset="/assets/img/slide_1_mediuml.webp" />
          <source media="(max-width: 1024px)" srcset="/assets/img/slide_1_mediuml.webp" />
          <img src="/assets/img/slide_1.png" class="d-block w-100" alt="..." />
        </picture>
        <div class="carousel-caption landing_page text-start d-block">
          <div class="container">
            <div class="row">
              <div class="col-md-8 col-xl-6 col-12">
                <h1 class="text-light" style="font-size: 2rem; line-height: 127.1%">
                  Unleash Your Potential, Embrace Success with <br />
                  <span class="fw-medium" style="color: rgba(167, 174, 203, 1); font-size: 2rem">
                    e-cadence</span
                  >
                </h1>
                <p
                  class="text-light fw-normal my-md-5 my-3 paragraph col-md-10 col-12"
                  style="text-align: justify"
                >
                  Your innovative platform designed to automate executions and revolutionise goal
                  achievement. Whether you're an individual or a business, e-cadence is here to
                  empower you with advanced features and a user-friendly interface.
                </p>

                <button class="btn btn-light px-5 text-primary mt-4 mt-md-0" (click)="login()">Get Started</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <picture>
          <source media="(max-width: 576px)" srcset="/assets/img/slide_2_small.png" />
          <source media="(max-width: 768px)" srcset="/assets/img/slide_2_mediuml.webp" />
          <source media="(max-width: 1024px)" srcset="/assets/img/slide_2_mediuml.webp" />
          <img src="/assets/img/slide_2.png" class="d-block w-100" alt="..." />
        </picture>

        <div class="carousel-caption landing_page text-start d-block">
          <div class="container-md">
            <div class="row">
              <div class="col-md-8 col-xl-6 col-12">
                <h1 class="text-light" style="font-size: 2rem; line-height: 127.1%">
                  Unlock Your Brilliance, Embrace Limitless Possibilities with
                  <br class="d-none d-md-block" />
                  <span class="fw-medium" style="color: rgba(167, 174, 203, 1); font-size: 2rem">
                    e-cadence</span
                  >
                </h1>
                <p class="text-light fw-normal my-md-5 my-3 paragraph col-md-10 col-12">
                  e-cadence is an innovative platform that automates executions and revolutionizes
                  goal achievement. It empowers individuals and businesses to unlock their
                  potential, providing tools for seamless execution and transformative success.
                </p>

                <button class="btn btn-light px-5 text-primary mt-4 mt-md-0" (click)="login()" >Get Started</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <picture>
          <source media="(max-width: 576px)" srcset="/assets/img/slide_3_small.png" />
          <source media="(max-width: 768px)" srcset="/assets/img/slide_3_mediuml.webp" />
          <source media="(max-width: 1024px)" srcset="/assets/img/slide_3_mediuml.webp" />
          <img src="/assets/img/slide_3.png" class="d-block w-100" alt="..." />
        </picture>
        <div class="carousel-caption landing_page text-start d-block">
          <div class="container-md">
            <div class="row">
              <div class="col-md-8 col-xl-6 col-12">
                <h1 class="text-light" style="font-size: 2rem; line-height: 127.1%">
                  Unlock Your Brilliance, Embrace Limitless Possibilities with <br />
                  <span class="fw-medium" style="color: rgba(167, 174, 203, 1); font-size: 2rem">
                    e-cadence</span
                  >
                </h1>
                <p class="text-light fw-normal my-md-5 my-3 paragraph col-md-10 col-12">
                  e-cadence is a groundbreaking platform that revolutionizes goal achievement by
                  automating processes and empowering users. Whether individuals or businesses,
                  e-cadence offers a comprehensive toolkit for seamless execution and success.
                </p>

                <button class="btn btn-light px-5 text-primary mt-4 mt-md-0" (click)="login()">
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<section class="my-md-5">
  <div class="container-md">
    <div class="row">
      <div class="card_feat p-4 py-5">
        <div class="row row-cols-xl-4 row-cols-md-2 row-cols-1">
          <div class="col mb-4 mb-xl-0">
            <div class="d-flex">
              <span class="iconify fs-2 me-3" data-icon="flat-color-icons:workflow"></span>
              <div>
                <p class="fw-bold">Enhanced Efficiency</p>
                <p class="small text-muted">Streamline workflows, eliminating manual processes.</p>
              </div>
            </div>
          </div>
          <div class="col mb-4 mb-xl-0">
            <div class="d-flex">
              <span
                class="iconify fs-2 me-3"
                data-icon="flat-color-icons:data-configuration"
              ></span>
              <div>
                <p class="fw-bold">Insightful Data</p>
                <p class="small text-muted">Provides valuable insights on performance metrics.</p>
              </div>
            </div>
          </div>
          <div class="col mb-4 mb-md-0">
            <div class="d-flex">
              <span
                class="iconify fs-2 me-3 text-primary"
                data-icon="fluent:target-arrow-16-regular"
              ></span>
              <div>
                <p class="fw-bold">Increased Accuracy</p>
                <p class="small text-muted">
                  Reduces risk of errors by following predefined rules consistently.
                </p>
              </div>
            </div>
          </div>
          <div class="col mb-4 mb-md-0">
            <div class="d-flex">
              <span class="iconify fs-2 me-3" data-icon="emojione:balance-scale"></span>
              <div>
                <p class="fw-bold">Scalability and Adaptability</p>
                <p class="small text-muted">
                  Handle growing workloads and adapt to business needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section_dark_bg" id="about">
  <div class="container">
    <div class="row py-5">
      <div class="col-md-6 col-12">
        <p class="paragraph text-light opacity-50 my-3">
          The fundamental step in the execution automation process.
        </p>
        <h1 class="text-light" style="font-size: 3rem">
          Documenting <br />
          Your Goals
        </h1>

        <div class="my-5">
          <p class="text-light fw-bold paragraph fs-18 mb-2">Goal Definition and Alignment</p>
          <p
            class="paragraph fw-normal text-light opacity-50 col-md-10 col-12"
            style="text-align: justify"
          >
            e-Cadence helps you clearly define and document your goals as WIGS, articulate the
            specific objectives, desired outcomes, and measurable targets. Ensuring that your
            documented goals align with the broader vision and strategy of your organisation or
            personal aspirations. This ensures consistency and coherence in your execution efforts.
          </p>
        </div>

        <button class="btn btn-primary px-5 mt-4" (click)="login()">Get Started</button>
      </div>
    </div>
  </div>
</section>

<section class="my-5">
  <div class="container">
    <div class="row">
      <div class="d-md-flex justify-content-between align-items-center">
        <div class="col-12 col-md-6 text-center">
          <img src="/assets/img/document_goal.webp" class="img-fluid" alt="" />
        </div>

        <div class="col-12 col-md-6 text-center text-md-start mt-4 mt-md-0">
          <div class="col-md-9 col mb-5">
            <p class="fw-bold text-primary text-start fs-18 mb-2">
              Integration and Execution Workflow
            </p>
            <p class="paragraph" style="text-align: justify">
              Use your documented goals to prioritise tasks and activities within the execution
              automation system. Identify which tasks contribute directly to the achievement of your
              goals, enabling efficient resource allocation and focus.
            </p>
          </div>

          <div class="col-md-9 col-12">
            <p class="fw-bold text-primary text-start fs-18 mb-2">
              Progress Tracking and Iterative Improvement
            </p>
            <p class="paragraph" style="text-align: justify">
              Leverage our execution automation system to track and monitor the progress of your
              goals in real-time. Monitor key performance indicators (KPIs) and milestones to assess
              the effectiveness of your goal pursuit. Analyse the data collected to gain insights
              into your goal progress. Suggest areas of improvement, refine goals, adjust
              strategies, and optimise workflows to enhance overall effectiveness.
            </p>

            <p class="mt-5 paragraph text-start">
              Document your goals, automate, and achieve purposeful execution.
            </p>
          </div>

          <button class="btn btn-primary px-5 mt-4" (click)="login()">Get Started</button>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="my-5">
  <div class="container-md">
    <div class="row row-cols-xl-2 row-cols-1 gx-md-5 ps-0">
      <div class="col-xl-8 col-12 visualise p-md-5 p-3">
        <div class="p-md-4 p-2">
          <p class="text-light my-3">Gaining clarity and insight.</p>
          <h1 class="text-light" style="font-size: 3rem">
            Visualise Your <br />
            Battles
          </h1>

          <div class="my-5">
            <p class="fw-bold text-light fs-18 mb-2">Goal Definition and Alignment</p>
            <p class="text-light paragraph opacity-75 small">
              e-Cadence helps you clearly define and document your goals as WIGS, articulate the
              specific objectives, desired outcomes, and measurable targets. Ensuring that your
              documented goals align with the broader vision and strategy of your organisation or
              personal aspirations. This ensures consistency and coherence in your execution
              efforts.
            </p>
          </div>

          <button class="btn btn-light text-primary px-5" (click)="login()">Get Started</button>
        </div>
      </div>

      <div class="col-xl-4 col-12 pe-0 ps-0 ps-md-2">
        <img src="/assets/img/writing_man.webp" class="img-fluid" alt="" />
      </div>
    </div>
  </div>
</section>

<section class="my-5">
  <div class="container">
    <div class="d-flex flex-column align-items-center justify-content-center my-5">
      <div class="text-center mt-5">
        <h1 class="" style="font-size: 3rem">Achieving Your Dreams</h1>
        <p class="my-3 text-center small">
          Ecadence empowers individuals and teams to efficiently pursue their dreams
          <br class="d-none d-md-block" />
          and turn them into tangible achievements.
        </p>
      </div>
    </div>
    <div class="d-md-flex justify-content-between align-items-center">
      <div class="col-12 col-md-6 text-center">
        <img src="/assets/img/dreams.png" class="img-fluid" alt="" />
      </div>

      <div class="col-12 col-md-6 text-center text-md-start mt-4 mt-md-0">
        <div class="col-md-9 col mb-5">
          <p class="fw-bold text-primary fs-18 mb-2">Execution Automation</p>
          <p class="paragraph">
            e-Cadence leverages automation to streamline workflows, optimise task management, and
            save time and effort. This allows individuals and teams to focus more and achieving
            goals efficiently.
          </p>
        </div>

        <div class="col-md-9 col-12">
          <p class="fw-bold text-primary fs-18 mb-2">Team Management</p>
          <p class="paragraph">
            e-Cadence provides collaboration features that facilitate effective team collaboration,
            coordination, and alignment. By managing teams within e-Cadence, individuals can
            leverage collective expertise, skills, and resources to accelerate progress towards
            achieving the organisations dream.
          </p>
        </div>

        <button class="btn btn-primary px-5" (click)="login()">Get Started</button>
      </div>
    </div>
  </div>
</section>

<section class="my-5">
  <div class="container py-4">
    <div class="row">
      <div
        class="d-flex flex-column-reverse flex-md-row justify-content-md-between align-items-md-center"
      >
        <div class="col-12 col-md-6 text-center text-md-start mt-4 mt-md-0">
          <div class="col-md-9 col mb-5">
            <p class="fw-bold text-primary fs-18 mb-2">Integration and Execution Workflow</p>
            <p class="paragraph">
              e-Cadence enables the management and tracking of WIGs. Users can define their WIGs,
              break them down into actionable tasks, assign responsibilities, and track progress.
              This ensures focus, accountability, and alignment with dreams, bringing them closer to
              reality.
            </p>
          </div>

          <div class="col-md-9 col-12">
            <p class="fw-bold text-primary fs-18 mb-2">Continuous Improvement</p>
            <p class="paragraph" style="text-align: justify">
              e-Cadence fosters a continuous improvement mindset through insights, feedback, and
              data analytics. Users can analyze progress, identify areas for enhancement, and refine
              strategies. This iterative approach enables individuals and teams to adapt and refine
              their execution plans, driving them towards the achievement of their dreams.
            </p>
          </div>

          <div class="mt-4">
            <button class="btn btn-primary px-5" (click)="login()">Get Started</button>
          </div>
        </div>

        <div class="col-12 col-md-6 text-center">
          <img src="/assets/img/wig_goal_achieved.png" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
</section>

<!--<section class="my-5 py-5" id="subscription">-->
<!--  <div class="container">-->
<!--    <div class="row">-->
<!--      <div class="d-flex flex-column align-items-center justify-content-center">-->
<!--        <div class="text-center col-xl-6">-->
<!--          <p class="my-3">Subscription Plans</p>-->
<!--          <h1 class="">Transparent Pricing For Exceptional Team</h1>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row row-cols-xl-3 row-cols-1 g-4 px-md-5">-->
<!--        <div class="col">-->
<!--          <div class="card p-4">-->
<!--            <img src="/assets/img/primary_icon.png" width="42" class="img-fluid" alt="" />-->

<!--            <div>-->
<!--              <div class="my-5">-->
<!--                <p class="fw-bold mt-4 fs-18">Basic Cadence</p>-->
<!--                <p class="fs-14 text-muted mt-2 col-lg-10">-->
<!--                  Suitable for individuals, small teams and start-ups.-->
<!--                </p>-->
<!--              </div>-->

<!--              <h3 class="my-4">-->
<!--                $9.99<sup><span class="fw-normal fs-12">/month</span></sup>-->
<!--              </h3>-->

<!--              <div class="px-2 px-md-3 px-xl-4 my-4">-->
<!--                <ul class="gap-4">-->
<!--                  <li class="small text-muted my-3">-->
<!--                    Access to basic execution automation tools and features.-->
<!--                  </li>-->
<!--                  <li class="small text-muted my-3">Goal documentation and tracking.</li>-->
<!--                  <li class="small text-muted my-3">Task management and progress tracking.</li>-->
<!--                  <li class="small text-muted my-3">Limited team collaboration capabilities.</li>-->
<!--                  <li class="small text-muted my-3">-->
<!--                    Visualisation of individual progress towards goals.-->
<!--                  </li>-->
<!--                  <li class="small text-muted my-3">24/7 Full email support.</li>-->
<!--                </ul>-->
<!--              </div>-->
<!--              <div class="d-flex flex-column mt-5">-->
<!--                <button class="btn btn-primary" (click)="login()">Get Started</button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col">-->
<!--          <div class="card p-4 bg-dark">-->
<!--            <span-->
<!--              class="position-absolute badge fw-bold end-0 pe-4 rounded-pill text-bg-warning fs-14"-->
<!--            >-->
<!--              Popular-->
<!--            </span>-->
<!--            <img src="/assets/img/secondary_icon.png" width="42" class="img-fluid" alt="" />-->

<!--            <div>-->
<!--              <div class="my-5">-->
<!--                <p class="fw-bold mt-4 fs-22 text-light">Pro Cadence</p>-->
<!--                <p class="fs-14 mt-2 col-lg-10 opacity-75 text-light">-->
<!--                  Key Features (Includes all Basic eCadence features, plus)-->
<!--                </p>-->
<!--              </div>-->

<!--              <h3 class="my-4 text-light">-->
<!--                $19.99<sup><span class="fw-normal fs-12">/month</span></sup>-->
<!--              </h3>-->

<!--              <div class="px-2 px-md-3 px-xl-4 my-4">-->
<!--                <ul class="gap-4 opacity-75">-->
<!--                  <li class="small text-light my-3">-->
<!--                    Advanced execution automation tools and features.Advanced execution automation-->
<!--                    tools and features.-->
<!--                  </li>-->
<!--                  <li class="small my-3 text-light">-->
<!--                    Enhanced team collaboration and communication features.-->
<!--                  </li>-->
<!--                  <li class="small text-light my-3">Priority customer support.</li>-->
<!--                  <li class="small text-light my-3">Performance analytics and data insights.</li>-->
<!--                  <li class="small text-light my-3">-->
<!--                    Visualization of team progress and goal alignment.-->
<!--                  </li>-->
<!--                  <li class="small text-light my-3">-->
<!--                    Integration with popular productivity tools.-->
<!--                  </li>-->
<!--                  <li class="small text-light my-3">24/7 Full email support.</li>-->
<!--                </ul>-->
<!--              </div>-->
<!--              <div class="d-flex flex-column mt-5">-->
<!--                <button class="btn btn-light text-primary" (click)="login()">Get Started</button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-md col-12">-->
<!--          <div class="card p-4">-->
<!--            <img src="/assets/img/primary_icon.png" width="42" class="img-fluid" alt="" />-->

<!--            <div>-->
<!--              <div class="my-5">-->
<!--                <p class="fw-bold mt-4 fs-18">Super Cadence</p>-->
<!--                <p class="fs-14 text-muted mt-2 col-lg-10">-->
<!--                  Key Features (Includes all Pro eCadence features, plus)-->
<!--                </p>-->
<!--              </div>-->

<!--              <h3 class="my-4">-->
<!--                $29.99<sup><span class="fw-normal fs-12">/month</span></sup>-->
<!--              </h3>-->

<!--              <div class="px-2 px-md-3 px-xl-4 my-4">-->
<!--                <ul class="gap-4">-->
<!--                  <li class="small text-muted my-3">Premium execution automation capabilities.</li>-->
<!--                  <li class="small text-muted my-3">Advanced reporting and data visualization.</li>-->
<!--                  <li class="small text-muted my-3">-->
<!--                    Advanced team management features, including role-based permissions.-->
<!--                  </li>-->
<!--                  <li class="small text-muted my-3">-->
<!--                    Priority access to new features and updates.-->
<!--                  </li>-->
<!--                  <li class="small text-muted my-3">Dedicated customer success manager.</li>-->
<!--                  <li class="small text-muted my-3">24/7 Full email support.</li>-->
<!--                </ul>-->
<!--              </div>-->
<!--              <div class="d-flex flex-column mt-5">-->
<!--                <button class="btn btn-primary" (click)="login()">Get Started</button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->

<section class="my-5 py-xxl-4">
  <div class="container page_supp">
    <div class="row p-lg-5 py-5 py-md-0">
      <div class="col-lg-7">
        <h4 class="text-light my-3">
          What can we do to support your growth and enable continuous improvement?
        </h4>
        <p class="my-5 col-xl-10 text-light paragraph">
          We empower our clients to revolutionise their business practices, optimise their human
          capital, and elevate their operations, resulting in unparalleled levels of success.
        </p>

        <div class="d-flex flex-column flex-md-row justify-content-md-start">
          <button class="btn btn-light px-5 me-md-3" (click)="login()">Try e-Cadence</button>
          <button class="btn btn-outline-light px-5 mt-3 mt-md-0" routerLink="contact-us" >Contact Us</button>
        </div>
      </div>
    </div>
  </div>
</section>

<footer class="mt-5 dark_brand_color">
  <div class="container">
    <div class="row mb-5 pt-5">
      <div class="d-flex flex-column flex-md-row justify-content-center">
        <div class="col-12 col-md-3">
          <img src="/assets/img/logo_secondary.webp" width="150" class="img-fluid" alt="" />
          <p class="my-3 fw-bold text-warning">✨ Automating Execution</p>
        </div>

        <div class="col-12 col-md my-4 mt-md-0">
          <div class="d-flex justify-content-between">
            <div class="col-lg-6">
              <p class="fw-bold opacity-50 mb-2">Company</p>
              <ul class="nav flex-column">
                <li class="nav-item ps-1">
                  <a class="nav-link ps-1" aria-current="page" href="#about">About Us</a>
                </li>
<!--                <li class="nav-item ps-1">-->
<!--                  <a class="nav-link ps-1" href="#subscription">Subscription</a>-->
<!--                </li>-->
                <li class="nav-item ps-1">
                  <a class="nav-link ps-1" href="#">Contact Us</a>
                </li>
              </ul>
            </div>
<!--            <div class="col-lg-6">-->
<!--              <p class="fw-bold opacity-50 mb-2">Industries</p>-->
<!--              <ul class="nav flex-column">-->
<!--                <li class="nav-item ps-1">-->
<!--                  <a class="nav-link ps-1" aria-current="page" href="#">Retail</a>-->
<!--                </li>-->
<!--                <li class="nav-item ps-1">-->
<!--                  <a class="nav-link ps-1" href="#">Technology</a>-->
<!--                </li>-->
<!--                <li class="nav-item ps-1">-->
<!--                  <a class="nav-link ps-1" href="#">Finance & Insurance</a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
          </div>
        </div>

        <div class="col-12 col-md-3 mt-4 mt-md-0">


          <p class="text-warning fs-12 my-2 fw-bold"> Coming soon...</p>
          <div class="d-flex justify-content-between flex-md-column flex-row">
            <a href="" class="mb-4 opacity-50">
              <img src="/assets/img/apple.webp" class="img-fluid" alt="" />
            </a>
            <a href="" class="opacity-50">
              <img src="/assets/img/google.webp" class="img-fluid" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="mt-5">-->
<!--      <hr class="opacity-25 m-0" />-->
<!--      <div class="row py-3">-->
<!--        <div class="d-md-flex justify-content-md-between">-->
<!--          <div class="d-flex justify-content-md-start justify-content-center flex-wrap">-->
<!--            <a class="fw-bold mb-0 fs-13 text-decoration-none text-light" aria-current="page">-->
<!--              <span class="iconify me- fw-normal" data-icon="mdi:web"></span>-->
<!--              English-->
<!--            </a>-->
<!--            <a class="fw-normal mx-3 mb-0 fs-13 text-decoration-none text-light opacity-75"-->
<!--              >Privacy Policy</a-->
<!--            >-->
<!--            <a class="fw-normal mx-3 mb-0 fs-13 text-decoration-none text-light opacity-75"-->
<!--              >Cookies</a-->
<!--            >-->

<!--            <a class="fw-normal mx-3 mb-0 fs-13 text-decoration-none text-light opacity-75"-->
<!--              >Term of Use</a-->
<!--            >-->
<!--          </div>-->

<!--          <div class="d-flex justify-content-center mt-3 my-md-0">-->
<!--            <div class="d-flex align-items-center">-->
<!--              <a href="" class="text-light">-->
<!--                <span class="iconify me-3 fs-4 opacity-25" data-icon="ic:baseline-facebook"> </span>-->
<!--              </a>-->
<!--              <a href="" class="text-light">-->
<!--                <span class="iconify fs-4 opacity-25" data-icon="mdi:linkedin"> </span>-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <hr class="opacity-25 m-0" />

    <div class=" d-flex justify-content-center justify-content-md-start">
      <div class="d-flex align-items-center pb-3 pt-3">
        <img src="/assets/img/footer_brand_icon.png" class="me-3 img-fluid" width="32" alt="" />
        <p class="opacity-50 fs-14 fw-medium">© 2023 e-Cadence. All rights reserved</p>
      </div>
    </div>
  </div>
</footer>
