import { Component, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { BlockerSearchDto } from '../../../../../../sdk/ecadence-sdk';
import { AssignBlockerModalComponent } from '../../../../pages/intranet/leverage-mgt/modals/assign-blocker-modal/assign-blocker-modal.component';

@Component({
  selector: 'blocker-card',
  templateUrl: './blocker-card.component.html',
  styleUrls: ['./blocker-card.component.css']
})
export class BlockerCardComponent {
  _showLimit = 2;
  @Input() blockers: BlockerSearchDto[];

  @Input()
  lagId: number;

  constructor(private modalService: BsModalService, private router: Router) {}

  goToBlockerSearch(): void {
    this.router.navigate([`/leverages/blockers`], {
      queryParams: {
        lagId: this.lagId,
        resolutionStatus: 'PENDING'
      }
    });
  }

  showBlocker(blocker: BlockerSearchDto): void {
    const assignBlockerModal: BsModalRef<AssignBlockerModalComponent> = this.modalService.show(
      AssignBlockerModalComponent,
      {
        initialState: {
          blockerDto: blocker
        },
        keyboard: false,
        backdrop: 'static',
        class: ''
      }
    );
  }
}
