import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Pipe({
  name: 'relativeTime',
  pure: false // Set pure to false to make the pipe responsive
})
export class RelativeTimePipe implements PipeTransform, OnDestroy {
  private timerSubscription: Subscription;

  transform(dateString: string): string {
    this.timerSubscription?.unsubscribe();

    const now = new Date();
    const date = new Date(dateString);
    const timeDiff = Math.abs(now.getTime() - date.getTime());
    const minutesDiff = Math.floor(timeDiff / (1000 * 60));
    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (minutesDiff < 1) {
      return 'Just now';
    } else if (minutesDiff < 60) {
      return `${minutesDiff} minute${minutesDiff > 1 ? 's' : ''} ago`;
    } else if (hoursDiff < 24) {
      return `${hoursDiff} hour${hoursDiff > 1 ? 's' : ''} ago`;
    } else {
      return `${daysDiff} day${daysDiff > 1 ? 's' : ''} ago`;
    }
  }

  ngOnDestroy(): void {
    this.timerSubscription?.unsubscribe();
  }
}
