export class FormConstants {
  public static readonly NAME_MINIMUM_LENGTH = 3;
  public static readonly NAME_MAXIMUM_LENGTH = 50;
  public static readonly SHORT_NOTE_MAXIMUM_LENGTH = 250;
  public static readonly MAX_NUMBER = Number.MAX_VALUE;
  public static readonly LARGE_TEXT_FIELD_MAX_LENGTH = 1000;
  public static readonly MUST_START_WITH_ALPHABET_REGEX =
    /^[a-zA-Z0-9][a-zA-Z 0-9@#$%^&*()-=_+~`\\[\]{}|;:'",.<>/?]*$/;
  public static readonly EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
}

export interface IsoNumber {
  countryCode: string;
  dialCode: string;
  e164Number: string;
  internationalNumber: string;
  nationalNumber: string;
  number: number;
}
