import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CadenceControllerService, CadenceSchedulePojo } from '../../../sdk/ecadence-sdk';

@Injectable({
  providedIn: 'root'
})
export class CadenceSchedulerService {
  schedules$: Observable<CadenceSchedulePojo>;
  private schedulesSubject: BehaviorSubject<CadenceSchedulePojo | null> =
    new BehaviorSubject<CadenceSchedulePojo | null>(null);
  private _hasStarted = false;
  private storageKey = 'cadenceData';
  private cadenceData: { data: CadenceSchedulePojo; countdown: number } | null = null;

  public cadenceEventId: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);

  constructor(private cadenceControllerService: CadenceControllerService) {
    this.schedules$ = this.schedulesSubject.asObservable();
  }

  _countdown = 60000;
  _defaultCountdown = 60000;

  _countdownIntervalFn: number;
  _backendCallIntervalFn: number;
  _countdownInitialize = false;

  delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  startScheduler(): void {
    // console.log(this._hasStarted, ' this._hasStarted');
    if (this._hasStarted) {
      return;
    }

    const savedData = this.getCadenceData();
    if (savedData) {
      this.schedulesSubject.next(savedData.data);
    }

    // Initialize countdown and default countdown values
    this._countdown = 0;
    this._defaultCountdown = 300000; // 5 minutes in milliseconds

    this._backendCallIntervalFn = setInterval(async () => {
      if (this._countdown <= 0) {
        try {
          const value = await this.cadenceControllerService.getDueSchedule().toPromise();
          if (value) {
            this.schedulesSubject.next(value);
            console.log('Made API check for schedules');
            this.performAction(value);
            // Increase the interval time or set it to the next hour (in milliseconds)
            this._countdown = 3600000; // 1 hour in milliseconds
            console.log('countdown value after call:', this._countdown);
            const currentTime = new Date();
            const currentHour = currentTime.getHours();
            const currentMinute = currentTime.getMinutes();
            const currentSecond = currentTime.getSeconds();
            console.log(`Current time: ${currentHour}:${currentMinute}:${currentSecond}`); // Output: Current time: 15:30:00
          } else {
            console.log('Made API check with no response');
            const currentTime = new Date();
            const currentHour = currentTime.getHours();
            const currentMinute = currentTime.getMinutes();
            const currentSecond = currentTime.getSeconds();
            console.log(`Current time: ${currentHour}:${currentMinute}:${currentSecond}`); // Output: Current time: 15:30:00
          }
        } catch (error) {
          console.log('An error occurred:', error);
          const currentTime = new Date();
          const currentHour = currentTime.getHours();
          const currentMinute = currentTime.getMinutes();
          const currentSecond = currentTime.getSeconds();
          console.log(`Current time: ${currentHour}:${currentMinute}:${currentSecond}`); // Output: Current time: 15:30:00

          this._countdown += this._defaultCountdown;
          // Handle error as needed
        }
      } else {
        console.log('Skipping API call. Countdown:', this._countdown);
        this._countdown += this._defaultCountdown;
        const currentTime = new Date();
        const currentHour = currentTime.getHours();
        const currentMinute = currentTime.getMinutes();
        const currentSecond = currentTime.getSeconds();
        console.log(`Current time: ${currentHour}:${currentMinute}:${currentSecond}`); // Output: Current time: 15:30:00
      }

      this._countdown -= this._defaultCountdown;
      console.log('after subtraction', this._countdown);
    }, this._defaultCountdown);
  }

  performAction(response: CadenceSchedulePojo): void {
    console.log('printed in action', response.title);
  }

  getCadenceData(): { data: CadenceSchedulePojo; countdown: number } | null {
    if (!this.cadenceData) {
      const data = localStorage.getItem(this.storageKey);
      this.cadenceData = data ? JSON.parse(data) : null;
    }
    return this.cadenceData;
  }

  saveCadenceData(data: CadenceSchedulePojo, countdown: number): void {
    this.cadenceData = { data, countdown };
    localStorage.setItem(this.storageKey, JSON.stringify(this.cadenceData));
  }

  clearCadenceData(): void {
    localStorage.removeItem(this.storageKey);
    this.cadenceData = null;
  }
}
