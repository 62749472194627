import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NotBlankValidator } from '../../../../../shared/validators/not-blank.validator';
import { FormConstants } from '../../../../../shared/constants/form-constants';
import {
  BlockerControllerService,
  CreateBlockerDto,
  NameIdPojo,
  WigControllerService,
  WigPojo
} from '../../../../../../../sdk/ecadence-sdk';
import { XAlertMessage } from '../../../../../shared/components/alert-message/alert-message.component';
import { SuccessDialogComponent } from '../../../../../shared/components/success-dialog/success-dialog.component';
import { Utils } from '../../../../../shared/utils/utils';
import WasteTypeEnum = CreateBlockerDto.WasteTypeEnum;

@Component({
  selector: 'raise-blocker',
  templateUrl: './raise-blocker.component.html',
  styleUrls: ['./raise-blocker.component.css']
})
export class RaiseBlockerComponent implements OnInit {
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    allowSearchFilter: true,
    textField: 'name',
    idField: 'id'
  };

  formGroup: FormGroup;
  _wigPojo: WigPojo;

  @Input()
  set wigPojo(value: WigPojo) {
    this._wigPojo = value;
    this.fetchLagsForWig(this._wigPojo.id);
  }

  isFetchingLags: boolean;
  lags: NameIdPojo[];
  isFetchingWasteType: boolean;
  isFetchingBlockerTypes: boolean;
  isCreatingBlocker = false;

  wasteTypes: WasteTypeEnum[] = Utils.enumValues(WasteTypeEnum);
  blockerTypes: NameIdPojo[];

  xAlertMessage: XAlertMessage = {
    message: undefined,
    messageType: 'info'
  };

  constructor(
    private modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private blockerController: BlockerControllerService,
    private modalService: BsModalService,
    private wigController: WigControllerService
  ) {}

  closeModal(): void {
    this.modalRef.hide();
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      blockerTypeId: ['', [Validators.required]],
      wasteType: ['', [Validators.required]],
      lagIds: ['', [Validators.required]],
      possibleInfluencer: [
        '',
        [
          NotBlankValidator.validate,
          Validators.maxLength(FormConstants.NAME_MAXIMUM_LENGTH),
          Validators.pattern(FormConstants.MUST_START_WITH_ALPHABET_REGEX)
        ]
      ],
      description: [
        '',
        [
          Validators.required,
          NotBlankValidator.validate,
          Validators.minLength(FormConstants.NAME_MINIMUM_LENGTH),
          Validators.maxLength(FormConstants.LARGE_TEXT_FIELD_MAX_LENGTH)
          //Validators.pattern(FormConstants.MUST_START_WITH_ALPHABET_REGEX)
        ]
      ]
    });

    this.formGroup.valueChanges.subscribe({
      next: (ignored) => {
        this.xAlertMessage.message = undefined;
      }
    });

    this.fetchLagsForWig(this._wigPojo.id);
    this.getBlockTypes();
  }

  fetchLagsForWig(wigId: number): void {
    this.isFetchingLags = true;
    this.wigController
      .getLagsForWig({ id: wigId })
      .subscribe(
        (response: NameIdPojo[]): void => {
          this.lags = [];
          this.lags = response;
        },
        (error: unknown): void => {
          this.xAlertMessage.messageType = 'danger';
          this.xAlertMessage.message =
            error instanceof HttpErrorResponse && error.error?.message && error.error.code !== 500
              ? error.error.message
              : 'Unable to load Lags for Wig';
        }
      )
      .add(() => {
        this.isFetchingLags = false;
      });
  }

  createBLocker(): void {
    this.formGroup.markAllAsTouched();
    this.isCreatingBlocker = false;

    if (this.formGroup.invalid) {
      return;
    } else {
      this.isCreatingBlocker = true;

      const payload: CreateBlockerDto = { ...this.formGroup.value };
      payload.lagIds = (this.formGroup.get('lagIds').value as NameIdPojo[]).map((x) => x.id);

      this.blockerController
        .createBlocker({ createBlockerDto: payload })
        .subscribe({
          next: (value: unknown) => {
            this.closeModal();
            const ref = this.modalService.show(SuccessDialogComponent, {
              initialState: {
                message: `Blocker Created Successfully`
              },
              class: 'modal-dialog modal-dialog-centered modal-sm'
            });
          },
          error: (err: unknown) => {
            this.xAlertMessage.messageType = 'danger';
            this.xAlertMessage.message =
              err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
                ? err.error.message
                : 'Unable to create blocker ';
          }
        })
        .add(() => {
          this.isCreatingBlocker = false;
        });
    }
  }

  protected readonly FormConstants = FormConstants;

  getDescriptionValue(): string {
    return this.formGroup.get('description')?.value;
  }

  getFormControlName(tag: HTMLLabelElement): string {
    return tag.control.attributes.getNamedItem('formcontrolname').nodeValue;
  }

  getBlockTypes(): void {
    this.isFetchingBlockerTypes = true;
    this.blockerController
      .searchBlockerCategory()
      .subscribe({
        next: (val: NameIdPojo[]) => {
          this.blockerTypes = val;
        },
        error: (err: unknown) => {
          this.xAlertMessage.messageType = 'danger';
          this.xAlertMessage.message =
            err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
              ? err.error.message
              : 'Unable to load blocker types';
        }
      })
      .add(() => {
        this.isFetchingBlockerTypes = false;
      });
  }

  onRoleSelectionUpdate($event: Array<any> | any): undefined {
    return undefined;
  }

  onRoleSelectionRemove($event: any): undefined {
    return undefined;
  }
}
