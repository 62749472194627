import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddCommitmentComponent } from '../../scoreboard/add-commitment/add-commitment.component';
import { UpdateLeadMeasureScoreComponent } from '../../scoreboard/update-lead-measure/update-lead-measure-score.component';

@Component({
  selector: 'lead-measure-update-link-card',
  templateUrl: './lead-measure-update-link-card.component.html',
  styleUrls: ['./lead-measure-update-link-card.component.css']
})
export class LeadMeasureUpdateLinkCardComponent {
  constructor(private modalService: BsModalService) {}

  updateLeadMeasure(): void {
    const createLeadMeasureModal: BsModalRef<UpdateLeadMeasureScoreComponent> =
      this.modalService.show(UpdateLeadMeasureScoreComponent, {
        initialState: {
          wigId: 25202
        },
        keyboard: false,
        backdrop: 'static',
        class: 'offcanvas offcanvas-end show'
      });
  }
}
