<div class="form-control" disabled>
  <div class="text-end pointer">
    <p (click)="removeCommitment()" class="fs-10 text-danger mb-0 fw-normal">Remove</p>
  </div>
  <div>
    <p class="fs-14 fw-bold">Lag Measure:</p>
    <p class="fw-normal text-muted fs-12">{{ commitment.lagMeasureName }}</p>
  </div>
  <div class="my-3">
    <p class="fs-14 fw-bold">Lead Measure:</p>
    <p class="fw-normal text-muted fs-12">{{ commitment.leadmeasureName }}</p>
  </div>
  <div class="d-flex justify-content-between mt-4">
    <div class="col-3">
      <p class="fs-12 fw-bold">Score Movement:</p>
      <p class="fw-normal text-muted fs-12">{{ commitment.playerScore }}</p>
    </div>
    <div class="col-3 pe-2">
      <p class="fs-12 fw-bold">Due Date:</p>
      <p class="fw-normal text-muted fs-12">
        {{ commitment.dueAt | date  }}
      </p>
    </div>
    <div class="col-3 pe-2">
      <p class="fs-12 fw-bold">Due Time:</p>
      <p class="fw-normal text-muted fs-12">
        {{ commitment.dueAt | timeOnly : 'HH:mm' }}
      </p>
    </div>
  </div>
  <div class="col pe-2">
    <p class="fs-12 fw-bold">Lead Measure Action:</p>
    <p class="fw-normal text-muted fs-12 flex-wrap">
      {{ commitment.commitments }}
    </p>
  </div>
  <div>
    <p class="fs-12 fw-bold">Jira Id:</p>
    <p class="fw-normal text-muted fs-12 flex-wrap">
      {{ commitment.jiraId}}
    </p>
  </div>
</div>
