import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { User } from '../../models/user/user.model';
import { AuthenticationService } from '../../services/authentication.service';
import {
  AppNotificationDto,
  DashboardControllerService,
  DashboardStatisticPojo,
  NotificationControllerService
} from '../../../../sdk/ecadence-sdk';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input()
  user: User;

  @Input()
  showWelcome = false;
  salutation: string;
  dashboardStatisticsData: DashboardStatisticPojo;
  teamMembers: number;
  notifications: AppNotificationDto[];
  notificationCount = 0;
  currentRoute: string;

  constructor(
    private authenticationService: AuthenticationService,
    private dashboardControllerService: DashboardControllerService,
    private notificationControllerService: NotificationControllerService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }
  ngOnInit(): void {
    // const currentPath = this.router.url;
    this.currentRoute = window.location.pathname;

    if (this.currentRoute === '/dashboard') {
      this.showWelcome = true;
    } else {
      this.showWelcome = false;
    }
    this.getDashboardData();
    this.getCurrentDate();
  }

  login(): void {
    this.authenticationService.login({
      redirectUri: `${window.location.origin}/accounts`
    });
  }

  logout(): void {
    this.authenticationService.logout(`${window.location.origin}`).subscribe();
  }

  getDashboardData(): void {
    this.dashboardControllerService
      .dashboardStatistics()
      .subscribe((data: DashboardStatisticPojo) => {
        this.dashboardStatisticsData = data;
      });
  }

  getTeamMembers(): void {
    this.dashboardControllerService.getTotalTeamMembers().subscribe(
      (res) => {
        this.teamMembers = res;
      },
      (error: unknown) => {
        console.log('error ===>', error);
      }
    );
  }

  getCurrentDate(): void {
    const currentDateTime = new Date();
    const hour = currentDateTime.getHours();
    if (hour > 0 && hour <= 11) {
      this.salutation = 'Good Morning';
    } else if (hour > 11 && hour <= 15) {
      this.salutation = 'Good Afternoon';
    } else if (hour > 15 && hour <= 24) {
      this.salutation = 'Good Evening';
    }
  }
}
