import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { interval, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import moment from 'moment';
import {
  CadenceControllerService,
  CadenceSchedulePojo,
  WigControllerService,
  WigPojo
} from '../../../../../sdk/ecadence-sdk';
import { CadenceSchedulerService } from '../../../services/cadence-scheduler.service';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-cadence-modal',
  templateUrl: './cadence-modal.component.html',
  styleUrls: ['./cadence-modal.component.css']
})
export class CadenceModalComponent implements OnInit {
  @Input() modalData: CadenceSchedulePojo;
  countdown = 59;
  countdownSubscription: Subscription;
  progressPercentage = 59;
  lastCountDownTime = 'lastCountDownTime';
  dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
  oneMinInSecs = 60;
  estimatedrefreshTimeInSecs = 3;
  wigPojo: WigPojo;
  cadenceEventId: number;

  @Output()
  hideEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public bsModalRef: BsModalRef,
    private cadenceScheduleService: CadenceSchedulerService,
    private cadenceControllerService: CadenceControllerService,
    private appComponent: AppComponent,
    private wigController: WigControllerService,
    private router: Router
  ) {
    const storedCountdown = localStorage.getItem('countdown3');
    this.appComponent.countdown$.subscribe((countdown) => {
      this.countdown = Number(countdown);
    });
    this.countdown = storedCountdown ? parseInt(storedCountdown, 10) : 59;
    this.startCountdown();
  }

  ngOnInit(): void {
    this.fetchWigDetails(this.modalData.wigID);
  }

  startCountdown(): void {
    this.countdownSubscription = interval(1000).subscribe(() => {
      const now = moment();
      const currentTime = now.format(this.dateTimeFormat);

      this.countdown--;
      this.progressPercentage = (this.countdown / 59) * 100;
      if (this.countdown === 0) {
        this.gotoScoreBoard();
        this.closeModal();
        this.cadenceControllerService
          .startCadence({ cadenceSchedulePojo: this.modalData })
          .subscribe(
            (value) => {
              this.cadenceEventId = value;
              this.cadenceScheduleService.cadenceEventId.next(value);
              localStorage.setItem('cadenceEventId', String(this.cadenceEventId));
            },
            (error: unknown) => {
              console.log(error);
              if (
                error instanceof HttpErrorResponse &&
                error.error &&
                typeof error.error == 'object' &&
                error.error.message &&
                error.status == 400
              ) {
                console.log('An error occurred:', error);
              }
            }
          );
        this.countdownSubscription.unsubscribe();
        localStorage.removeItem('countdown');
      } else {
        // Save the current countdown value to localStorage
        localStorage.setItem('countdown', this.countdown.toString());
      }
    });
  }

  closeModal(): void {
    // Save the updated countdown value before closing the modal
    this.cadenceScheduleService.saveCadenceData(this.modalData, this.countdown);
    this.bsModalRef.hide();
    this.hideEvent.emit();
  }

  ngOnDestroy(): void {
    this.cadenceScheduleService.clearCadenceData();

    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

  gotoScoreBoard(): void {
    this.cadenceScheduleService.clearCadenceData();
    this.router.navigate(['/wig/' + this.modalData.wigID + '/scoreboard'], {
      state: { wigPojo: this.wigPojo, _isCadence: true, cadenceEventId: this.cadenceEventId }
    });
  }

  fetchWigDetails(wigId: number): void {
    this.wigController.getWig({ id: wigId }).subscribe({
      next: (value: WigPojo) => {
        this.wigPojo = value;
      },
      error: (err: unknown) => {
        console.log('Unable to fetch Wig Details');
      }
    });
  }
}
