import { AccountMembershipPojo } from '../../../../sdk/ecadence-sdk';
import { RolePojo } from './role.pojo.model';

export class Account {
  public name!: string;
  public code!: string;
  public accountType!: string;
  public roles!: RolePojo[];
  public jurisdiction!: string;
  public countryCode!: string;
  public enabledForIntegration?: boolean;

  constructor(data: any) {
    Object.assign(this, data);
  }

  public hasPermission(permission: string): boolean {
    return (
      this.roles.map(
        (role: RolePojo) =>
          role.permissions.filter(
            (p: AccountMembershipPojo.PermissionsEnum) => p.toString() === permission
          ).length
      ).length > 0
    );
  }
}
