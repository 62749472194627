import { SearchHandler } from './search-handler';
import { SearchFilterSource } from './search-filter-source';
import { QueryResults } from './query-results';

export class SearchManager<E, S> {
  _working: boolean | undefined;
  private _filter: S | undefined;

  private _queryResult: QueryResults<E> | undefined;

  private searchHandler: SearchHandler<E, S>;
  public filterSource: SearchFilterSource<S> | undefined;

  private _page: number | undefined;
  private _itemsPerPage = 10;

  constructor(searchHandler: SearchHandler<E, S>, filterSource?: SearchFilterSource<S>) {
    this.searchHandler = searchHandler;
    this.filterSource = filterSource;
  }

  get working(): boolean {
    return this._working;
  }

  private set queryResult(queryResult: QueryResults<E>) {
    this._queryResult = queryResult;
    this._page = this.offset / this.itemsPerPage + 1;
  }

  get offset(): number {
    if (!this._queryResult) {
      return 0;
    }
    return this._queryResult.offset;
  }

  get totalAmount(): number {
    if (!this._queryResult) {
      return 0;
    }
    if (!this._queryResult.totalAmount) {
      return 0;
    }
    return this._queryResult.totalAmount;
  }

  get totalCount(): number {
    if (!this._queryResult) {
      return 0;
    }
    return this._queryResult.total;
  }

  get list(): E[] {
    return this._queryResult ? this._queryResult.results : [];
  }

  get itemsPerPage(): number {
    return this._itemsPerPage;
  }

  set itemsPerPage(value: number) {
    this._itemsPerPage = value;
  }

  get filter(): S | undefined {
    return Object.assign({}, this._filter);
  }

  get page(): number | undefined {
    return this._page;
  }

  set page(page: number | undefined) {
    this._page = page;
    this.goToPage(page);
  }

  getQueryResult(): QueryResults<E> | undefined {
    return this._queryResult;
  }

  public reloadAndShowFirstPage(): void {
    const f: S | undefined = this.filterSource && this.filterSource.getFilter();
    // if (this._sessionStoreService) {
    //     this._sessionStoreService.saveData('searchFilter', location.href, f);
    // }
    if (typeof f === 'string' || typeof f === 'number') {
      this._filter = f;
    } else if (f) {
      this._filter = Object.assign({}, f);
    }
    this.page = 1;
  }

  private goToPage(page: number | undefined): void {
    this._working = true;
    if (this._filter && (typeof this._filter === 'string' || typeof this._filter === 'number')) {
      this.searchHandler.search(page, this._filter).subscribe(
        (queryResult) => {
          this.doneWorking();
          this.queryResult = queryResult;
        },
        () => {
          this.doneWorking();
        }
      );
    } else {
      this.searchHandler.search(page, this.filter).subscribe(
        (queryResult) => {
          this.doneWorking();
          this.queryResult = queryResult;
        },
        () => {
          this.doneWorking();
        }
      );
    }
    this.saveFilterData();
  }

  private doneWorking(): void {
    setTimeout(() => {
      this._working = false;
    });
  }

  private saveFilterData(): void {
    localStorage.setItem(this.filterSource.getPersistentKey(), JSON.stringify(this._filter));
  }

  getSavedFilterData(): S {
    return JSON.parse(localStorage.getItem(this.filterSource.getPersistentKey()));
  }
}
