<div class="container mt-5">
  <div class="row">
    <div class="card p-md-4 p-3">
      <div class="d-flex justify-content-center">
        <div>
          <div class="my-3">
            <h4 class="fw-bold"><strong> Update LAG </strong></h4>
          </div>

          <div
            class="my-4 fs-14 text-primary rounded-0 p-2"
            style="
              border-left: 2px solid #0b4eab;
              background: #0b4eab;
              background: rgba(164, 202, 255, 0.3);
            "
          >
            <span class="iconify fs-4 me-2" data-icon="ph:info-thin"></span>
            <strong>Innocent</strong> is an influencer of this LAG
          </div>
          <div class="row d-flex flex-md-row-reverse">
            <div class="col-lg-6 col-12">
              <div class="items mb-4">
                <label class="form-label">LAG Name:</label>
                <input type="text" class="form-control" placeholder="eCadence" name="" id="" />
              </div>

              <div class="items mb-4">
                <label class="form-label">Due Date: </label>
                <input type="date" class="form-control" placeholder="dd/mm/yy" name="" id="" />
              </div>

              <div class="items mb-4">
                <label class="form-label">Priority Level</label>
                <select name="" class="form-control" id="">
                  <option value="">-- Select Level --</option>
                </select>
              </div>

              <div class="items mb-4">
                <label class="form-label">Customer/Influencer </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter name of customer/influencer"
                    name=""
                    id=""
                  />
                  <button class="btn btn-outline-primary" type="button">Add</button>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-12">
              <div class="overflow-scroll">
                <div class="alert alert-success mb-4 rounded-0">
                  <p class="fw-bold">Lag Measures</p>
                </div>
                <div class="overflow-scroll pe-2">
                  <div class="mb-4 p-3" style="border: 0.67px #94c1ff dotted">
                    <div class="row row-gap-3">
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <label for="" class="form-label">Lag Measure</label>
                        <input type="text" class="form-control" placeholder="20" name="" id="" />
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <label for="" class="form-label">UOM</label>
                        <input type="text" class="form-control" placeholder="Views" name="" id="" />
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <label for="" class="form-label">UOM Direction</label>
                        <input type="text" class="form-control" placeholder="Up" name="" id="" />
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <h6 class="fw-bold mb-1">Benchmark 1</h6>
                        <div class="items d-flex justify-content-between">
                          <div class="pos">
                            <p class="fw-bold fs-12">Due Date</p>
                            <p class="fs-13">23/23/2-23</p>
                          </div>
                          <div class="pos">
                            <p class="fw-bold fs-13">Score</p>
                            <p class="fs-13">20</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <h6 class="fw-bold mb-1">Benchmark 2:</h6>
                        <div class="items d-flex justify-content-between">
                          <div class="pos">
                            <p class="fw-bold fs-12">Due Date</p>
                            <p class="fs-13">14/2/2033</p>
                          </div>
                          <div class="pos">
                            <p class="fw-bold fs-13">Score</p>
                            <p class="fs-13">10</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <button class="btn btn-outline-danger me-3">Cancel</button>
      <button class="btn btn-primary full-width-layout">Update Lag</button>
    </div>
  </div>
</div>
