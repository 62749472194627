<div class="container-fluid p-md-3 p-xl-5">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Manage Blocker</li>
      </ol>
    </nav>
    <div class="row mt-5">
      <div class="d-flex justify-content-between border-bottom pb-3">
        <h4>Manage Blockers</h4>
      </div>
      <form class="row g-4" [formGroup]="formGroup">
        <div class="p-md-3" style="background-color: #ededed6e">
          <div
            *ngIf="_searchViewType === 'TABLE' || _showFilter"
            class="collapse d-block d-lg-block"
          >
            <div class="row g-2 row-cols-2 row-cols-lg-4" autocomplete="off">
              <div class="col mb-3">
                <custom-select-field
                  [showRequiredRedIcon]="false"
                  [form]="formGroup"
                  [controlName]="'wigId'"
                  [isLoading]="_isFetchingWigs"
                  [name]="'Wig'"
                  [rawData]="_wigs"
                  [keyField]="'id'"
                  [valueField]="'name'"
                >
                </custom-select-field>
              </div>

              <div class="col mb-3">
                <span
                  class="fs-10 pointer float-end text-decoration-underline"
                  [tooltip]="'Updated on WIG Selection'"
                >
                  <span
                    class="iconify"
                    data-icon="material-symbols:info"
                    style="color: #3285f8"
                  ></span>
                </span>
                <custom-select-field
                  [showRequiredRedIcon]="false"
                  [form]="formGroup"
                  [controlName]="'lagId'"
                  [isLoading]="_isFetchingLags"
                  [name]="'Lag'"
                  [rawData]="_lags"
                  [keyField]="'id'"
                  [valueField]="'name'"
                >
                </custom-select-field>
              </div>

              <div class="col mb-3">
                <custom-select-field
                  [showRequiredRedIcon]="false"
                  [form]="formGroup"
                  [controlName]="'blockerTypeId'"
                  [isLoading]="_isFetchingBlockerTypes"
                  [name]="'Blocker Type'"
                  [rawData]="blockerTypes"
                  [keyField]="'id'"
                  [valueField]="'name'"
                >
                </custom-select-field>
              </div>

              <div class="col mb-3">
                <custom-select-field
                  [showRequiredRedIcon]="false"
                  [form]="formGroup"
                  [controlName]="'wasteType'"
                  [isLoading]="false"
                  [name]="'Waste Type'"
                  [rawData]="wasteTypes"
                >
                </custom-select-field>
              </div>

              <div class="mb-3">
                <span
                  class="fs-10 pointer float-end text-decoration-underline"
                  [tooltip]="'Updated on WIG Selection'"
                >
                  <span
                    class="iconify"
                    data-icon="material-symbols:info"
                    style="color: #3285f8"
                  ></span>
                </span>
                <custom-select-field
                  [showRequiredRedIcon]="false"
                  [form]="formGroup"
                  [controlName]="'assignedTo'"
                  [isLoading]="_isFetchingPlayers"
                  [name]="'Assigned To'"
                  [rawData]="_users"
                  [keyField]="'code'"
                  [valueField]="'name'"
                >
                </custom-select-field>
              </div>

              <div class="mb-3">
                <custom-select-field
                  [showRequiredRedIcon]="false"
                  [form]="formGroup"
                  [controlName]="'resolutionStatus'"
                  [isLoading]="false"
                  [name]="'Resolution Type'"
                  [rawData]="resolutionStatus"
                >
                </custom-select-field>
              </div>
              <div class="form-group mb-3">
                <div class="mb-1">
                  <label for="createdAfter" class="form-label fs-13">Created After</label>
                  <div class="input-group">
                    <div class="input-group">
                      <input
                        #cratedAfterDp="bsDatepicker"
                        bsDatepicker
                        type="text"
                        class="form-control"
                        id="createdAfter"
                        [bsConfig]="datePickerConfig"
                        autocomplete="off"
                        [maxDate]="createdAfterMaxDate"
                        placeholder="YYYY-MM-DD"
                        formControlName="createdAfter"
                      />
                      <span class="input-group-text bg-white" (click)="cratedAfterDp.toggle()"
                        ><span class="iconify" data-icon="uil:calender"></span
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <div class="mb-1">
                  <label for="createdBefore" class="form-label fs-13">Created Before</label>
                  <div class="input-group">
                    <div class="input-group">
                      <input
                        #createdBeforeDp="bsDatepicker"
                        bsDatepicker
                        type="text"
                        class="form-control"
                        [bsConfig]="datePickerConfig"
                        id="createdBefore"
                        autocomplete="off"
                        [minDate]="createdBeforeMinDate"
                        [maxDate]="today()"
                        placeholder="YYYY-MM-DD"
                        formControlName="createdBefore"
                      />
                      <span class="input-group-text bg-white" (click)="createdBeforeDp.toggle()"
                        ><span class="iconify" data-icon="uil:calender"></span
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <div class="mb-1">
                  <label for="code" class="form-label fs-13">Code</label>
                  <input id="code" type="text" class="form-control" formControlName="code"
                  placeholder="BCLK-002" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 justify-content-end d-flex my-3">
            <div class="d-flex align-items-center">
              <button
                *ngIf="_searchViewType === 'CARD'"
                (click)="toggleFilter()"
                class="btn btn-outline-dark rounded-5 border-0 me-3 py-2"
              >
                <span class="iconify" data-icon="mdi:filter"></span>
                Filters
              </button>

              <app-button-with-loader
                [buttonType]="'submit'"
                [classText]="'btn btn-primary rounded-5 py-2'"
                [idleStateText]="'Search'"
                [isProcessing]="searchManager.working"
                [icon]="'<span class=\'iconify icon\' data-icon=\'basil:search-outline\'></span>'"
                [workingStateText]="'Searching...'"
                (buttonClick)="searchNow()"
              >
              </app-button-with-loader>
            </div>
          </div>
        </div>
      </form>

      <div class="mt-5 d-flex justify-content-between">
        <div>
          <p class="mb-0 fw-bold text-muted">Showing Blocker Results</p>
          <p class="fs-14 text-muted">Based on your search</p>
        </div>
      </div>

      <div class="card p-lg-4 rounded-3 mt-3">
        <div class="table-responsive">
          <table class="table">
            <thead *ngIf="_searchViewType === 'TABLE'" class="border-bottom">
              <tr>
                <th scope="col" class="small">SN</th>
                <th scope="col" class="small">Type</th>
                <th scope="col" class="small">Waste Type</th>
                <th scope="col" class="small">Affecting Lags</th>
                <th scope="col" class="small">Assigned to</th>
                <th scope="col" class="small">Status</th>
                <th scope="col" class="small">Created</th>
                <th scope="col" class="small">Action</th>
              </tr>
            </thead>

            <tbody>
              <ng-container *ngIf="!searchManager.working; else searching">
                <ng-container *ngIf="searchManager.list?.length > 0; else noData">
                  <ng-container *ngFor="let item of searchManager.list; index as i">
                    <blocker-search-card
                      (viewMoreEvent)="previewBlocker($event)"
                      *ngIf="_searchViewType === 'CARD'"
                      [blockerSearchDto]="item"
                    >
                    </blocker-search-card>

                    <ng-container *ngIf="_searchViewType === 'TABLE'">
                      <tr>
                        <td>{{ i + 1 + searchManager?.offset }}</td>
                        <td>{{ item.blockerType | uppercase }}</td>
                        <td>{{ item.wasteType | uppercase }}</td>
                        <td>
                          <div class="btn p-0 position-relative">
                            <span
                              class="iconify"
                              data-icon="material-symbols:flag"
                              data-width="30"
                              data-height="30"
                            ></span>
                            <span
                              class="position-absolute top-0 pointer float-end start-100 translate-middle badge rounded-pill bg-danger"
                            >
                              <span class="visually-hidden">Affecting Lags</span>
                              <div
                                [container]="'div'"
                                [placement]="'right bottom'"
                                [tooltip]="restOfUsers"
                                [containerClass]="'overflow-auto'"
                              >
                                {{ item.lags.length }}
                              </div>
                            </span>
                            <ng-template #restOfUsers>
                              <div *ngFor="let rest of item.lags">
                                {{ rest }}
                                <br />
                              </div>
                            </ng-template>
                          </div>
                        </td>
                        <td>{{ item.assignedTo || '-' | uppercase }}</td>
                        <td
                          [ngClass]="{
                            'text-danger': item.resolutionStatus === 'PENDING',
                            'text-warning': item.resolutionStatus === 'IN_PROGRESS',
                            'text-success': item.resolutionStatus === 'RESOLVED'
                          }"
                        >
                          {{ item.resolutionStatus | uppercase | underscoreToSpace }}
                        </td>
                        <td>{{ item.createdAt | shortestDate }}</td>
                        <td>
                          <div class="dropdown dropend">
                            <button
                              class="btn-unset-all"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span
                                class="iconify"
                                data-icon="ph:dots-three-vertical"
                                data-width="30"
                                data-height="30"
                              ></span>
                            </button>
                            <ul class="dropdown-menu p-0 dropend dropdown-menu-end">
                              <li (click)="previewBlocker(item)">
                                <a class="dropdown-item pointer"
                                  ><span
                                    class="iconify"
                                    data-icon="iwwa:assign"
                                    data-width="30"
                                    data-height="30"
                                  ></span>
                                  Preview
                                </a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-between align-items-center flex-wrap mt-2">
          <div *ngIf="searchManager?.totalCount > 0">
            <span class="my-1 fs-15"
              ><strong>{{ searchManager?.offset + 1 }}</strong> to
              <strong>{{
                searchManager?.totalCount > searchManager?.offset + searchManager?.itemsPerPage
                  ? searchManager?.offset + searchManager?.itemsPerPage
                  : searchManager?.totalCount
              }}</strong>
              of
              <strong>{{ searchManager?.totalCount }}</strong>
            </span>
          </div>

          <div>
            <div
              class="d-flex justify-content-end"
              *ngIf="searchManager?.totalCount > searchManager.itemsPerPage"
            >
              <pagination
                class="raleway-font fw-600 fs-12"
                style="color: black"
                (click)="scrollUp()"
                [pageBtnClass]="'btn-dark'"
                [rotate]="true"
                [boundaryLinks]="true"
                previousText="&lsaquo;"
                nextText="&rsaquo;"
                firstText="&laquo;"
                lastText="&raquo;"
                [totalItems]="searchManager?.totalCount"
                [itemsPerPage]="searchManager?.itemsPerPage"
                [maxSize]="5"
                name="page"
                [(ngModel)]="searchManager.page"
                ngDefaultControl
              ></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noData>
  <tr>
    <td colspan="100%">
      <lottie-in-full-table [path]="'assets/lottie/no-data.json'" [message]="'No Data Found'">
      </lottie-in-full-table>
    </td>
  </tr>
</ng-template>

<ng-template #searching>
  <tr>
    <td colspan="100%">
      <lottie-in-full-table [path]="'assets/lottie/loading.json'" [message]="'Searching...'">
      </lottie-in-full-table>
    </td>
  </tr>
</ng-template>
