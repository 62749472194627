import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AttachmentDto } from '../../../../../sdk/ecadence-sdk';
import TypeEnum = AttachmentDto.TypeEnum;

@Component({
  selector: 'app-full-file-preview',
  templateUrl: './full-file-preview.component.html',
  styleUrls: ['./full-file-preview.component.css']
})
export class FullFilePreviewComponent {
  private _fileUrl: string;
  _fileType: TypeEnum;
  isPdfFile: boolean;
  sanitizedFileUrl: SafeResourceUrl;

  @Input()
  set fileType(value: TypeEnum) {
    this._fileType = value;
    if (this._fileType === TypeEnum.PDF) {
      this.isPdfFile = true;
    }
  }

  get fileUrl(): string {
    return this._fileUrl;
  }

  @Input()
  set fileUrl(value: string) {
    this._fileUrl = value;
    this.sanitizedFileUrl = this.sanitizeUrl(this.fileUrl);
    if (this._fileType === TypeEnum.PDF) {
      this.isPdfFile = true;
    }
  }

  constructor(public bsModalRef: BsModalRef, private sanitizer: DomSanitizer) {}

  close(): void {
    this.bsModalRef.hide();
  }

  private sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
