import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class NotBlankValidator {
  public static get validate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      if (control && control.value) {
        if (typeof control.value === 'string' && control.value.trim() === '') {
          return { notBlank: true };
        }
      }
      return null;
    };
  }
}
