import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.css']
})
export class SuccessDialogComponent {
  @Input() title = 'Success';
  @Input() message = 'Action was successful';
  /**
   * @deprecated use close event instead
   */
  @Input() onClose: () => void;
  lottieIconPath = 'assets/lottie/success.json';
  @Output() closeEvent = new EventEmitter<void>();

  @Input()
  closeButtonMessage = 'Close';

  constructor(private modalRef: BsModalRef) {}

  close(): void {
    if (this.onClose) {
      this.onClose();
    }
    this.closeEvent.emit();
    this.modalRef.hide();
  }
}
