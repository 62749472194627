import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  constructor(
    protected readonly router2: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly authenticationService: AuthenticationService
  ) {}

  get authenticated(): Promise<boolean> {
    return this.authenticationService.isMobileApp
      ? new Promise<boolean>((resolve, reject) => {
          resolve(!!this.authenticationService.getMobileToken());
        })
      : this.keycloak.isLoggedIn();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return from(this.authenticated).pipe(
      switchMap((authenticated) => {
        if (!authenticated) {
          this.router2.events.subscribe((v: any) => {
            this.authenticationService.login({
              redirectUri: `${window.location.origin}/${v.url}`
            });
          });

          return of(authenticated);
        }
        return this.authenticationService.getUser().pipe(
          switchMap((user) => {
            if (!user) {
              window.location.href = window.location.origin;
              return of(false);
            }
            const allowedPermissions: RoutePermissionRestriction[] =
              (route.data['permissions'] as RoutePermissionRestriction[]) ?? [];
            //no permission restriction. allow
            if (!allowedPermissions || allowedPermissions.length < 1) {
              return of(true);
            }
            for (let i = 0; i < allowedPermissions.length; i++) {
              const hasRestrictedRoles = (allowedPermissions[i].roles?.length ?? 0) > 0;
              const hasRestrictedPermissions = (allowedPermissions[i].permissions?.length ?? 0) > 0;
              if (this.authenticationService.hasAccountType(allowedPermissions[i].accountType)) {
                if (!hasRestrictedRoles && !hasRestrictedPermissions) {
                  return of(true);
                }
                if (
                  (hasRestrictedRoles &&
                    this.authenticationService.hasAnyRole(allowedPermissions[i].roles)) ||
                  (hasRestrictedPermissions &&
                    this.authenticationService.hasAnyPermission(allowedPermissions[i].permissions))
                ) {
                  return of(true);
                }
                return of(true);
              }
            }
            this.authenticationService.forbidAccess();
            window.location.href = window.location.origin;
            return of(false);
          }),
          catchError((err: unknown) => {
            console.log(err);
            this.authenticationService.forbidAccess();
            window.location.href = window.location.origin;
            return of(false);
          })
        );
      })
    );
  }

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }
}

export interface RoutePermissionRestriction {
  accountType: string;
  roles: string[];
  permissions: string[];
}
