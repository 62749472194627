import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ChartData } from 'chart.js';
import { HttpErrorResponse } from '@angular/common/http';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import {
  XBenchmarkPojo,
  XCreateLeadMeasureForm
} from '../create-lead-measure-modal/create-lead-measure-modal.component';
import {
  CreateLeadMeasureDto,
  LeverageControllerService,
  PerformanceBenchMarkDto
} from '../../../../../../../sdk/ecadence-sdk';
import { XAlertMessage } from '../../../../../shared/components/alert-message/alert-message.component';

@Component({
  selector: 'preview-lead-measure-modal',
  templateUrl: './preview-lead-measure-modal.component.html',
  styleUrls: ['./preview-lead-measure-modal.component.css']
})
export class PreviewLeadMeasureModalComponent {
  constructor(
    private bsModalRef: BsModalRef,
    private leverageController: LeverageControllerService,
    private datePipe: DatePipe
  ) {}

  _barChartData: ChartData<'bar'>;
  _maxY: number;

  viewState: 'CREATE' | 'PREVIEW' | 'EDIT' = 'CREATE';

  _xBenchmarkPojos: XBenchmarkPojo[] = [];
  leadMeasureId: number;

  get xBenchmarkPojos(): XBenchmarkPojo[] {
    return this._xBenchmarkPojos;
  }

  @Input()
  set xBenchmarkPojos(value: XBenchmarkPojo[]) {
    this._xBenchmarkPojos = value;
    this.computeGraphData(this._xBenchmarkPojos);
  }

  @Input()
  xCreateLeadMeasureForm: XCreateLeadMeasureForm;

  @Input()
  wigName = 'Test Wig';

  @Input()
  lagName = 'Test Lag';

  @Input()
  lagMeasureName = 'Test Lag Measure';

  isCreatingLeadMeasure = false;

  xAlertMessage: XAlertMessage = {
    message: undefined,
    messageType: 'info'
  };

  @Output() successEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeEmitter: EventEmitter<any> = new EventEmitter<any>();

  computeGraphData(benchmarkDetails: XBenchmarkPojo[]): void {
    const labels: string[] = [];
    const benchmarkScores: number[] = [];
    const predictedLagScore: number[] = [];

    benchmarkDetails.forEach((bench) => {
      labels.push(this.getGraphDate(bench.dueDate, bench.dueTime));
      benchmarkScores.push(bench.targetScore);
      predictedLagScore.push(bench.predictedLagScore);
    });

    this._maxY = Math.round(Math.max(...benchmarkScores, ...predictedLagScore) * 1.1);
    this._barChartData = {
      labels: [...labels],
      datasets: [
        { data: benchmarkScores, label: 'Target' },
        { data: predictedLagScore, label: 'Lag Target' }
      ]
    };
  }

  getGraphDate(date: Date, time: Date): string {
    const timeFormatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    });
    return this.datePipe.transform(date, 'dd-MM-YYYY') + '\n' + timeFormatter.format(time);
  }

  formatDateAndTime(date: Date, time: Date): string {
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    const timeFormatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(time);

    return `${formattedDate} ${formattedTime}`;
  }

  closeModal(): void {
    this.bsModalRef.hide();
    this.closeEmitter.emit();
  }

  createLeadMeasure(): void {
    this.isCreatingLeadMeasure = true;
    this.xAlertMessage.message = undefined;

    const createLeadMeasureDto: CreateLeadMeasureDto = {
      lagMeasureId: this.xCreateLeadMeasureForm.lagMeasureId,
      desiredDirectionConstant: this.xCreateLeadMeasureForm.desiredDirectionConstant,
      currentValue: this.xCreateLeadMeasureForm.currentValue,
      measurementUnit: this.xCreateLeadMeasureForm.measurementUnit,
      lagPredictionNote: this.xCreateLeadMeasureForm.lagPredictionNote,
      performanceBenchMarkDtos: this.xBenchmarkPojos.map((x): PerformanceBenchMarkDto => {
        return {
          predictedLagScore: x.predictedLagScore,
          targetScore: x.targetScore,
          dueDate: moment(
            this.formatDateAndTime(x.dueDate, x.dueTime),
            'MMMM DD, YYYY h:mm A'
          ).format('YYYY-MM-DDTHH:mm')
        };
      }),
      userIds: this.xCreateLeadMeasureForm.users.map((x) => x.code)
    };

    const subscription: Observable<any> =
      this.viewState === 'CREATE'
        ? this.leverageController.createLeadMeasure({ createLeadMeasureDto: createLeadMeasureDto })
        : this.leverageController.updateLeadMeasure({
            id: this.leadMeasureId,
            createLeadMeasureDto: createLeadMeasureDto
          });

    subscription
      .subscribe({
        next: (v) => {
          this.successEmitter.emit(true);
        },
        error: (err: unknown) => {
          this.xAlertMessage.messageType = 'danger';
          this.xAlertMessage.message =
            err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
              ? err.error.message
              : 'Unable to create Lead Measures';
        }
      })
      .add(() => {
        this.isCreatingLeadMeasure = false;
      });
  }

  protected readonly JSON = JSON;
}
