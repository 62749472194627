/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LeadMeasureBenchmarkPojo } from './lead-measure-benchmark-pojo';


export interface LeadMeasurePojo { 
    leadMeasureId?: number;
    unit?: string;
    desiredDirection?: LeadMeasurePojo.DesiredDirectionEnum;
    leadMeasureBenchmark?: Array<LeadMeasureBenchmarkPojo>;
    maxValue?: number;
    currentValue?: number;
    lastUpdated?: string;
}
export namespace LeadMeasurePojo {
    export type DesiredDirectionEnum = 'UP' | 'DOWN';
    export const DesiredDirectionEnum = {
        UP: 'UP' as DesiredDirectionEnum,
        DOWN: 'DOWN' as DesiredDirectionEnum
    };
}


