<form [formGroup]="form">
  <label
    [ngClass]="{ required: _showRequiredRedIcon, _labelClass: true }"
    class="form-label fs-15 required"
  >
    {{ name }}
  </label>
  <div *ngIf="isLoading" class="float-end">
    <div class="spinner-border spinner-border-sm text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <select class="form-control" [formControlName]="controlName">
    <ng-container *ngIf="isLoading">
      <option value="">-- Loading --</option>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <ng-container *ngIf="_refinedData && _refinedData.length > 0; else noData">
        <option value="">-- Choose {{ name  }} --</option>
        <option style="max-width: 300px" *ngFor="let x of _refinedData" [value]="x?.key">
          {{ (truncateLength ? getTruncatedValue(x.value) : x.value ) | underscoreToSpace | uppercase }}
        </option>
      </ng-container>
      <ng-template #noData>
        <option value="">-- No {{ name }} Available --</option>
      </ng-template>
    </ng-container>
  </select>
  <error-message
    [controlName]="controlName"
    [form]="form"
    [label]="name"
    [fontSize]="10"
    [singularError]="true"
  >
  </error-message>
</form>
