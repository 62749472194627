import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { WigSummaryDto } from '../../../../../sdk/ecadence-sdk';

@Component({
  selector: 'wig-milestones',
  templateUrl: './wig-milestones.component.html',
  styleUrls: ['./wig-milestones.component.css']
})
export class WigMilestonesComponent implements OnChanges {
  @Input() wigSummaryDto: WigSummaryDto;
  _wigSummaryDto: WigSummaryDto;

  selectedItem: Array<any> = [];

  ngOnChanges(changes: SimpleChanges): void {
    this._wigSummaryDto = changes['wigSummaryDto'].currentValue;
    console.log('wig summary', this._wigSummaryDto);
  }

  completed(lag: any): boolean {
    if (
      new Date(lag['completedAt']).getDate() < new Date().getDate() ||
      new Date(lag['startAt']).getDate() < new Date(lag['dueAt']).getDate()
    ) {
      return true;
    } else {
      return false;
    }
  }

  overDue(lag: any): boolean {
    if (new Date(lag['createdAt']).getDate() < new Date().getDate() && !this.completed(lag)) {
      return true;
    } else if (new Date().getDate() > new Date(lag['dueAt']).getDate() && !this.completed(lag)) {
      return true;
    } else if (lag['overdueBenchmark'].length > 0 && !this.completed(lag)) {
      return true;
    }
    return false;
  }

  progress(lag: any): boolean {
    if (
      lag['players'].length > 0 &&
      new Date(lag['startDate']).getDate() < new Date(lag['dueDate']).getDate() &&
      !this.completed(lag)
    ) {
      return true;
    } else {
      return false;
    }
  }

  lagScores(): string {
    const scoreArr = this._wigSummaryDto?.lagPojos.map((item) => item['score']);
    if (scoreArr?.length > 0) {
      const overallScore = scoreArr?.reduce((acc, item) => acc + item, 0);
      return (overallScore / scoreArr?.length).toFixed(2);
    } else {
      return '0.00';
    }
  }

  lineStatus(index: number, lags: Array<any>): boolean {
    if (index === 0 && index === lags.length - 1) {
      return false;
    } else if (index === lags.length - 1) {
      return false;
    } else if (lags.length === 2 && index === 1) {
      return true;
    }

    return true;
  }
}
