import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommittmentDto } from '../../../../../../../sdk/ecadence-sdk';

@Component({
  selector: 'commitment-preview-card',
  templateUrl: './commitment-preview-card.component.html',
  styleUrls: ['./commitment-preview-card.component.css']
})
export class CommitmentPreviewCardComponent {
  @Input()
  commitment: CommittmentDto;

  @Output()
  removeCommitmentEvent: EventEmitter<CommittmentDto> = new EventEmitter<CommittmentDto>();

  removeCommitment(): void {
    this.removeCommitmentEvent.emit(this.commitment);
  }
}
