<!-- Offcanvas score  -->
<div class="overflow-y-auto">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasRightLabel">Lag Details</h5>
    <button
      (click)="closeModal()"
      type="button"
      class="btn border-0 small btn-outline-dark"
      data-bs-dismiss="offcanvas"
    >
      <span class="iconify" data-icon="iconoir:cancel"></span>
      Cancel
    </button>
  </div>

  <div class="offcanvas-body" style="overflow-y: scroll">
    <app-alert-message
      *ngIf="xAlertMessage"
      [messageType]="xAlertMessage!.messageType"
      [message]="xAlertMessage!.message"
    >
    </app-alert-message>

    <div class="display-box mb-3">
      <div class="mb-2 text-smaller-header">Lag Name</div>
      <div class="fs-16">{{ lag.lagName }}</div>
    </div>

    <div class="light-blue-bg mb-4" style="border-radius: 0px">
      <div class="mb-2 text-smaller-header">Lag Description</div>
      <div class="fs-16">{{ lag.description }}</div>
    </div>

    <div>
      <div class="mt-5 mb-3 text-tinier-header">KPI Lists</div>
      <ng-container *ngIf="lag.kpis && lag.kpis.length > 0; else noKpis">
        <ng-container *ngFor="let kpi of lag.kpis">
          <div
            class="mb-3"
            [ngClass]="{
              'display-blue-box': !kpi.completed,
              'display-green-border-box': kpi.completed
            }"
          >
            <div class="d-flex justify-content-between">
              <div class="mb-2 kpi-box-header">{{ kpi.description }}</div>
            </div>
            <div class="fs-13 fw-medium">
              Weight: <span class="fw-normal"> {{ kpi.weight }}|</span> Due Date:
              <span class="fw-normal"> {{ kpi.dueDate | shortestDate }}</span> |
              <span
                class=""
                [ngClass]="{ 'text-danger': !kpi.completed, 'text-success': kpi.completed }"
              >
                {{ kpi.completed ? 'Done' : 'Not Done' }}</span
              >
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #noKpis>
        <div class="px-3 py-5 justify-content-center mx-auto text-center">
          <div>
            <span
              class="iconify mb-2"
              style="font-size: 28px"
              data-icon="fluent:document-error-16-regular"
            ></span>
          </div>
          <div>No KPIs</div>
        </div>
      </ng-template>
    </div>

    <div>
      <div class="mt-5 purple-outline-div">
        <div class="p-2" style="color: #4e3aa4; font-size: 19px; font-weight: 500">
          Lag Measures
        </div>
      </div>
      <div class="profile-bottom-div fs-16 p-2">
        <ng-container *ngIf="lagMeasures && lagMeasures.length > 0; else noLagMeasures">
          <ng-container *ngFor="let measure of lagMeasures">
            <div class="purple-border-dashed py-2 mb-1">
              <p class="fs-17 mb-1 fw-medium" style="color: #2e2e2e">{{ measure.maxValue }}</p>
              <div class="fs-14 mb-1 fw-medium" style="color: #646464">
                UOM: <span class="fw-normal">{{ measure.unit }}</span>
              </div>
              <div class="fs-12 fw-medium" style="color: #757575">
                UOM Direction: <span class="fw-normal">{{ measure.desiredDirection }}</span> Score:
                <span class="fw-normal">{{ measure.currentValue }}</span>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-template #noLagMeasures>
          <div class="px-3 py-5 justify-content-center mx-auto text-center">
            <div>
              <span
                class="iconify mb-2"
                style="font-size: 28px"
                data-icon="fluent:document-error-16-regular"
              ></span>
            </div>
            <div>No Lag Measures</div>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="my-5">
      <app-lag-supporting-doc [lagId]="lag.id"></app-lag-supporting-doc>
    </div>

<!--    <div>-->
<!--      <div class="mt-5 mb-3 text-tinier-header">Supporting Document</div>-->
<!--      <div class="profile-bottom-div p-3">-->
<!--        <div class="row gy-2">-->
<!--          <div class="col-md-6">-->
<!--            <div class="mx-auto text-center justify-content-center">-->
<!--              <img width="210" height="210" class="img-fluid img-thumbnail-pdf-doc-small" alt="" />-->
<!--              <div class="text-center fs-14" style="color: #0b2d9f">rrrr</div>-->
<!--              &lt;!&ndash;          <div class="text-center text-danger pointer fs-12">&ndash;&gt;-->
<!--              &lt;!&ndash;            Remove&ndash;&gt;-->
<!--              &lt;!&ndash;          </div>&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-6">-->
<!--            <div class="mx-auto text-center justify-content-center">-->
<!--              <img width="210" height="210" class="img-fluid img-thumbnail-pdf-doc-small" alt="" />-->
<!--              <div class="text-center fs-14" style="color: #0b2d9f">rrrr</div>-->
<!--              &lt;!&ndash;          <div class="text-center text-danger pointer fs-12">&ndash;&gt;-->
<!--              &lt;!&ndash;            Remove&ndash;&gt;-->
<!--              &lt;!&ndash;          </div>&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->








    <!--  <p class="fw-normal">{{ lag.lagName }}</p>-->

    <!--  <div class="my-lg-4">-->
    <!--    <input type="text" class="form-control" value="{{ lag.description }}" disabled />-->
    <!--  </div>-->

    <!--  <div class="mb-3">-->
    <!--    <p class="fw-bold border-bottom pb-2">KPI List</p>-->
    <!--    <div style="overflow-y: scroll; max-height: 500px">-->
    <!--      <ng-container *ngIf="lag.kpis && lag.kpis.length > 0; else noKpis">-->
    <!--        <ng-container *ngFor="let kpi of lag.kpis">-->
    <!--          <div-->
    <!--            class="d-flex justify-content-between align-items-center p-3"-->
    <!--            style="background-color: rgba(214, 214, 214, 0.369)"-->
    <!--          >-->
    <!--            <div class="flex-column d-flex">-->
    <!--              <div>-->
    <!--                <p class="fw-bold mb-2">{{ kpi.description }}</p>-->
    <!--              </div>-->
    <!--              <div class="d-flex justify-content-between flex-column">-->
    <!--                <p class="mb-0 fw-medium text-muted fs-12">-->
    <!--                  Weight: <span class="">{{ kpi.weight }}</span>-->
    <!--                </p>-->

    <!--                <div class="d-flex mt-2">-->
    <!--                  <p class="mb-0 fs-12 me-2">Status:</p>-->
    <!--                  <p-->
    <!--                    class="fs-12 mb-0 fw-normal mb-0"-->
    <!--                    [ngClass]="{ 'text-success': kpi.completed, 'text-danger': !kpi.completed }"-->
    <!--                  >-->
    <!--                    {{ kpi.completed ? 'Done' : 'Not Done' }}-->
    <!--                  </p>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <button-->
    <!--              [disabled]="_markingKpi"-->
    <!--              (click)="markKpi(kpi)"-->
    <!--              class="btn btn-primary p-2 rounded-5 btn-sm py-1 fs-10"-->
    <!--            >-->
    <!--              Mark as {{ kpi.completed ? 'undone' : 'done' }}-->
    <!--            </button>-->
    <!--          </div>-->
    <!--        </ng-container>-->
    <!--      </ng-container>-->
    <!--    </div>-->

    <!--    <ng-template #noKpis>-->
    <!--      <lottie-in-full-table [path]="'assets/lottie/no-data.json'" [message]="'No KPI Available'">-->
    <!--      </lottie-in-full-table>-->
    <!--    </ng-template>-->
    <!--  </div>-->

    <!--  <div class="my-3">-->
    <!--    <div style="overflow-y: auto; max-height: 500px">-->
    <!--      <div class="form-control" disabled *ngFor="let lm of lagMeasures">-->
    <!--        <div>-->
    <!--          <p class="fs-12 fw-bold">Lag Measure</p>-->
    <!--          <p class="fw-normal text-muted fs-12">{{ lm.lagMeasureName }}</p>-->
    <!--        </div>-->

    <!--        <div class="d-flex justify-content-between my-4">-->
    <!--          <div class="col-6 pe-2">-->
    <!--            <p class="fs-12 fw-bold">UOM:</p>-->
    <!--            <p class="fw-normal text-muted fs-12">{{ lm.unit }}</p>-->
    <!--          </div>-->
    <!--          <div class="col-6 ps-2">-->
    <!--            <p class="fs-12 fw-bold">UOM Direction:</p>-->
    <!--            <p class="fw-normal text-muted fs-12">{{ lm.desiredDirection }}</p>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        <div class="my-3">-->
    <!--          <p class="fs-12 fw-bold">Score:</p>-->
    <!--          <div class="d-flex">-->
    <!--            <p class="fw-normal text-muted fs-12">{{ lm.currentValue }}/{{ lm.maxValue }}</p>-->
    <!--            <button *ngIf="false" (click)="getLagMeasureScoreSummary(lm.lagMeasureId)" class="btn">-->
    <!--              <span class="iconify ms-2" data-icon="iconoir:edit"></span>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        <div class="mt-4">-->
    <!--          <progressbar-->
    <!--            class="progress-striped progress-bar-animated"-->
    <!--            [value]="lm.currentValue"-->
    <!--            [max]="lm.maxValue"-->
    <!--            type="success"-->
    <!--            [striped]="true"-->
    <!--            [animate]="true"-->
    <!--          >-->
    <!--          </progressbar>-->
    <!--        </div>-->

    <!--        <div class="d-flex justify-content-between mt-4">-->
    <!--          <div class="col-6 pe-2" *ngFor="let lmb of lm.lagMeasureBenchmark; index as i">-->
    <!--            <p class="fw-bold small mb-2">BenchMark {{ i + 1 }}:</p>-->
    <!--            <div class="d-flex justify-content-between">-->
    <!--              <div>-->
    <!--                <p class="fs-10 fw-medium text-muted">Due Date:</p>-->
    <!--                <p class="fw-normal text-muted fs-12">{{ lmb.dueDate }}</p>-->
    <!--              </div>-->
    <!--              <div class="">-->
    <!--                <p class="fs-10 fw-medium text-muted">Score:</p>-->
    <!--                <p class="fw-normal text-muted fs-12">{{ lmb.targetValue }}</p>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <ng-container *ngIf="showScoreSummary == true">-->
    <!--          <h5>Update Lag Measure Score</h5>-->
    <!--          <form [formGroup]="formGroup">-->
    <!--            <div class="row">-->
    <!--              <div class="col-6">-->
    <!--                <label class="form-label fs-12">Current Score</label>-->
    <!--                <p class="form-control border-0 ps-0">-->
    <!--                  {{ lagMeasureScoreSummary.lagMeasureCurrentScore }}/{{-->
    <!--                  lagMeasureScoreSummary.lagMeasureMaxScore-->
    <!--                  }}-->
    <!--                </p>-->
    <!--              </div>-->
    <!--              <div class="col-6">-->
    <!--                <label class="form-label fs-12">Score:</label>-->
    <!--                <div class="input-group">-->
    <!--                  <input-->
    <!--                    class="input-sm form-control form-control-sm p-1"-->
    <!--                    type="text"-->
    <!--                    formControlName="score"-->
    <!--                  />-->
    <!--                  <button-->
    <!--                    *ngIf="lagMeasureScoreSummary.hasLeadMeasureScoreGreaterThanZero == true"-->
    <!--                    type="submit"-->
    <!--                    class="btn btn-secondary p-1"-->
    <!--                    (click)="updateLagMeasureScore(lagMeasureScoreSummary.lagMeasureId)"-->
    <!--                  >-->
    <!--                    Save-->
    <!--                  </button>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </form>-->
    <!--          <ng-container-->
    <!--            *ngIf="-->
    <!--            lagMeasureScoreSummary.lagMeasureLeadMeasurePojoList.length > 0;-->
    <!--            else noLeadMeasures-->
    <!--          "-->
    <!--          >-->
    <!--            <div *ngFor="let leadMeasure of lagMeasureScoreSummary.lagMeasureLeadMeasurePojoList">-->
    <!--              <div class="row">-->
    <!--                <div class="col-6">-->
    <!--                  <label class="form-label fs-12">Lead Measure</label>-->
    <!--                  <p class="form-control border-0 ps-0">{{ leadMeasure.leadMeasure }}</p>-->
    <!--                </div>-->
    <!--                <div class="col-6">-->
    <!--                  <label class="form-label fs-12">Score:</label>-->
    <!--                  <div class="input-group">-->
    <!--                    <p>{{ leadMeasure.leadMeasureScore }}</p>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </ng-container>-->
    <!--          <ng-template #noLeadMeasures>-->
    <!--            <div class="text-center">No Lead Measures For Lag Measure</div>-->
    <!--          </ng-template>-->
    <!--        </ng-container>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--  </div>-->
  </div>
</div>
