<div class="d-flex justify-content-between align-items-center mb-3">
  <div class="text-mini-header">Lags</div>

  <button *ngIf="showAddLagButton"
    (click)="createNewLag()"
    type="button"
    class="btn btn-outline-primary fs-14 pointer"
    style="border: none; position: relative;"
  >
    <span class="iconify" data-icon="ic:baseline-plus"></span>
    Add Lag
    <span class="edit-icon" style="position: absolute; top: 0; right: 0;">
    </span>
  </button>
</div>
<div class="row gy-3 mb-5">
  <div class="col-12" *ngIf="!(wigPojo?.lags?.length > 0)" >
    <empty-lag-card></empty-lag-card>
  </div>
  <div class="col-md-4" *ngFor="let lag of wigPojo?.lags">
    <lag-card (closeEvent)="updateEvent()" [lag]="lag" [wigId]="wigPojo.id" (wigPojoEvent)="emitWigPojo($event)"></lag-card>
  </div>
</div>

