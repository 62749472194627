<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Edit KPI</h5>
    <button
      (click)="closeModal()"
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body">
    <app-alert-message
      *ngIf="xAlertMessage"
      [messageType]="xAlertMessage!.messageType"
      [message]="xAlertMessage!.message"
    >
    </app-alert-message>
    <form [formGroup]="form">
      <div class="mb-3">
        <label for="description" class="form-label required"> KPI description </label>
        <input
          type="text"
          id="description"
          class="form-control"
          placeholder="Enter KPI name"
          [formControlName]="'description'"
        />
        <error-message
          [controlName]="'name'"
          [form]="form"
          [label]="'Name'"
          [fontSize]="10"
          [singularError]="true"
        >
        </error-message>
      </div>
      <div class="mb-3">
        <input
          id="weight"
          type="number"
          class="form-control"
          [formControlName]="'weight'"
          placeholder="3"
          min="1"
          (keydown)="NumberOnly.allowOnlyNumbers($event)"
        />
        <error-message
          [controlName]="'weight'"
          [form]="form"
          [label]="'Weight'"
          [fontSize]="10"
          [singularError]="true"
        >
        </error-message>
      </div>
      <div class="mb-3">
        <label for="dueDate" class="form-label required"> Due Date </label>
        <div class="input-group">
          <div class="input-group">
            <input
              #dueDate="bsDatepicker"
              bsDatepicker
              type="text"
              class="form-control"
              id="dueDate"
              [bsConfig]="datePickerConfig"
              autocomplete="off"
              [minDate]="today"
              placeholder="YYYY-MM-DD"
              [formControlName]="'dueDate'"
            />
            <span class="input-group-text bg-white" (click)="dueDate.toggle()"
              ><span class="iconify" data-icon="uil:calender"></span
            ></span>
          </div>
        </div>
        <error-message
          [controlName]="'dueDate'"
          [form]="form"
          [label]="'Due Date'"
          [fontSize]="10"
          [singularError]="true"
        >
        </error-message>
      </div>
    </form>
  </div>
  <div class="modal-footer" *ngIf="!_isDisabled">
    <app-button-with-loader
      [buttonType]="'button'"
      [classText]="'btn btn-primary'"
      [idleStateText]="'Update'"
      [isProcessing]="_isLoading"
      [icon]="'<span class=\'iconify icon\' data-icon=\'basil:search-outline\'></span>'"
      [workingStateText]="'Updating...'"
      (buttonClick)="updateKpi()"
    >
    </app-button-with-loader>
  </div>
</div>
