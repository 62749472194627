import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO } from '@capgo/ngx-intl-tel-input';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import {
  FileControllerService,
  PlayerProfilePojo,
  UpdateProfileDto,
  UserProfileControllerService
} from '../../../../../sdk/ecadence-sdk';
import { XAlertMessage } from '../../../shared/components/alert-message/alert-message.component';
import { XPhoneNumber } from '../../../services/helper.service';
import { NgxTelPhoneNumberValidator } from '../../../shared/validators/ngx-tel-phone-number-validator';
import { NotBlankValidator } from '../../../shared/validators/not-blank.validator';
import { SuccessDialogComponent } from '../../../shared/components/success-dialog/success-dialog.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  _loading = true;
  _updating = false;
  _hasImage = false;
  _maxFileLimit = 1;

  _loadingImage = false;

  _playerPojo: PlayerProfilePojo;
  _form: FormGroup;

  updatedImage: string;
  initialImage: string;

  fullName: string;

  countryAlpha = 'ng';
  selectedCountryISO: CountryISO = CountryISO.Nigeria;

  xAlertMessage: XAlertMessage = {
    message: undefined,
    messageType: 'info'
  };

  _textPosition: 'LEFT' | 'CENTER' = 'LEFT';

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.setSearchViewType(Math.min(event.target.innerWidth, event.target.outerWidth));
  }

  setSearchViewType(width: number): void {
    if (width < 768) {
      this._textPosition = 'CENTER';
    } else {
      this._textPosition = 'LEFT';
    }
  }

  constructor(
    private profileController: UserProfileControllerService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private router: Router,
    private fileController: FileControllerService
  ) {}

  ngOnInit(): void {
    this.setSearchViewType(window.innerWidth);
    this.profileController
      .getUserProfile()
      .subscribe({
        next: (value: PlayerProfilePojo) => {
          this._playerPojo = value;
          this.fullName = value.name;
          if (value.profileImage && value.profileImage.id) {
            this.getImageDataURI(value.profileImage.id);
          }

          this._form = this.formBuilder.group({
            name: [''],
            emailAddress: [''],
            phoneNumber: [
              '',
              [Validators.required, NgxTelPhoneNumberValidator(), NotBlankValidator.validate]
            ],
            playerRole: [''],
            linkedInUrl: [
              '',
              [Validators.pattern(/^(https?:\/\/)?(www\.)?([A-Za-z0-9.-]+\.[A-Za-z]{2,})(\/.*)?$/)]
            ],
            image: ['']
          });
          this._form.patchValue(value);
          this._form.get('name').disable();
          this._form.get('emailAddress').disable();

          this._form.get('image').valueChanges.subscribe({
            next: (c) => {
              this.updatedImage = c;
              this._hasImage = true;
            }
          });
        }
      })
      .add(() => {
        this._loading = false;
      });
  }

  showMessage(error: string, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
    this.xAlertMessage.messageType = type;
    this.xAlertMessage.message = error;
    setTimeout(() => {
      this.xAlertMessage.message = undefined;
    }, 4000);
  }

  updateProfile(): void {
    this._form.markAllAsTouched();
    if (this._form.invalid) {
      return;
    }

    this._updating = true;
    const payload: UpdateProfileDto = { ...this._form.value };
    payload.image = this.updatedImage;
    payload.phoneNumber = (this._form.get('phoneNumber').value as XPhoneNumber)?.e164Number;
    this.profileController
      .updateUserProfile({ updateProfileDto: payload })
      .subscribe({
        next: (value) => {
          //   reload profile image if any
          this._form.patchValue(value);
          const successModal = this.modalService.show(SuccessDialogComponent, {
            initialState: {
              message: 'You have successfully updated your profile',
              closeButtonMessage: 'Go to Dashboard'
            },
            class: 'modal-dialog modal-dialog-centered modal-sm',
            animated: true,
            ignoreBackdropClick: true,
            keyboard: false
          });

          successModal.content.closeEvent.subscribe({
            next: () => {
              this.router.navigate(['/dashboard']);
            }
          });
        },
        error: (err: unknown) => {
          this.showMessage(
            err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
              ? err.error.message
              : 'Unable to update profile',
            'danger'
          );
        }
      })
      .add(() => (this._updating = false));
  }

  changeProfileImage($event: MouseEvent): void {
    document.getElementById('ngxDropZoneElementId').click();
  }

  getImageDataURI(id: number): void {
    this._loadingImage = true;
    this.fileController
      .getDataURI({ id: id })
      .subscribe({
        next: (value) => {
          this.initialImage = value.code;
        }
      })
      .add(() => (this._loadingImage = false));
  }

  get organizationRoles(): Observable<string[]> {
    return this.profileController.organizationRoles({
      name: this._form.get('playerRole')?.value || ' '
    });
  }

  get userInitial(): string {
    // Split the name into individual words
    const words = this.fullName.split(' ');

    // Extract the first letter of each word and convert it to uppercase
    const initials = words.map((word) => word.charAt(0).toUpperCase());

    // Join the initials together
    return initials.join('');
  }
}
