import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SearchHandler } from '../../search/search-handler';
import {
  QueryResultsScoreboardPlayerSummaryPojo,
  ScoreboardControllerService,
  ScoreboardPlayerSummaryPojo,
  UserWithoutWigPojo
} from '../../../../../sdk/ecadence-sdk';
import { SearchFilterSource } from '../../search/search-filter-source';
import { SearchManager } from '../../search/search-manager';
import { NameValuePair } from '../../../models/etc/name-value-pair.model';
import { PaginatedSearch } from '../../search/paginated-search';
import { PlayerLeadmeasuresComponent } from '../player-leadmeasures/player-leadmeasures.component';
import { PlayerLagmeasuresComponent } from '../player-lagmeasures/player-lagmeasures.component';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: 'player-summary',
  templateUrl: './player-summary.component.html',
  styleUrls: ['./player-summary.component.css']
})
export class PlayerSummaryComponent
  implements
    OnInit,
    SearchHandler<ScoreboardPlayerSummaryPojo, ScoreboardPlayerSummaryPojo>,
    SearchFilterSource<ScoreboardPlayerSummaryPojo>
{
  @Input() wigId: number;
  maxPageSize = 10;
  wigPlayersSummary: QueryResultsScoreboardPlayerSummaryPojo;
  searchManager!: SearchManager<ScoreboardPlayerSummaryPojo, ScoreboardPlayerSummaryPojo>;
  hideUsersWithoutAssignedWig = false;
  usersWithoutWig: UserWithoutWigPojo[];
  selectPlayerId: number;

  constructor(
    private scoreboardControllerService: ScoreboardControllerService,
    private bsModalService: BsModalService,
    private helpService: HelperService
  ) {
    this.searchManager = new SearchManager<
      ScoreboardPlayerSummaryPojo,
      ScoreboardPlayerSummaryPojo
    >(this, this);
  }

  ngOnInit(): void {
    this.getWigPlayerSummary();
    this.getUsersWithoutAssignedWigs();
  }

  getWigPlayerSummary(): any {
    this.scoreboardControllerService
      .getPlayersForAWig({
        wigId: this.wigId,
        limit: this.maxPageSize,
        offset: 10
      })
      .subscribe(
        (res) => {
          this.wigPlayersSummary = res;
          this.searchManager.itemsPerPage = this.maxPageSize;
          this.searchManager.reloadAndShowFirstPage();
        },
        (error: unknown) => {
          console.log('error ==>', error);
        }
      );
  }

  getFilter(): ScoreboardPlayerSummaryPojo {
    return undefined;
  }

  getPersistentKey(): string {
    return '';
  }

  getSearchDescriptor(filter: ScoreboardPlayerSummaryPojo): NameValuePair[] {
    return [];
  }

  search(page: number | undefined, filter?: ScoreboardPlayerSummaryPojo): Observable<any> {
    const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;
    const paginatedSearch = PaginatedSearch.createCleanFilter(
      filter,
      this.searchManager.itemsPerPage,
      offset
    );
    return this.scoreboardControllerService.getPlayersForAWig({
      wigId: this.wigId,
      limit: 10,
      offset: 0
    });
  }

  gotoAssignedLeadMeasures(playerId: number, playerName: string): void {
    this.bsModalService.show(PlayerLeadmeasuresComponent, {
      initialState: {
        playerId: playerId,
        wigId: this.wigId,
        playerName: playerName
      },
      class: 'modal-dialog-centered'
    });
  }

  gotoAssignedLagMeasures(playerId: number, playerName: string): void {
    this.bsModalService.show(PlayerLagmeasuresComponent, {
      initialState: {
        playerId: playerId,
        wigId: this.wigId,
        playerName: playerName
      },
      class: 'modal-dialog-centered'
    });
  }

  viewUsersWithoutWig(): void {
    this.hideUsersWithoutAssignedWig = !this.hideUsersWithoutAssignedWig;
  }

  getUsersWithoutAssignedWigs(): void {
    this.scoreboardControllerService.getUsersWithoutAssignedWig().subscribe(
      (res) => {
        this.usersWithoutWig = res;
      },
      (error: unknown) => {
        console.log('error ', error);
      }
    );
  }

  removePopOverBody(): void {
    setTimeout(() => {
      this.helpService.removePopOverBody();
    }, 5);
  }

  updateSelectedId(playerId: number): void {
    this.selectPlayerId = playerId;
    this.removePopOverBody();
  }
}
