import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, Observable, of, switchMap } from 'rxjs';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { debounceTime } from 'rxjs/operators';
import {
  AccountMembershipPojo,
  ApiResponseBoolean,
  CreateLeadMeasureDto,
  LagControllerService,
  LagMeasurePojo,
  LeadMeasureSearchPojo,
  LeverageControllerService,
  MeasurementUnitControllerService,
  NameCodePojo,
  NameIdPojo,
  SuggestLeadMeasureDto,
  WigControllerService,
  WigPojo
} from '../../../../../../../sdk/ecadence-sdk';
import { PreviewLeadMeasureModalComponent } from '../preview-lead-measure-modal/preview-lead-measure-modal.component';
import { XAlertMessage } from '../../../../../shared/components/alert-message/alert-message.component';
import { NotBlankValidator } from '../../../../../shared/validators/not-blank.validator';
import { FormConstants } from '../../../../../shared/constants/form-constants';
import { Utils } from '../../../../../shared/utils/utils';
import { ReviewLeadMeasureSuggestionComponent } from '../review-lead-measure-suggestion-modal/review-lead-measure-suggestion-modal.component';
import { NumberOnly } from '../../../../../shared/validators/number-only';
import { SuccessDialogComponent } from '../../../../../shared/components/success-dialog/success-dialog.component';
import { HelperService } from '../../../../../services/helper.service';
import { AuthenticationService } from '../../../../../services/authentication.service';
import DesiredDirectionEnum = LagMeasurePojo.DesiredDirectionEnum;
import DesiredDirectionConstantEnum = CreateLeadMeasureDto.DesiredDirectionConstantEnum;

@Component({
  selector: 'create-lead-measure-modal',
  templateUrl: './create-lead-measure-modal.component.html',
  styleUrls: ['./create-lead-measure-modal.component.css']
})
export class CreateLeadMeasureModalComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-dark-blue',
    showWeekNumbers: false,
    dateInputFormat: 'YYYY-MM-DD'
  };

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    allowSearchFilter: true,
    textField: 'name',
    idField: 'code'
  };

  _wigPojo: WigPojo;
  hideCreateForm = false;
  _leadMeasureSearchPojo: LeadMeasureSearchPojo;
  _isFetchingUsers: boolean;
  _hasPatchedLag = false;
  _hasPatchLagMeasure = false;
  lagMeasureInterval: any;
  _showBenchmarkForm = true;

  _isSuggestion = false;

  _hide = false;

  @Input()
  set isSuggestion(value: boolean) {
    this._isSuggestion = value;
  }

  @Input()
  set leadMeasureSearchPojo(value: LeadMeasureSearchPojo) {
    this._leadMeasureSearchPojo = value;
    this.patchFormValue();
  }

  get wigPojo(): WigPojo {
    return this._wigPojo;
  }

  @Input()
  set wigPojo(value: WigPojo) {
    this._wigPojo = value;
  }

  @Output()
  doneEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  hideForPreview: EventEmitter<any> = new EventEmitter<any>();

  xAlertMessage: XAlertMessage = {
    messageType: 'info',
    message: undefined
  };
  benchmarkError: string = undefined;
  form: FormGroup;
  subForm: FormGroup;

  benchmarks: XBenchmarkPojo[] = [];

  //lists
  lags: NameIdPojo[];
  lagMeasures: NameIdPojo[];
  desiredDirections: DesiredDirectionEnum[] = Utils.enumValues(DesiredDirectionEnum);
  users: NameCodePojo[] = [];

  // booleans
  isFetchingLags = false;
  isFetchingLagMeasures = false;

  minimumBenchmarkDay: Date = new Date();
  minimumBenchmarkTime: Date = new Date();

  _suggesting = false;

  get measurementUnits(): Observable<string[]> {
    return this.measurementUnitController.searchForMeasurements({
      name: this.form.get('measurementUnit').value
    });
  }

  constructor(
    private wigController: WigControllerService,
    private formBuilder: FormBuilder,
    private lagController: LagControllerService,
    private bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private measurementUnitController: MeasurementUnitControllerService,
    private leverageController: LeverageControllerService,
    private helperService: HelperService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.initializeMainForm();
    this.initializeSubForm();
    this.listenToMainFormChanges();
    this.listenToSubFormChanges();
    this.fetchLagsForWig(this._wigPojo.id);
    this.fetchPlayers(this._wigPojo.id);
    this.patchFormValue();
  }

  initializeMainForm(): void {
    this.form = this.formBuilder.group({
      lagId: ['', [Validators.required]],
      lagMeasureId: ['', [Validators.required]],
      /*leadMeasureName: [
        this._leadMeasureSearchPojo?.name ? this._leadMeasureSearchPojo?.name : '',
        [
          Validators.required,
          NotBlankValidator.validate,
          Validators.minLength(FormConstants.NAME_MINIMUM_LENGTH),
          Validators.maxLength(FormConstants.NAME_MAXIMUM_LENGTH),
          Validators.pattern(FormConstants.MUST_START_WITH_ALPHABET_REGEX)
        ]
      ],*/
      measurementUnit: [
        this._leadMeasureSearchPojo?.measurementUnit
          ? this._leadMeasureSearchPojo?.measurementUnit
          : '',
        [
          Validators.required,
          NotBlankValidator.validate,
          Validators.minLength(FormConstants.NAME_MINIMUM_LENGTH),
          Validators.maxLength(FormConstants.NAME_MAXIMUM_LENGTH),
          Validators.pattern(FormConstants.MUST_START_WITH_ALPHABET_REGEX)
        ]
      ],
      desiredDirectionConstant: [
        this._leadMeasureSearchPojo?.desiredDirection
          ? this._leadMeasureSearchPojo?.desiredDirection
          : '',
        [Validators.required]
      ],
      currentValue: [
        this._leadMeasureSearchPojo?.currentScore ? this._leadMeasureSearchPojo?.currentScore : 0,
        [Validators.required, Validators.min(0), Validators.max(FormConstants.MAX_NUMBER)]
      ],
      lagPredictionNote: [
        this._leadMeasureSearchPojo?.predictionNote
          ? this._leadMeasureSearchPojo?.predictionNote
          : '',
        [
          Validators.required,
          NotBlankValidator.validate,
          Validators.minLength(FormConstants.NAME_MINIMUM_LENGTH),
          Validators.maxLength(FormConstants.SHORT_NOTE_MAXIMUM_LENGTH),
          Validators.pattern(FormConstants.MUST_START_WITH_ALPHABET_REGEX)
        ]
      ],
      users: ['', [Validators.required]]
    });
  }

  checkForUniqueUOM(uom: string, control: AbstractControl): void {
    const lagMeasureId: number = this.form.get('lagMeasureId').value;

    if (lagMeasureId == undefined || lagMeasureId < 1 || uom == undefined || uom.length < 1) {
      return;
    } else {
      this.leverageController
        .isUniqueForLagMeasure({
          uom: uom,
          id: lagMeasureId,
          ignore: this._leadMeasureSearchPojo?.measurementUnit
        })
        .subscribe({
          next: (value: ApiResponseBoolean) => {
            if (value && !value.data) {
              control.setErrors({ leadMeasureForLagMeasure: true });
            } else {
              control.setErrors({ leadMeasureForLagMeasure: null });
              control.updateValueAndValidity();
            }
          },
          error: (error: unknown) => {
            return of({ leadMeasureForLagMeasure: true });
          }
        });
    }
  }

  initializeSubForm(): void {
    this.subForm = this.formBuilder.group({
      dueDate: ['', [Validators.required]],
      dueTime: ['', [Validators.required]],
      predictedLagScore: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      targetScore: [
        '',
        [Validators.required, Validators.min(0), Validators.max(FormConstants.MAX_NUMBER)]
      ]
    });
    // this.subForm.get('dueDate').patchValue(new Date());
  }

  listenToSubFormChanges(): void {
    // this.subForm.get('dueDate').valueChanges.subscribe({
    //   next: (value: Date) => {
    //     if (value < new Date()) {
    //       this.minimumBenchmarkTime = new Date();
    //       this._dueTime = this.minimumBenchmarkTime;
    //       return;
    //     }
    //
    //     const lastBenchmarkDate: Date = this.benchmarks.at(this.benchmarks.length - 1)?.dueTime;
    //     if (value < lastBenchmarkDate) {
    //       this.minimumBenchmarkTime = new Date(lastBenchmarkDate.getTime() + 60000);
    //       this._dueTime = this.minimumBenchmarkTime;
    //       return;
    //     }
    //
    //     const minCopy = this.minimumBenchmarkDay;
    //     const compareValue = this.compareDates(value, minCopy);
    //     if (compareValue == 1) {
    //       this.minimumBenchmarkTime = this.setDateToMidnight(value);
    //       this._dueTime = this.setDateToMidnight(this.minimumBenchmarkTime);
    //     } else if (compareValue == 0) {
    //       this.minimumBenchmarkTime = this.minimumBenchmarkDay;
    //       this._dueTime = this.minimumBenchmarkTime;
    //     }
    //   }
    // });
  }

  setDateToMidnight(date: Date): Date {
    date.setHours(0, 0, 0, 0);
    return date;
  }

  compareDates(date1: Date, date2: Date): number {
    const date1Midnight = this.setDateToMidnight(date1);
    const date2Midnight = this.setDateToMidnight(date2);

    if (date1Midnight.getTime() < date2Midnight.getTime()) {
      return -1;
    } else if (date1Midnight.getTime() > date2Midnight.getTime()) {
      return 1;
    } else {
      return 0;
    }
  }

  listenToMainFormChanges(): void {
    this.form.valueChanges.subscribe((val): void => {
      if (this.xAlertMessage) {
        this.xAlertMessage.message = undefined;
      }
    });

    this.form
      .get('lagId')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((lagId: any) => {
        if (lagId) {
          this.fetchLagMeasuresForLag(lagId);
        }
      });

    const measurementUnitFormControl: AbstractControl = this.form.get('measurementUnit');

    this.form
      .get('lagMeasureId')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((lagId: any) => {
        if (lagId) {
          this.checkForUniqueUOM(measurementUnitFormControl.value, measurementUnitFormControl);
        }
      });

    measurementUnitFormControl.valueChanges
      .pipe(distinctUntilChanged())
      .pipe(debounceTime(1000))
      .subscribe((changes: any) => {
        if (changes) {
          this.checkForUniqueUOM(changes, measurementUnitFormControl);
        }
      });

    this.form
      .get('desiredDirectionConstant')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((changes: any) => {
        if (changes) {
          this.benchmarks = [];
        }
      });

    this.form
      .get('currentValue')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((changes: any) => {
        if (changes) {
          this.benchmarks = [];
        }
      });
  }

  fetchLagsForWig(wigId: number): void {
    this.isFetchingLags = true;
    this.wigController
      .getLagsForWig({ id: wigId })
      .subscribe(
        (response: NameIdPojo[]): void => {
          this.lags = [];
          this.lags = response;
          if (this._leadMeasureSearchPojo) {
            this.form.get('lagId').patchValue(this._leadMeasureSearchPojo.lagId);
            this._hasPatchedLag = true;
            this.form.get('lagId').disable();
          }
        },
        (error: unknown): void => {
          this.xAlertMessage.messageType = 'danger';
          this.xAlertMessage.message =
            error instanceof HttpErrorResponse && error.error?.message && error.error.code !== 500
              ? error.error.message
              : 'Unable to load Lags for Wig';
        }
      )
      .add(() => {
        this.isFetchingLags = false;
      });
  }

  fetchLagMeasuresForLag(lagId: number): void {
    this.isFetchingLagMeasures = true;
    this.lagController
      .getMeasuresForLag({ id: lagId })
      .subscribe(
        (response: NameIdPojo[]): void => {
          this.lagMeasures = [];
          this.lagMeasures = response;
          if (this._leadMeasureSearchPojo) {
            this.lagMeasureInterval = setInterval(() => {
              if (this._hasPatchedLag && !this._hasPatchLagMeasure) {
                this.form.get('lagMeasureId').patchValue(this._leadMeasureSearchPojo.lagMeasureId);
                this.form.get('lagMeasureId').disable();
                if (this.lagMeasureInterval) {
                  clearInterval(this.lagMeasureInterval);
                }
              }
            }, 100);
          }
        },
        (error: unknown): void => {
          this.xAlertMessage.messageType = 'danger';
          this.xAlertMessage.message =
            error instanceof HttpErrorResponse && error.error?.message && error.error.code !== 500
              ? error.error.message
              : 'Unable to load Lag Measures';
        }
      )
      .add(() => {
        this.isFetchingLagMeasures = false;
      });
  }

  getFormControlName(tag: HTMLLabelElement): string {
    return tag.control.attributes.getNamedItem('formcontrolname').nodeValue;
  }

  today(): Date {
    return new Date();
  }

  getFormattedDate(date: Date): string {
    return date.toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    });
  }

  getFormattedTime(date: Date): string {
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  }

  onRoleSelectionUpdate($event: Array<any> | any): undefined {
    return undefined;
  }

  onRoleSelectionRemove($event: any): undefined {
    return undefined;
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

  addBenchmark(): void {
    this.benchmarkError = undefined;
    this.subForm.markAllAsTouched();
    if (this.subForm.invalid) {
      return;
    }

    const currentValueFormControl = this.form.get('currentValue');
    const directionFormControl = this.form.get('desiredDirectionConstant');

    if (currentValueFormControl.invalid || directionFormControl.invalid) {
      currentValueFormControl.markAsTouched();
      directionFormControl.markAsTouched();
      this.benchmarkError = 'Please, fill desired direction and/or current value';
      return;
    }
    this.benchmarkError = undefined;

    const desiredDirectionControl = directionFormControl.value;
    const currentValueControl = currentValueFormControl.value;

    const lastBenchMark = this.benchmarks.at(this.benchmarks.length - 1);

    const newBenchmarkTargetScore = Number(this.subForm.get('targetScore').value);
    const newBenchmarkPredictedLagScore = Number(this.subForm.get('predictedLagScore').value);

    if (lastBenchMark === undefined) {
      if (
        desiredDirectionControl == DesiredDirectionConstantEnum.DOWN &&
        newBenchmarkTargetScore >= currentValueControl
      ) {
        this.benchmarkError = 'Target score must be less that current value';
        return;
      } else if (
        desiredDirectionControl == DesiredDirectionConstantEnum.UP &&
        newBenchmarkTargetScore <= currentValueControl
      ) {
        this.benchmarkError = 'Target score must be more than current score';
        return;
      }
    } else {
      if (desiredDirectionControl == DesiredDirectionConstantEnum.DOWN) {
        if (lastBenchMark.targetScore <= newBenchmarkTargetScore) {
          this.benchmarkError = 'Choose a target score lower than the last benchmark';
          return;
        } else if (newBenchmarkTargetScore >= currentValueControl) {
          this.benchmarkError = 'Target score cannot be more than lead measure current value';
          return;
        }
      }
      if (desiredDirectionControl == DesiredDirectionConstantEnum.UP) {
        if (lastBenchMark.targetScore >= newBenchmarkTargetScore) {
          this.benchmarkError = 'Choose a target score greater than the last benchmark';
          return;
        } else if (newBenchmarkTargetScore <= currentValueControl) {
          this.benchmarkError = 'Target score cannot be less than lead measure current value';
          return;
        }
      }

      if (lastBenchMark.predictedLagScore > newBenchmarkPredictedLagScore) {
        this.benchmarkError = "Predicted Lag score is less than previous benchmark's";
        return;
      }

      if (
        this.combineDates(lastBenchMark.dueDate, lastBenchMark.dueTime) >=
        this.combineDates(
          this.subForm.get('dueDate').value as Date,
          this.subForm.get('dueTime').value as Date
        )
      ) {
        this.benchmarkError = 'Benchmark Due Time must be after the last benchmark due time';
        return;
      }
    }

    this.benchmarkError = undefined;
    this.benchmarks.push({ ...this.subForm.value });
    this.minimumBenchmarkDay = new Date(
      (this.subForm.get('dueTime').value as Date).getTime() + 60000
    );
    this.subForm.get('dueTime').patchValue(this.minimumBenchmarkDay);
    this.minimumBenchmarkTime = this.minimumBenchmarkDay;

    this.subForm.reset();
  }

  combineDates(date1: Date, date2: Date): Date {
    return new Date(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate(),
      date2.getHours(),
      date2.getMinutes(),
      date2.getSeconds()
    );
  }

  removeBenchmark(indexToRemove: number): void {
    this.benchmarks.splice(indexToRemove, 1);
  }

  scrollUp(): void {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  previewLeadMeasure(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.benchmarks || this.benchmarks?.length < 1) {
      this.benchmarkError = 'Please, enter at least one benchmark to proceed';
      return;
    }

    if (this._isSuggestion) {
      this._suggesting = true;
      const payload: SuggestLeadMeasureDto = { ...this.form.value };
      payload.userIds = (this.form.get('users').value as NameCodePojo[]).map((x) => x.code);
      payload.performanceBenchMarkDtos = this.helperService.convertToPerformanceBenchMarkDto(
        this.benchmarks
      );
      this.leverageController
        .suggestLeadMeasure({ suggestLeadMeasureDto: payload })
        .subscribe({
          next: (value) => {
            this.xAlertMessage.messageType = 'success';
            this.xAlertMessage.message = 'Lead Measure Suggestion Created';
            this.scrollUp();
            this.bsModalRef.hide();

            const ref = this.bsModalService.show(SuccessDialogComponent, {
              initialState: {
                message: 'Lead measure suggestion created'
              },
              class: 'modal-dialog modal-dialog-centered modal-sm',
              animated: true,
              ignoreBackdropClick: true,
              keyboard: false
            });

            ref?.content.closeEvent.subscribe({
              next: () => {
                this.bsModalRef.hide();
              }
            });
          },
          error: (err: unknown) => {
            this.xAlertMessage.messageType = 'danger';
            this.xAlertMessage.message =
              err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
                ? err.error.message
                : 'Unable to create Lead Measure suggestion';
          }
        })
        .add(() => {
          this._suggesting = false;
        });
    } else {
      const previewModal = this.bsModalService.show(PreviewLeadMeasureModalComponent, {
        initialState: {
          wigName: this._wigPojo.name,
          lagName: this.lags
            .filter((x) => {
              return x.id == this.form.get('lagId').value;
            })
            .map((y) => {
              return y.name;
            })
            .at(0),
          lagMeasureName: this.lagMeasures
            .filter((x) => {
              return x.id == this.form.get('lagMeasureId').value;
            })
            .map((y) => {
              return y.name;
            })
            .at(0),
          xBenchmarkPojos: this.benchmarks,
          xCreateLeadMeasureForm: { ...this.form.value },
          viewState: this._leadMeasureSearchPojo ? 'EDIT' : 'CREATE',
          leadMeasureId: this._leadMeasureSearchPojo?.id
        },
        keyboard: false,
        backdrop: 'static',
        class: 'modal-dialog-centered modal-lg'
      });

      this._hide = true;
      this.hideForPreview.emit();

      previewModal.content.closeEmitter.subscribe({
        next: (ignore) => {
          this._hide = false;
          previewModal?.hide();
          this.helperService.showOffCanvasClass();
        }
      });

      previewModal?.content?.successEmitter
        .pipe(
          switchMap((result: any) => {
            previewModal?.hide();

            const ref = this.bsModalService.show(SuccessDialogComponent, {
              initialState: {
                message: `Lead measure ${
                  this._leadMeasureSearchPojo ? ' updated ' : ' created '
                } successfully`
              },
              class: 'modal-dialog modal-dialog-centered modal-sm'
            });
            return ref.content.closeEvent.pipe(
              switchMap((v: any) => {
                this.bsModalRef.hide();
                this.doneEvent.emit();
                return of(v);
              })
            );
          })
        )
        .subscribe();
    }
  }

  getLagPredictionValue(): string {
    return this.form.get('lagPredictionNote')?.value;
  }

  fetchPlayers(widId: number): void {
    this._isFetchingUsers = true;
    this.wigController
      .getPlayersForWig({ id: widId })
      .subscribe({
        next: (players: NameCodePojo[]) => {
          this.users = players;
          if (
            !this.authenticationService.hasPermission(
              AccountMembershipPojo.PermissionsEnum.CREATE_LEAD_MEASURE
            )
          ) {
            console.log('No permission to creare');
            this.authenticationService.fetchUser().subscribe({
              next: (value) => {
                this.users = this.users.filter((x) => x.code == value.userId);
              }
            });
          } else {
            console.log('Has create permission');
          }
          if (this._leadMeasureSearchPojo?.assignedUsers) {
            this.form.get('users')?.patchValue(this._leadMeasureSearchPojo?.assignedUsers);
          }
        }
      })
      .add(() => {
        this._isFetchingUsers = false;
      });
  }

  protected readonly FormConstants = FormConstants;

  patchFormValue(): void {
    if (!this._leadMeasureSearchPojo) {
      return;
    }

    this.benchmarks = this._leadMeasureSearchPojo.benchmarks.map((x) => {
      return {
        dueDate: new Date(x.dueTime),
        dueTime: new Date(x.dueTime),
        predictedLagScore: x.predictedLagScore,
        targetScore: x.target
      };
    });
  }

  showPerformanceBenchmarkForm(): void {
    this._showBenchmarkForm = !this._showBenchmarkForm;
  }

  protected readonly NumberOnly = NumberOnly;

  // get canReviewLeadMeasure(): boolean {
  //   return this.authenticationService.hasPermission(
  //     AccountMembershipPojo.PermissionsEnum.CREATE_LEAD_MEASURE
  //   );
  // }
  //
  // reviewLeadMeasure(): void {
  //   const suggestionModal: BsModalRef<ReviewLeadMeasureSuggestionComponent> =
  //     this.bsModalService.show(ReviewLeadMeasureSuggestionComponent, {
  //       initialState: {
  //         leadMeasureSearchPojo: this._leadMeasureSearchPojo
  //       },
  //       keyboard: false,
  //       backdrop: 'static',
  //       class: 'modal-lg'
  //     });
  // }
}

export interface XBenchmarkPojo {
  dueDate: Date;
  dueTime: Date;
  predictedLagScore: number;
  targetScore: number;
}

export interface XCreateLeadMeasureForm {
  lagId: number;
  lagMeasureId: number;
  measurementUnit: string;
  desiredDirectionConstant: DesiredDirectionConstantEnum;
  currentValue: number;
  users: NameCodePojo[];
  lagPredictionNote: string;
}
