<script src="add-lag-kpi.component.ts"></script>
<div class="overflow-y-auto" #template>
  <div class="modal-content">
    <div class="my-3 mx-3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="fs-18 fw-bold">Lag KPIs</div>
        <div class="align-self-center">
          <button
            (click)="hideModal()"
            aria-label="Close"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            type="button"
          ></button>
        </div>
      </div>

      <div class="mt-2">
        <app-alert-message
          *ngIf="xAlertMessage"
          [messageType]="xAlertMessage!.messageType"
          [message]="xAlertMessage!.message"
        >
        </app-alert-message>
        <div class="my-3">
          <div class="display-box">
            <div class="mb-2 text-smaller-header">Lag Name</div>
            <div class="fs-16">{{ _lag.lagName }}</div>
          </div>

          <div class="mt-5 mb-3 text-tinier-header">KPI Lists</div>
          <ng-container *ngIf="_lag.kpis && _lag.kpis.length > 0; else noKpi">
            <ng-container *ngFor="let kpi of _lag.kpis; let i = index; trackBy: kpiTrackBy">
              <!--      Change class from display-blue-box to display-green-border-box when kpi has been marked as done-->
              <div
                class="mb-3"
                [ngClass]="{
                  'display-blue-box': !kpi.completed,
                  'display-green-border-box': kpi.completed
                }"
              >
                <div class="d-flex justify-content-between">
                  <div class="mb-2 kpi-box-header">{{ kpi.description }}</div>
                  <div class="mb-2 kpi-box-header" *ngIf="kpi.lagkpiTypeConstant">
                    {{ kpi?.lagkpiTypeConstant }}
                  </div>
                  <div class="mb-3 pointer text-end" (click)="editKpiModal(kpi)">
                    <span class="iconify fs-20 text-blue" data-icon="fluent:edit-20-regular"></span>
                  </div>
                </div>

                <div class="mb-4 d-flex justify-content-between" *ngIf="kpi.hasSupportingDocuments">
                  <button
                    [disabled]="_downloadingDocument"
                    (click)="downloadSupportingDocument(kpi)"
                    style=""
                    class="mini-button px-3 py-1"
                  >
                    <span class="iconify fs-20" data-icon="fluent:arrow-download-48-regular"></span>
                    Download Supporting Document
                  </button>
                </div>

                <div class="fs-13 fw-medium">
                  Weight: <span class="fw-normal"> {{ kpi.weight }} |</span> Due Date:
                  <span class="fw-normal">
                    {{ kpi.dueDate ? (kpi.dueDate | shortestDate) : 'NONE' }} |</span
                  >
                  <span
                    class=""
                    [ngClass]="{ 'text-danger': !kpi.completed, 'text-success': kpi.completed }"
                  >
                    {{ kpi.completed ? 'Done' : 'Not Done' }}</span
                  >
                </div>
                <div class="mb-2 mt-4 d-flex align-items-center justify-content-end">
                  <button
                    style=""
                    class="px-3 py-1 pointer d-flex"
                    [ngClass]="{
                      'mini-danger-button': kpi.completed,
                      'mini-success-button': !kpi.completed
                    }"
                    (click)="markAs(kpi, i)"
                  >
                    <span
                      class="iconify fs-20"
                      data-icon="fluent:arrow-sync-checkmark-24-regular"
                    ></span>

                    Mark as {{ kpi.completed ? 'not done' : 'done' }}
                  </button>

                  <button (click)="deleteKpi(kpi)" class="mini-danger-button ms-2 px-3 py-1">
                    <span class="iconify fs-18" data-icon="fluent:delete-24-regular"></span>
                    Delete
                  </button>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #noKpi>
            <div class="px-3 py-5 justify-content-center mx-auto text-center">
              <div>
                <span
                  class="iconify mb-2"
                  style="font-size: 28px"
                  data-icon="fluent:document-error-16-regular"
                ></span>
              </div>
              <div>No KPI Available</div>
            </div>
          </ng-template>

        </div>
        <form [formGroup]="form">
          <div class="my-3">
            <div class="mt-5 mb-2 text-tinier-header">Add KPI</div>
            <hr class="mb-4" />
            <div class="mb-3">
              <label for="description" class="form-label required"> KPI </label>
              <input
                type="text"
                id="description"
                class="form-control"
                placeholder="Enter KPI description"
                [formControlName]="'description'"
              />
              <error-message
                [controlName]="'description'"
                [form]="form"
                [label]="'Description'"
                [fontSize]="10"
                [singularError]="true"
              >
              </error-message>
            </div>
            <div class="mb-3">
              <label for="weight" class="form-label required"> Weight </label>
              <input
                id="weight"
                type="number"
                class="form-control"
                [formControlName]="'weight'"
                placeholder="1"
                min="1"
                (keydown)="NumberOnly.allowOnlyNumbers($event)"
              />
              <error-message
                [controlName]="'weight'"
                [form]="form"
                [label]="'Weight'"
                [fontSize]="10"
                [singularError]="true"
              >
              </error-message>
            </div>
            <div class="mb-3">
              <label for="dueDate" class="form-label required"> Due Date </label>
              <div class="input-group">
                <div class="input-group">
                  <input
                    #dueDate="bsDatepicker"
                    bsDatepicker
                    type="text"
                    class="form-control"
                    id="dueDate"
                    [bsConfig]="datePickerConfig"
                    autocomplete="off"
                    [minDate]="today"
                    placeholder="YYYY-MM-DD"
                    [formControlName]="'dueDate'"
                  />
                  <span class="input-group-text bg-white" (click)="dueDate.toggle()"
                    ><span class="iconify" data-icon="uil:calender"></span
                  ></span>
                </div>
              </div>
              <error-message
                [controlName]="'dueDate'"
                [form]="form"
                [label]="'Due Date'"
                [fontSize]="10"
                [singularError]="true"
              >
              </error-message>
            </div>
            <div class="col-12 mb-3">
              <label for="kpiType" class="form-label fw-bold required" aria-required="true">
                Lag KPI Type
              </label>
              <div class="dropdown">
                <select
                  id="kpiType"
                  class="form-control"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  [formControlName]="'lagkpiTypeConstant'"
                >
                  <option [value]="''" selected>Select Lag KPI Type</option>
                  <option *ngFor="let lag of lagKpiType" [value]="lag">
                    {{lag | uppercase }}
                  </option>
                </select>
              </div>
              <error-message
                [controlName]="'lagkpiTypeConstant'"
                [form]="form"
                [label]="'Lag KPI Type'"
                [fontSize]="10"
                [singularError]="true"
              >
              </error-message>
            </div>
            <div class="mb-3 text-end">
              <button (click)="addKpi()" class="btn btn-outline-primary text-end">
                <span class="iconify fs-20" data-icon="carbon:add"></span> Add KPI
              </button>
            </div>
          </div>
        </form>

        <div class="dash-dotted">
          <ng-container *ngFor="let kpi of kpiDtoList; let i = index">
            <div class="display-blue-box mb-2">
              <div class="mb-2 kpi-box-header">{{ kpi.description }}</div>
              <div class="mb-2 kpi-box-header">{{ kpi.lagkpiTypeConstant }}</div>
              <div class="fs-13 fw-medium">
                Weight: <span class="fw-normal"> {{ kpi.weight }} |</span> Due Date:
                <span class="fw-normal"> {{ kpi.dueDate | shortestDate }} </span>
              </div>
              <div (click)="removeKpi(kpi)" class="mt-2 align-items-center justify-content-end">
                <div class="text-danger fs-14">Delete</div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="mt-5">
          <button (click)="save()" class="btn btn-primary fs-16 w-100">Save KPI</button>
        </div>
      </div>
    </div>
  </div>
</div>
