<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      Upload Supporting Document <span class="fs-16 fw-normal">(optional)</span>
    </h5>
    <button
      (click)="closeModal()"
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="!_successful; else successLottie">
      <div class="mb-3 mt-2">
        Do you have any relevant supporting documents to upload?. This is the time to do it!
      </div>
      <app-alert-message
        *ngIf="xAlertMessage"
        [messageType]="xAlertMessage!.messageType"
        [message]="xAlertMessage!.message"
      >
      </app-alert-message>
      <div class="display-box">
        <div class="mb-2 fs-16 fw-medium">KPI Name</div>
        <div class="fs-14">{{ kpi.description }}</div>
      </div>
      <div class="my-3">
        <form [formGroup]="_form">
          <label class="file-select-card">
            <input
              #fileInput
              type="file"
              class="form-control"
              placeholder=""
              accept="image/*,application/pdf"
              (change)="documentChanges($event)"
            />
            <span
              class="iconify icon mb-3"
              style="color: #0b2d9f"
              data-icon="icon-park-outline:upload-logs"
            ></span>

            <div class="fs-16 mb-2">
              Choose file <span class="fw-normal">or</span> drag here to upload
            </div>
            <div class="instructions">
              File format must only be PDF or Images. File size should not be more than 3MB.
            </div>
          </label>
        </form>
        <ng-container *ngIf="documentFormControl.touched">
          <small style="color: red" class="fs-10" *ngIf="documentFormControl.hasError('fileSize')">
            Maximum file size is {{ _maxSizeInMB }} MB
          </small>
          <small style="color: red" class="fs-10" *ngIf="documentFormControl.hasError('fileType')">
            Only JPEG, JPG, PNG and PDF file allowed
          </small>
        </ng-container>
      </div>

      <div class="">
        <div class="d-flex align-items-center file-selected-card">
          <ng-container *ngFor="let doc of _documents; let i = index">
            <div class="object-fit-contain ms-3">
              <ng-container *ngIf="isImage(doc); else pdfPlaceholder">
                <img
                  width="150"
                  height="150"
                  class="img-fluid img-thumbnail-pdf"
                  [alt]="doc.name"
                  [src]="doc.data"
                />
              </ng-container>
              <ng-template #pdfPlaceholder>
                <span
                  class="iconify"
                  data-icon="bi:file-pdf-fill"
                  style="color: #980909"
                  data-width="75"
                  data-height="75"
                ></span>
              </ng-template>

              <div class="text-center fs-14 limit-to-10-chars" style="color: #0b2d9f">
                {{ doc.name }}
              </div>
              <div (click)="remove(doc, i)" class="text-center text-danger pointer fs-12">
                Remove
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer" *ngIf="!_successful">
    <app-button-with-loader
      [buttonType]="'button'"
      [classText]="'btn btn-primary'"
      [idleStateText]="'Mark as Done'"
      [isProcessing]="_marking"
      [icon]="
        '<span class=\'iconify fs-20\' data-icon=\'fluent:arrow-sync-checkmark-24-regular\'></span>'
      "
      [workingStateText]="'Marking...'"
      (buttonClick)="markAsDone()"
    >
    </app-button-with-loader>
  </div>
</div>

<ng-template #successLottie>
  <div class="my-3">
    <lottie-in-full-table [path]="'assets/lottie/success.json'" [message]="'KPI marked as done'">
    </lottie-in-full-table>
  </div>
</ng-template>
