import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AccountMembershipPojo } from '../../sdk/ecadence-sdk';
import { LandingPageComponent } from './pages/extranet/landing-page/landing-page.component';
import { DashboardComponent } from './pages/intranet/dashboard/dashboard.component';
import { LoginPageComponent } from './pages/extranet/login-page/login-page.component';
import { WigMgtComponent } from './pages/intranet/wig-mgt/wig-mgt.component';
import { LoggedInGuard } from './shared/guards/logged-in.guard';
import { LagMgtModule } from './pages/intranet/lag-mgt/lag-mgt.module';
import { LagMgtComponent } from './pages/intranet/lag-mgt/lag-mgt.component';
import { PortalAccountComponent } from './pages/intranet/portal-account/portal-account.component';
import { LagDetailsComponent } from './pages/intranet/lag-mgt/lag-details/lag-details.component';
import { WigMilestonesComponent } from './pages/intranet/wig-milestones/wig-milestones.component';
import { BlockerSearchComponent } from './pages/intranet/leverage-mgt/blocker-search/blocker-search.component';
import { HasMembershipGuard } from './shared/guards/has-membership.guard';
import { DashNewComponent } from './pages/intranet/dash-new/dash-new.component';
import { ContactUsComponent } from './pages/extranet/contact-us/contact-us.component';
import { ForbiddenPageComponent } from './shared/forbidden-page/forbidden-page.component';
import { PermissionGuard } from './shared/guards/permission-guard';
import { ProfileComponent } from './pages/intranet/profile/profile.component';
import PermissionsEnum = AccountMembershipPojo.PermissionsEnum;

const routes: Routes = [
  {
    path: 'accounts',
    component: PortalAccountComponent,
    pathMatch: 'full',
    // canActivate: [LoggedInGuard],
    data: {
      isExtranet: false
    }
  },

  {
    path: '404',
    component: ForbiddenPageComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true
    }
    // canActivate: [NotLogged]
  },

  {
    path: '',
    component: LandingPageComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true
    }
    // canActivate: [NotLogged]
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true
    }
    // canActivate: [NotLogged]
  },
  {
    path: 'login',
    component: LoginPageComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true
    }
  },

  {
    path: 'blocker',
    component: BlockerSearchComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard, HasMembershipGuard],
    data: {
      isExtranet: false
    }
  },

  {
    path: 'dash',
    component: DashNewComponent,
    pathMatch: 'full',
    // canActivate: [LoggedInGuard, SubscriptionGuard],
    data: {
      isExtranet: false
    }
  },

  {
    path: 'dashboard',
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard, HasMembershipGuard],
    data: {
      isExtranet: false
    }
  },
  {
    path: 'blocker',
    component: BlockerSearchComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard, HasMembershipGuard],
    data: {
      isExtranet: false
    }
  },
  {
    path: 'wig',
    loadChildren: () =>
      import('./pages/intranet/wig-mgt/wig-mgt.module').then((m) => m.WigMgtModule),
    canActivate: [LoggedInGuard, HasMembershipGuard, PermissionGuard],
    data: {
      isExtranet: false,
      allowedPermissions: [PermissionsEnum.VIEW_WIG, PermissionsEnum.CREATE_WIG]
    }
  },

  {
    path: 'users',
    loadChildren: () =>
      import('./pages/intranet/user-management/user-management.module').then(
        (m) => m.UserManagementModule
      ),
    canActivate: [LoggedInGuard, HasMembershipGuard, PermissionGuard],
    data: {
      isExtranet: false,
      hideLayout: false,
      allowedPermissions: [PermissionsEnum.VIEW_USER, PermissionsEnum.CREATE_USER]
    }
  },

  {
    path: 'lagdetails',
    component: LagDetailsComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard, HasMembershipGuard],
    data: {
      isExtranet: false
    }
  },

  { path: 'lag', pathMatch: 'full', redirectTo: 'lag/create-lag' },

  {
    path: 'lag',
    canActivate: [LoggedInGuard, HasMembershipGuard],
    component: LagMgtComponent,
    data: {
      isExtranet: false
    },
    children: [
      {
        path: ':id/create-lag',
        loadChildren: () =>
          import('./pages/intranet/lag-mgt/create-lag/create-lag.module').then(
            (m) => m.CreateLagModule
          )
      },

      {
        path: ':id/update-lag',
        loadChildren: () =>
          import('./pages/intranet/lag-mgt/update-lag/update-lag.module').then(
            (m) => m.UpdateLagModule
          ),
        canActivate: [LoggedInGuard, HasMembershipGuard]
      }
    ]
  },
  {
    path: 'leverages',
    loadChildren: () =>
      import('./pages/intranet/leverage-mgt/leverage-mgt.module').then((m) => m.LeverageMgtModule),
    canActivate: [LoggedInGuard, HasMembershipGuard]
  },
  {
    path: 'master-record',
    loadChildren: () =>
      import('./pages/intranet/master-record-management/master-record-management.module').then(
        (m) => m.MasterRecordManagementModule
      ),
    canActivate: [LoggedInGuard, HasMembershipGuard],
    data: {
      isExtranet: false,
      hideLayout: false
    }
  },
  {
    path: 'forbidden',
    component: ForbiddenPageComponent,
    data: {
      isExtranet: true
    }
  },

  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      isExtranet: false
    }
  },
  {
    path: 'player-profile',
    loadChildren: () => import('./pages/intranet/player/player.module').then((m) => m.PlayerModule),
    canActivate: [LoggedInGuard, HasMembershipGuard],
    data: {
      isExtranet: false,
      hideLayout: false
    }
  }
];
const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
