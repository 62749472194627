import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomValidators } from '../../../custom-validators';
import { FormConstants } from '../../../constants/form-constants';
import { XAlertMessage } from '../../alert-message/alert-message.component';

@Component({
  selector: 'app-disapproval-dialog',
  templateUrl: './disapproval-dialog.component.html',
  styleUrls: ['./disapproval-dialog.component.css']
})
export class DisapprovalDialogComponent implements OnInit {
  form!: FormGroup;

  xAlertMessage: XAlertMessage = {
    message: undefined,
    messageType: 'info'
  };

  @Output()
  confirmClicked: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  loading = false;

  @Input() onCancel = new EventEmitter<boolean>();

  @Input()
  headerMessage = 'Disapprove';

  @Input()
  promptText = 'Are you sure you want to disapprove this request?';

  @Input()
  formLabel = 'Label';

  @Input()
  successMessage = 'Action Successful';

  showSuccess = false;

  constructor(private modalRef: BsModalRef, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      reason: ['', [Validators.required, CustomValidators.minLength(3), Validators.maxLength(100)]]
    });
  }

  onDone(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.confirmClicked.emit(this.form.get('reason').value);
  }

  closeModal(): void {
    this.onCancel.emit(true);
    this.modalRef.hide();
  }

  protected readonly FormConstants = FormConstants;
}
