<div class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="bg-body-secondary position-fixed bd-example-toasts rounded-3 m-3" style="top: 0; right: 0;">
    <div class="toast-container p-3 top-0 rounded-0" style="right: 22rem" id="toastPlacement">
      <div class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="progress rounded-0" style="height: 8px" [style.width.%]="progressPercentage">
          <div class="progress-bar progress-bar-striped bg-danger progress-bar-animated" style="background-color: red;"></div>
        </div>
        <div class="toast-header border-0 flex-column">
          <div class="pe-3 text-end w-100">
            <span class="iconify fs-4 text-primary" data-icon="healthicons:group-discussion-meetingx3"></span>
          </div>
        </div>
        <div class="toast-body fw-bold">
          <!-- Countdown timer -->
          <div class="me-auto fs12 fw-medium">Cadence in {{ countdown }} seconds</div>
          <p class="my-2">{{ modalData.wigName }} : {{ modalData.title }}</p>
          <span class="text-decoration-underline fs-10 text-primary fw-normal pointer" (click)="gotoScoreBoard()">
            Go to cadence now
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
