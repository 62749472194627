<div class="bg-black vh-100 graphik-font">
  <div class="container">
    <div class="row">
      <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="col-4 p-5 d-flex flex-column">
          <div class="navbar-brand my-5 text-center">
            <img
              src="/assets/img/template/eLogo.svg"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="d-flex flex-column">
            <div class="mb-3">
              <label for="email" class="text-light opacity-75 mb-2 form-label"
                >Username/Email
              </label>
              <input
                type="text"
                class="form-control text-light"
                id="email"
                placeholder="eg. jamesjd@bytehealth.com"
                style="background-color: #ffffff3d; color: white"
              />
            </div>

            <div>
              <label for="pass" class="text-light opacity-75 mb-2 form-label"
                >Password</label
              >
              <div class="mb-3 input-group">
                <input
                  type="password"
                  class="form-control text-light"
                  id="pass"
                  placeholder="**************"
                  style="background-color: #ffffff3d; color: white"
                />
                <button
                  class="btn btn-light position-absolute p-2 border-0 text-light"
                  style="right: 0px; top: 0px; background-color: transparent"
                  type="button"
                >
                  <span class="iconify" data-icon="la:eye-slash-solid"></span>
                </button>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked
                  />
                  <label
                    class="form-check-label text-light fs-12"
                    for="flexCheckChecked"
                  >
                    Remember me
                  </label>
                </div>
              </div>

              <div>
                <a href="" class="fs-12 text-light"> Forgot password? </a>
              </div>
            </div>
            <div class="d-flex flex-column my-5">
              <button class="btn btn-light px-5">Sign In</button>
            </div>

            <div class="my-5 d-flex flex-column align-items-center text-light">
              <div>
                <ul class="d-flex gap-5 fs-10 opacity-50">
                  <li>Terms of Use</li>
                  <li>Privacy Policy</li>
                </ul>
              </div>
              <p class="fs-12 my-2">Copyrights 2023. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
