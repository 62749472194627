<div class="my-5">
  <div class="text-mini-header mb-3">
    Supporting Document
  </div>
  <div *ngIf="attachmentList.length > 0; else noData" class="profile-bottom-div p-5">
    <div class="row gy-3">
      <div *ngFor="let attachment of attachmentList" class="col-md-12">
        <img
          width="210"
          height="210"
          class="img-fluid img-thumbnail-pdf-doc mx-auto"
          alt=""
          [src]="
            attachment.fileType === DocType.IMAGE
              ? getFileUrl(attachment)
              : 'assets/img/pdf-image.png'
          "
          (click)="previewFile(attachment.fileType, attachment?.fileId)"
        />
        <div class="text-center fs-14" style="color: #0b2d9f">{{ attachment.name }}</div>
        <!--          <div class="text-center text-danger pointer fs-12">-->
        <!--            Remove-->
        <!--          </div>-->
      </div>
    </div>
  </div>
  <ng-template #noData>
    <lottie-in-full-table [path]="'assets/lottie/no-data.json'" [message]="'No Lag Documents'">
    </lottie-in-full-table>
  </ng-template>
</div>
