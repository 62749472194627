<div class="offcanvas offcanvas-end show overflow-y-auto">
  <div class="modal-content">
    <div class="my-3 mx-3">
      <app-alert-message
        *ngIf="xAlertMessage"
        [messageType]="xAlertMessage!.messageType"
        [message]="xAlertMessage!.message"
      >
      </app-alert-message>
      <div class="d-flex justify-content-between align-items-center">
        <div class="fs-16 fw-bold">Add Commitment</div>
        <div class="align-self-center">
          <button
            (click)="closeModal()"
            type="button"
            class="btn close fs-19 pointer"
            data-dismiss="modal"
          >
            <span
              class="iconify"
              data-icon="mdi:cancel-box-outline"
              data-width="30"
              data-height="30"
            ></span>
            Cancel
          </button>
        </div>
      </div>
      <div class="mt-2">
        <form [formGroup]="form">
          <div class="my-3 fs-12">

            <div class="mb-4 form-group">
              <custom-select-field
                [showRequiredRedIcon]="true"
                [form]="form"
                [controlName]="'lag'"
                [isLoading]="fetchingLagsAndItsMeasures"
                [name]="'Lag'"
                [rawData]="lagUI"
                [keyField]="'id'"
                [valueField]="'name'"
              >
              </custom-select-field>
            </div>

            <div>
              <label class="form-label fs-12 required">Lag measure</label>
              <select
                name="lagMeasure"
                formControlName="lagMeasure"
                class="form-control"
                (change)="handleSelection($event)"
              >
                <option *ngIf="fetchingLagMeasures" [value]="''">Fetching Lag Measures...</option>
                <option *ngIf="!fetchingLagMeasures" [value]="''">-- Select Lag Measure --</option>
                <option *ngIf="!fetchingLagMeasures && lagMeasureUI?.length < 1" [value]="''">
                  No Lag measure found --
                </option>
                <option *ngFor="let s of lagMeasureUI" [value]="s.lagMeasureId">
                  {{ s.lagMeasureName | titlecase }}
                </option>
              </select>
              <error-message
                [form]="form"
                [controlName]="'lagMeasures'"
                [customErrorMessages]="[
                  { type: 'required', message: 'Lag measure is required' },
                  { type: 'maxlength', message: 'Lag measure is required' }
                ]"
              ></error-message>
            </div>

            <div class="my-3" *ngIf="selectedLagMeasure">
              <div class="form-control">
                <div>
                  <p class="fs-14 fw-bold">Current benchmark to meets</p>
                </div>
                <div class="d-flex justify-content-between mt-4">
                  <div class="col-6 pe-2">
                    <p class="fs-12 fw-bold">Due Date:</p>
                    <p class="fw-normal text-muted fs-12">
                      {{ getLagBenchmarkToMeet()?.dueDate | date : 'yyyy-MM-dd HH:mm' || 'NIL' }}
                    </p>
                  </div>
                  <div class="col-6 ps-2">
                    <p class="fs-12 fw-bold">Score</p>
                    <p class="fw-normal text-muted fs-12">
                      {{ (getLagBenchmarkToMeet()?.targetValue) || '0' }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-3 fs-12">
            <label class="form-label required fs-12">Lead measure</label>
            <select
              name="leadMeasure"
              formControlName="leadMeasure"
              class="form-control"
              (change)="playerScore($event)"
            >
              <option *ngIf="fetchingLeadMeasures" [value]="''">Fetching Lead Measures...</option>
              <option *ngIf="!fetchingLeadMeasures" [value]="''">-- Select Lead Measure --</option>
              <option *ngIf="!fetchingLeadMeasures && leadMeasureUI?.length < 1" [value]="''">
                No Lead measure found --
              </option>
              <option *ngFor="let s of leadMeasureUI" [value]="s.leadMeasureId">
                {{ s?.unit ?? '0' | titlecase }}
              </option>
            </select>
            <error-message
              [form]="form"
              [controlName]="'leadMeasures'"
              [customErrorMessages]="[
                { type: 'required', message: 'Lead measure is required' },
                { type: 'maxlength', message: 'Lead measure is required' }
              ]"
            ></error-message>
            <div class="my-3" *ngIf="selectedLeadMeasure">
              <div class="form-control my-3">
                <div>
                  <p class="fs-12 fw-bold">Current benchmark to meet</p>
                </div>

                <div class="col-6 my-3">
                  <p class="fs-12 fw-bold">Predicted Lag Score</p>
                  <p class="fw-normal text-muted fs-12">
                    {{ getLeadBenchmarkToMeet()?.predictedLagValue ?? '0' }}
                  </p>
                </div>

                <div class="d-flex justify-content-between">
                  <div class="col-6 pe-2">
                    <p class="fs-12 fw-bold">Due Date:</p>
                    <p class="fw-normal text-muted fs-12">
                      {{ getLeadBenchmarkToMeet()?.dueDate | date : 'yyyy-MM-dd HH:mm' ?? '0' }}
                    </p>
                  </div>
                  <div class="col-6 ps-2">
                    <p class="fs-12 fw-bold">Score</p>
                    <p class="fw-normal text-muted fs-12">
                      {{ getLeadBenchmarkToMeet()?.targetValue ?? '0' }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="my-4" *ngIf="selectedLeadMeasure?.currentValue">
              <p class="fw-bold fs-12">
                Current Lead Measure Score:
                <span class="fw-normal">{{ selectedLeadMeasure?.currentValue ?? '0' }}</span>
              </p>
              <span
                *ngIf="selectedLeadMeasure?.currentValue > 0 && selectedLeadMeasure?.lastUpdated"
                class="text-primary fw-normal small"
                >Last updated:
                <span>{{ selectedLeadMeasure?.lastUpdated | relativeTime }}</span>
              </span>
            </div>
            <div class="my-4" *ngIf="playerScorePojo?.playerScore">
              <div>
                <p class="fw-bold fs-12">
                  Current Player Score:<span class="fw-normal ms-1">{{
                    playerScorePojo?.playerScore ?? '0'
                  }}</span>
                </p>
                <span
                  *ngIf="playerScorePojo?.playerScore > 0 && playerScorePojo?.lastUpdated"
                  class="text-primary fw-normal small"
                  >Last updated:
                  <span>{{ playerScorePojo?.lastUpdated | relativeTime }}</span></span
                >
              </div>
            </div>
            <div>
              <div class="my-3">
                <label class="form-label fs-12 required"
                  >Target Score Movement
                  <span class="iconify fs-6" data-icon="basil:info-circle-solid"></span
                ></label>
                <input
                  required
                  type="number"
                  formControlName="playerScore"
                  class="form-control"
                  placeholder="Enter Player score "
                  [min]="playerScorePojo?.playerScore ?? 0"
                  [max]="getMaxCommitmentScore()"
                />
                <error-message [controlName]="'playerScore'" [form]="form"></error-message>
              </div>
              <div class="my-3">
                <label class="form-label fs-12 required">Lead Measure Action</label>
                <custom-text-area
                  [maximumTextLength]="FormConstants.SHORT_NOTE_MAXIMUM_LENGTH"
                  [placeholder]="'Lead Measure Action'"
                  [form]="form"
                  [controlName]="'commitmentText'"
                >
                </custom-text-area>
              </div>
              <div class="my-3">
                <label class="form-label fs-12 required"> Jira id </label>
                <input
                  required
                  type="text"
                  formControlName="jiraId"
                  class="form-control"
                  placeholder="Enter jira Id"
                />
                <error-message [controlName]="'jiraId'" [form]="form"></error-message>
              </div>
              <div class="border border-2 border-secondary-subtle mt-2 p-2">
                <div class="mb-2">
                  <label #dueDateLabel for="dueDate" class="form-label fs-12 required">Due Date</label>
                  <div class="d-flex justify-content-center">
                    <div class="input-group">
                      <input
                        #dueDate="bsDatepicker"
                        bsDatepicker
                        type="text"
                        class="form-control"
                        id="dueDate"
                        [bsConfig]="datePickerConfig"
                        [placement]="'top'"
                        autocomplete="off"
                        placeholder="YYYY-MM-DD"
                        formControlName="dueDate"
                      />
                      <span class="input-group-text bg-white" (click)="dueDate.toggle()">
                        <span class="iconify" data-icon="uil:calender"></span>
                      </span>
                    </div>
                    <error-message
                      [controlName]="getFormControlName(dueDateLabel)"
                      [form]="form"
                      [label]="dueDateLabel.innerText"
                      [fontSize]="10"
                      [singularError]="true"
                    >
                    </error-message>
                  </div>
                </div>

                <div class="d-flex justify-content-center ps-2">
                  <timepicker
                    id="dueTime"
                    formControlName="dueTime"
                    [hourStep]="1"
                    [minuteStep]="1"
                    [showMeridian]="true"
                    [mousewheel]="true"
                    [showSeconds]="false"
                  ></timepicker>
                  <error-message
                    [controlName]="'dueTime'"
                    [form]="form"
                    [label]="'Due Time'"
                    [fontSize]="10"
                    [singularError]="true"
                  >
                  </error-message>
                </div>
              </div>

              <div class="text-md-end mt-4">
                <button (click)="addCommitmentsToList()" class="btn btn-outline-primary">
                  <span class="iconify" data-icon="carbon:add"></span> Add
                </button>
              </div>
            </div>
            <hr class="hr" />
            <div *ngFor="let commitment of commitmentsList; let i = index" class="my-3">
              <commitment-preview-card
                [commitment]="commitment"
                (removeCommitmentEvent)="removeCommitment($event)"
              ></commitment-preview-card>
            </div>

            <div class="d-flex flex-column">
              <button (click)="saveCommittments()" class="btn btn-primary">Save Commitment</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
