<script src="../../../../../shared/components/custom-text-area/custom-text-area.component.ts"></script>
<div class="modal-content rounded rounded-3 text-dark">
  <div class="modal-content">
    <div class="modal-header">
      <h1 class="modal-title fs-14 fw-bolder" id="staticBackdropLabel">Preview Lead Measure</h1>
      <button
        (click)="closeModal()"
        type="button"
        class="btn-close pointer"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body pt-0">
      <app-alert-message
        *ngIf="xAlertMessage"
        [messageType]="xAlertMessage!.messageType"
        [message]="xAlertMessage!.message"
      >
      </app-alert-message>
      <!--      <div class="callout callout-info text-primary fs-11">-->
      <!--        <strong>You </strong> are creating a lead measure-->
      <!--      </div>-->

      <div class="container">
        <div class="row align-items-start my-3">
          <div class="col">
            <div class="fs-14 fw-bold text-dark my-2">Wig:</div>
            <div class="fs-11">{{ wigName }}</div>
          </div>
          <div class="col">
            <div class="fs-14 fw-bold text-dark my-2">Lag:</div>
            <div class="fs-11">{{ lagName }}</div>
          </div>
        </div>

        <div class="row align-items-start my-3">
          <div class="col-3">
            <div class="fs-14 fw-bold text-dark my-2">Lag Measure:</div>
            <div class="fs-11">{{ lagMeasureName }}</div>
          </div>
          <div class="col-9">
            <div class="card p-0 rounded-0 border-0">
              <div class="card-header border rounded-0 p-2 text-primary border-1 border-primary">
                <span class="iconify fs-5 me-2" data-icon="emojione:light-bulb"></span>
                <span>This lead measure predicts that </span>
              </div>
              <div class="card-body border">
                <p class="fw-normal text-muted small">
                  {{ xCreateLeadMeasureForm.lagPredictionNote }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row align-items-start my-3">
          <div class="col">
            <div class="fs-14 fw-bold text-dark my-2">Direction:</div>
            <div class="fs-11">
              {{ xCreateLeadMeasureForm?.desiredDirectionConstant | uppercase }}
            </div>
          </div>
          <div class="col">
            <div class="fs-14 fw-bold text-dark my-2">Current Value:</div>
            <div class="fs-11">{{ xCreateLeadMeasureForm?.currentValue }}</div>
          </div>
          <div class="col">
            <div class="fs-14 fw-bold text-dark my-2">UOM:</div>
            <div class="fs-11">{{ xCreateLeadMeasureForm?.measurementUnit }}</div>
          </div>
          <div class="col">
            <div class="fs-14 fw-bold text-dark my-2">Users:</div>
            <div class="fs-11">
              <div class="btn p-0 position-relative">
                <ng-container *ngIf="xCreateLeadMeasureForm.users.length === 1">
                  <span
                    class="iconify"
                    data-icon="material-symbols:person"
                    style="color: gray"
                    data-width="30"
                    data-height="30"
                  ></span>
                </ng-container>
                <ng-container *ngIf="xCreateLeadMeasureForm.users.length > 1">
                  <span
                    class="iconify"
                    data-icon="ic:baseline-people"
                    style="color: gray"
                    data-width="30"
                    data-height="30"
                  ></span>
                </ng-container>
                <!--                <ng-container *ngFor="let user of xCreateLeadMeasureForm.users; let x = index">-->
                <!--                  <span class="mr-2" *ngIf="x < maxUserPreview">-->
                <!--                    {{ user.name }}-->
                <!--                  </span>-->
                <!--                </ng-container>-->
                <span
                  class="position-absolute top-0 pointer float-end start-100 translate-middle badge rounded-pill bg-danger"
                >
                  <span class="visually-hidden">Assigned Users</span>
                  <div
                    [placement]="'top right'"
                    [adaptivePosition]="true"
                    [tooltip]="users"
                    [containerClass]="'overflow-auto'"
                  >
                    {{ xCreateLeadMeasureForm.users.length }}
                  </div>
                </span>

                <ng-template #users>
                  <div *ngFor="let rest of xCreateLeadMeasureForm.users">
                    {{ rest.name }}
                    <br />
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row align-items-start my-3"
          *ngIf="_xBenchmarkPojos && _xBenchmarkPojos.length > 0"
        >
          <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="fs-14 fw-bold text-dark my-2">Benchmarks:</div>
            <div
              class="fs-11 d-flex flex-column overflow-y-auto overflow-x-auto"
              style="max-height: 230px"
            >
              <ng-container *ngIf="xBenchmarkPojos">
                <ng-container *ngFor="let benchmark of xBenchmarkPojos; let i = index">
                  <div
                    class="card mb-2 me-2 p-2 border-primary-subtle"
                    style="width: 100%; border-style: dotted"
                  >
                    <div>
                      <div class="d-flex align-items-center justify-content-between my-1">
                        <div class="text-primary fs-11 fw-medium">Benchmark {{ i + 1 }}</div>
                      </div>

                      <div class="d-flex flex-row justify-content-between fs-10">
                        <div>
                          <div class="fw-bold">Due Date:</div>
                          {{ formatDateAndTime(benchmark.dueDate, benchmark.dueTime) }}
                        </div>
                        <div>
                          <div class="fw-bold">Lag Score:</div>
                          {{ benchmark.predictedLagScore }}
                        </div>
                        <div>
                          <div class="fw-bold">Target:</div>
                          {{ benchmark.targetScore }}
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <div class="fs-14 fw-bold text-dark my-2">Benchmark Graph:</div>
            <div class="fs-11">
              <app-bar-chart
                [maxYValue]="_maxY"
                [bottomTitle]="'Benchmark Graph'"
                [barChartData]="_barChartData"
              >
              </app-bar-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        class="d-flex justify-content-center mb-3 align-items-center align-self-center"
        *ngIf="viewState === 'CREATE' || viewState === 'EDIT'"
      >
        <app-button-with-loader
          [classText]="'btn btn-primary fs-14'"
          [idleStateText]="viewState === 'CREATE' ? ' Create ' : ' Update ' + 'Lead Measure'"
          [isProcessing]="isCreatingLeadMeasure"
          (buttonClick)="createLeadMeasure()"
          [workingStateText]="
            viewState === 'CREATE' ? ' Creating ' : ' Updating ' + ' Lead Measure'
          "
        >
        </app-button-with-loader>
      </div>
    </div>
  </div>
</div>
