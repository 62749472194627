<div class="mt-5">
  <div class="row row-cols-2 g-3">
    <div class="col-lg-2">
      <div
        class="card rounded-0 border-success text-center py-4"
        *ngIf="scoreboardDataItem?.winning"
      >
        <div>
          <span
            class="iconify my-2 text-success text-center fs-26"
            data-icon="ic:outline-thumb-up"
          ></span>
        </div>
        <p class="fw-bold text-success">WINNING</p>
      </div>

      <div
        class="card rounded-0 border-danger text-center py-4"
        *ngIf="!scoreboardDataItem?.winning"
      >
        <div>
          <span
            class="iconify my-2 text-danger text-center fs-26"
            data-icon="ic:outline-thumb-down"
          ></span>
        </div>
        <p class="fw-bold"></p>
        <p class="fw-bold text-danger">LOSING</p>
      </div>
    </div>
    <div class="col-lg">
      <div class="card rounded-0 text-center py-2" style="border: 1px solid #3e017c">
        <p class="fs-26 fw-bold my-2">
          {{ helperService.cutText(scoreboardDataItem?.wigDefinition?.name, 57) }}
        </p>
        <p class="small text-muted my-2">
          {{ helperService.cutText(scoreboardDataItem?.wigDefinition?.description, 100) }}
        </p>
        <!--        <a href="" class="text-decoration-underline small">View KPI List </a>-->
        <button
          class="btn-unset-all text-primary"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#kpiList"
          aria-controls="offcanvasRight"
        >
          KPI List
        </button>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="d-flex flex-column">
        <div class="card rounded-0 border-danger text-center py-3 mb-1">
          <p
            class="fw-bold mb-0"
            [ngClass]="{
              'text-danger': Number(scoreboardDataItem?.score) < 100,
              'text-success': Number(scoreboardDataItem?.score) === 100
            }"
          >
            Score: <span class="fs-24">{{ scoreboardDataItem?.score || '0.00' }}%</span>
          </p>
        </div>
        <div class="d-flex justify-content-between">
          <div class="col-6 pe-1">
            <div class="card rounded-0 border-primary text-center py-1">
              <p class="mb-0 fw-bold text-primary">{{ scoreboardDataItem?.cadenceDone }}</p>
              <p class="fs-10 mb-0">Cadences done</p>
            </div>
          </div>
          <div class="col-6 ps-1">
            <div class="card rounded-0 text-center py-1" style="border: #35016c solid 1px">
              <p class="fw-bold mb-0" style="color: 35016c">
                {{ scoreboardDataItem?.attendance }}
                <ng-container *ngIf="scoreboardDataItem?.attendance > 1; else onePersonAttendance">
                  <span class="fs-10 fw-normal">people</span>
                </ng-container>
                <ng-template #onePersonAttendance>
                  <span class="fs-10 fw-normal">person</span>
                </ng-template>
              </p>

              <p class="fs-10 mb-0">Attended Last Cadence</p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="kpiList"
        aria-labelledby="offcanvasRightLabel"
      >
        <div class="offcanvas-header">
          <div class="off-header mt-3" id="offcanvasRightLabel">KPI List</div>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div *ngIf="scoreboardDataItem?.wigPojo?.kpis?.length > 0; else noData">
            <div
              *ngFor="let k of scoreboardDataItem?.wigPojo.kpis; let i = index"
              class="fs-15 fw-medium dash-dotted"
            >
              {{ k.name }}
              <div class="fs-13 fw-medium">
                Actual Score: {{ k.actualScore | number }} |
                <span
                  class="tiny-status"
                  [ngStyle]="{ color: k.completionStatus === 'COMPLETED' ? '#06BC44' : '#bc0621' }"
                >
                  {{ k.completionStatus === 'COMPLETED' ? 'Done' : 'Not Done' }}</span
                >
              </div>
              <span> {{ k.duaDateTime | shortestDate }} </span>
            </div>
          </div>
          <ng-template #noData>
            <h1>No KPI Data</h1>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
