import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Utils } from '../../../../utils/utils';
import { FullFilePreviewComponent } from '../../../full-file-preview/full-file-preview.component';
import {
  AttachmentDto,
  AttachmentPojo,
  LagControllerService,
  WigControllerService
} from '../../../../../../../sdk/ecadence-sdk';
import TypeEnum = AttachmentDto.TypeEnum;

@Component({
  selector: 'app-lag-supporting-doc',
  templateUrl: './lag-supporting-doc.component.html',
  styleUrls: ['./lag-supporting-doc.component.css']
})
export class LagSupportingDocComponent implements OnInit {
  @Input()
  lagId: number;
  attachmentList: AttachmentPojo[] = [];
  modalRef: BsModalRef;
  errorMessage: string;
  DocType = TypeEnum;

  constructor(private lagController: LagControllerService, private modalService: BsModalService) {}

  ngOnInit(): void {
    this.fetchDocuments();
  }

  fetchDocuments(): void {
    this.lagController.getLagSupportingDocuments({ id: this.lagId }).subscribe(
      (value) => {
        this.attachmentList = value;
        console.log(value + 'attachement');
      },
      (error: unknown) => {
        if (
          error instanceof HttpErrorResponse &&
          error.error &&
          typeof error.error == 'object' &&
          error.error.message
        ) {
          this.errorMessage = error.error.message;
          return;
        }
        this.errorMessage = 'Could not fetch sub categories.';
      }
    );
  }

  getFileUrl(a: AttachmentPojo): string {
    return Utils.getFileUrl(a.fileId, false);
  }

  previewFile(docType: TypeEnum, id: number | undefined): void {
    const fileUrl = Utils.getFileUrl(id, false);
    console.log('preview url', fileUrl);

    console.log(fileUrl, ' fileUrl');
    this.modalRef = this.modalService.show(FullFilePreviewComponent, {
      initialState: {
        fileUrl: fileUrl,
        fileType: docType
      },
      keyboard: false,
      backdrop: 'static',
      class: 'modal-lg+96'
    });
  }
}
