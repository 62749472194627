import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SearchHandler } from '../../search/search-handler';
import {
  PlayerLagMeasurePojo,
  PlayerLeadMeasurePojo,
  QueryResultsPlayerLagMeasurePojo,
  QueryResultsPlayerLeadMeasurePojo,
  ScoreboardControllerService
} from '../../../../../sdk/ecadence-sdk';
import { SearchFilterSource } from '../../search/search-filter-source';
import { SearchManager } from '../../search/search-manager';
import { NameValuePair } from '../../../models/etc/name-value-pair.model';
import { PaginatedSearch } from '../../search/paginated-search';

@Component({
  selector: 'player-lagmeasures',
  templateUrl: './player-lagmeasures.component.html',
  styleUrls: ['./player-lagmeasures.component.css']
})
export class PlayerLagmeasuresComponent
  implements
    OnInit,
    SearchHandler<PlayerLagMeasurePojo, PlayerLagMeasurePojo>,
    SearchFilterSource<PlayerLagMeasurePojo>
{
  @Input() playerId: number;
  @Input() wigId: number;
  @Input() playerName: string;

  currentRoute: string;
  playerLeadMeasureSummary: QueryResultsPlayerLagMeasurePojo;
  searchManager!: SearchManager<PlayerLagMeasurePojo, PlayerLagMeasurePojo>;
  maxPageSize = 10;

  constructor(
    private scoreboardControllerService: ScoreboardControllerService,
    private bsModalRef: BsModalRef
  ) {
    this.searchManager = new SearchManager<PlayerLagMeasurePojo, PlayerLagMeasurePojo>(this, this);
  }

  ngOnInit(): void {
    this.getPlayerScoreboardLagMeasures();
  }

  getPlayerScoreboardLagMeasures(): any {
    this.scoreboardControllerService
      .getPlayerLagMeasures({
        limit: this.maxPageSize,
        playerId: this.playerId,
        wigId: this.wigId,
        offset: 0
      })
      .subscribe(
        (res) => {
          this.playerLeadMeasureSummary = res;
          console.log('Player lag Measures ===>', this.playerLeadMeasureSummary);
          this.searchManager.itemsPerPage = this.maxPageSize;
          //this.searchManager.reloadAndShowFirstPage();
        },
        (error: unknown) => {
          console.log('error ==> ', error);
        }
      );
  }

  getFilter(): PlayerLagMeasurePojo {
    return undefined;
  }

  getPersistentKey(): string {
    return '';
  }

  getSearchDescriptor(filter: PlayerLagMeasurePojo): NameValuePair[] {
    return [];
  }

  // @ts-expect-error: angular ts warning
  search(page: number | undefined, filter?: PlayerLagMeasurePojo): Observable<any> {
    const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;
    const paginatedSearch = PaginatedSearch.createCleanFilter(
      filter,
      this.searchManager.itemsPerPage,
      offset
    );
    return this.scoreboardControllerService.getPlayerLagMeasures({
      playerId: this.playerId,
      wigId: this.wigId,
      limit: 10,
      offset: 0
    });
  }

  close(): void {
    this.bsModalRef.hide();
  }
}
