/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccount } from './portal-account';
import { BlockerCategory } from './blocker-category';
import { PortalUser } from './portal-user';


export interface Blocker { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: Blocker.StatusEnum;
    reasonForStatusChange?: string;
    deactivatedBy?: PortalUser;
    resolutionStatus?: Blocker.ResolutionStatusEnum;
    description?: string;
    wasteType?: Blocker.WasteTypeEnum;
    possibleInfluencer?: string;
    code?: string;
    taskId?: string;
    blockerCategory?: BlockerCategory;
    portalAccount?: PortalAccount;
}
export namespace Blocker {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum,
        DELETED: 'DELETED' as StatusEnum
    };
    export type ResolutionStatusEnum = 'PENDING' | 'IN_PROGRESS' | 'RESOLVED' | 'CANCELLED';
    export const ResolutionStatusEnum = {
        PENDING: 'PENDING' as ResolutionStatusEnum,
        IN_PROGRESS: 'IN_PROGRESS' as ResolutionStatusEnum,
        RESOLVED: 'RESOLVED' as ResolutionStatusEnum,
        CANCELLED: 'CANCELLED' as ResolutionStatusEnum
    };
    export type WasteTypeEnum = 'TIME' | 'INVENTORY' | 'MOTION' | 'WAITING' | 'OVER_PRODUCTION' | 'OVER_PROCESSING' | 'DEFECT';
    export const WasteTypeEnum = {
        TIME: 'TIME' as WasteTypeEnum,
        INVENTORY: 'INVENTORY' as WasteTypeEnum,
        MOTION: 'MOTION' as WasteTypeEnum,
        WAITING: 'WAITING' as WasteTypeEnum,
        OVER_PRODUCTION: 'OVER_PRODUCTION' as WasteTypeEnum,
        OVER_PROCESSING: 'OVER_PROCESSING' as WasteTypeEnum,
        DEFECT: 'DEFECT' as WasteTypeEnum
    };
}


