/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LagMeasureBenchmarkPojo } from './lag-measure-benchmark-pojo';


export interface LagMeasurePojo { 
    lagMeasureName?: string;
    lagId?: number;
    wigName?: string;
    wigId?: number;
    lagName?: string;
    lagMeasureId?: number;
    unit?: string;
    desiredDirection?: LagMeasurePojo.DesiredDirectionEnum;
    lagMeasureBenchmark?: Array<LagMeasureBenchmarkPojo>;
    maxValue?: number;
    currentValue?: number;
    hasLead?: boolean;
    lastUpdated?: string;
    lagMeasureType?: string;
}
export namespace LagMeasurePojo {
    export type DesiredDirectionEnum = 'UP' | 'DOWN';
    export const DesiredDirectionEnum = {
        UP: 'UP' as DesiredDirectionEnum,
        DOWN: 'DOWN' as DesiredDirectionEnum
    };
}


