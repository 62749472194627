import { EventEmitter, Injectable, Input, Output } from '@angular/core';
import { WigControllerService, WigPojo } from '../../../sdk/ecadence-sdk';

@Injectable({
  providedIn: 'root'
})
export class WigPojoService {
  @Output()
  wigReloadEvent: EventEmitter<WigPojo> = new EventEmitter<WigPojo>();

  constructor(private wigController: WigControllerService) {}

  public reloadWigPojo(wigId: number): void {
    this.wigController.getWig({ id: wigId }).subscribe({
      next: (val) => {
        this.wigReloadEvent.emit(val);
      }
    });
  }
}
