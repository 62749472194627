import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Lag,
  LagCardPojo,
  LagControllerService,
  LagKpiPojo,
  LagMeasurePojo,
  LagMeasureScorePojo,
  ScoreboardControllerService
} from '../../../../../../sdk/ecadence-sdk';
import { SuccessDialogComponent } from '../../../../shared/components/success-dialog/success-dialog.component';
import { XAlertMessage } from '../../../../shared/components/alert-message/alert-message.component';

@Component({
  selector: 'lag-measure-details',
  templateUrl: './lag-measure-details.component.html',
  styleUrls: ['./lag-measure-details.component.css']
})
export class LagMeasureDetailsComponent {
  @Input() lag: LagCardPojo;
  @Input() lagMeasures: LagMeasurePojo[];
  lagMeasureScoreSummary: LagMeasureScorePojo;
  showScoreSummary: boolean;

  formGroup: FormGroup;
  _markingKpi = false;
  xAlertMessage: XAlertMessage = {
    message: undefined,
    messageType: 'info'
  };

  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private modalRef: BsModalRef,
    private scoreboardControllerService: ScoreboardControllerService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private lagController: LagControllerService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.formGroup = this.formBuilder.group({
      score: ['', Validators.pattern(/^[1-9]\d*$/)]
    });
  }

  getLagMeasureScoreSummary(id: number): void {
    this.scoreboardControllerService.getLagMeasureScore({ id: id }).subscribe(
      (res) => {
        this.lagMeasureScoreSummary = res;
        this.showScoreSummary = true;
        this.formGroup.get('score').setValue(res.lagMeasureCurrentScore);
      },
      (error: unknown) => {
        console.log('error ===>', error);
      }
    );
  }

  updateLagMeasureScore(id: number): void {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid) {
      return;
    } else {
      this.scoreboardControllerService
        .updateLagMeasureScore({ id: id, score: this.formGroup.get('score').value })
        .subscribe(
          (res) => {
            const ref = this.modalService.show(SuccessDialogComponent, {
              initialState: {
                message: `Lag Measure Score Updated Successfully`
              },
              class: 'modal-dialog modal-dialog-centered modal-sm'
            });
          },
          (error: unknown) => {
            console.log('error ===>', error);
          }
        );
    }
  }

  closeModal(): void {
    this.modalRef.hide();

    this.closeEvent.emit();
  }

  markKpi(kpi: LagKpiPojo): void {
    let subscription: Observable<any>;

    if (kpi.completed) {
      subscription = this.lagController.markKpiAsNotDone({ lagIg: this.lag.id, kpiId: kpi.id });
    } else {
      subscription = this.lagController.markKpiAsDone1({
        lagIg: this.lag.id,
        kpiId: kpi.id,
        dataURI: []
      });
    }
    this._markingKpi = true;
    subscription
      .subscribe({
        next: (value: LagKpiPojo) => {
          kpi.completed = value.completed;
          kpi.completedAt = value.completedAt;
          this.recomputeLagScore();
        },
        error: (err: unknown) => {
          this.xAlertMessage.messageType = 'danger';
          this.xAlertMessage.message =
            err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
              ? err.error.message
              : 'Unable to load Lag Measures';
        }
      })
      .add(() => {
        this._markingKpi = false;
      });
  }

  recomputeLagScore(): void {
    this.lagController.recomputeScore({ lagIg: this.lag.id }).subscribe({
      next: (value: Lag) => {
        this.lag.actualScore = value.score;
      }
    });
  }
}
