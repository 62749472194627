/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AttachmentDto } from '../model/models';
import { CompletionReportSearchFilter } from '../model/models';
import { NameIdPojo } from '../model/models';
import { PlayerProfilePojo } from '../model/models';
import { QueryResultsCompletionReportSearchDto } from '../model/models';
import { UpdateProfileDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface DownloadKpiReportRequestParams {
    completionReportSearchFilter: CompletionReportSearchFilter;
    portalUserId: number;
    wigId: number;
}

export interface DownloadReportRequestParams {
    completionReportSearchFilter: CompletionReportSearchFilter;
    portalUserId: number;
    wigId: number;
}

export interface GetPlayerProfileRequestParams {
    playerId: number;
}

export interface GetPlayerProfileWigsRequestParams {
    playerId: number;
}

export interface GetProfileWithPortalUserIdRequestParams {
    id: number;
}

export interface GetWig1RequestParams {
    wigId: number;
}

export interface OrganizationRolesRequestParams {
    name: string;
    limit?: number;
}

export interface SearchCompletionReportRequestParams {
    completionReportSearchFilter: CompletionReportSearchFilter;
    portalUserId: number;
    wigId: number;
}

export interface UpdateUserProfileRequestParams {
    updateProfileDto: UpdateProfileDto;
}


@Injectable({
  providedIn: 'root'
})
export class UserProfileControllerService {

    protected basePath = 'http://ecadence.ng/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadKpiReport(requestParameters: DownloadKpiReportRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<AttachmentDto>;
    public downloadKpiReport(requestParameters: DownloadKpiReportRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<AttachmentDto>>;
    public downloadKpiReport(requestParameters: DownloadKpiReportRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<AttachmentDto>>;
    public downloadKpiReport(requestParameters: DownloadKpiReportRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const completionReportSearchFilter = requestParameters.completionReportSearchFilter;
        if (completionReportSearchFilter === null || completionReportSearchFilter === undefined) {
            throw new Error('Required parameter completionReportSearchFilter was null or undefined when calling downloadKpiReport.');
        }
        const portalUserId = requestParameters.portalUserId;
        if (portalUserId === null || portalUserId === undefined) {
            throw new Error('Required parameter portalUserId was null or undefined when calling downloadKpiReport.');
        }
        const wigId = requestParameters.wigId;
        if (wigId === null || wigId === undefined) {
            throw new Error('Required parameter wigId was null or undefined when calling downloadKpiReport.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (completionReportSearchFilter !== undefined && completionReportSearchFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completionReportSearchFilter, 'completionReportSearchFilter');
        }
        if (portalUserId !== undefined && portalUserId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalUserId, 'portalUserId');
        }
        if (wigId !== undefined && wigId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>wigId, 'wigId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<AttachmentDto>(`${this.configuration.basePath}/users/profiles/downloadKpiReport`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadReport(requestParameters: DownloadReportRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<AttachmentDto>;
    public downloadReport(requestParameters: DownloadReportRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<AttachmentDto>>;
    public downloadReport(requestParameters: DownloadReportRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<AttachmentDto>>;
    public downloadReport(requestParameters: DownloadReportRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const completionReportSearchFilter = requestParameters.completionReportSearchFilter;
        if (completionReportSearchFilter === null || completionReportSearchFilter === undefined) {
            throw new Error('Required parameter completionReportSearchFilter was null or undefined when calling downloadReport.');
        }
        const portalUserId = requestParameters.portalUserId;
        if (portalUserId === null || portalUserId === undefined) {
            throw new Error('Required parameter portalUserId was null or undefined when calling downloadReport.');
        }
        const wigId = requestParameters.wigId;
        if (wigId === null || wigId === undefined) {
            throw new Error('Required parameter wigId was null or undefined when calling downloadReport.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (completionReportSearchFilter !== undefined && completionReportSearchFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completionReportSearchFilter, 'completionReportSearchFilter');
        }
        if (portalUserId !== undefined && portalUserId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalUserId, 'portalUserId');
        }
        if (wigId !== undefined && wigId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>wigId, 'wigId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<AttachmentDto>(`${this.configuration.basePath}/users/profiles/downloadReport`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlayerProfile(requestParameters: GetPlayerProfileRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PlayerProfilePojo>;
    public getPlayerProfile(requestParameters: GetPlayerProfileRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PlayerProfilePojo>>;
    public getPlayerProfile(requestParameters: GetPlayerProfileRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PlayerProfilePojo>>;
    public getPlayerProfile(requestParameters: GetPlayerProfileRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const playerId = requestParameters.playerId;
        if (playerId === null || playerId === undefined) {
            throw new Error('Required parameter playerId was null or undefined when calling getPlayerProfile.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PlayerProfilePojo>(`${this.configuration.basePath}/users/profiles/players/${encodeURIComponent(String(playerId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlayerProfileWigs(requestParameters: GetPlayerProfileWigsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<NameIdPojo>>;
    public getPlayerProfileWigs(requestParameters: GetPlayerProfileWigsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<NameIdPojo>>>;
    public getPlayerProfileWigs(requestParameters: GetPlayerProfileWigsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<NameIdPojo>>>;
    public getPlayerProfileWigs(requestParameters: GetPlayerProfileWigsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const playerId = requestParameters.playerId;
        if (playerId === null || playerId === undefined) {
            throw new Error('Required parameter playerId was null or undefined when calling getPlayerProfileWigs.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<NameIdPojo>>(`${this.configuration.basePath}/users/profiles/wigs/${encodeURIComponent(String(playerId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfileWithPortalUserId(requestParameters: GetProfileWithPortalUserIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PlayerProfilePojo>;
    public getProfileWithPortalUserId(requestParameters: GetProfileWithPortalUserIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PlayerProfilePojo>>;
    public getProfileWithPortalUserId(requestParameters: GetProfileWithPortalUserIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PlayerProfilePojo>>;
    public getProfileWithPortalUserId(requestParameters: GetProfileWithPortalUserIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getProfileWithPortalUserId.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PlayerProfilePojo>(`${this.configuration.basePath}/users/profiles/portal-users/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserProfile(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PlayerProfilePojo>;
    public getUserProfile(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PlayerProfilePojo>>;
    public getUserProfile(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PlayerProfilePojo>>;
    public getUserProfile(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PlayerProfilePojo>(`${this.configuration.basePath}/users/profiles/user-profile`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWig1(requestParameters: GetWig1RequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<NameIdPojo>;
    public getWig1(requestParameters: GetWig1RequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<NameIdPojo>>;
    public getWig1(requestParameters: GetWig1RequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<NameIdPojo>>;
    public getWig1(requestParameters: GetWig1RequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const wigId = requestParameters.wigId;
        if (wigId === null || wigId === undefined) {
            throw new Error('Required parameter wigId was null or undefined when calling getWig1.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<NameIdPojo>(`${this.configuration.basePath}/users/profiles/wig/${encodeURIComponent(String(wigId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationRoles(requestParameters: OrganizationRolesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<string>>;
    public organizationRoles(requestParameters: OrganizationRolesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<string>>>;
    public organizationRoles(requestParameters: OrganizationRolesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<string>>>;
    public organizationRoles(requestParameters: OrganizationRolesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const name = requestParameters.name;
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling organizationRoles.');
        }
        const limit = requestParameters.limit;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (name !== undefined && name !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>name, 'name');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/users/profiles/roles`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchCompletionReport(requestParameters: SearchCompletionReportRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsCompletionReportSearchDto>;
    public searchCompletionReport(requestParameters: SearchCompletionReportRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsCompletionReportSearchDto>>;
    public searchCompletionReport(requestParameters: SearchCompletionReportRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsCompletionReportSearchDto>>;
    public searchCompletionReport(requestParameters: SearchCompletionReportRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const completionReportSearchFilter = requestParameters.completionReportSearchFilter;
        if (completionReportSearchFilter === null || completionReportSearchFilter === undefined) {
            throw new Error('Required parameter completionReportSearchFilter was null or undefined when calling searchCompletionReport.');
        }
        const portalUserId = requestParameters.portalUserId;
        if (portalUserId === null || portalUserId === undefined) {
            throw new Error('Required parameter portalUserId was null or undefined when calling searchCompletionReport.');
        }
        const wigId = requestParameters.wigId;
        if (wigId === null || wigId === undefined) {
            throw new Error('Required parameter wigId was null or undefined when calling searchCompletionReport.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (completionReportSearchFilter !== undefined && completionReportSearchFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completionReportSearchFilter, 'completionReportSearchFilter');
        }
        if (portalUserId !== undefined && portalUserId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalUserId, 'portalUserId');
        }
        if (wigId !== undefined && wigId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>wigId, 'wigId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsCompletionReportSearchDto>(`${this.configuration.basePath}/users/profiles/completion`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserProfile(requestParameters: UpdateUserProfileRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PlayerProfilePojo>;
    public updateUserProfile(requestParameters: UpdateUserProfileRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PlayerProfilePojo>>;
    public updateUserProfile(requestParameters: UpdateUserProfileRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PlayerProfilePojo>>;
    public updateUserProfile(requestParameters: UpdateUserProfileRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const updateProfileDto = requestParameters.updateProfileDto;
        if (updateProfileDto === null || updateProfileDto === undefined) {
            throw new Error('Required parameter updateProfileDto was null or undefined when calling updateUserProfile.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<PlayerProfilePojo>(`${this.configuration.basePath}/users/profiles`,
            updateProfileDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
