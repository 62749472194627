<div class="modal-header border border-dark">
  <h1 class="modal-title fs-5" id="exampleModalLabel">Blocker Details</h1>
  <button
    (click)="closeModal()"
    type="button"
    class="btn-close"
    data-bs-dismiss="modal"
    aria-label="Close"
  ></button>
</div>
<div class="modal-body">
  <app-alert-message
    *ngIf="xAlertMessage"
    [messageType]="xAlertMessage!.messageType"
    [message]="xAlertMessage!.message"
  >
  </app-alert-message>

  <ng-container *ngIf="!_loading; else loading">
    <ng-container *ngIf="_blockerDto; else notFound">
      <div class="container">
        <div class="row align-items-start mb-3">
          <div class="col">
            <div>
              <div class="fs-12 fw-bolder">Type:</div>
              <div class="fs-12">
                {{ _blockerDto.blockerType | uppercase }}
              </div>
            </div>
          </div>
          <div class="col">
            <div>
              <div class="fs-12 fw-bolder">Affected Lags :</div>
              <div class="btn p-0 position-relative">
                <span
                  class="iconify"
                  data-icon="material-symbols:flag"
                  data-width="30"
                  data-height="30"
                ></span>
                <span
                  class="position-absolute top-0 pointer float-end start-100 translate-middle badge rounded-pill bg-danger"
                >
                  <span class="visually-hidden">Affecting Lags</span>
                  <div
                    [container]="'div'"
                    [placement]="'right bottom'"
                    [tooltip]="restOfUsers"
                    [containerClass]="'overflow-auto'"
                  >
                    {{ _blockerDto.lags.length }}
                  </div>
                </span>
                <ng-template #restOfUsers>
                  <div *ngFor="let rest of _blockerDto.lags">
                    {{ rest }}
                    <br />
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>

        <div class="row align-items-start mb-3">
          <div class="col">
            <div>
              <div class="fs-12 fw-bolder">Waste Type:</div>
              <div class="fs-12">
                {{ _blockerDto.wasteType | underscoreToSpace }}
              </div>
            </div>
          </div>
          <div class="col">
            <div>
              <div class="fs-12 fw-bolder">Created :</div>
              <div class="fs-12">
                {{ _blockerDto.createdAt | shortestDate }}
              </div>
            </div>
          </div>
        </div>

        <div class="row align-items-start mb-3">
          <div class="col">
            <div>
              <div class="fs-12 fw-bolder">Raised By :</div>
              <div class="fs-12">
                {{ _blockerDto.raisedBy | uppercase }}
              </div>
            </div>
          </div>
          <div class="col">
            <div class="fs-12 fw-bolder">Resolution Status:</div>
            <div
              class="fs-12"
              [ngClass]="{
                'text-danger': _blockerDto.resolutionStatus === 'PENDING',
                'text-warning': _blockerDto.resolutionStatus === 'IN_PROGRESS',
                'text-success': _blockerDto.resolutionStatus === 'RESOLVED'
              }"
            >
              {{ _blockerDto.resolutionStatus | underscoreToSpace }}
            </div>
          </div>
        </div>

        <div class="row align-items-start mb-3">
          <div class="col" *ngIf="_blockerDto.possibleInfluencer">
            <div class="fs-12 fw-bolder">Possible Influencer:</div>
            <div class="fs-12">
              {{ _blockerDto.possibleInfluencer | uppercase }}
            </div>
          </div>
        </div>

        <div class="row align-items-start mb-3">
          <div class="col">
            <div class="fs-12 fw-bolder">Note :</div>
            <div class="fs-12">
              {{ _blockerDto.description }}
            </div>
          </div>
        </div>

        <div class="row align-items-start mb-3">
          <div class="col" *ngIf="assignedTo">
            <div class="fs-12 fw-bolder">Assigned To:</div>
            <div class="fs-12 d-flex align-items-center align-self-center">
              {{ _blockerDto.assignedTo | uppercase }}
              <div
                class="ms-2"
                [tooltip]="'Assigned to External Influencer'"
                *ngIf="_blockerDto.assignedToExternalInfluencer"
              >
                <span class="iconify" data-icon="gg:external"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="row align-items-start mb-3">
          <div class="col">
            <ng-container
              *ngIf="
                _blockerDto.canMarkAsResolved && _blockerDto.resolutionStatus === 'IN_PROGRESS'
              "
            >
              <app-button-with-loader
                [buttonType]="'button'"
                [classText]="'btn btn-primary btn-sm float-end'"
                [idleStateText]="'Mark As Resolved'"
                [isProcessing]="_isResolving"
                [workingStateText]="'Resolving...'"
                (buttonClick)="markAsResolved()"
              >
              </app-button-with-loader>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="canAssignBlocker">
          <div
            class="border border-black border-2 p-3 overflow-y-scroll"
            style="max-height: 400px"
            *ngIf="
              _blockerDto.resolutionStatus === 'PENDING' ||
              _blockerDto.resolutionStatus === 'IN_PROGRESS'
            "
          >
            <div class="row align-items-start mb-3">
              <div class="col">
                <div class="d-flex justify-content-between">
                  <div class="btn-group">
                    <label
                      class="btn btn-sm btn-primary rounded-start-pill fs-12"
                      [(ngModel)]="isUser"
                      [btnRadio]="true"
                      tabindex="0"
                      role="button"
                      >Internal</label
                    >
                    <label
                      class="btn btn-sm btn-primary rounded-end-pill fs-12"
                      [(ngModel)]="isUser"
                      [btnRadio]="false"
                      tabindex="0"
                      role="button"
                      >External</label
                    >
                  </div>

                  <button
                    (click)="toggleNewInfluencerForm()"
                    style="color: #544ec3; border-color: #544ec3"
                    class="btn btn-outline py-1"
                    *ngIf="!isUser"
                  >
                    <div *ngIf="!showNewInfluencerForm">
                      <span class="iconify fs-4" data-icon="carbon:add"></span>
                    </div>
                    <div *ngIf="showNewInfluencerForm">
                      <span class="iconify" data-icon="mi:select"></span>
                    </div>

                    {{ showNewInfluencerForm ? 'Select' : 'Add' }}
                  </button>
                </div>

                <form class="mt-3" [formGroup]="_formGroup">
                  <div class="mb-3">
                    <ng-container *ngIf="_isUser">
                      <custom-select-field
                        [showRequiredRedIcon]="this._isUser"
                        [form]="_formGroup"
                        [controlName]="'playerId'"
                        [isLoading]="_isFetchingUsers"
                        [name]="'Player'"
                        [rawData]="_users"
                        [keyField]="'id'"
                        [valueField]="'name'"
                      >
                      </custom-select-field>
                    </ng-container>
                  </div>
                  <div class="mb-3">
                    <ng-container *ngIf="!_isUser">
                      <ng-container *ngIf="!showNewInfluencerForm; else newInfluenceForm">
                        <custom-select-field
                          [showRequiredRedIcon]="!this._isUser"
                          [form]="_formGroup"
                          [controlName]="'influencerId'"
                          [isLoading]="_isFetchingExternalInfluencers"
                          [name]="'External Influencer'"
                          [rawData]="_externalInfluencers"
                          [keyField]="'id'"
                          [valueField]="'name'"
                        >
                        </custom-select-field>
                      </ng-container>
                      <ng-template #newInfluenceForm>
                        <div class="form-group mb-3">
                          <label for="name" class="form-label fs-12 required">Name</label>
                          <input
                            id="name"
                            class="form-control"
                            [formControlName]="'name'"
                            placeholder="Joshua O"
                          />
                          <error-message
                            [controlName]="'name'"
                            [form]="_formGroup"
                            [label]="'Name'"
                            [fontSize]="10"
                            [singularError]="true"
                          >
                          </error-message>
                        </div>

                        <div class="form-group mb-3">
                          <label for="emailAddress" class="form-label fs-12 required">Email</label>
                          <input
                            id="emailAddress"
                            class="form-control"
                            [formControlName]="'emailAddress'"
                            placeholder="samsmith@example.com"
                          />
                          <error-message
                            [controlName]="'emailAddress'"
                            [form]="_formGroup"
                            [label]="'Email'"
                            [fontSize]="10"
                            [singularError]="true"
                          >
                          </error-message>
                        </div>

                        <div class="form-group mb-3">
                          <label #phoneNumberLabel for="phoneNumber" class="form-label fs-12"
                            >Phone Number</label
                          >
                          <ngx-intl-tel-input
                            id="phoneNumber"
                            class="w-100 d-flex"
                            [cssClass]="'form-control w-100'"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [id]="'phoneNumber'"
                            [maxLength]="15"
                            [phoneValidation]="true"
                            [preferredCountries]="[this.countryAlpha]"
                            [searchCountryFlag]="true"
                            [selectedCountryISO]="selectedCountryISO"
                            [selectFirstCountry]="true"
                            [customPlaceholder]="'9104345234'"
                            [formControlName]="'phoneNumber'"
                          ></ngx-intl-tel-input>
                          <error-message
                            [controlName]="'phoneNumber'"
                            [form]="_formGroup"
                            [label]="phoneNumberLabel.innerText"
                            [fontSize]="10"
                            [singularError]="true"
                          >
                          </error-message>
                        </div>
                      </ng-template>
                    </ng-container>
                  </div>
                </form>
                <div class="float-end">
                  <app-button-with-loader
                    [buttonType]="'button'"
                    [classText]="'btn btn-primary btn-sm'"
                    [idleStateText]="
                      _blockerDto.resolutionStatus === 'PENDING' ? 'Assign' : 'Reassign'
                    "
                    [isProcessing]="_isAssigning"
                    [workingStateText]="
                      _blockerDto.resolutionStatus === 'PENDING' ? 'Assigning' : 'Reassigning'
                    "
                    (buttonClick)="assignBlocker()"
                  >
                  </app-button-with-loader>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #loading>
    <lottie-in-full-table [path]="'assets/lottie/loading.json'" [message]="'Searching...'">
    </lottie-in-full-table>
  </ng-template>
  <ng-template #notFound>
    <lottie-in-full-table
      [path]="'assets/lottie/no-data.json'"
      [message]="'Could not find blocker'"
    >
    </lottie-in-full-table>
  </ng-template>
</div>
