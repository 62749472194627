import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { HttpErrorResponse } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  KpiDto,
  LagCardPojo,
  LagControllerService,
  LagKpiDto,
  LagKpiPojo,
  WigPojo
} from '../../../../../../sdk/ecadence-sdk';
import { CustomValidators } from '../../../custom-validators';
import { removeUndefinedOrNullFields } from '../../../utils/utils';
import { SuccessDialogComponent } from '../../success-dialog/success-dialog.component';
import { KpiDocumentModalComponent } from '../modal/kpi-document-modal/kpi-document-modal.component';
import { EditKpiModalComponent } from '../modal/edit-kpi-modal/edit-kpi-modal.component';
import { XAlertMessage } from '../../alert-message/alert-message.component';
import { NumberOnly } from '../../../validators/number-only';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { WigPojoService } from '../../../../services/wig-pojo.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'add-lag-kpi',
  templateUrl: './add-lag-kpi.component.html',
  styleUrls: ['./add-lag-kpi.component.css']
})
export class AddLagKpiComponent implements OnInit {
  @Input()
  wigPojo: WigPojo;

  @Input()
  wigId: number;

  existingKpis: LagKpiPojo[];

  _lag: LagCardPojo;

  _downloadingDocument = false;

  @Input()
  set lag(value: LagCardPojo) {
    this._lag = value;
  }

  kpiDtoList: KpiDto[] = [];

  form: FormGroup;

  errorMessage: string;
  showErrorMessageTrigger = false;

  submitting = false;

  @ViewChild('template') modal: ElementRef<HTMLElement>;

  @Output()
  wigPojoEvent: EventEmitter<WigPojo> = new EventEmitter<WigPojo>();

  @Output()
  closeEvent: EventEmitter<any> = new EventEmitter<any>();
  lagKpiType = ['CPR', 'CSF'];

  xAlertMessage: XAlertMessage = {
    messageType: 'info',
    message: undefined,
    icon: undefined
  };

  datePickerConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-dark-blue',
    showWeekNumbers: false,
    dateInputFormat: 'YYYY-MM-DD'
  };

  constructor(
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private lagController: LagControllerService,
    private wigPojoService: WigPojoService,
    private keycloakService: KeycloakService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    if (this.wigPojo?.lags[this.wigPojo?.lags.indexOf(this.lag)]?.kpis) {
      /* eslint-disable-next-line no-unsafe-optional-chaining */
      this.existingKpis = [...this.wigPojo?.lags[this.wigPojo?.lags.indexOf(this.lag)].kpis];
    }

    this.initForm();
  }

  get today(): Date {
    return new Date();
  }

  private initForm(): void {
    this.form = this.fb.group({
      description: [
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.notBlank,
          Validators.min(3),
          Validators.max(250)
        ])
      ],
      weight: ['', Validators.compose([Validators.required, Validators.min(1)])],
      dueDate: ['', [Validators.required]],
      lagkpiTypeConstant: ['', [Validators.required]]
    });
  }

  addKpi(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) return;
    const data: KpiDto = this.form.getRawValue();
    this.kpiDtoList.push(data);
    this.form.reset();
  }

  save(): void {
    if (this.kpiDtoList?.length < 1) {
      this.showMessage('Please fill the form and add a KPI to continue', 'warning');
      return;
    }
    this.submitting = true;
    let pojo;
    this.lagController
      .createLagKpis({ lagKpiDto: this.getDto(), id: this._lag.id })
      .subscribe(
        (v) => {
          pojo = v;
          const updatedLag = v.lags.find((x) => x.id == this._lag.id);
          this._lag = { ...updatedLag };
          this.kpiDtoList = [];
          this.wigPojoService.reloadWigPojo(v.id);
          const ref = this.modalService.show(SuccessDialogComponent, {
            initialState: { message: 'KPIs updated successfully' },
            class: 'modal-dialog modal-dialog-centered modal-sm'
          });

          ref.content.closeEvent.subscribe((v) => {
            // this.modalRef?.hide();
            this.wigPojoEvent.emit(pojo);
          });
        },
        (e: unknown) => {
          this.showMessage(
            e instanceof HttpErrorResponse && e.error?.message && e.error.code !== 500
              ? e.error.message
              : 'Unable to save KPIs',
            'danger'
          );
        }
      )
      .add(() => (this.submitting = false));
  }

  deleteKpi(kpi: LagKpiPojo): void {
    const bsModalRef2 = this.modalService.show(ConfirmDialogComponent, {
      initialState: {
        body: 'Are you sure you want to approve this KPI?',
        positiveButtonText: 'Yes',
        negativeButtonText: 'No'
      },
      class: 'modal-md modal-dialog-centered',
      keyboard: false,
      backdrop: 'static'
    });

    bsModalRef2.content.confirm.subscribe((result: any) => {
      bsModalRef2.hide();
      this.lagController.deleteKpi({ lagIg: this._lag.id, kpiId: kpi.id }).subscribe({
        next: (wPojo: WigPojo) => {
          this.showMessage('KPI deleted successfully', 'success');
          const updatedLag = wPojo.lags.find((x) => x.id == this._lag.id);
          this._lag = { ...updatedLag };
          this.wigPojoService.reloadWigPojo(wPojo.id);
        },
        error: (err: unknown) => {
          this.showMessage(
            err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
              ? err.error.message
              : 'Unable to delete KPIs',
            'danger'
          );
        }
      });
    });
  }

  getDto(): LagKpiDto {
    const data: LagKpiDto = {
      kpis: this.kpiDtoList,
      kpisToRemove: undefined
    };
    return removeUndefinedOrNullFields(data);
  }

  removeKpi(dto: KpiDto): void {
    this.kpiDtoList.splice(this.kpiDtoList.indexOf(dto), 1);
  }

  hideModal(): void {
    this.closeEvent.emit();
    this.modalRef?.hide();
  }

  showMessage(
    error: any,
    type: 'info' | 'primary' | 'success' | 'danger' | 'warning',
    icon?: SafeHtml
  ): void {
    this.xAlertMessage.messageType = type;
    this.xAlertMessage.message = error;
    this.xAlertMessage.icon = icon;
    this.modal.nativeElement.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      this.xAlertMessage.message = undefined;
    }, 4000);
  }

  getErrorMessage(): string {
    return this.errorMessage;
  }

  kpiDocumentModal(kpi: LagKpiPojo): void {
    const modalRef = this.modalService.show(KpiDocumentModalComponent, {
      initialState: {
        _lag: this._lag,
        kpi: kpi
      },
      class: '',
      animated: true,
      ignoreBackdropClick: true,
      keyboard: false
    });

    modalRef.content.doneEvent.subscribe({
      next: (val) => {
        this.wigPojoService.reloadWigPojo(this.wigId);
      }
    });
  }

  editKpiModal(kpi: LagKpiPojo): void {
    const modalRef = this.modalService.show(EditKpiModalComponent, {
      initialState: {
        kpi: kpi,
        form: this.form,
        lag: this._lag
      },
      class: '',
      animated: true,
      ignoreBackdropClick: true,
      keyboard: false
    });

    modalRef.content.closedEvent.subscribe({
      next: () => {
        this.form.reset();
        this.wigPojoService.reloadWigPojo(this.wigId);
      }
    });
  }

  protected readonly NumberOnly = NumberOnly;

  markAs(kpi: LagKpiPojo, i: number): void {
    if (kpi.completed) {
      this.lagController.markKpiAsNotDone({ lagIg: this._lag.id, kpiId: kpi.id }).subscribe({
        next: (value) => {
          console.log('mark as done: ', value.completed);
          const index = this._lag.kpis.findIndex((obj) => obj.id === kpi.id);
          this._lag.kpis[index] = value;
          this.wigPojoService.reloadWigPojo(this.wigId);
        }
      });
    } else {
      this.kpiDocumentModal(kpi);
    }
  }

  downloadSupportingDocument(kpi: LagKpiPojo): void {
    this._downloadingDocument = true;
    const icon = this.sanitizer.bypassSecurityTrustHtml(
      '<span class="iconify fs-21" data-icon="iconamoon:attention-circle-thin"></span>'
    );
    this.showMessage('Attachment will be downloaded in the background', 'success', icon);
    this.keycloakService
      .getToken()
      .then((token) => {
        const downloadLink =
          window.location.origin +
          environment.apiBaseUrl +
          // eslint-disable-next-line promise/always-return
          (environment.apiBaseUrl.endsWith('/') ? '' : '/') +
          environment.lagKpiDownloadLink.replace('[id]', kpi.id.toString());

        // Create a new XMLHttpRequest object
        const xhr = new XMLHttpRequest();
        xhr.open('GET', downloadLink, true);

        // Set the authorization header with the Bearer token
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);

        // Set the response type to blob
        xhr.responseType = 'blob';

        // Handle the response
        xhr.onload = function (): void {
          if (xhr.status === 200) {
            // Create a temporary download link for the blob
            const blob = new Blob([xhr.response], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);

            // Create a new anchor element and trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.download = kpi.description + '.zip';
            link.click();

            // Clean up the temporary URL object
            window.URL.revokeObjectURL(url);
          }
        };
        this._downloadingDocument = false;
        // Send the request
        xhr.send();
      })
      .catch((error) => {
        this._downloadingDocument = false;
        console.error('Promise failed:', error);
      });
  }

  kpiTrackBy(index: number, kpi: LagKpiPojo): number {
    return kpi.id;
  }

  isCompleted(kpi: LagKpiPojo): boolean {
    return kpi.completed;
  }
}
