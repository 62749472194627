import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AccountMembershipPojo,
  BaseSearchDto,
  SearchWigLeadMeasuresRequestParams,
  UserControllerService,
  UserPojo,
  V2DashboardControllerService,
  WigSummaryDto
} from '../../../../../sdk/ecadence-sdk';
import { PaginatedSearchHandler } from '../../../shared/search/paginated-search-handler';
import { SearchFilterSource } from '../../../shared/search/search-filter-source';
import { PaginatedSearchManager } from '../../../shared/search/paginated-search-manager';
import { NameValuePair } from '../../../models/etc/name-value-pair.model';
import { QueryResults } from '../../../shared/search/query-results';
import { XAlertMessage } from '../../../shared/components/alert-message/alert-message.component';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent
  implements
    OnInit,
    PaginatedSearchHandler<WigSummaryDto, BaseSearchDto>,
    SearchFilterSource<BaseSearchDto>
{
  currentRoute: string;
  user: UserPojo;
  salutation: string;
  _playersInitials: string[] = [];
  _xAlertMessage: XAlertMessage = {
    messageType: 'info',
    message: undefined
  };
  searchManager: PaginatedSearchManager<WigSummaryDto, BaseSearchDto>;

  _searchOrMilestone: 'SEARCH' | 'MILESTONE' = 'SEARCH';

  _selectedWigSummaryDto: WigSummaryDto;

  _wigCount = 3;
  selectedWigSummaryDto: WigSummaryDto;
  userPermissions: Array<string> = [];

  @ViewChild('scrollButton', { static: true })
  scrollButtonRef: ElementRef<HTMLButtonElement>;

  constructor(
    private v2DashboardController: V2DashboardControllerService,
    private userControllerService: UserControllerService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
    this.searchManager = new PaginatedSearchManager<WigSummaryDto, BaseSearchDto>(this);
  }
  getFilter(): BaseSearchDto {
    throw new Error('Method not implemented.');
  }
  getSearchDescriptor(filter: BaseSearchDto): NameValuePair[] {
    throw new Error('Method not implemented.');
  }

  scrollDownAndUp(): void {
    const scrollDuration = 1000; // 2 seconds
    const scrollDistance = 2000; // Scroll distance in pixels

    // Scroll down
    window.scrollTo({
      top: scrollDistance,
      behavior: 'smooth'
    });

    // After 2 seconds, scroll back up
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, scrollDuration);
  }

  teamMembers: number;

  ngOnInit(): void {
    this.getCurrentDate();
    this.fetchLoginUser();
    this.getPlayersInTeam();
    this.searchManager.reloadAndShowFirstPage();
    //this.userPermissions = this.authenticationService.hasPermission()
  }

  getPlayersInTeam(): void {
    this.v2DashboardController.getPlayersInitials().subscribe({
      next: (value: string[]) => {
        this._playersInitials = value;
      },
      error: (err: unknown) => {
        this._xAlertMessage.messageType = 'danger';
        this._xAlertMessage.message =
          err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
            ? err.error.message
            : 'Unable to fetch Lead measures';
      }
    });
  }

  fetchLoginUser(): void {
    this.userControllerService.userDetails().subscribe((user: UserPojo) => (this.user = user));
  }

  getCurrentDate(): void {
    const currentDateTime = new Date();
    const hour = currentDateTime.getHours();
    if (hour >= 0 && hour <= 11) {
      this.salutation = 'Good Morning';
    } else if (hour > 11 && hour <= 15) {
      this.salutation = 'Good Afternoon';
    } else if (hour > 15 && hour <= 24) {
      this.salutation = 'Good Evening';
    }
  }

  scrollUp(el: HTMLElement): void {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  // getFilter(): SearchWigLeadMeasuresRequestParams {
  //   return undefined;
  // }

  getPersistentKey(): string {
    return '';
  }

  // getSearchDescriptor(filter: SearchWigLeadMeasuresRequestParams): NameValuePair[] {
  //   return [];
  // }

  search(page: number, filter?: BaseSearchDto): Observable<QueryResults<WigSummaryDto>> {
    this.searchManager.itemsPerPage = 10;
    const offset = (page - 1) * this.searchManager.itemsPerPage;
    return this.v2DashboardController.getWigSummaryForDashboard({
      baseSearchDto: {
        offset: offset,
        limit: this.searchManager.itemsPerPage
      }
    });
  }

  getColor(i: number): string {
    if (i === 0) {
      return 'deepskyblue';
    }
    if (i === 1) {
      return 'deeppink';
    } else {
      return 'deepskyblue';
    }
  }

  getMarginLeft(i: number): string {
    if (i === 0) {
      return '0';
    }
    return '-.5rem';
  }

  getSelectedWig(): WigSummaryDto {
    return this._selectedWigSummaryDto;
  }

  // selectedWig(event: WigSummaryDto): void {
  //   this._selectedWigSummaryDto = event;
  //   this._searchOrMilestone = 'MILESTONE';
  //   this.isMilestone = true;
  //
  // }

  selectedWig(index: number): void {
    this.selectedWigSummaryDto = this.searchManager.list.find((item) => item['wigId'] === index);
  }

  goBack(): void {
    window.location.reload();
  }

  get wigCount(): number {
    return this._wigCount;
  }
  setWigCount(count: number): void {
    this._wigCount = count;
  }
}
