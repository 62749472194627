import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  EmailValidationControllerService,
  MailgunEmailValidationResponse
} from '../../../../sdk/ecadence-sdk';

@Injectable()
export class DeliverableEmailValidator {
  constructor(private emailValidationControllerService: EmailValidationControllerService) {}

  emailExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }
      return this.emailValidationControllerService
        .validateTaxpayerEmail({ email: control.value })
        .pipe(
          delay(800),
          map((v: MailgunEmailValidationResponse) => {
            return v && v.result && (v.result === 'deliverable' || v.result === 'unknown')
              ? control.errors
              : {
                  ...control.errors,
                  notDeliverable: true
                };
          }),
          catchError((err: unknown) => {
            return of({ downTime: true });
          })
        );
    };
  }
}
