import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';
import {
  DataURI,
  LagCardPojo,
  LagControllerService,
  LagKpiPojo
} from '../../../../../../../sdk/ecadence-sdk';
import { XAlertMessage } from '../../../alert-message/alert-message.component';
import { HelperService, XFile } from '../../../../../services/helper.service';

@Component({
  selector: 'kpi-document-modal',
  templateUrl: './kpi-document-modal.component.html',
  styleUrls: ['./kpi-document-modal.component.css']
})
export class KpiDocumentModalComponent implements OnInit {
  _lag: LagCardPojo;
  _kpi: LagKpiPojo;
  _marking = false;
  _successful = false;

  _documents: DataURI[] = [];
  _form: FormGroup;

  xAlertMessage: XAlertMessage = {
    messageType: 'info',
    message: undefined,
    icon: undefined
  };

  _maxSizeInMB = 3;

  @Output()
  doneEvent: EventEmitter<any> = new EventEmitter<any>();

  get lag(): LagCardPojo {
    return this._lag;
  }

  @Input()
  set lag(value: LagCardPojo) {
    this._lag = value;
  }

  get kpi(): LagKpiPojo {
    return this._kpi;
  }

  @Input()
  set kpi(value: LagKpiPojo) {
    this._kpi = value;
  }

  constructor(
    private modalRef: BsModalRef,
    private lagController: LagControllerService,
    private helperService: HelperService,
    private formBuilder: FormBuilder
  ) {}

  closeModal(): void {
    this.modalRef.hide();
  }

  markAsDone(): void {
    this._marking = true;
    this.lagController
      .markKpiAsDone1({ lagIg: this.lag.id, kpiId: this.kpi.id, dataURI: this._documents })
      .subscribe({
        next: (val) => {
          this.doneEvent.emit();
          this._successful = true;
          this.kpi.completed = true;
          console.log('mark as done successful: ', val.completed);
        },
        error: (err: unknown) => {
          this.showMessage(
            err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
              ? err.error.message
              : 'Unable to mark KPI as done',
            'danger'
          );
        }
      })
      .add(() => {
        this._marking = false;
      });
  }

  showMessage(error: any, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
    this.xAlertMessage.messageType = type;
    this.xAlertMessage.message = error;
    setTimeout(() => {
      this.xAlertMessage.message = undefined;
    }, 4000);
  }

  protected readonly HelperService = HelperService;

  documentChanges($event): void {
    if (this._documents.length >= 3) {
      this.showMessage('Maximum of 3 files allowed', 'info');
      return;
    }
    this._form.get('document').markAsTouched();
    this.helperService.onFileSelected(
      $event,
      ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'],
      this._maxSizeInMB * 1024 * 1024,
      this._form.get('document')
    );
  }

  ngOnInit(): void {
    this._form = this.formBuilder.group({
      document: ['']
    });

    this._form
      .get('document')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe({
        next: (value: XFile) => {
          if (!value.name) {
            return;
          }

          const newDoc: DataURI = {
            name: value.name,
            data: value.data
          };
          this._documents.push(newDoc);
        }
      });
  }

  get documentFormControl(): AbstractControl {
    return this._form.get('document');
  }

  remove(doc: DataURI, i: number): void {
    this._documents.splice(this._documents.indexOf(doc), 1);
  }

  isImage(doc: DataURI): boolean {
    const type = doc.data.split(':')[0];
    return type.includes('png') || type.includes('jpg') || type.includes('jpeg');
  }
}
