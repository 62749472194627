<ngx-dropzone
  (change)="onFileSelected($event)"
  [accept]="'image/png, image/jpeg, image/jpg'"
  [maxFileSize]="maxFileSizeInBytes"
  id="ngxDropZoneElementId"
  style="width: 100%"
>
  <div class="d-flex align-items-center flex-wrap flex-sm-nowrap gap-2 gap-sm-2 gap-md-5">
    <label class="file-select-card">
      <span class="iconify icon" data-icon="basil:upload-outline"></span>
      <div class="text-black" [innerHTML]="'fileUpload.chooseText' | translate"></div>
      <div class="instructions">
        {{
          'fileUpload.formatSize' | translate : { size: getReadableFileSize(maxFileSizeInBytes) }
        }}
      </div>
    </label>
  </div>
</ngx-dropzone>

<!--<ng-container *ngIf="file">-->
<!--&lt;!&ndash;  <app-image-cropper [imageFile]="file" (onCroppedImage)="onImageCrop($event)"></app-image-cropper>&ndash;&gt;-->
<!--</ng-container>-->
