import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {
  @Input() header = 'Action Confirmation';
  @Input() body = 'Are you sure?';
  @Input() positiveButtonText = 'Proceed';
  @Input() negativeButtonText = 'Cancel';
  @Input() dismissOnConfirm = true;
  @Input() showLoadingOnConfirmClick = true;
  @Output()
  confirm = new EventEmitter();
  @Output()
  cancel = new EventEmitter();
  @Input() loading = false;

  @Input()
  /**
   * @deprecated Use the confirm event instead
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  onConfirm: Function;

  /**
   * @deprecated Use the cancel event instead
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() onCancel: Function;

  constructor(public modalRef: BsModalRef) {}

  close(): void {
    this.modalRef.hide();
    this.cancel.emit();
    this.onCancel?.call(null);
  }

  confirmButtonClicked(): void {
    if (this.showLoadingOnConfirmClick) {
      this.loading = true;
    }
    this.confirm.emit();
    if (this.dismissOnConfirm) {
      this.modalRef.hide();
    }
    this.onConfirm?.call(null);
  }
}
