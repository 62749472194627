<div class="modal-content">
  <div class="modal-header">
    <div class="pull-left">
      <h6 class="modal-title" id="modal-title">{{ headerMessage }}</h6>
    </div>
    <span type="button" class="close pull-right pointer" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </span>
  </div>
  <ng-container *ngIf="!showSuccess; else success">
    <div class="modal-body">
      <app-alert-message
        *ngIf="xAlertMessage"
        [messageType]="xAlertMessage!.messageType"
        [message]="xAlertMessage!.message"
      >
      </app-alert-message>
      <div>
        <form [formGroup]="form" id="form">
          <div class="col-sm-12 mb-4">
            <span>{{ promptText }}</span>
          </div>
          <div class="form-group col-sm-12">
            <label class="mb-3">{{ formLabel }}</label>
            <custom-text-area
              [maximumTextLength]="FormConstants.SHORT_NOTE_MAXIMUM_LENGTH"
              [placeholder]="'Reason for Disapproval'"
              [form]="form"
              [controlName]="'reason'"
            >
            </custom-text-area>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button
        [disabled]="loading"
        (click)="closeModal()"
        class="btn btn-warning mt-1 mr-2 pointer"
        type="button"
      >
        <i class="fa fa-times mr-1"></i>Cancel
      </button>
      <button
        [disabled]="loading"
        (click)="onDone()"
        class="btn btn-danger mt-1 pointer"
        type="button"
      >
        <div *ngIf="!loading">
          <span class="iconify" data-icon="fluent-mdl2:set-action"></span>
          Proceed
        </div>
        <div *ngIf="loading">
          <span class="iconify" data-icon="eos-icons:loading"></span>
          Proceeding
        </div>
      </button>
    </div>
  </ng-container>
</div>

<ng-template #success>
  <div class="my-3">
    <lottie-in-full-table [path]="'assets/lottie/success.json'" [message]="successMessage">
    </lottie-in-full-table>
  </div>
</ng-template>
