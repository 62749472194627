<!--<div class="my-3">-->
<div
  class="card"
  style="
    max-width: 315px;
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.18);
    border-radius: 5px;
    border: 0.6px solid rgba(78, 58, 164, 0.78);
  "
>
  <div class="card-body" style="padding: 12px">
    <ng-container *ngIf="!_loading; else spinner">
      <div class="d-flex align-items-center">
        <div class="name-hover-image">
          <ng-container *ngIf="!_loadingImage; else spinner">
            <ng-container *ngIf="profilePicture; else initialPlaceHolder">
              <img
                [src]="_profilePicture"
                class="rounded-circle img-fluid"
                style="
                  height: 60px;
                  width: 60px;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                "
              />
            </ng-container>
          </ng-container>

          <ng-template #initialPlaceHolder>
            <span style="
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-weight: 500;
              "
              >{{ userInitial }}</span
            >
          </ng-template>
        </div>
        <div class="ms-1">
          <div class="fs-17 fw-medium">{{ _playerProfilePojo?.name }}</div>
          <div class="fs-13" style="color: #6a6a6a">
            {{ _playerProfilePojo?.playerRole || 'Player' }}
          </div>
        </div>
      </div>
      <div class="">
        <div class="fs-13 fw-medium mb-1 mt-3" style="color: #474747">Contact Information</div>
        <div class="d-flex mb-1 text-blue">
          <div><span class="iconify" data-icon="fluent:chat-mail-20-regular"></span></div>
          <a class="ms-1 fs-13 pointer" [href]="'mailto:' + _playerProfilePojo?.emailAddress"
            ><u>{{ _playerProfilePojo?.emailAddress }}</u></a
          >
        </div>
        <div class="d-flex mb-1 text-blue">
          <div><span class="iconify" data-icon="fluent:call-28-regular"></span></div>
          <a class="ms-1 fs-13 pointer" [href]="'tel:' + _playerProfilePojo?.phoneNumber"
            ><u>{{ _playerProfilePojo?.phoneNumber || '-' }}</u></a
          >
        </div>
      </div>
      <div class="" *ngIf="_playerProfilePojo?.linkedInUrl">
        <div class="fs-13 fw-medium mb-1 mt-3" style="color: #474747">Social Media Profile</div>
        <div class="d-flex mb-1 text-blue align-items-center">
          <div><span class="iconify" data-icon="ant-design:linkedin-filled"></span></div>
          <a class="ms-1 fs-13 pointer" [href]="_playerProfilePojo.linkedInUrl" target="_blank"
            ><u>{{ _playerProfilePojo.linkedInUrl || '-' }}</u></a
          >
        </div>
      </div>
    </ng-container>
  </div>
</div>
<!--</div>-->

<ng-template #spinner>
  <div
    class="d-flex justify-content-center align-items-center"
    style="
      height: 60px;
      width: 60px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    "
  >
    <span
      class="iconify"
      data-icon="eos-icons:loading"
      style="color: #6610ff"
      data-rotate="90deg"
    ></span>
  </div>
</ng-template>
