<div class="offcanvas offcanvas-end overflow-y-auto" [ngClass]="{ show: !_hide }">
  <div class="modal-content">
    <div class="my-3 mx-3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="fs-18 fw-bold">
          {{ _isSuggestion ? 'Suggest ' : _leadMeasureSearchPojo ? 'Update ' : 'Create ' }} Lead
          Measure
        </div>
        <div class="align-self-center">
          <button
            (click)="closeModal()"
            type="button"
            class="btn close fs-19 pointer"
            data-dismiss="modal"
          >
            <span
              class="iconify"
              data-icon="mdi:cancel-box-outline"
              data-width="30"
              data-height="30"
            ></span>
            Cancel
          </button>
        </div>
      </div>

      <div class="mt-2">
        <app-alert-message
          *ngIf="xAlertMessage"
          [messageType]="xAlertMessage!.messageType"
          [message]="xAlertMessage!.message"
        >
        </app-alert-message>
        <form [formGroup]="form">
          <div class="mb-3">
            <custom-select-field
              id="lag"
              [form]="form"
              [controlName]="'lagId'"
              [isLoading]="isFetchingLags"
              [name]="'Lag'"
              [rawData]="lags"
              [keyField]="'id'"
              [valueField]="'name'"
            >
            </custom-select-field>
          </div>

          <div class="mb-3">
            <custom-select-field
              id="lagMeasure"
              [form]="form"
              [controlName]="'lagMeasureId'"
              [isLoading]="isFetchingLagMeasures"
              [name]="'Lag Measure'"
              [rawData]="lagMeasures"
              [keyField]="'id'"
              [valueField]="'name'"
            >
            </custom-select-field>
          </div>

          <ng-container>
            <div class="mb-3">
              <label #measurementUnitLabel for="measurementUnit" class="form-label fs-13 required"
                >Lead Measure</label
              >

              <input
                type="text"
                id="measurementUnit"
                class="form-control"
                autocomplete="off"
                formControlName="measurementUnit"
                [typeahead]="measurementUnits"
                [typeaheadAsync]="true"
                [placeholder]="'Enter or Select ' + measurementUnitLabel.innerText"
              />
              <error-message
                [controlName]="getFormControlName(measurementUnitLabel)"
                [form]="form"
                [label]="measurementUnitLabel.innerText"
                [fontSize]="10"
                [singularError]="true"
              >
              </error-message>
            </div>
          </ng-container>

          <ng-container>
            <label class="form-label fs-13 required"> Correlation Profile </label>

            <select [disabled]="true" class="form-control mb-3">
              <option value="">RANDOM</option>
            </select>
          </ng-container>

          <div class="mb-3">
            <span
              class="fs-10 pointer float-end text-decoration-underline"
              [tooltip]="'Changing this value will reset the benchmarks'"
            >
              <span class="iconify" data-icon="material-symbols:info" style="color: #3285f8"></span>
            </span>
            <custom-select-field
              id="desiredDirection"
              [form]="form"
              [controlName]="'desiredDirectionConstant'"
              [isLoading]="false"
              [name]="'Desired Direction'"
              [rawData]="desiredDirections"
            >
            </custom-select-field>
          </div>

          <div class="form-group mb-3">
            <label #currentValueLabel for="currentValue" class="form-label fs-13 required"
              >Current Value</label
            >
            <span
              class="fs-10 pointer float-end text-decoration-underline"
              [tooltip]="'Changing this value will reset the benchmarks'"
            >
              <span class="iconify" data-icon="material-symbols:info" style="color: #3285f8"></span>
            </span>
            <input
              id="currentValue"
              type="number"
              class="form-control"
              formControlName="currentValue"
              placeholder="3"
              min="0"
              (keydown)="
                NumberOnly.decimal($event);
                NumberOnly.maxDecimalPlace($event, form.get('currentValue').value, 4)
              "
            />
            <error-message
              [controlName]="getFormControlName(currentValueLabel)"
              [form]="form"
              [label]="currentValueLabel.innerText"
              [fontSize]="10"
              [singularError]="true"
            >
            </error-message>
          </div>

          <div class="form-group mb-3">
            <label #lagPredictionNoteLabel for="lagPredictionNote" class="form-label fs-13 required"
              >Lag Measure Prediction Note</label
            >
            <textarea
              id="lagPredictionNote"
              formControlName="lagPredictionNote"
              [maxlength]="FormConstants.SHORT_NOTE_MAXIMUM_LENGTH"
              class="form-control"
              style="min-height: 130px; resize: unset"
              [placeholder]="'Enter ' + lagPredictionNoteLabel.innerText"
            ></textarea>
            <div
              class="d-flex justify-content-end"
              [ngClass]="{
                'text-danger':
                  getLagPredictionValue().length / FormConstants.SHORT_NOTE_MAXIMUM_LENGTH === 1,
                'text-success':
                  getLagPredictionValue().length / FormConstants.SHORT_NOTE_MAXIMUM_LENGTH < 1
              }"
            >
              {{
                (getLagPredictionValue().length || 0) +
                  '/' +
                  FormConstants.SHORT_NOTE_MAXIMUM_LENGTH
              }}
            </div>
            <error-message
              [controlName]="getFormControlName(lagPredictionNoteLabel)"
              [form]="form"
              [label]="lagPredictionNoteLabel.innerText"
              [fontSize]="10"
              [singularError]="true"
            >
            </error-message>
          </div>

          <ng-container>
            <a class="float-end pointer fs-12" (click)="showPerformanceBenchmarkForm()">
              {{ _showBenchmarkForm ? 'Hide' : 'Show' }} Benchmark
            </a>
          </ng-container>

          <ng-container *ngIf="_showBenchmarkForm">
            <div class="my-4">
              <div class="fs-13 fw-bold">
                Performance Benchmarks
                <span
                  class="fs-10 pointer text-decoration-underline"
                  [tooltip]="'Scores are percentage based (0 - 100)'"
                >
                  <span
                    class="iconify"
                    data-icon="material-symbols:info"
                    style="color: #3285f8"
                  ></span>
                </span>
              </div>

              <form [formGroup]="subForm">
                <div class="border border-2 border-success-subtle mt-2">
                  <div class="mb-3">
                    <div class="container px-3 my-3">
                      <span
                        class="fs-12 pointer float-end text-decoration-underline"
                        [tooltip]="'24 Hours Time Format'"
                      >
                        <span
                          class="iconify"
                          data-icon="material-symbols:info"
                          style="color: #3285f8"
                        ></span>
                      </span>
                      <div class="row align-items-start">
                        <div class="col-6">
                          <div class="mb-3">
                            <label #dueDateLabel for="dueDate" class="form-label fs-13 required"
                              >Due Date</label
                            >
                            <div class="input-group">
                              <div class="input-group">
                                <input
                                  #dueDate="bsDatepicker"
                                  bsDatepicker
                                  type="text"
                                  class="form-control"
                                  id="dueDate"
                                  [bsConfig]="datePickerConfig"
                                  autocomplete="off"
                                  [minDate]="minimumBenchmarkDay"
                                  placeholder="YYYY-MM-DD"
                                  formControlName="dueDate"
                                />
                                <span class="input-group-text bg-white" (click)="dueDate.toggle()"
                                  ><span class="iconify" data-icon="uil:calender"></span
                                ></span>
                              </div>
                            </div>
                            <error-message
                              [controlName]="getFormControlName(dueDateLabel)"
                              [form]="subForm"
                              [label]="dueDateLabel.innerText"
                              [fontSize]="10"
                              [singularError]="true"
                            >
                            </error-message>
                          </div>
                        </div>

                        <!--                        {{this._dueTime}}-->

                        <div class="col-6">
                          <div class="mb-3">
                            <div>
                              <timepicker
                                id="dueTime"
                                formControlName="dueTime"
                                [hourStep]="1"
                                [minuteStep]="1"
                                [showMeridian]="false"
                                [mousewheel]="true"
                                [showSeconds]="false"
                              ></timepicker>
                              <error-message
                                [controlName]="'dueTime'"
                                [form]="subForm"
                                [label]="'Due Time'"
                                [fontSize]="10"
                                [singularError]="true"
                              >
                              </error-message>
                            </div>
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="mb-3">
                            <label
                              #targetScoreLabel
                              for="targetScore"
                              class="form-label fs-12 required"
                              >Target Score</label
                            >
                            <span
                              class="fs-10 pointer float-end text-decoration-underline"
                              [tooltip]="'This is the target lead measure score'"
                            >
                              <span
                                class="iconify"
                                data-icon="material-symbols:info"
                                style="color: #3285f8"
                              ></span>
                            </span>
                            <input
                              id="targetScore"
                              type="number"
                              class="form-control"
                              formControlName="targetScore"
                              (keydown)="
                                NumberOnly.decimal($event);
                                NumberOnly.maxDecimalPlace(
                                  $event,
                                  subForm.get('targetScore').value,
                                  4
                                )
                              "
                            />
                            <error-message
                              [controlName]="getFormControlName(targetScoreLabel)"
                              [form]="subForm"
                              [label]="targetScoreLabel.innerText"
                              [fontSize]="10"
                              [singularError]="true"
                            >
                            </error-message>
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="mb-3">
                            <label
                              #predictedLagScoreLabel
                              for="predictedLagScore"
                              class="form-label required fs-12"
                              >Predicted Lag Score</label
                            >
                            <input
                              id="predictedLagScore"
                              type="number"
                              class="form-control"
                              formControlName="predictedLagScore"
                              max="100"
                              min="0"
                              (keydown)="
                                NumberOnly.decimal($event);
                                NumberOnly.maxDecimalPlace(
                                  $event,
                                  subForm.get('predictedLagScore').value,
                                  4
                                )
                              "
                            />
                            <error-message
                              [controlName]="getFormControlName(predictedLagScoreLabel)"
                              [form]="subForm"
                              [label]="predictedLagScoreLabel.innerText"
                              [fontSize]="10"
                              [singularError]="true"
                            >
                            </error-message>
                          </div>
                        </div>

                        <div class="d-flex justify-content-end">
                          <button
                            (click)="addBenchmark()"
                            type="button"
                            class="btn btn-primary pointer"
                          >
                            <span
                              class="iconify"
                              data-icon="ic:baseline-plus"
                              data-width="20"
                              data-height="20"
                            ></span>
                            Add Benchmark
                          </button>
                        </div>
                        <small style="font-size: 10px" class="text-danger" *ngIf="benchmarkError">
                          {{ benchmarkError }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <ng-container *ngIf="benchmarks && benchmarks.length > 0">
              <div class="my-4">
                <div class="mb-3">
                  <div class="container p-0 my-3 overflow-y-auto" style="max-height: 350px">
                    <ng-container *ngFor="let benchmark of benchmarks; let i = index">
                      <div>
                        <div
                          class="card mb-2 p-2 border-primary-subtle"
                          style="width: 100%; border-style: dotted"
                        >
                          <div>
                            <div class="d-flex align-items-center justify-content-between my-1">
                              <div class="text-primary fs-14 fw-medium">Benchmark {{ i + 1 }}</div>
                              <button
                                (click)="removeBenchmark(i)"
                                class="btn pointer mr-0 mb-0 px-0 py-0"
                                type="button"
                              >
                                <span
                                  class="iconify"
                                  data-icon="typcn:delete"
                                  style="color: red"
                                  data-width="20"
                                  data-height="20"
                                ></span>
                              </button>
                            </div>

                            <div class="d-flex flex-row justify-content-between fs-10">
                              <div>
                                <div class="fw-bold">Due Date:</div>
                                {{ getFormattedDate(benchmark.dueDate) }}
                                {{ getFormattedTime(benchmark.dueTime) }}
                              </div>
                              <div>
                                <div class="fw-bold">Target Score:</div>
                                {{ benchmark.targetScore }}
                              </div>
                              <div>
                                <div class="fw-bold">Predicted Lag Score:</div>
                                {{ benchmark.predictedLagScore }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <div class="my-4">
            <div class="fs-14 fw-bold red-star">
              Assign Users
              <div *ngIf="_isFetchingUsers" class="float-end">
                <div class="spinner-border spinner-border-sm text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div class="mb-3">
                <div class="form-group">
                  <label [hidden]="true" for="users" class="form-label fs-13">Assign User</label>
                  <ng-container *ngIf="users">
                    <ng-multiselect-dropdown
                      class="multiselect"
                      [placeholder]="'Select User(s)...'"
                      [settings]="dropdownSettings"
                      [data]="users"
                      (onSelect)="onRoleSelectionUpdate($event)"
                      (onDeSelect)="onRoleSelectionRemove($event)"
                      (onSelectAll)="onRoleSelectionUpdate($event)"
                      formControlName="users"
                      id="users"
                    >
                    </ng-multiselect-dropdown>
                    <error-message
                      [controlName]="'users'"
                      [form]="form"
                      [label]="'Users'"
                      [fontSize]="10"
                      [singularError]="true"
                    >
                    </error-message>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <div class="d-grid gap-2 col-12 mx-auto">
            <button
              [disabled]="_suggesting"
              (click)="previewLeadMeasure()"
              class="btn btn-primary"
              type="button"
            >
              {{
                _isSuggestion
                  ? 'Suggest Lead Measure'
                  : 'Preview' + (_leadMeasureSearchPojo ? ' Update ' : ' Lead Measure')
              }}
            </button>
          </div>

          <!--          button-->
<!--          <div class="d-grid gap-2 col-12 mx-auto" *ngIf="canReviewLeadMeasure && _leadMeasureSearchPojo">-->
<!--            <button-->
<!--              (click)="reviewLeadMeasure()"-->
<!--              class="btn btn-outline-primary"-->
<!--              type="button"-->
<!--            >-->
<!--              Review-->
<!--            </button>-->
<!--          </div>-->
          <!--          butto end-->
        </form>
      </div>
    </div>
  </div>
</div>
