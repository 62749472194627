/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BenchmarkPojo } from './benchmark-pojo';
import { NameCodePojo } from './name-code-pojo';


export interface LeadMeasureSearchPojo { 
    id?: number;
    leadMeasureCommitmentId?: number;
    playerName?: string;
    desiredDirection?: LeadMeasureSearchPojo.DesiredDirectionEnum;
    completionStatus?: LeadMeasureSearchPojo.CompletionStatusEnum;
    assignedUsers?: Array<NameCodePojo>;
    lagMeasureName?: string;
    lagMeasureId?: number;
    benchmarks?: Array<BenchmarkPojo>;
    lagScore?: number;
    commitmentTargetScore?: number;
    commitmentDueDate?: string;
    lagName?: string;
    leadMeasureName?: string;
    lagId?: number;
    wigName?: string;
    wigId?: number;
    predictionNote?: string;
    currentScore?: number;
    measurementUnit?: string;
    reviewStatus?: LeadMeasureSearchPojo.ReviewStatusEnum;
    createdAt?: string;
    reviewNote?: string;
    reviewedBy?: string;
    reviewedAt?: string;
    leadMeasureAction?: string;
    createdBy?: string;
}
export namespace LeadMeasureSearchPojo {
    export type DesiredDirectionEnum = 'UP' | 'DOWN';
    export const DesiredDirectionEnum = {
        UP: 'UP' as DesiredDirectionEnum,
        DOWN: 'DOWN' as DesiredDirectionEnum
    };
    export type CompletionStatusEnum = 'COMPLETED' | 'IN_REVIEW' | 'NOT_COMPLETED';
    export const CompletionStatusEnum = {
        COMPLETED: 'COMPLETED' as CompletionStatusEnum,
        IN_REVIEW: 'IN_REVIEW' as CompletionStatusEnum,
        NOT_COMPLETED: 'NOT_COMPLETED' as CompletionStatusEnum
    };
    export type ReviewStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ReviewStatusEnum = {
        APPROVED: 'APPROVED' as ReviewStatusEnum,
        PENDING: 'PENDING' as ReviewStatusEnum,
        DISAPPROVED: 'DISAPPROVED' as ReviewStatusEnum
    };
}


