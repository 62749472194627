/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerContactPojo } from './customer-contact-pojo';


export interface CustomerSearchPojo { 
    id?: number;
    name?: string;
    contactPojo?: Array<CustomerContactPojo>;
    status?: CustomerSearchPojo.StatusEnum;
    countryName?: string;
    dateCreated?: string;
}
export namespace CustomerSearchPojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum,
        DELETED: 'DELETED' as StatusEnum
    };
}


