/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AttachmentDto { 
    base64Data?: string;
    type?: AttachmentDto.TypeEnum;
    fileName?: string;
}
export namespace AttachmentDto {
    export type TypeEnum = 'pdf' | 'image';
    export const TypeEnum = {
        PDF: 'pdf' as TypeEnum,
        IMAGE: 'image' as TypeEnum
    };
}


