<!--<div-->
<!--  class="modal fade"-->
<!--  id="assignedLead"-->
<!--  tabindex="-1"-->
<!--  aria-labelledby="exampleModalLabel"-->
<!--  aria-hidden="true"-->
<!--&gt;-->
  <div class="modal-dialog  modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Player Name : {{playerName}}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
      </div>
      <div class="modal-body ">
        <div>
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th scope="col" class="fs-12 text-muted">S/N</th>
                <th scope="col" class="fs-12 text-muted">Lag Measures</th>
                <th scope="col" class="fs-12 text-muted">Lead Measures</th>
                <th scope="col" class="fs-12 text-muted">Lead Measure Score</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let leadMeasure of searchManager.list; let i = index">
                <td class="fs-12 text-muted">{{i + 1}}</td>
                <td class="fs-12 text-muted">{{ leadMeasure.lagMeasure }}</td>
                <td class="fs-12 text-muted">{{ leadMeasure.leadMeasure}}</td>
                <td class="fs-12 text-muted">{{ leadMeasure.actualScore}}</td>
              </tr>
              </tbody>
              <ng-container *ngIf="searchManager.list.length == 0">
                <tbody>
                <tr>
                  <td colspan="8">
                    <div class="d-flex justify-content-center gap-2 my-5 py-5">
                      <h3>No Records</h3>
                    </div>
                  </td>
                </tr>
                </tbody>
              </ng-container>
            </table>
          </div>
          <div *ngIf="searchManager.totalCount > 0 && !searchManager.working" class="row mt-3">
            <div class="col-12 d-flex justify-content-end">
              <pagination
                [(ngModel)]="searchManager.page"
                [boundaryLinks]="true"
                [itemsPerPage]="searchManager.itemsPerPage"
                [maxSize]="maxPageSize"
                [rotate]="true"
                [totalItems]="searchManager.totalCount"
                firstText="&laquo;"
                lastText="&raquo;"
                nextText="&rsaquo;"
                previousText="&lsaquo;"
              ></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--</div>-->
