import { Component, HostListener, OnInit } from '@angular/core';
import { ExtraOptions, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthenticationService } from '../../../../app/services/authentication.service';
import { CustomValidators } from '../../../shared/custom-validators';
import { DeliverableEmailValidator } from '../../../shared/validators/deliverable-email.validator';
import { telePhoneNumberValidator, Utils } from '../../../shared/utils/utils';
import { Constant } from '../../../models/etc/constant';
import { SuccessDialogComponent } from '../../../shared/components/success-dialog/success-dialog.component';

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
  providers: [DeliverableEmailValidator]
})
export class ContactUsComponent implements OnInit {
  form: FormGroup;
  nameRegex = Constant.STRICT_NAME_REGEX;

  constructor(
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    // private emailController: EmailControllerService,
    private deliverableEmailValidator: DeliverableEmailValidator,
    private modalService: BsModalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initForm();
    const routerOptions: ExtraOptions = {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    };
  }

  initForm(): void {
    this.form = this.fb.group({
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(40),
          Utils.noWhitespaceValidator,
          Validators.pattern(this.nameRegex.toString())
        ])
      ],
      email: [
        '',
        [CustomValidators.notBlank, Validators.email],
        [this.deliverableEmailValidator.emailExistsValidator(), Validators.required]
      ],
      message: ['', [Validators.required, CustomValidators.notBlank]],
      phoneNumber: ['', [telePhoneNumberValidator()]]
    });
  }

  login(): void {
    this.authenticationService.login({
      // It should go to /accounts
      redirectUri: `${window.location.origin}/accounts`
    });
  }

  isScrolled = false;
  logoSrc = '/assets/img/logo.svg'; // Default logo source

  @HostListener('window:scroll')
  onWindowScroll(): void {
    this.isScrolled = window.scrollY > 0;
    this.logoSrc = this.isScrolled ? '/assets/img/logo_dark.svg' : ' /assets/img/logo.svg';
  }

  // getDto(): ContactUsDto {
  //   let data: ContactUsDto = this.form.getRawValue();
  //   data.phoneNumber = this.form.controls['phoneNumber']?.value?.internationalNumber?.replace(
  //     /\s/g,
  //     ''
  //   );
  //   return data;
  // }

  // sendMessage(): void {
  //   this.emailController.mailUs({contactUsDto: this.getDto()}).subscribe(v => {
  //     this.form.reset();
  //     this.modalService.show(SuccessDialogComponent, {
  //       class: 'modal-dialog modal-dialog-centered modal-sm'
  //     });
  //   });
  // }
}
