<!--<div class="card losing-lag px-3 py-3 rounded-2">-->
<div class="card px-3 py-3 rounded-2" [class.losing-lag]="!lag.winning" [class.winning-lag]="lag.winning">
  <!--  todo dev note-->
  <!-- todo  change winning-lag to losing-lag when the lag is losing-->

  <div class="d-flex justify-content-between dropstart mb-3">
    <blocker-card *ngIf="!minimalDetails" [lagId]="lag.id" [blockers]="blockers"></blocker-card>
    <div *ngIf="!minimalDetails" class="d-flex justify-content-end dropstart">
      <button class="btn px-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <span class="iconify" data-icon="charm:menu-kebab"></span>
      </button>
      <ul class="dropdown-menu dropdown-menu-end">
        <li (click)="updateLag(lag?.id)" class="pointer">
          <a class="dropdown-item fs-15">
            <span class="iconify fs-22 me-1 text-blue" data-icon="fluent:edit-20-regular"></span>
            Edit Lag
          </a>
        </li>
        <li (click)="addKpi()" class="pointer">
          <a class="dropdown-item fs-15">
            <span
              class="iconify fs-22 me-1 text-blue"
              data-icon="fluent:window-edit-20-regular"
            ></span
            >Manage KPI</a
          >
        </li>
      </ul>
    </div>
  </div>

  <div class="d-flex justify-content-between my-2">
    <p class="fw-bold">LAG:</p>
    <div [tooltip]="lag?.lagName" class="text-muted limit-to-40-chars pointer">
      {{ lag?.lagName }}
    </div>
  </div>
  <div class="d-flex justify-content-between my-2">
    <p class="fw-bold me-2">Description:</p>
    <div [tooltip]="lag?.description" class="text-muted text-justify limit-to-40-chars pointer">
      {{ lag?.description }}
    </div>
  </div>
  <div class="d-flex justify-content-between my-2">
    <p class="fw-bold">Total KPIs:</p>
    <p class="text-muted text-justify">{{ lag.kpis.length }}</p>
  </div>
  <div class="d-flex justify-content-between my-2">
    <p class="fw-bold">Completed KPIs:</p>
    <p class="text-muted text-justify">{{ completedKpis }}</p>
  </div>
  <div class="d-flex justify-content-between my-2">
    <p class="fw-bold">Total Players:</p>
    <p class="text-muted text-justify">{{ lag.playerCount }}</p>
  </div>

  <div class="card my-2 p-2">
    <div class="d-flex justify-content-between">
      <div class="my-1">
        <div
          class="d-flex justify-content-center align-items-center"
          style="
            width: 24px;
            height: 24px;
            background: rgba(53, 1, 108, 0.1);
            border-radius: 1.17px;
          "
        >
          <span class="iconify" style="color: #3e017c" data-icon="ic:baseline-sports-score"></span>
        </div>
        <p class="my-2 fw-normal text-muted">Max Score:</p>
        <p class="mb-0 fw-bold">100%</p>
      </div>
      <div class="my-1">
        <div class="d-flex justify-content-end">
          <div
            class="d-flex justify-content-center align-items-center"
            style="
              width: 24px;
              height: 24px;
              background: rgba(53, 1, 108, 0.1);
              border-radius: 1.17px;
            "
          >
            <span
              class="iconify"
              style="color: #3e017c"
              data-icon="ic:baseline-sports-score"
            ></span>
          </div>
        </div>
        <p class="my-2 fw-normal text-muted">Actual Score:</p>
        <p class="mb-0 fw-bold text-end">{{ (lag?.actualScore || 0 | number : '1.1-2') || 0 }}%</p>
      </div>
    </div>
  </div>

  <div class="my-2 py-2 dash-dotted" *ngIf="timerObject">
    <div class="d-flex justify-content-center">
      <p class="fw-bold mb-0 me-2">Time Left:</p>
      <div class="d-flex justify-content-end">
        <div class="mx-2 text-end">
          <p
            [ngClass]="{
              'text-primary': timerObject.days >= 0,
              'text-danger':
                timerObject.seconds <= 0 &&
                timerObject.minutes <= 0 &&
                timerObject.hours <= 0 &&
                timerObject.days <= 0
            }"
            class="mb-0 fs-22 fw-bold"
          >
            {{ timerObject?.days }}
          </p>
          <p class="mb-0 fs-12 opacity-75">days</p>
        </div>
        <span class="fw-bold fs-20">:</span>
        <div class="mx-2 text-end">
          <p
            [ngClass]="{
              'text-primary': timerObject.hours >= 0,
              'text-danger':
                timerObject.seconds <= 0 &&
                timerObject.minutes <= 0 &&
                timerObject.hours <= 0 &&
                timerObject.days <= 0
            }"
            class="mb-0 fs-22 fw-bold"
          >
            {{ timerObject?.hours }}
          </p>
          <p class="mb-0 fs-12 opacity-75">hours</p>
        </div>
        <div>
          <span class="fw-bold fs-20">:</span>
        </div>
        <div class="mx-2 text-end">
          <p
            [ngClass]="{
              'text-primary': timerObject.minutes >= 0,
              'text-danger':
                timerObject.seconds <= 0 &&
                timerObject.minutes <= 0 &&
                timerObject.hours <= 0 &&
                timerObject.days <= 0
            }"
            class="mb-0 fs-22 fw-bold"
          >
            {{ timerObject?.minutes }}
          </p>
          <p class="mb-0 fs-12 opacity-75">minutes</p>
        </div>

        <div>
          <span class="fw-bold fs-20">:</span>
        </div>
        <div class="ms-2 text-end">
          <p
            [ngClass]="{
              'text-primary': timerObject.seconds >= 0,
              'text-danger':
                timerObject.seconds <= 0 &&
                timerObject.minutes <= 0 &&
                timerObject.hours <= 0 &&
                timerObject.days <= 0
            }"
            class="mb-0 fs-22 fw-bold"
          >
            {{ timerObject?.seconds }}
          </p>
          <p class="mb-0 fs-12 opacity-75">seconds</p>
        </div>
      </div>
    </div>
  </div>

  <ng-template>
    <lottie-in-full-table [path]="'assets/lottie/loading.json'" [message]="'Searching...'">
    </lottie-in-full-table>
  </ng-template>

  <div *ngIf="!minimalDetails" class="d-flex justify-content-between my-2">
    <button (click)="showLagDetails()" style="" class="mini-button px-4 py-1 pointer">
      View Lag Details
    </button>

    <button (click)="gotoPlayerProfile(wigId, lag.id)" style="" class="mini-button px-4 py-1 pointer">
      View Players Scores
    </button>
  </div>

  <!--  <div class="d-flex mt-4">-->
  <!--    <a *ngIf="showHistoryLink" class="fs-12 text-decoration-underline">View History</a>-->
  <!--    <a  class="fs-12 text-decoration-underline pointer">-->
  <!--      View LAG details</a-->
  <!--    >-->
  <!--  </div>-->
</div>
