<div class="modal-content">
  <div class="modal-header">
    <h1 class="modal-title fs-5" id="exampleModalLabel">Raise a blocker for WIG: {{ _wigPojo.name }}</h1>
    <button
      (click)="closeModal()"
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body">
    <app-alert-message
      *ngIf="xAlertMessage"
      [messageType]="xAlertMessage!.messageType"
      [message]="xAlertMessage!.message"
    >
    </app-alert-message>

    <form [formGroup]="formGroup">
      <div class="row align-items-start">
        <div class="col-sm-12 col-md">
          <div class="mb-3">
            <custom-select-field
              [form]="formGroup"
              [controlName]="'blockerTypeId'"
              [isLoading]="isFetchingBlockerTypes"
              [name]="'Blocker Type'"
              [rawData]="blockerTypes"
              [keyField]="'id'"
              [valueField]="'name'"
            >
            </custom-select-field>
          </div>
        </div>

        <div class="col-sm-12 col-md">
          <div class="mb-3">
            <custom-select-field
              [form]="formGroup"
              [controlName]="'wasteType'"
              [isLoading]="isFetchingWasteType"
              [name]="'Waste Type'"
              [rawData]="wasteTypes"
            >
            </custom-select-field>
          </div>
        </div>
      </div>
      <div class="row align-items-start">
        <div class="col-sm-12 col-md">
          <div class="mb-3">
            <div class="form-group">
              <label for="lags" class="form-label fs-13">Affecting Lag(s) </label>
              <ng-container *ngIf="lags">
                <ng-multiselect-dropdown
                  class="multiselect"
                  [placeholder]="'Select Lag(s)...'"
                  [settings]="dropdownSettings"
                  [data]="lags"
                  (onSelect)="onRoleSelectionUpdate($event)"
                  (onDeSelect)="onRoleSelectionRemove($event)"
                  (onSelectAll)="onRoleSelectionUpdate($event)"
                  formControlName="lagIds"
                  id="lags"
                >
                </ng-multiselect-dropdown>
                <error-message
                  [controlName]="'lagIds'"
                  [form]="formGroup"
                  [label]="'Lag'"
                  [fontSize]="10"
                  [singularError]="true"
                >
                </error-message>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-start">
        <div class="col-sm-12 col-md">
          <div class="mb-3">
            <label #possibleInfluencerLabel for="possibleInfluencer" class="form-label fs-13"
              >Possible Influencer</label
            >
            <input
              type="text"
              id="possibleInfluencer"
              class="form-control"
              autocomplete="off"
              formControlName="possibleInfluencer"
              [placeholder]="'Enter ' + possibleInfluencerLabel.innerText"
            />
            <error-message
              [controlName]="getFormControlName(possibleInfluencerLabel)"
              [form]="formGroup"
              [label]="possibleInfluencerLabel.innerText"
              [fontSize]="10"
              [singularError]="true"
            >
            </error-message>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="form-group mb-3">
          <label #descriptionLabel for="description" class="form-label required">Description</label>
          <textarea
            id="description"
            formControlName="description"
            [maxlength]="FormConstants.LARGE_TEXT_FIELD_MAX_LENGTH"
            class="form-control"
            style="min-height: 130px; resize: unset"
            [placeholder]="'Enter ' + descriptionLabel.innerText"
          ></textarea>
          <div
            class="d-flex justify-content-end"
            [ngClass]="{
              'text-danger':
                getDescriptionValue().length / FormConstants.LARGE_TEXT_FIELD_MAX_LENGTH === 1,
              'text-success':
                getDescriptionValue().length / FormConstants.LARGE_TEXT_FIELD_MAX_LENGTH < 1
            }"
          >
            {{
              (getDescriptionValue().length || 0) + '/' + FormConstants.LARGE_TEXT_FIELD_MAX_LENGTH
            }}
          </div>
          <error-message
            [controlName]="getFormControlName(descriptionLabel)"
            [form]="formGroup"
            [label]="descriptionLabel.innerText"
            [fontSize]="10"
            [singularError]="true"
          >
          </error-message>
        </div>
      </div>
    </form>

    <div class="modal-footer">
      <app-button-with-loader
        [classText]="'btn btn-primary fs-14'"
        [idleStateText]="'Create Blocker'"
        [isProcessing]="isCreatingBlocker"
        (buttonClick)="createBLocker()"
        [workingStateText]="'Creating Blocker'"
      >
      </app-button-with-loader>
    </div>
  </div>
</div>
