import { Injectable } from '@angular/core';
import { TimerObject } from '../pages/intranet/wig-mgt/wig-profile/wig-profile.component';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  public timerIntervals: number[] = [];

  public timerObject: TimerObject;

  startTimer(targetDate: string, callback: (time: TimerObject) => void): void {
    const targetTime = new Date(targetDate).getTime();

    const interval = setInterval(() => {
      const timeRemaining = targetTime - new Date().getTime();

      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      const timerObject: TimerObject = { days, hours, minutes, seconds };

      callback(timerObject);
    }, 1000);

    this.timerIntervals.push(interval);
  }

  stopTimer(): void {
    this.timerIntervals.forEach((interval) => clearInterval(interval));
    this.timerIntervals = [];
  }
}
