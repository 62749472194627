import { Component, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  FileControllerService,
  PlayerProfilePojo,
  UserProfileControllerService
} from '../../../../../sdk/ecadence-sdk';
import { XAlertMessage } from '../alert-message/alert-message.component';

@Component({
  selector: 'player-profile-card',
  templateUrl: './player-profile-card.component.html',
  styleUrls: ['./player-profile-card.component.css']
})
export class PlayerProfileCardComponent {
  constructor(
    private userProfileController: UserProfileControllerService,
    private fileController: FileControllerService
  ) {}

  private _playerId: number;
  _playerProfilePojo: PlayerProfilePojo;
  _loading = true;

  _loadingImage = true;
  _profilePicture: string;

  get profilePicture(): string {
    return this._profilePicture;
  }

  set profilePicture(value: string) {
    this._profilePicture = value;
  }

  xAlertMessage: XAlertMessage = {
    messageType: 'info',
    message: undefined
  };

  get playerId(): number {
    return this._playerId;
  }

  @Input()
  set playerId(value: number) {
    if (this._playerId == value) {
      return;
    }
    this._playerId = value;
    this.getPlayerDetails();
  }

  getPlayerDetails(): void {
    this.userProfileController
      .getPlayerProfile({ playerId: this.playerId })
      .subscribe({
        next: (pojo) => {
          this._playerProfilePojo = pojo;
          this.getImageDataURI(pojo.profileImage?.fileId);
        },
        error: (err: unknown) => {
          this.showMessage(
            err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
              ? err.error.message
              : 'Unable to fetch player profile',
            'danger'
          );
        }
      })
      .add(() => {
        this._loading = false;
      });
  }

  showMessage(error: any, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
    this.xAlertMessage.messageType = type;
    this.xAlertMessage.message = error;
    setTimeout(() => {
      this.xAlertMessage.message = undefined;
    }, 4000);
  }

  get userInitial(): string {
    // Split the name into individual words
    const words = this._playerProfilePojo?.name.split(' ');

    // Extract the first letter of each word and convert it to uppercase
    const initials = words.map((word) => word.charAt(0).toUpperCase());

    // Join the initials together
    return initials.join('');
  }

  getImageDataURI(fileId: number): void {
    if (!fileId) {
      this._loadingImage = false;
      return;
    }
    this._loadingImage = true;
    this.fileController
      .getDataURI({ id: fileId })
      .subscribe({
        next: (value) => {
          this.profilePicture = value.code;
        }
      })
      .add(() => (this._loadingImage = false));
  }
}
