<div class=" m-0">
  <div class="modal-header">
    <div class="pull-left">
      <h6 class="modal-title" id="modal-title">Crop Image</h6>
    </div>
    <span type="button" class="close pull-right" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </span>
  </div>
  <div class="modal-body text-center">
    <div class="row mb-2">
      <div class="col-12">
        Drag the edges of the rectangle to resize the image to you specification
      </div>
    </div>
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="maintainAspectRatio"
      [aspectRatio]="aspectRatio"
      format="png"
      [resizeToHeight]="maxHeight"
      [resizeToWidth]="maxWidth"
      (imageCropped)="onImageCropped($event)"
      [backgroundColor]="'#000000'"
      (imageLoaded)="imageLoaded($event)"
      (cropperReady)="cropperReady()"
      [imageFile]="imageFile"
      [alignImage]="'center'"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
    <div>
      <hr/>
    </div>
    <img [src]="croppedImage" class="img-fluid w-50" />
  </div>
  <div class="modal-footer">
    <button (click)="close()" class="btn btn-outline-danger" type="button">
      <i class="fa fa-times mr-1 fa-spinner "></i
      >Close
    </button>
    <button (click)="confirm()" class="btn btn-primary" type="button">
      <i class="fa fa-crop"></i>
      Crop
    </button>
  </div>
</div>


<!--<div class="modal" tabindex="-1">-->
<!--  <div class="modal-dialog">-->
<!--    <div class="modal-content">-->
<!--      <div class="modal-header">-->
<!--        <h5 class="modal-title">Modal title</h5>-->
<!--        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
<!--      </div>-->
<!--      <div class="modal-body">-->

<!--      </div>-->
<!--      <div class="modal-footer">-->
<!--        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
<!--        <button type="button" class="btn btn-primary">Save changes</button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

