import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ScoreBoardDataItem } from '../../../pages/intranet/scoreboard/scoreboard.component';
import { TimerObject } from '../../../pages/intranet/wig-mgt/wig-profile/wig-profile.component';

@Component({
  selector: 'upcoming-deadlines',
  templateUrl: './upcoming-deadlines.component.html',
  styleUrls: ['./upcoming-deadlines.component.css']
})
export class UpcomingDeadlinesComponent implements OnInit, OnChanges {
  @Input() showDeadlines: boolean;
  @Input() scoreboardDataItem: ScoreBoardDataItem;

  _countDown: Array<TimerObject> = [];

  ngOnInit(): void {
    this.showDeadlines = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.scoreboardDataItem = changes['scoreboardDataItem']?.currentValue;
    console.log('deadlines====>', this.scoreboardDataItem);
    this.scoreboardDataItem?.leadMeasureBenchmark?.forEach(async (item) => {
      const _timerObject: TimerObject = await this.modalCountdown(item.dueDate);
      this._countDown.push(_timerObject);
    });
  }

  modalCountdown(targetDate: string): Promise<TimerObject> {
    return new Promise<TimerObject>((resolve) => {
      const targetTime = new Date(targetDate).getTime();

      const interval = setInterval(() => {
        const timeRemaining = targetTime - new Date().getTime();

        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        const timerObject: TimerObject = { days, hours, minutes, seconds };

        resolve(timerObject);
      }, 1000);
    });
  }
}
