import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { distinctUntilChanged, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';
import {
  Blocker,
  BlockerControllerService,
  BlockerSearchDto,
  BlockerSearchFilter,
  NameCodePojo,
  NameIdPojo,
  WigControllerService
} from '../../../../../../sdk/ecadence-sdk';
import { Utils } from '../../../../shared/utils/utils';
import { XAlertMessage } from '../../../../shared/components/alert-message/alert-message.component';
import { PaginatedSearchManager } from '../../../../shared/search/paginated-search-manager';
import { NameValuePair } from '../../../../models/etc/name-value-pair.model';
import { QueryResults } from '../../../../shared/search/query-results';
import { SearchFilterSource } from '../../../../shared/search/search-filter-source';
import { PaginatedSearchHandler } from '../../../../shared/search/paginated-search-handler';
import { AssignBlockerModalComponent } from '../modals/assign-blocker-modal/assign-blocker-modal.component';

import { NumberOnly } from '../../../../shared/validators/number-only';
import WasteTypeEnum = Blocker.WasteTypeEnum;
import ResolutionStatusEnum = Blocker.ResolutionStatusEnum;

@Component({
  selector: 'blocker-search',
  templateUrl: './blocker-search.component.html',
  styleUrls: ['./blocker-search.component.css']
})
export class BlockerSearchComponent
  implements
    OnInit,
    PaginatedSearchHandler<BlockerSearchDto, BlockerSearchFilter>,
    SearchFilterSource<BlockerSearchFilter>
{
  datePickerConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-dark-blue',
    showWeekNumbers: false,
    dateInputFormat: 'YYYY-MM-DD'
  };

  searchManager: PaginatedSearchManager<BlockerSearchDto, BlockerSearchFilter>;

  formGroup: FormGroup;

  _searchViewType: 'CARD' | 'TABLE' = 'TABLE';

  _wigs: NameIdPojo[];
  _lags: NameIdPojo[];
  wasteTypes: WasteTypeEnum[] = Utils.enumValues(WasteTypeEnum);
  resolutionStatus: ResolutionStatusEnum[] = Utils.enumValues(ResolutionStatusEnum);
  blockerTypes: NameIdPojo[];
  _users: NameCodePojo[];

  _isFetchingBlockerTypes: boolean;
  _isFetchingWigs: boolean;
  _isFetchingLags: boolean;
  _isFetchingPlayers: boolean;

  _showFilter = false;

  createdAfterMaxDate: Date = new Date();

  createdBeforeMinDate: Date | undefined;
  createdBeforeMaxDate: Date = new Date();

  xAlertMessage: XAlertMessage = {
    message: undefined,
    messageType: 'info'
  };

  constructor(
    private formBuilder: FormBuilder,
    private blockerController: BlockerControllerService,
    private wigController: WigControllerService,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute
  ) {
    this.searchManager = new PaginatedSearchManager<BlockerSearchDto, BlockerSearchFilter>(
      this,
      this
    );
  }

  ngOnInit(): void {
    this.initializeForm();
    this.formListener();
    this.getWigs();
    this.getBlockTypes();
    this.setSearchViewType(window.innerWidth);

    this.activatedRoute.queryParams.subscribe((params) => {
      console.log(params);
      if (params['wigId'] != null && params['blockerStatus'] != null) {
        this.formGroup.get('wigId').patchValue(params['wigId']);
        this.formGroup.get('resolutionStatus').patchValue(params['blockerStatus']);
        this.searchNow();
      }
      if (params['lagId'] != null && params['resolutionStatus'] != null) {
        this.formGroup.get('lagId').patchValue(params['lagId']);
        this.formGroup.get('resolutionStatus').patchValue(params['resolutionStatus']);
        this.searchNow();
      }

      if (params['wigId'] != null) {
        this.formGroup.get('wigId').patchValue(params['wigId']);
        this.searchNow();
      }

      if (params['blockerCode'] != null) {
        this.formGroup.get('code').patchValue(params['blockerCode']);
        this.searchNow();
      }

      if (params['id'] != null) {
        this.formGroup.get('blockerId').patchValue(params['blockerId']);
        this.searchNow();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.setSearchViewType(Math.min(event.target.innerWidth, event.target.outerWidth));
  }

  setSearchViewType(width: number): void {
    if (width <= 700) {
      this._searchViewType = 'CARD';
    } else {
      this._searchViewType = 'TABLE';
    }
  }

  initializeForm(): void {
    this.formGroup = this.formBuilder.group({
      blockerId: [''],
      wigId: [''],
      lagId: [''],
      code: [''],
      blockerTypeId: [''],
      wasteType: [''],
      assignedTo: [''],
      resolutionStatus: [''],
      createdAfter: [''],
      createdBefore: ['']
    });
  }

  formListener(): void {
    this.formGroup.get('createdAfter')?.valueChanges.subscribe((value) => {
      if (value) {
        this.createdBeforeMinDate = value;
      } else {
        // choose a date in 2100
        this.createdBeforeMaxDate = new Date(4102444800000);
      }
    });

    this.formGroup.get('createdBefore')?.valueChanges.subscribe((value) => {
      if (value) {
        this.createdAfterMaxDate = value;
      } else {
        // choose a date in 2100
        this.createdAfterMaxDate = this.today();
      }
    });

    this.formGroup
      .get('wigId')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe({
        next: (value) => {
          if (value) {
            this.fetchLagsForWig(value);
            this.fetchPlayers(value);
          }
        }
      });
  }

  getBlockTypes(): void {
    this._isFetchingBlockerTypes = true;
    this.blockerController
      .searchBlockerCategory()
      .subscribe({
        next: (val: NameIdPojo[]) => {
          this.blockerTypes = val;
        },
        error: (err: unknown) => {
          this.xAlertMessage.messageType = 'danger';
          this.xAlertMessage.message =
            err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
              ? err.error.message
              : 'Unable to load blocker types';
        }
      })
      .add(() => {
        this._isFetchingBlockerTypes = false;
      });
  }

  getWigs(): void {
    this._isFetchingWigs = true;
    this.wigController
      .getWigAsNameId()
      .subscribe({
        next: (val: NameIdPojo[]) => {
          this._wigs = val;
        },
        error: (err: unknown) => {
          this.xAlertMessage.messageType = 'danger';
          this.xAlertMessage.message =
            err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
              ? err.error.message
              : 'Unable to fetch wigs';
        }
      })
      .add(() => {
        this._isFetchingWigs = false;
      });
  }

  fetchLagsForWig(wigId: number): void {
    this._isFetchingLags = true;
    this.wigController
      .getLagsForWig({ id: wigId })
      .subscribe(
        (response: NameIdPojo[]): void => {
          this._lags = [];
          this._lags = response;
        },
        (error: unknown): void => {
          this.xAlertMessage.messageType = 'danger';
          this.xAlertMessage.message =
            error instanceof HttpErrorResponse && error.error?.message && error.error.code !== 500
              ? error.error.message
              : 'Unable to fetch lags';
        }
      )
      .add(() => {
        this._isFetchingLags = false;
      });
  }

  fetchPlayers(widId: number): void {
    this._isFetchingPlayers = true;
    this.wigController
      .getPlayersForWig({ id: widId })
      .subscribe({
        next: (players: NameCodePojo[]) => {
          this._users = [];
          this._users = players;
        }
      })
      .add(() => {
        this._isFetchingPlayers = false;
      });
  }

  today(): Date {
    return new Date();
  }

  scrollUp(): void {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  getFilter(): BlockerSearchFilter {
    const data: BlockerSearchFilter = this.formGroup?.value;
    if (data.createdAfter) {
      data.createdAfter = moment(data.createdAfter).format('YYYY-MM-DD');
    }
    if (data.createdBefore) {
      data.createdBefore = moment(data.createdBefore).format('YYYY-MM-DD');
    }
    console.log(data);
    return data;
  }

  getPersistentKey(): string {
    return '';
  }

  getSearchDescriptor(filter: BlockerSearchFilter): NameValuePair[] {
    return [];
  }

  search(page: number, filter?: BlockerSearchFilter): Observable<QueryResults<BlockerSearchDto>> {
    const offset = (page - 1) * this.searchManager.itemsPerPage;
    return this.blockerController.searchBlocker({
      blockerSearchFilter: {
        ...filter,
        offset: offset,
        limit: this.searchManager.itemsPerPage
      }
    });
  }

  searchNow(): void {
    this.searchManager.reloadAndShowFirstPage();
  }

  preview(item: BlockerSearchDto): void {
    console.log('ok');
  }

  edit(item: BlockerSearchDto): void {
    console.log('ok');
  }

  toggleFilter(): void {
    this._showFilter = !this._showFilter;
  }

  previewBlocker(item: BlockerSearchDto): void {
    const assignBlockerModal: BsModalRef<AssignBlockerModalComponent> = this.modalService.show(
      AssignBlockerModalComponent,
      {
        initialState: {
          blockerDto: item
        },
        keyboard: false,
        backdrop: 'static',
        class: ''
      }
    );
  }
}
