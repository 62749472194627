import { Component, Input } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent } from 'chart.js';

import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent {
  @Input()
  _maxYValue: number;

  public barChartType: ChartConfiguration<'bar'>['type'] = 'bar';

  _barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 1
      }
    },
    plugins: {
      legend: {
        display: true
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };

  @Input()
  set maxYValue(value: number) {
    this._maxYValue = value;
    this._barChartOptions = {
      responsive: true,
      // We use these empty structures as placeholders for dynamic theming.
      scales: {
        x: {},
        y: {
          min: 1,
          max: value
        }
      },
      plugins: {
        legend: {
          display: true
        },
        datalabels: {
          anchor: 'end',
          align: 'end'
        }
      }
    };
  }

  public barChartPlugins = [DataLabelsPlugin];

  _barChartData: ChartData<'bar'> = {
    labels: ['2006', '2007', '2008', '2009', '2010', '2011', '2012'],
    datasets: [
      { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
      { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
    ]
  };

  get barChartData(): ChartData<'bar'> {
    return this._barChartData;
  }

  @Input()
  set barChartData(value: ChartData<'bar'>) {
    this._barChartData = value;
  }

  @Input() size = 170;
  @Input() hideShowDetails = false;

  @Input()
  bottomTitle = '';

  constructor() {
    return undefined;
  }

  chartHovered($event: { event: ChartEvent; active: unknown }): undefined {
    return undefined;
  }

  chartClicked($event: { event?: ChartEvent; active?: unknown }): undefined {
    return undefined;
  }
}
