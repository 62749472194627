<app-full-page-loader *ngIf="_isLoading; else renderData" [customText]="'Loading Scoreboard'">

</app-full-page-loader>

<ng-template #renderData>



<div class="row">
  <div class="d-flex justify-content-end">
    <app-cadence-timer
      *ngIf="isCadence"
      [cadenceEventId]="cadenceEventId"
      (isCadence)="cadenceStatus($event)"
      [wigId]="wigId"
    ></app-cadence-timer>
  </div>
  <div class="d-flex justify-content-between">
    <p class="mb-0 small">
      <span class="fw-bold"> Last Updated: </span> {{ formattedDate | datetime }}
    </p>

    <div>
      <!--      <a href="" class="small text-decoration-underline"> View Execution Pipeline</a>-->
    </div>
  </div>

  <div class="row mt-3 gy-3">
    <div class="col-md-8">
      <div class="d-flex align-items-center">
        <div class="">

          <button
            (click)="createCommitmentEvent.emit()"
            class="py-2 px-3 position-relative btn btn-primary border-0"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span class="iconify" data-icon="carbon:add"></span>
            Add commitment
          </button>

          <!--        <user-without-wig-card [showUsers]="hideUsersWithoutAssignedWig"></user-without-wig-card>-->
        </div>
        <div class="">
          <button
            (click)="gotoUnassignedBlocker()"
            class="py-2 px-3 ms-3 position-relative btn btn-outline-dark"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span class="iconify" data-icon="material-symbols:block"></span>
            Unassigned blockers
            <span
              class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
            >
            {{
              wigScoreStatistics?.unassignedBlockers ? wigScoreStatistics?.unassignedBlockers : 0
              }}
              <!--    <span class="visually-hidden">Users without WIG</span>--></span
            >
          </button>

          <!--        <user-without-wig-card [showUsers]="hideUsersWithoutAssignedWig"></user-without-wig-card>-->
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="d-flex justify-content-md-end  flex-md-row">
        <p class="fw-bold mb-3 mb-md-0">Time Left:</p>
        <div *ngIf="showCountDown()" class="d-flex justify-content-end">
          <div class="mx-3 text-end">
            <p class="mb-0 fs-26 fw-bold text-primary">{{ Number(timerObject?.days) }}</p>
            <p class="mb-0 fs-12 opacity-75">days</p>
          </div>
          <div class="mx-3 text-end">
            <p class="mb-0 fs-26 text-muted fw-bold">{{ timerObject?.hours }}</p>
            <p class="mb-0 fs-12 opacity-75">hours</p>
          </div>
          <div>
            <span class="fw-bold fs-20">:</span>
          </div>
          <div class="mx-3 text-end">
            <p class="mb-0 fs-26 text-muted fw-bold">{{ timerObject?.minutes }}</p>
            <p class="mb-0 fs-12 opacity-75">minutes</p>
          </div>

          <div class="d-none d-md-block">
            <span class="fw-bold fs-20">:</span>
          </div>
          <div class="ms-3 text-end d-none d-md-block">
            <p class="mb-0 fs-22 text-muted fw-bold">{{ timerObject?.seconds }}</p>
            <p class="mb-0 fs-12 opacity-75">seconds</p>
          </div>
        </div>
        <div *ngIf="!showCountDown()" class="d-flex justify-content-end">
          {{scoreboardDataItem?.wigPojo?.dueDate | date }}
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-center mt-4 flex-wrap">

    <div class="row row-cols-1">



    </div>

    <!--    <div>-->
    <!--      <button (click)="viewUsersWithoutWig()"-->
    <!--              class="p-2 position-relative btn btn-primary border-0"-->
    <!--              type="button"-->
    <!--              data-bs-toggle="dropdown"-->
    <!--              aria-expanded="false"-->
    <!--      >unassigned blockers-->
    <!--        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">-->
    <!--        {{usersWithoutWig?.length}}-->
    <!--          &lt;!&ndash;    <span class="visually-hidden">Users without WIG</span>&ndash;&gt;</span>-->
    <!--      </button>-->

    <!--      <user-without-wig-card [showUsers]="hideUsersWithoutAssignedWig"></user-without-wig-card>-->
    <!--    </div>-->
    <div class="col-lg-4 mt-4 mt-lg-0">

    </div>

  </div>

  <div class=" text-end my-1 mb-lg-0">
    <button
      class="btn btn-dark p-2 position-relative"
      type="button"
      (click)="loadUpcomingDeadlines()"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span class="iconify" data-icon="uis:link-h"></span> View Upcoming Deadlines
    </button>

    <upcoming-deadlines
      [showDeadlines]="hideUpcomingDeadlines"
      [scoreboardDataItem]="scoreboardDataItem"
    ></upcoming-deadlines>
  </div>
</div>

</ng-template>
  <!--    <div *ngIf="!isLocal"></div>-->
