import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlockerSearchDto } from '../../../../../../../sdk/ecadence-sdk';

@Component({
  selector: 'blocker-search-card',
  templateUrl: './blocker-search-card.component.html',
  styleUrls: ['./blocker-search-card.component.css']
})
export class BlockerSearchCardComponent {
  _blockerSearchDto: BlockerSearchDto;

  @Output()
  viewMoreEvent: EventEmitter<BlockerSearchDto> = new EventEmitter<BlockerSearchDto>();

  @Input()
  set blockerSearchDto(value: BlockerSearchDto) {
    this._blockerSearchDto = value;
  }

  viewMore(): void {
    this.viewMoreEvent.emit(this._blockerSearchDto);
  }
}
