<div class="container-fluid dash-wrapper p-3">
  <div
    class="toast-container p-3 top-0 rounded-0 position-absolute"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    style="right: 22rem"
    id="toastPlacement"
  ></div>

  <div class="p-md-3 dash-overview-banner">
    <ng-container *ngIf="currentRoute === '/dashboard'">
      <nav-template></nav-template>
    </ng-container>
  </div>

  <div class="dash-new-container p-3">
    <div class="dash-new-banner mt-4 mb-5 py-md-5 py-4 ps-md-5 ps-3">
      <div class="pt-md-4 pt-3 pb-2">
        <h1 class="text-white">{{ salutation }}, {{ user?.firstName }}</h1>
      </div>
      <div class="text-white">Embrace success with eCadence right away!</div>
    </div>

    <div class="mb-5 d-flex justify-content-between align-items-center">
      <div class="col-md-6">
        <div class="row gy-3">
          <div class="col-md-6">
            <lead-measure-update-link-card></lead-measure-update-link-card>
          </div>
          <div class="col-md-6">
            <div class="card h-100" style="border-radius: 8px; border-left: 2.5px solid #E487AF; background: #F8ECF1;">
              <div class="card-body">
                <div class="p-2" >
                  <h5>eCadence Help Desk</h5>
                  <div class="mb-3">Know more about eCadence. See FAQs</div>
                  <div class="d-flex text-end float-bottom ms-auto justify-content-end">
                    <div class="p-1 me-1 rounded-5 lead-card-2">
                      <span class="iconify fs-3" data-icon="fluent:chevron-right-16-regular"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- INSERT MEMBERS DIVS -->
      <div class="d-none d-md-block">
        <div class="text-light d-flex align-items-center">
          <ng-container *ngIf="_playersInitials.length > 0; else noPlayer"></ng-container>
          <ng-container *ngFor="let player of _playersInitials; let i = index">
            <ng-container *ngIf="i < 3">
              <p
                class="p-2 rounded-circle mb-0"
                [style.background-color]="getColor(i)"
                [style.margin-left]="getMarginLeft(i)"
              >
                {{ player }}
              </p>
              <ng-container *ngIf="i === 2">
                <div class="ms-2" style="color: #000; font-size: 12px; font-weight: 500">
                  {{ _playersInitials.length }} Member{{ _playersInitials.length > 1 ? 's' : ' ' }}
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #noPlayer>No player</ng-template>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div class="d-flex justify-content-between align-items-center">
        <h5 style="color: #3644d0">WIGs Overview</h5>
        <div>
          <button class="btn btn-outline-secondary rounded-5 fs-16 py-1 px-4 me-2" #scrollButton (click)="scrollDownAndUp(); setWigCount(searchManager.totalCount)" *ngIf="searchManager.totalCount > 4">
            Show all
          </button>


          <a class="btn btn-outline-primary rounded-5 fs-16 py-1 px-4" [href]="'wig'">
          <span class="iconify" style="font-size: 19px;" data-icon="fluent:apps-list-20-regular"></span>View list
        </a></div>

      </div>

      <div class="row mt-md-4 mt-3 gy-3">
        <ng-container *ngIf="_searchOrMilestone === 'SEARCH'">
          <ng-container *ngIf="!searchManager.working; else searching">
            <ng-container *ngIf="searchManager.list?.length > 0; else noData">
              <ng-container *ngFor="let item of searchManager.list; index as i">
                <wig-overview
                  class="col-md-3"
                  *ngIf="i <= wigCount"
                  [wigSummaryDto]="item"
                ></wig-overview>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #noData>
  <lottie-in-full-table [path]="'assets/lottie/no-data.json'" [message]="'No WIG Available'">
  </lottie-in-full-table>
</ng-template>

<ng-template #searching>
<!--  <div class="spinner-border text-secondary" role="status">-->
<!--    <span class="visually-hidden">Loading...</span>-->
<!--  </div>-->
  <lottie-in-full-table [path]="'assets/lottie/loading.json'" [message]="'Searching...'">
  </lottie-in-full-table>
</ng-template>

<div
  class="offcanvas pt-2 ms-auto offcanvas-bottom"
  style="width: 500px; height: 630px"
  tabindex="-1"
  id="offcanvasBottom"
  aria-labelledby="offcanvasBottomLabel"
>
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasBottomLabel">WIG Milestones</h5>
    <button
      type="button"
      class="btn-close text-reset"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div class="offcanvas-body small">
    <wig-milestones [wigSummaryDto]="selectedWigSummaryDto"> </wig-milestones>
  </div>
</div>
