/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CadenceSchedulePojo { 
    wigID: number;
    scheduleItemId?: number;
    cadenceTypeId?: number;
    title: string;
    startTime: string;
    endTime: string;
    cycle: CadenceSchedulePojo.CycleEnum;
    dayOfTheWeek?: CadenceSchedulePojo.DayOfTheWeekEnum;
    daysOfTheWeek?: Array<CadenceSchedulePojo.DaysOfTheWeekEnum>;
    dayOfTheMonth?: number;
    repeatEvery?: number;
    wigName?: string;
    tillEndOfWig?: boolean;
    lastCadenceEvent?: string;
    nextCadenceEvent?: string;
    cadenceEventId?: number;
}
export namespace CadenceSchedulePojo {
    export type CycleEnum = 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'YEARLY';
    export const CycleEnum = {
        DAILY: 'DAILY' as CycleEnum,
        WEEKLY: 'WEEKLY' as CycleEnum,
        MONTHLY: 'MONTHLY' as CycleEnum,
        YEARLY: 'YEARLY' as CycleEnum
    };
    export type DayOfTheWeekEnum = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
    export const DayOfTheWeekEnum = {
        MONDAY: 'MONDAY' as DayOfTheWeekEnum,
        TUESDAY: 'TUESDAY' as DayOfTheWeekEnum,
        WEDNESDAY: 'WEDNESDAY' as DayOfTheWeekEnum,
        THURSDAY: 'THURSDAY' as DayOfTheWeekEnum,
        FRIDAY: 'FRIDAY' as DayOfTheWeekEnum,
        SATURDAY: 'SATURDAY' as DayOfTheWeekEnum,
        SUNDAY: 'SUNDAY' as DayOfTheWeekEnum
    };
    export type DaysOfTheWeekEnum = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
    export const DaysOfTheWeekEnum = {
        MONDAY: 'MONDAY' as DaysOfTheWeekEnum,
        TUESDAY: 'TUESDAY' as DaysOfTheWeekEnum,
        WEDNESDAY: 'WEDNESDAY' as DaysOfTheWeekEnum,
        THURSDAY: 'THURSDAY' as DaysOfTheWeekEnum,
        FRIDAY: 'FRIDAY' as DaysOfTheWeekEnum,
        SATURDAY: 'SATURDAY' as DaysOfTheWeekEnum,
        SUNDAY: 'SUNDAY' as DaysOfTheWeekEnum
    };
}


