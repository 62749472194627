<app-full-page-loader *ngIf="loading"></app-full-page-loader>
<div class="container-fluid p-md-3 p-xl-5">
  <div class="container">
    <div class="row">
      <scoreboard-header-component (createCommitmentEvent)="triggerCreateCommitment()" [wigId]="wigPojo.id" [scoreboardDataItem]="scoreboardDataItem" [cadenceTimer]="cadenceTimer" [isCadence]="isCadence" [cadenceEventId]="cadenceEventId">
      </scoreboard-header-component>
    </div>
    <div class="mt-5">
      <scoreboard-status-component
        [scoreboardDataItem]="scoreboardDataItem"
      ></scoreboard-status-component>
    </div>
    <div class="my-4">
      <lag-profile (lagUpdate)="updateWigPojo($event)" [wigPojo]="wigPojo" [showAddLagButton]="false"></lag-profile>
    </div>
    <div class="my-4">
      <div class="row row-cols-2">
        <div class="col-lg-7 col-12">
          <player-summary [wigId]="wigPojo.id"></player-summary>
        </div>
        <div class="col-lg-5 col-12">
          <recent-commitment-holder  [wig]="wigPojo"></recent-commitment-holder>
        </div>
      </div>
    </div>
  </div>

  <!-- view upcoming deadline dialog  -->
</div>
