import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ChartData } from 'chart.js';
import { UpdateLeadMeasureScorePreviewDto } from '../update-lead-measure/update-lead-measure-score.component';
import { XLagMeasurePojo } from '../update-lag-measure-score/update-lag-measure-score.component';

@Component({
  selector: 'update-lead-measure-score-preview',
  templateUrl: './update-lead-measure-score-preview.component.html',
  styleUrls: ['./update-lead-measure-score-preview.component.css']
})
export class UpdateLeadMeasureScorePreviewComponent implements OnInit {
  constructor(private modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.computeGraphData();
  }

  @Input()
  dto: UpdateLeadMeasureScorePreviewDto;

  @Input()
  updatedLagMeasure: XLagMeasurePojo;

  @Output()
  updateEvent: EventEmitter<number> = new EventEmitter<number>();

  _barChartData: ChartData<'bar'>;
  _maxY: number;

  update(): void {
    this.updateEvent.emit(this.updatedLagMeasure?.newValue);
    this.close();
  }

  close(): void {
    this.modalRef.hide();
  }

  computeGraphData(): void {
    const labels: string[] = ['lag Measure Score'];
    const newValue = this.updatedLagMeasure?.newValue;
    const benchmarkScores: number[] = [this.dto.nextBenchmark.targetValue];
    const predictedLagScore: number[] = [newValue ?? this.dto.lagMeasureCurrentValue];

    this._maxY = Math.round(Math.max(...benchmarkScores, ...predictedLagScore) * 1.1);
    this._barChartData = {
      labels: [...labels],
      datasets: [
        { data: predictedLagScore, label: 'Current Score' },
        { data: benchmarkScores, label: 'Next Benchmark Score' }
      ]
    };
  }

  benchmarkPassed(): boolean {
    return new Date(this.dto?.nextBenchmark?.dueDate) < new Date();
  }
}
