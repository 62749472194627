import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import moment from 'moment';
import { Router } from '@angular/router';
import {
  Blocker,
  BlockerControllerService,
  BlockerSearchDto,
  GetMeasuresAndBenchmarksForLagRequestParams,
  LagCardPojo,
  LagMeasureControllerService,
  LagMeasurePojo,
  ScoreboardControllerService,
  WigPojo
} from '../../../../../../sdk/ecadence-sdk';
import { TimerObject } from '../../../../pages/intranet/wig-mgt/wig-profile/wig-profile.component';
import { LagMeasureDetailsComponent } from '../../../../pages/intranet/scoreboard/lag-measure-details/lag-measure-details.component';
import { HelperService } from '../../../../services/helper.service';
import { AddLagKpiComponent } from '../add-lag-kpi/add-lag-kpi.component';

@Component({
  selector: 'lag-card',
  templateUrl: './lag-card.component.html',
  styleUrls: ['./lag-card.component.css']
})
export class LagCardComponent implements OnInit {
  @Input()
  lag: LagCardPojo;
  @Input()
  showHistoryLink = false;
  @Input()
  showBlocker = false;
  blockers: BlockerSearchDto[] = [];
  @Input() wigId;

  @Input() minimalDetails = false;

  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  wigPojoEvent: EventEmitter<any> = new EventEmitter<any>();

  // @ViewChild('leadMeasureUpdate')
  // leadMeasureUpdate!: LeadMeasureUpdateComponent;

  timerObject: TimerObject;
  private lagMeasures: LagMeasurePojo[] = [];
  private gettingLagMeasures: boolean;
  private errorMessage: string;

  constructor(
    private modalService: BsModalService,
    public helperService: HelperService,
    private scoreboardControllerService: ScoreboardControllerService,
    private lagMeasureService: LagMeasureControllerService,
    private router: Router,
    private blockerController: BlockerControllerService
  ) {}
  ngOnInit(): void {
    console.log('lag winning status: ', this.lag.winning);
    this.countdown(this.lag.endDate);
    this.fetchAffectedLag(this.lag.id);
  }

  countdown(targetDate: string): void {
    const targetTime = new Date(targetDate).getTime();

    const int = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeRemaining = targetTime - currentTime;

      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      this.timerObject = {
        days,
        hours,
        minutes,
        seconds
      };
    }, 1000);
  }

  showLagDetailCanvas(): void {
    const modal = this.modalService.show(LagMeasureDetailsComponent, {
      initialState: {
        lag: this.lag,
        lagMeasures: this.lagMeasures
      },
      keyboard: false,
      backdrop: 'static',
      class: 'offcanvas offcanvas-end show overflow-y-auto'
    });
    setTimeout(() => {
      this.helperService.removeModalDialogClass();
    }, 100);
    modal.content.closeEvent.subscribe({
      next: (val) => {
        this.closeEvent.emit();
      }
    });
  }

  showLagDetails(): void {
    const params: GetMeasuresAndBenchmarksForLagRequestParams = {
      lagId: this.lag.id
    };
    this.gettingLagMeasures = true;
    this.lagMeasureService.getMeasuresAndBenchmarksForLag(params).subscribe(
      (res) => {
        this.gettingLagMeasures = false;
        if (res !== null) {
          this.lagMeasures = res;
          this.showLagDetailCanvas();
        }
      },
      (error: unknown) => {
        this.gettingLagMeasures = false;

        if (
          error instanceof HttpErrorResponse &&
          error.error &&
          typeof error.error == 'object' &&
          error.error.message
        ) {
          this.errorMessage = error.error.message;
          //this.showErrorMessage(this.errorMessage);
          return;
        }
        this.errorMessage = 'Something went wrong. Please try again later.';
        return;
      }
    );
  }

  fetchAffectedLag(lagId: number): void {
    this.blockerController.getUnassignedBlockerForWig({ lagId: lagId }).subscribe({
      next: (blocks: BlockerSearchDto[]) => {
        this.blockers = blocks;
      }
    });
    // this.scoreboardControllerService
    //   .affectedLags({ lagId })
    //   .subscribe((data: Blocker[]) => {
    //     if (data.length > 0) {
    //       this.showBlocker = true;
    //       this.blockers = data;
    //     }
    //   });
  }

  getTimeLeft(dueDate: string): string {
    const timeLeft: { days: number; hours: number; minutes: number; seconds: number } =
      this.calculateTimeLeft(dueDate);
    return `${timeLeft.days} days, ${timeLeft.hours} hrs, ${timeLeft.minutes} mins, ${timeLeft.seconds} sec`;
  }

  calculateTimeLeft(dueDate: string): {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } {
    const currentDate = moment();
    const endDate = moment(dueDate);

    const duration = moment.duration(endDate.diff(currentDate));

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return {
      days,
      hours,
      minutes,
      seconds
    };
  }

  updateLag(id: number): void {
    this.router.navigate([`lag/${id}/update-lag`], { queryParams: { wig: this.wigId } });
  }

  get completedKpis(): number {
    return this.lag.kpis.filter((x) => x.completed).length;
  }

  addKpi(): void {
    const ref = this.modalService.show(AddLagKpiComponent, {
      initialState: {
        lag: this.lag,
        existingKpis: this.lag.kpis,
        wigId: this.wigId
      },
      ignoreBackdropClick: true,
      class: 'offcanvas offcanvas-end show overflow-y-auto'
    });
    setTimeout(() => {
      this.helperService.removeModalDialogClass();
    }, 100);

    ref.content.wigPojoEvent.subscribe((v) => {
      console.log('IN lag card');
      this.wigPojoEvent.emit(v);
      console.log('emitted in lag card');
    });

    ref.content.closeEvent.subscribe((v) => {
      this.wigPojoEvent.emit(v);
    });
  }

  gotoPlayerProfile(wigId: number, id: number): void {
    this.router.navigate(['/player-profile'], {
      queryParams: { wigId: wigId, lagId: id }
    });
  }
}
