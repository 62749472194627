<div class="light-blue-bg">
  <h6 class="mb-2">{{ _wigSummaryDto?.wigName.substring(0, 58) }}</h6>
  <div class="col-md-4 mb-2">
    <div class="fs-13 fw-medium">
      Overall Score:
      <span class="fw-normal time-stamp-dashboard ms-1">{{ lagScores() }}% </span>
    </div>
  </div>
  <div class="row gy-2">
    <div class="col-md-6">
      <div class="fs-13 fw-medium">
        Due Date:
        <span class="fw-normal time-stamp-dashboard ms-1">{{ _wigSummaryDto?.dueAt | date }}</span>
      </div>
    </div>
    <div class="col-md-6">
      <div class="d-flex align-items-center">
        <div class="fs-13 fw-medium">Customers:</div>
        <div class="ms-2">
          <div class="btn border-0 p-1 position-relative">
            <span class="iconify fs-5" data-icon="mdi:users"></span>
            <span
              class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
            >
              {{ _wigSummaryDto?.customers?.length }}
              <span class="visually-hidden">unread messages</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-4" *ngIf="_wigSummaryDto?.lagPojos.length > 0; else noData">
  <ng-container *ngFor="let lag of _wigSummaryDto?.lagPojos; let i = index">
    <div class="my" *ngIf="completed(lag)">
      <div>
        <div class="d-flex align-items-center">
          <div class="p-1 me-1 rounded-5 miles-completed">
            <span class="iconify fs-3" data-icon="fluent:arrow-sync-checkmark-24-regular"></span>
          </div>
          <p class="mb-0 ms-2 fs-14 fw-normal">
            Lag {{ i + 1 }} |
            <span class="text-success">Completed at : {{ lag['completedAt'] | datetime }}</span>
          </p>
        </div>
      </div>

      <div
        class="ms-3 pt-1"
        style="padding-left: 30px"
        *ngIf="!lineStatus(i, _wigSummaryDto?.lagPojos)"
      >
        <h6>{{ lag['name'] }}</h6>
        <div class="fw-medium" *ngIf="lag['players']?.length > 0">
          Players: <span class="fw-normal">{{ lag['players']?.length }}</span>
        </div>
        <div class="fw-medium" *ngIf="lag['blockers']?.length > 0" class="text-danger">
          Blockers: <span class="fw-normal">{{ lag['blockers']?.length }}</span>
        </div>
      </div>
      <div
        class="vertical-miles-completed ms-3 pt-1"
        style="padding-left: 30px"
        *ngIf="lineStatus(i, _wigSummaryDto?.lagPojos)"
      >
        <h6>{{ lag['name'] }}</h6>
        <div class="fw-medium" *ngIf="lag['players']?.length > 0">
          Players: <span class="fw-normal">{{ lag['players']?.length }}</span>
        </div>
        <div class="fw-medium" *ngIf="lag['blockers']?.length > 0" class="text-danger">
          Blockers: <span class="fw-normal">{{ lag['blockers']?.length }}</span>
        </div>
      </div>
    </div>

    <div class="my" *ngIf="progress(lag) && !completed(lag) && !overDue(lag)">
      <div>
        <div class="d-flex align-items-center">
          <div class="p-1 me-1 rounded-5 miles-ongoing">
            <span class="iconify fs-3" data-icon="fluent:arrow-sync-24-regular"></span>
          </div>
          <p class="mb-0 ms-2 fs-14 fw-normal">
            Lag {{ i + 1 }} |
            <span class="text-warning">Due by : {{ lag['dueDate'] | date }}</span>
          </p>
        </div>
      </div>

      <div
        class="ms-3 pt-1"
        style="padding-left: 30px"
        *ngIf="!lineStatus(i, _wigSummaryDto?.lagPojos)"
      >
        <h6>{{ lag['name'] }}</h6>
        <div class="fw-medium" *ngIf="lag['players']?.length > 0">
          Players: <span class="fw-normal">{{ lag['players']?.length }}</span>
        </div>
        <div class="fw-medium" *ngIf="lag['blockers']?.length > 0" class="text-danger">
          Blockers: <span class="fw-normal">{{ lag['blockers']?.length }}</span>
        </div>
      </div>
      <div
        class="vertical-miles-ongoing ms-3 pt-1"
        style="padding-left: 30px"
        *ngIf="lineStatus(i, _wigSummaryDto?.lagPojos) && lag['completed'] === false"
      >
        <h6>{{ lag['name'] }}</h6>
        <div class="fw-medium" *ngIf="lag['players']?.length > 0">
          Players: <span class="fw-normal">{{ lag['players']?.length }}</span>
        </div>
        <div class="fw-medium" *ngIf="lag['blockers']?.length > 0" class="text-danger">
          Blockers: <span class="fw-normal">{{ lag['blockers']?.length }}</span>
        </div>
      </div>
    </div>

    <div class="my" *ngIf="overDue(lag) && !completed(lag)">
      <div>
        <div class="d-flex align-items-center">
          <div class="p-1 me-1 rounded-5 miles-error">
            <span class="iconify fs-3" data-icon="fluent:arrow-sync-dismiss-24-regular"></span>
          </div>
          <p class="mb-0 ms-2 fs-14 fw-normal">
            Lag {{ i + 1 }} |
            <span class="text-danger">Due Date : {{ lag['dueDate'] | date }}</span>
          </p>
        </div>
      </div>

      <div
        class="ms-3 pt-1"
        style="padding-left: 30px"
        *ngIf="!lineStatus(i, _wigSummaryDto?.lagPojos)"
      >
        <h6>{{ lag['name'] }}</h6>

        <div class="fw-medium" *ngIf="lag['players']?.length > 0">
          Players: <span class="fw-normal">{{ lag['players']?.length }}</span>
        </div>
        <div class="fw-medium" *ngIf="lag['blockers']?.length > 0" class="text-danger">
          Blockers: <span class="fw-normal">{{ lag['blockers']?.length }}</span>
        </div>
      </div>
      <div
        class="vertical-miles-error ms-3 pt-1"
        style="padding-left: 30px"
        *ngIf="lineStatus(i, _wigSummaryDto?.lagPojos) && lag['completed'] === false"
      >
        <h6>{{ lag['name'] }}</h6>
        <div class="fw-medium" *ngIf="lag['players']?.length > 0">
          Players: <span class="fw-normal">{{ lag['players']?.length }}</span>
        </div>
        <div class="fw-medium" *ngIf="lag['blockers']?.length > 0" class="text-danger">
          Blockers: <span class="fw-normal">{{ lag['blockers']?.length }}</span>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #noData>
  <lottie-in-full-table [path]="'assets/lottie/no-data.json'" [message]="'No Lag Available'">
  </lottie-in-full-table>
</ng-template>
