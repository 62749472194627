<div class="h-100" style="border: none">
  <div>
    <div class="text-center mb-3">
      <div class="d-flex justify-content-center align-items-center">
        <canvas
          baseChart
          class="chart"
          [data]="barChartData"
          [options]="_barChartOptions"
          [plugins]="barChartPlugins"
          [type]="barChartType"
          (chartHover)="chartHovered($event)"
          (chartClick)="chartClicked($event)"
        >
        </canvas>

<!--        <canvas-->
<!--          baseChart-->
<!--          class="chart"-->
<!--          [data]="_chartData"-->
<!--          [options]="lineChartOptions"-->
<!--          [type]="lineChartType"-->
<!--          (chartHover)="chartHovered($event)"-->
<!--          (chartClick)="chartClicked($event)"-->
<!--        ></canvas>-->
      </div>
      <div class="d-flex justify-content-center align-items-center align-self-center" *ngIf="bottomTitle">
        {{ bottomTitle }}
      </div>
    </div>
  </div>
</div>
