<div *ngIf="!previewOpen" #updateLeadMeasureScoreTop>
  <div
    class="offcanvas offcanvas-end show overflow-y-auto"
    tabindex="-1"
    id="leadScore"
    aria-labelledby="leadScoreLabel"
  >
    <div class="offcanvas-header pt-4 pb-3">
      <h5 id="leadScoreLabel">Update Lag Measure Score</h5>
      <button
        (click)="close()"
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <form>
      <div class="offcanvas-body">
        <app-alert-message
          *ngIf="xAlertMessage"
          [messageType]="xAlertMessage!.messageType"
          [message]="xAlertMessage!.message"
        >
        </app-alert-message>


        <div class="mb-4 ">
          <div class="fw-bold mb-1">Lead Measures</div>
          <div class="fs-14">Hi, enter your new lag measure score</div>
        </div>

        <div class="mb-4" *ngIf="_lagMeasure">
          <div class="display-box">
            <ng-container>
              <div class="fs-13 fw-bold mb-3">Lag Name</div>
              <div class="row gy-3">

                <div class="fs-12">
                  {{ _lagMeasure.lagName }}
                </div>

              </div>
            </ng-container>

          </div>
        </div>

        <div class="mb-4">
          <ng-container *ngIf="_lagMeasure">
            <div
              class="display-box-dotted"
              style=""
            >
              <div class="row">
                <div class="col-md-8">
                  <div class="mb-3">
                    <div class="form-label fs-15 mb-1">{{ _lagMeasure.maxValue + '  (' +_lagMeasure.unit + ')'}} </div>
                    <div class="fs-15 fw-medium">Current Lag Measure Value: <span
                      class="fw-normal"> {{ _lagMeasure.currentValue }}</span></div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="input-group">
                    <label class="form-label fs-15">Score:</label>
                    <div class="input-group mb-3">
                      <input
                        [(ngModel)]="_lagMeasure.newValue"
                        [ngModelOptions]="{ standalone: true }"
                        type="text"
                        class="form-control p-2"
                        placeholder="20"
                        (change)="formTouched()"
                      />
                      <app-form-error *ngIf="_lagMeasure?.newValue < lagMeasure.currentValue && touched"
                      [message]="'Score can not be less than previous score'">
                      </app-form-error>
                      <app-form-error *ngIf="_lagMeasure?.newValue > lagMeasure.maxValue && touched"
                                      [message]="'Score can not be more than maximum score'">
                      </app-form-error>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

        </div>

        <ng-container *ngIf="xAlertMessage.message">
          <small class="fs-10 text-danger" style="color: red">
            {{xAlertMessage.message}}
          </small>
        </ng-container>
        <button (click)="preview()" class="btn fs-16 btn-primary w-100 mt-4">
          {{ !touched ? 'Skip to Preview' : 'Preview and Save' }}
        </button>
      </div>
    </form>
  </div>
</div>
