import { SessionStoreService } from '../../services/session-store.service';
import { QueryResults } from './query-results';
import { PaginatedSearchHandler } from './paginated-search-handler';
import { SearchFilterSource } from './search-filter-source';

export class PaginatedSearchManager<E, S> {
  _working: boolean;
  private _filter: S;

  private _queryResult: QueryResults<E>;

  private searchHandler: PaginatedSearchHandler<E, S>;
  public filterSource: SearchFilterSource<S>;

  private _page: number;
  private _itemsPerPage = 10;

  private _sessionStoreService: SessionStoreService;

  constructor(searchHandler: PaginatedSearchHandler<E, S>, filterSource?: SearchFilterSource<S>) {
    this.searchHandler = searchHandler;
    this.filterSource = filterSource;
  }

  getQueryResult(): unknown {
    return this._queryResult;
  }

  private set queryResult(queryResult: QueryResults<E>) {
    this._queryResult = queryResult;
    this._page = this.offset / this.itemsPerPage + 1;
  }

  get working(): boolean {
    return this._working;
  }

  get offset(): number {
    if (!this._queryResult) {
      return 0;
    }
    return this._queryResult.offset;
  }

  get list(): E[] {
    return this._queryResult ? this._queryResult.results : [];
  }

  get totalCount(): number {
    if (!this._queryResult) {
      return 0;
    }
    return this._queryResult.total;
  }

  set itemsPerPage(value: number) {
    this._itemsPerPage = value;
  }

  get itemsPerPage(): number {
    return this._itemsPerPage;
  }

  get page(): number {
    return this._page;
  }

  set page(page: number) {
    this._page = page;
    this.goToPage(page);
  }

  set sessionStoreService(sessionStoreService: SessionStoreService) {
    this._sessionStoreService = sessionStoreService;
    if (sessionStoreService) {
      sessionStoreService.getData('searchFilter', location.href).subscribe((filter) => {
        if (!filter) {
          return;
        }
        this._filter = filter;
        if (this.filterSource && this.filterSource['setFilter']) {
          this.filterSource['setFilter'](this._filter);
        }
      });
    }
  }

  public reloadAndShowFirstPage(): void {
    const f: S = this.filterSource && this.filterSource.getFilter();
    if (this._sessionStoreService) {
      this._sessionStoreService.saveData('searchFilter', location.href, f);
    }
    if (typeof f === 'string' || typeof f === 'number') {
      this._filter = f;
    } else if (f) {
      this._filter = Object.assign({}, f);
    }
    this.page = 1;
  }

  private goToPage(page: number): void {
    this._working = true;
    if (this._filter && (typeof this._filter === 'string' || typeof this._filter === 'number')) {
      this.searchHandler.search(page, this._filter).subscribe(
        (queryResult) => {
          this.doneWorking();
          this.queryResult = queryResult;
        },
        () => {
          this.doneWorking();
        }
      );
    } else {
      this.searchHandler.search(page, this.filter).subscribe(
        (queryResult) => {
          this.doneWorking();
          this.queryResult = queryResult;
        },
        () => {
          this.doneWorking();
        }
      );
    }
  }

  get filter(): S {
    return Object.assign({}, this._filter);
  }

  private doneWorking(): void {
    setTimeout(() => {
      this._working = false;
    });
  }
}
