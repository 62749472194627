import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import moment from 'moment/moment';
import { LeadMeasureSearchPojo, PerformanceBenchMarkDto } from '../../../sdk/ecadence-sdk';
import {
  XBenchmarkPojo,
  XCreateLeadMeasureForm
} from '../pages/intranet/leverage-mgt/modals/create-lead-measure-modal/create-lead-measure-modal.component';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  getInvalidFormFields(form: FormGroup): any[] {
    const invalidControls = [];

    for (const controlName in form.controls) {
      const control = form.controls[controlName];
      control.markAsTouched();
      if (control.disabled) continue;
      if (control.invalid) {
        invalidControls.push({
          name: controlName,
          errors: control.errors
        });
      }
    }
    return invalidControls;
  }

  cutText(name: string, chars: number): string {
    if (!name) {
      ('');
    }
    return name?.length > chars ? name.substr(0, chars) + '...' : name;
  }

  getFullName(data: any, cut = false, length = 15): string {
    const fullName = data?.firstName + ' ' + data?.otherNames + ' ' + data?.lastName;
    if (cut) {
      return this.cutText(fullName, length);
    }
    return fullName;
  }

  preventNumberInput(event: KeyboardEvent): void {
    const charCode = typeof event.which == 'undefined' ? event.keyCode : event.which;
    const charStr = String.fromCharCode(charCode);
    if (event?.code?.includes('Numpad')) {
      event?.preventDefault();
    } else if (/\d/.test(charStr)) {
      event?.preventDefault();
    }
  }
  getTodayDate(): string {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();

    return `${year}-${month}-${day}`;
  }

  public removeModalDialogClass(): void {
    const elements = document.getElementsByClassName('modal-dialog');

    if (elements.length > 0) {
      elements[0].classList.remove('modal-dialog');
    }
  }

  public hideOffCanvasClass(): void {
    const elements = document.getElementsByClassName('offcanvas');

    if (elements.length > 0) {
      elements[0].classList.remove('show');
    }
  }

  showOffCanvasClass(): void {
    const elements = document.getElementsByClassName('offcanvas');

    if (elements.length > 0) {
      elements[0].classList.add('show');
    }
  }

  public getXBenchmarkPojo(item: LeadMeasureSearchPojo): XBenchmarkPojo[] {
    return item.benchmarks.map((x) => {
      return {
        dueDate: new Date(x.dueTime),
        dueTime: new Date(x.dueTime),
        predictedLagScore: x.predictedLagScore,
        targetScore: x.target
      };
    });
  }

  public getxCreateLeadMeasureForm(item: LeadMeasureSearchPojo): XCreateLeadMeasureForm {
    return {
      lagId: null,
      lagMeasureId: null,
      measurementUnit: item.measurementUnit,
      desiredDirectionConstant: item.desiredDirection,
      currentValue: item.currentScore,
      users: item.assignedUsers,
      lagPredictionNote: item.predictionNote
    };
  }
  public deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) {
      return true;
    }

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  public onFileSelected(
    event,
    allowedType: string[],
    maxSizeInByte: number,
    control: AbstractControl
  ): void {
    let fileData;
    if (event.target.files && event.target.files[0]) {
      const fileType = event.target.files[0].type;
      const fileName = event.target.files[0].name;
      const fileSize = event.target.files[0].size;

      const reader = new FileReader();

      reader.onload = (event: any): any => {
        fileData = event.target.result.toString();

        const exceededLimit = fileSize > maxSizeInByte;
        console.log(fileSize);
        console.log(maxSizeInByte);
        console.log(exceededLimit);

        if (exceededLimit) {
          console.log('Exceeded');
          control.setErrors({ fileSize: true });
          return;
        } else {
          control.setErrors({ fileSize: null });
          control.updateValueAndValidity();
        }

        const invalidType = allowedType.includes(fileType);
        console.log(allowedType);
        console.log(fileType);
        console.log(invalidType);

        if (!invalidType) {
          console.log('invalid type');
          control.setErrors({ fileType: true });
          return;
        } else {
          control.setErrors({ fileType: null });
          control.updateValueAndValidity();
        }

        const file: XFile = {
          name: fileName,
          data: fileData,
          size: fileSize,
          type: fileType
        };

        control.setValue(file);

        // this.document.certificateOfIncorp = {
        //   name: fileName,
        //   data: fileData
        // };

        return (fileData = event.target.result.toString());
      };
      typeof reader.readAsDataURL(event.target.files[0]);
    }
  }

  public convertToPerformanceBenchMarkDto(
    xBenchmarkPojos: XBenchmarkPojo[]
  ): PerformanceBenchMarkDto[] {
    return xBenchmarkPojos.map((x): PerformanceBenchMarkDto => {
      return {
        predictedLagScore: x.predictedLagScore,
        targetScore: x.targetScore,
        dueDate: moment(
          this.formatDateAndTime(x.dueDate, x.dueTime),
          'MMMM DD, YYYY h:mm A'
        ).format('YYYY-MM-DDTHH:mm')
      };
    });
  }

  formatDateAndTime(date: Date, time: Date): string {
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    const timeFormatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(time);

    return `${formattedDate} ${formattedTime}`;
  }

  public removeTooltip(): void {
    const elements = document.getElementsByClassName('tooltip-inner');

    if (elements.length > 0) {
      elements[0].classList.remove('tooltip-inner');
    }
  }
  public removePopOverBody(): void {
    const elements = document.getElementsByClassName('popover-body');

    if (elements.length > 0) {
      elements[0].classList.remove('popover-body');
    }
  }
}

export interface XFile {
  name: string;
  size: string;
  type: string;
  data: string;
}

export interface XPhoneNumber {
  number?: string;
  internationalNumber?: string;
  nationalNumber?: string;
  countryCode?: string;
  dialCode?: string;
  e164Number?: string;
}
