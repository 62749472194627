import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '../../../shared/shared.module';
import { ScoreboardRoutingModule } from './scoreboard-routing.module';
import { ScoreboardStatusComponent } from './scoreboard-status/scoreboard-status.component';
import { ScoreboardHeaderComponent } from './scoreboard-header/scoreboard-header.component';
import { LagMeasureDetailsComponent } from './lag-measure-details/lag-measure-details.component';
import { WigKpiModalComponent } from './modals/wig-kpi-modal/wig-kpi-modal.component';
import { WigDeadlinesModalComponent } from './modals/wig-deadlines-modal/wig-deadlines-modal.component';
import { LagHistoryComponent } from './lag-history/lag-history.component';
import { RecentCommitmentHolderComponent } from './recent-commitment-holder/recent-commitment-holder.component';
import { CommitmentCardComponent } from './recent-commitment-holder/commitment-card/commitment-card.component';
import { CommitmentSearchComponent } from './commitment-search/commitment-search.component';
import { UpdateLagMeasureScoreComponent } from './update-lag-measure-score/update-lag-measure-score.component';

@NgModule({
  declarations: [
    ScoreboardStatusComponent,
    ScoreboardHeaderComponent,
    LagMeasureDetailsComponent,
    WigKpiModalComponent,
    WigDeadlinesModalComponent,
    LagHistoryComponent,
    RecentCommitmentHolderComponent,
    CommitmentCardComponent,
    CommitmentSearchComponent,
    UpdateLagMeasureScoreComponent
  ],
  exports: [
    ScoreboardHeaderComponent,
    ScoreboardStatusComponent,
    LagMeasureDetailsComponent,
    WigDeadlinesModalComponent,
    RecentCommitmentHolderComponent
  ],
  imports: [
    CommonModule,
    ScoreboardRoutingModule,
    BrowserModule,
    SharedModule,
    FormsModule,
    ProgressbarModule,
    PopoverModule,
    TooltipModule
  ]
})
export class ScoreboardModule {}
