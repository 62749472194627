<!--<div class="p-4 pointer" style="border-radius: 8px; border-left: 2.5px solid #BB7EE3; background: #F3EAFA;" (click)="updateLeadMeasure()">-->
<!--  <h5>Lead Measure Update</h5>-->
<!--  <div class="mb-3">Update Lead Measure</div>-->
<!--  <div class="d-flex text-end ms-auto justify-content-end">-->
<!--    <div class="p-1 me-1 rounded-5 lead-card">-->
<!--      <span class="iconify fs-3" data-icon="fluent:chevron-right-16-regular"></span>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="card pointer h-100" style="border-radius: 8px; border-left: 2.5px solid #BB7EE3; background: #F3EAFA;" (click)="updateLeadMeasure()">
  <div class="card-body">
    <div class="p-2" >
      <h5>Lead Measure Update</h5>
      <div class="mb-3">Be on track. Update lead measure scores </div>
      <div class="d-flex text-end ms-auto justify-content-end">
        <div class="p-1 me-1 rounded-5 lead-card">
          <span class="iconify fs-3" data-icon="fluent:chevron-right-16-regular"></span>
        </div>
      </div>
    </div>
  </div>
</div>
