<div>
  <div>
    <div class="card my-3 p-3" style="max-width: 100%">
      <div class="d-flex justify-content-between">
        <div>
          <p class="small mb-0 fw-bold">Type</p>
          <p class="fs-12">{{ _blockerSearchDto.blockerType | uppercase }}</p>
        </div>
        <div>
          <a class="nav-drop btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="iconify" data-icon="charm:menu-meatball"></span>
          </a>

          <ul class="dropdown-menu shadow-lg">
            <li (click)="viewMore()">
              <a class="dropdown-item pointer"
                ><span
                  class="iconify"
                  data-icon="iwwa:assign"
                  data-width="30"
                  data-height="30"
                ></span>
                Preview
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="my-2">
        <p class="fs-14 mb-0 fw-bold">Description</p>
        <p class="fs-12 text-truncate text-wrap" style="max-width: inherit">{{ _blockerSearchDto.description }}</p>
      </div>

      <div class="d-flex my-2 justify-content-between align-items-end">
        <div class="me-2">
          <p class="fs-12 mb-0 fw-bold">Waste Type</p>
          <small class="fs-12">{{ _blockerSearchDto.wasteType }}</small>
        </div>

        <div>
          <p class="fs-12 mb-0 fw-bold">Affecting Lags</p>
          <small class="fs-12 text-muted">
            <div class="btn p-0 position-relative">
              <span
                class="iconify"
                data-icon="material-symbols:flag"
                data-width="30"
                data-height="30"
              ></span>
              <span
                class="position-absolute top-0 pointer float-end start-100 translate-middle badge rounded-pill bg-danger"
              >
                <span class="visually-hidden">Affecting Lags</span>
                <div
                  [container]="'div'"
                  [placement]="'right bottom'"
                  [tooltip]="restOfUsers"
                  [containerClass]="'overflow-auto'"
                >
                  {{ _blockerSearchDto.lags.length }}
                </div>
              </span>
              <ng-template #restOfUsers>
                <div *ngFor="let rest of _blockerSearchDto.lags">
                  {{ rest }}
                  <br />
                </div>
              </ng-template>
            </div>
          </small>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-end">
        <div class="me-2">
          <p class="fs-12 mb-0 fw-bold">Assigned to</p>
          <small class="fs-12 text-muted">{{ _blockerSearchDto.assignedTo }}</small>
        </div>

        <div>
          <p class="fs-12 mb-0 fw-bold">Resolution Status</p>
          <small
            [ngClass]="{
              'text-danger': _blockerSearchDto.resolutionStatus === 'PENDING',
              'text-warning': _blockerSearchDto.resolutionStatus === 'IN_PROGRESS',
              'text-success': _blockerSearchDto.resolutionStatus === 'RESOLVED'
            }"
          >
            {{ _blockerSearchDto.resolutionStatus | uppercase }}</small
          >
        </div>
      </div>
    </div>
  </div>
</div>
