export class NumberOnly {
  public static allowOnlyNumbers(event: KeyboardEvent): void {
    // Check if the keycode is not a number or a number pad key.
    const keyCode = event.keyCode;
    const isNumber = keyCode >= 48 && keyCode <= 57;
    const isNumberPad = keyCode >= 96 && keyCode <= 105;
    const isBackspace = keyCode === 8;
    const isDelete = keyCode === 46;
    const isEnter = keyCode === 13;

    // If the keycode is not a number or a number pad key or backspace or delete or enter, prevent the default action.
    if (!isNumber && !isNumberPad && !isBackspace && !isDelete && !isEnter) {
      event.preventDefault();
    }
  }

  public static decimal(event: KeyboardEvent): void {
    // Check if the keycode is not a number or a number pad key.
    const keyCode = event.keyCode;
    const isNumber = keyCode >= 48 && keyCode <= 57;
    const isNumberPad = keyCode >= 96 && keyCode <= 105;
    const isBackspace = keyCode === 8;
    const isDelete = keyCode === 46;
    const isEnter = keyCode === 13;
    const isFullPoint = keyCode === 110;
    const isFullPointd = keyCode === 190;

    // If the keycode is not a number or a number pad key or backspace or delete or enter, prevent the default action.
    if (
      !isNumber &&
      !isNumberPad &&
      !isBackspace &&
      !isDelete &&
      !isEnter &&
      !isFullPoint &&
      !isFullPointd
    ) {
      event.preventDefault();
    }
  }

  public static maxDecimalPlace(event: KeyboardEvent, formValue: any, dp: number): void {
    const keyCode = event.keyCode;
    const isBackspace = keyCode === 8;
    const isDelete = keyCode === 46;
    const isEnter = keyCode === 13;
    console.log(formValue);
    if (formValue === null || formValue === undefined) {
      return;
    }

    const valueString: string = formValue.toString() as string;

    if (valueString.includes('.')) {
      const decimalPointIndex = valueString.indexOf('.');
      if (decimalPointIndex === -1) {
        return;
      }

      const decimalPart = valueString.substring(decimalPointIndex + 1);
      if (decimalPart.length >= dp && !isEnter && !isBackspace && !isDelete) {
        event.preventDefault();
      }
    }
  }
}
