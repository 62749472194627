import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import {
  LagCardPojo,
  Wig,
  WigControllerService,
  WigPojo
} from '../../../../../../sdk/ecadence-sdk';

@Component({
  selector: 'lag-profile',
  templateUrl: './lag-profile.component.html',
  styleUrls: ['./lag-profile.component.css'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 1500, noPause: false, showIndicators: true } }
  ]
})
export class LagProfileComponent {
  @Input()
  wigPojo: WigPojo;
  @Input()
  showAddLagButton = true;

  @Output() lagUpdate: EventEmitter<WigPojo> = new EventEmitter<WigPojo>();

  noWrapSlides = false;
  showIndicator = true;
  @Output()
  wigPojoEvent: EventEmitter<WigPojo> = new EventEmitter<WigPojo>();

  constructor(private router: Router, private wigController: WigControllerService) {}
  createNewLag(): void {
    this.router.navigate(['/lag/' + this.wigPojo.id + '/create-lag']);
  }
  emitWigPojo(event): void {
    if (event) {
      this.wigPojo = { ...event };
      this.wigPojoEvent.emit(event);
    } else {
      this.wigController.getWig({ id: this.wigPojo.id }).subscribe({
        next: (val) => {
          this.wigPojo = { ...val };
          this.lagUpdate.emit(this.wigPojo);
          console.log('Emitted in wig pojo');
          console.log('updated wig pojo is ', this.wigPojo.kpis);
        }
      });
    }
  }

  updateEvent(): void {
    console.log('In lag profile');
    this.wigController.getWig({ id: this.wigPojo.id }).subscribe({
      next: (val) => {
        this.wigPojo = { ...val };
        this.lagUpdate.emit(this.wigPojo);
        console.log('Emitted in wig pojo');
        console.log('updated wig pojo is ', this.wigPojo.kpis);
      }
    });
  }
}
