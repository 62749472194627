import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import {
  CadenceControllerService,
  PortalUser,
  ScoreboardControllerService,
  Wig,
  WigControllerService,
  WigPojo
} from '../../../../../sdk/ecadence-sdk';
import { CadenceSchedulerService } from '../../../services/cadence-scheduler.service';
import { RecentCommitmentHolderComponent } from './recent-commitment-holder/recent-commitment-holder.component';

@Component({
  selector: 'scoreboard',
  templateUrl: './scoreboard.component.html',
  styleUrls: ['./scoreboard.component.css']
})
export class ScoreboardComponent implements OnInit {
  scoreboardDataItem: ScoreBoardDataItem;
  cadenceTimerObject: CadenceTimerObject;
  cadenceTimer: number;
  wigPojo: WigPojo;
  cadenceEventId: number;
  loading = true;
  isCadence = false;

  @ViewChild(RecentCommitmentHolderComponent) childComponent: RecentCommitmentHolderComponent;

  constructor(
    private router: Router,
    private scoreboardControllerService: ScoreboardControllerService,
    private cadenceControllerService: CadenceControllerService,
    private activatedRoute: ActivatedRoute,
    private wigController: WigControllerService,
    private cadenceService: CadenceSchedulerService
  ) {
    this.wigPojo = this.router.getCurrentNavigation()?.extras.state['wigPojo'];
    this.isCadence = this.router.getCurrentNavigation()?.extras.state['_isCadence'];
    this.cadenceEventId = this.router.getCurrentNavigation()?.extras.state['cadenceEventId'];
  }

  ngOnInit(): void {
    this.isCadence = this.router.getCurrentNavigation()?.extras.state['_isCadence'];
    if (this.wigPojo) {
      this.fetchWig(this.wigPojo?.id);
    } else {
      this.activatedRoute.paramMap.subscribe((params) => {
        this.fetchWig(Number(params.get('id')));
      });
    }
    // this.fetChWig(this.wigPojo?.id);
    this.getOngoingCadence();
    if (this.getOngoingCadence() == true) {
      this.isCadence = true;
    }
  }

  fetchWig(wigId: number): void {
    this.scoreboardControllerService.scoreboardStatus({ wigId: wigId }).subscribe((data) => {
      this.scoreboardDataItem = {
        attendance: data.totalAttendance,
        cadenceDone: data.totalCadenceDone,
        lastCadence: data.lastCadence,
        player: data.membersInAttendance,
        score: data.score,
        wigDefinition: data.wigDefinition,
        winning: data.winning,
        wigPojo: this.wigPojo,
        leadMeasureBenchmark: data.lagMeasureBenchmark
      };
      this.loading = false;
    });
  }

  getOngoingCadence(): boolean {
    let value = false;

    this.cadenceControllerService.getOngoingCadence({ wigId: this.wigPojo.id }).subscribe(
      (data) => {
        if (data) {
          this.cadenceControllerService.startCadence({ cadenceSchedulePojo: data }).subscribe(
            (value) => {
              this.cadenceEventId = value;
              this.cadenceService.cadenceEventId.next(value);

              localStorage.setItem('cadenceEventId', String(this.cadenceEventId));
            },
            (error: unknown) => {
              console.log(error);
              if (
                error instanceof HttpErrorResponse &&
                error.error &&
                typeof error.error == 'object' &&
                error.error.message &&
                error.status == 400
              ) {
                console.log('An error occurred:', error);
              }
            }
          );
          const currentTime = new Date(); // Get the current date and time
          const fomartedstartTime = moment(data.startTime, 'H:mm');
          const formatedEndTime = moment(data.endTime, 'H.mm');
          const formattedEndTime = moment(data.endTime, 'H:mm');
          const currentTimeInMonment = moment(); // Get the current time

          const diffSeconds = formattedEndTime.diff(currentTimeInMonment, 'seconds');

          this.cadenceTimer = diffSeconds;

          value = true;
          this.isCadence = true;
        } else {
          value = false;
          this.isCadence = false;
        }
      },
      (error: unknown) => {
        // Handle errors
        console.error(error);

        value = false;
        this.isCadence = false;
      }
    );
    return value;
  }

  triggerCreateCommitment(): void {
    this.childComponent.addCommitment();
  }

  updateWigPojo($event: any): void {
    this.fetchWig(this.wigPojo?.id);
  }
}

export interface ScoreBoardDataItem {
  winning: boolean;
  wigDefinition: Wig;
  score: string;
  attendance: number;
  lastCadence: string;
  player: Array<PortalUser>;
  cadenceDone: number;
  wigPojo: WigPojo;
  leadMeasureBenchmark: Array<any>;
}

export interface CadenceTimerObject {
  initialMinutes: number;
  initialSeconds: number;
}
