<ng-template #loader>
  <app-full-page-loader [customText]="'Loading Profile...'"></app-full-page-loader>
</ng-template>

<ng-container *ngIf="!_loading; else loader">
  <div class="my-5" style="max-width: 900px; margin: auto">
    <div class="card" style="border-radius: 8px; border: 1px solid #d6d6d6">
      <div class="d-none d-md-block d-lg-block">
        <div class="" style="height: 180px">
          <img alt="" class="w-100" src="assets/img/profile-bg.png" />
        </div>
        <div class="ps-4" style="margin-top: -5.7rem">
          <div class="img-thumbnail-circle-large mx-1">
            <ng-container *ngIf="!_loadingImage; else spinner">
              <ng-container *ngIf="updatedImage || initialImage; else initialPlaceHolder">
                <!--              <div class="object-fit-contain" style="width: 150px; height: 150px">-->
                <img
                  [src]="updatedImage ? updatedImage : initialImage"
                  alt=""
                  class="rounded-circle img-fluid"
                  style="
                    height: 168px;
                    width: 168px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  "
                />
                <!--              </div>-->
              </ng-container>
            </ng-container>
          </div>
          <ng-container *ngIf="false; else imageNote"></ng-container>
        </div>
      </div>
      <div class="d-block d-md-none pt-3">
        <div class="mb-4 ms-2 mt-3 fw-bold text-primary fs-24">Profile</div>
        <div class="mt-4">
          <div class="mx-auto img-thumbnail-circle-small-screen">
            <ng-container *ngIf="_hasImage; else initialPlaceHolder"></ng-container>
          </div>
          <ng-container *ngIf="false; else imageNote"></ng-container>
        </div>
      </div>

      <div class="px-4 my-md-5 my-4 py-md-2">
        <app-alert-message
          *ngIf="xAlertMessage"
          [messageType]="xAlertMessage!.messageType"
          [message]="xAlertMessage!.message"
        >
        </app-alert-message>
        <form [formGroup]="_form">
          <div class="row g-3">
            <div class="col-md-6">
              <div class="">
                <label aria-required="true" class="form-label" for="name"> Full Name </label>
                <input [formControlName]="'name'" class="form-control" id="name" type="text" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="">
                <label aria-required="true" class="form-label" for="emailAddress">
                  Email Address
                </label>
                <input
                  [formControlName]="'emailAddress'"
                  class="form-control"
                  id="emailAddress"
                  type="text"
                />
              </div>
            </div>
            <div class="col-md-6">
              <label aria-required="true" class="form-label" for="playerRole"> Role </label>

              <input
                type="text"
                id="playerRole"
                class="form-control"
                autocomplete="off"
                formControlName="playerRole"
                [typeahead]="organizationRoles"
                [typeaheadAsync]="true"
                [placeholder]="'Enter or Select Role'"
              />
            </div>
            <div class="col-md-6">
              <label aria-required="true" class="form-label" for="phoneNumber">
                Phone Number
              </label>
              <div>
                <ngx-intl-tel-input
                  [cssClass]="'form-control w-100'"
                  [customPlaceholder]="'9103452345'"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [id]="'phoneNumber'"
                  [maxLength]="15"
                  [phoneValidation]="true"
                  [preferredCountries]="[this.countryAlpha]"
                  [searchCountryFlag]="true"
                  [selectFirstCountry]="true"
                  [selectedCountryISO]="selectedCountryISO"
                  formControlName="phoneNumber"
                  id="phoneNumber"
                ></ngx-intl-tel-input>
              </div>
              <error-message
                [controlName]="'phoneNumber'"
                [fontSize]="10"
                [form]="_form"
                [singularError]="true"
              >
              </error-message>
            </div>
            <div class="col-md-6">
              <label aria-required="true" class="form-label" for="linkedInUrl">
                LinkedIn url
              </label>
              <input
                [formControlName]="'linkedInUrl'"
                class="form-control"
                id="linkedInUrl"
                placeholder="Enter LinkedIn Url"
                type="text"
              />
              <error-message
                [controlName]="'linkedInUrl'"
                [fontSize]="10"
                [form]="_form"
                [singularError]="true"
              >
              </error-message>
            </div>
          </div>

          <file-upload
            (onErrorEmitter)="showMessage($event, 'danger')"
            [cropperAspectRatio]="16 / 9"
            [cropperMaxHeight]="300"
            [cropperMaxWidth]="300"
            [hidden]="true"
            [maintainAspectRatio]="false"
            [maxFileSizeInKB]="1024 * _maxFileLimit"
            formControlName="image"
            id="imageFormField"
          ></file-upload>
        </form>
      </div>
      <div class="text-end mb-5 mt-2 pe-4">
        <app-button-with-loader
          (buttonClick)="updateProfile()"
          [buttonType]="'button'"
          [classText]="'btn btn-primary px-4'"
          [idleStateText]="'Save Update'"
          [isProcessing]="_updating"
          [workingStateText]="'Updating...'"
        >
        </app-button-with-loader>
      </div>
    </div>
  </div>

</ng-container>

<ng-template #initialPlaceHolder>
  <span
    style="
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-weight: 500;
    "
    > {{userInitial}}</span
  >
</ng-template>

<ng-template #imageNote>
  <div
    [ngClass]="{
      'justify-content-center': _textPosition === 'CENTER',
      'justify-content-start': _textPosition === 'LEFT'
    }"
    class="d-flex"
  >
    <div class="mt-3 text-center" style="max-width: 190px">
      <a (click)="changeProfileImage($event)" class="text-primary fw-medium fs-16 pointer"
        >Tap to Change image</a
      >
      <div class="text-muted mt-1" style="font-size: 13px; line-height: normal">
        File format must only be PNG or JPG. Max 1MB in size.
      </div>
    </div>
  </div>
</ng-template>

<ng-template #spinner>
  <div
    class="d-flex justify-content-center align-items-center"
    *ngIf="_loadingImage"
    style="
      height: 168px;
      width: 168px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    "
  >
    <span
      class="iconify"
      data-icon="eos-icons:loading"
      style="color: #6610ff"
      data-rotate="90deg"
    ></span>
  </div>
</ng-template>
