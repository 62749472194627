<div class="mb-3">
  <ng-container *ngIf="commitment.targetScore !== null">
    <div>
      <div
        class="d-flex justify-content-between"
        [ngClass]="{
          'commit-header-success': commitment.commitmentCompletionStatus === 'COMPLETED',
          'commit-header': commitment.commitmentCompletionStatus !== 'COMPLETED'
        }"
      >
        <!-- todo DEV NOTE: -->
        <!-- Change commit-header to commit-header-success if the task has been marked as done -->

        <div class="fs-13">{{ commitment.player }}</div>
        <div class="d-flex align-items-center">
          <div class="fs-11">{{ commitment.createdAt | shortestDate }}</div>
          <div
            class="ms-2"
            *ngIf="commitment.cancelled"
            [tooltip]="'Cancelled because ' + commitment.cancellationNote"
          >
            <span class="iconify" data-icon="material-symbols:cancel" style="color: red"></span>
          </div>
          <div *ngIf="commitment.commitmentCompletionStatus === 'COMPLETED'">
            <span
              class="iconify text-success ms-2"
              data-icon="material-symbols:check-circle"
              style="color: #00913e"
            ></span>
          </div>
          <div
            class="ms-2 nav-drop"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            *ngIf="commitment.canMarkAsDone"
          >
            <span class="iconify" data-icon="ant-design:more-outlined"></span>
            <ul class="dropdown-menu py-2">
              <li *ngIf="commitment.commitmentCompletionStatus === 'NOT_COMPLETED'">
                <a
                  (click)="
                    actionConfirmationModal(
                      'Mark commitment as done',
                      'markCommitmentAsDone',
                      commitment.commitmentId
                    )
                  "
                  class="dropdown-item cursor-pointer"
                  role="button"
                >
                  <span
                    class="iconify text-success fs-18"
                    data-icon="material-symbols:check-circle"
                  ></span>
                  Mark as done</a
                >
              </li>
              <li *ngIf="commitment.commitmentCompletionStatus === 'COMPLETED'">
                <a
                  (click)="
                    actionConfirmationModal(
                      'Mark commitment as not done',
                      'markCommitmentAsNotdone',
                      commitment.commitmentId
                    )
                  "
                  class="dropdown-item cursor-pointer"
                  role="button"
                >
                  <span
                    class="iconify text-success fs-18"
                    data-icon="material-symbols:check-circle"
                  ></span>
                  Mark as not done</a
                >
              </li>
              <ng-container *ngIf="!commitment.cancelled && commitment.canDeleteCommitment">
                <a
                  (click)="deleteCommitment(commitment)"
                  class="dropdown-item cursor-pointer"
                  role="button"
                >
                  <span
                    class="iconify"
                    data-icon="material-symbols:delete"
                    style="color: #a10505"
                  ></span>
                  Delete</a
                >
              </ng-container>
            </ul>
          </div>
          <!-- DEV NOTE
        Add this icon when task has been marked as done -->
          <!-- <div class="ms-2"><span class="iconify text-success fs-18" data-icon="material-symbols:check-circle"></span></div> -->
        </div>
      </div>
      <div class="commit-body">
        <!--        <div class="text-end"><span class="iconify  fs-16" style="color: #35016C" data-icon="material-symbols:edit"></span></div>-->
        <div class="mb-2">
          <div class="fw-bold">
            Lag Measure ({{ helperService.cutText(commitment.lagMeasure, 50) }}):
          </div>
          <div>{{ commitment.lagMeasureScore }}</div>
        </div>
        <div class="mb-2">
          <div class="fw-bold">
            Lead Measure ({{ helperService.cutText(commitment.leadMeasure, 50) }}):
          </div>
          <div>{{ commitment.leadMeasureScore | number }}</div>
        </div>
        <div class="row gy-2 mb-2">
          <div class="col-md-6">
            <div class="mb-2">
              <div class="fw-bold">Target Score Movement:</div>
              <div>{{ commitment.targetScore }}</div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-2">
              <div class="fw-bold">Due Date:</div>
              <div>{{ commitment.dueDate | shortestDate }}</div>
            </div>
          </div>

          <!--        <div class="col-md-4">-->
          <!--          <div class="mb-2">-->
          <!--            <div class="fw-bold">Due Time:</div>-->
          <!--            <div>{{ commitment.commitmentDueDate | datetime }}</div>-->
          <!--          </div>-->
          <!--        </div>-->
        </div>
        <div class="mb-2">
          <div class="fw-bold">Lead Measure Action:</div>
          <div class="text-wrap" style="width: 100%">{{ commitment.leadMeasureAction }}</div>
        </div>
      </div>
    </div>
  </ng-container>
  <!--  <ng-template #noCommitments>-->
  <!--    <span>No Commitments</span>-->
  <!--  </ng-template>-->
</div>
