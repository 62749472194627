import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'custom-text-area',
  templateUrl: './custom-text-area.component.html',
  styleUrls: ['./custom-text-area.component.css']
})
export class CustomTextAreaComponent {
  private _maximumTextLength: number;

  private _placeholder = 'Enter Text Here';

  private _styleClass: string;
  private _form: FormGroup;
  private _controlName: string;

  private _label: string;

  get label(): string {
    return this._label;
  }

  @Input() set label(value: string) {
    this._label = value;
  }

  get maximumTextLength(): number {
    return this._maximumTextLength;
  }

  @Input() set maximumTextLength(value: number) {
    this._maximumTextLength = value;
  }

  get placeholder(): string {
    return this._placeholder;
  }

  @Input() set placeholder(value: string) {
    this._placeholder = value;
  }

  get styleClass(): string {
    return this._styleClass;
  }

  @Input() set styleClass(value: string) {
    this._styleClass = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  @Input() set form(value: FormGroup) {
    this._form = value;
  }

  get controlName(): string {
    return this._controlName;
  }

  @Input() set controlName(value: string) {
    this._controlName = value;
  }

  get formValue(): string {
    return this._form?.get(this._controlName)?.value;
  }
}
