import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { User } from '../../models/user/user.model';
import { AuthenticationService } from '../../services/authentication.service';
import {
  AccountMembershipPojo,
  AppNotificationDto,
  CadenceControllerService,
  DashboardControllerService,
  DashboardStatisticPojo,
  FileControllerService,
  NotificationControllerService,
  PlayerProfilePojo,
  PortalAccount,
  UserControllerService,
  UserProfileControllerService
} from '../../../../sdk/ecadence-sdk';
import { UserAccount } from '../../models/account/user-account.model';
import { AssignBlockerModalComponent } from '../../pages/intranet/leverage-mgt/modals/assign-blocker-modal/assign-blocker-modal.component';
import { ReviewLeadMeasureSuggestionComponent } from '../../pages/intranet/leverage-mgt/modals/review-lead-measure-suggestion-modal/review-lead-measure-suggestion-modal.component';
import PermissionsEnum = AccountMembershipPojo.PermissionsEnum;

@Component({
  selector: 'nav-template',
  templateUrl: './nav-template.component.html',
  styleUrls: ['./nav-template.component.css']
})
export class NavTemplateComponent implements OnInit {
  user: User;
  userAccounts: PortalAccount[] = [];
  currentAccount: UserAccount;

  @Input()
  showWelcome = false;
  salutation: string;
  dashboardStatisticsData: DashboardStatisticPojo;
  teamMembers: number;
  notifications: AppNotificationDto[];
  notificationCount = 0;

  _loadingImage = true;
  _profilePicture: string;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserControllerService,
    private dashboardControllerService: DashboardControllerService,
    private notificationControllerService: NotificationControllerService,
    private router: Router,
    private profileController: UserProfileControllerService,
    private route: ActivatedRoute,
    private fileController: FileControllerService,
    private modalService: BsModalService,
    private cadenceController: CadenceControllerService
  ) {}

  ngOnInit(): void {
    this.getImageDataURI();
    const currentPath = this.router.url;

    if (currentPath === '/dashboard') {
      this.showWelcome = true;
    } else {
      this.showWelcome = false;
    }

    //this.getDashboardData();
    this.getCurrentDate();
    this.getPendingNotifications();
    this.getPortalUser();
    this.getUserAccounts();
  }

  login(): void {
    this.authenticationService.login({
      redirectUri: `${window.location.origin}/accounts`
    });
  }

  logout(): void {
    this.authenticationService.logout(`${window.location.origin}`).subscribe();
  }

  getDashboardData(): void {
    this.dashboardControllerService
      .dashboardStatistics()
      .subscribe((data: DashboardStatisticPojo) => {
        this.dashboardStatisticsData = data;
      });
  }

  getTeamMembers(): void {
    this.dashboardControllerService.getTotalTeamMembers().subscribe(
      (res) => {
        this.teamMembers = res;
      },
      (error: unknown) => {
        console.log('error ===>', error);
      }
    );
  }

  getCurrentDate(): void {
    const currentDateTime = new Date();
    const hour = currentDateTime.getHours();
    if (hour > 0 && hour <= 11) {
      this.salutation = 'Good Morning';
    } else if (hour > 11 && hour <= 15) {
      this.salutation = 'Good Afternoon';
    } else if (hour > 15 && hour <= 24) {
      this.salutation = 'Good Evening';
    }
  }

  getPendingNotifications(): void {
    this.notificationControllerService
      .getAllNotifications({ limit: 20, deliveryStatus: ['PENDING', 'SENT'] })
      .subscribe((res) => {
        this.notifications = res.results;
        this.notificationCount = res.total;
        setTimeout(this.getPendingNotifications, 120000);
      });
  }

  getPortalUser(): void {
    this.authenticationService.getUser().subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }

  getUserAccounts(): void {
    this.userService.getUserAccounts().subscribe((v) => {
      this.userAccounts = v;

      this.currentAccount = this.authenticationService.getCurrentAccount();
    });
  }

  toManageBlocker(): void {
    this.router.navigate(['leverages/blockers']);
  }

  toPlayerDailyProfile(): void {
    this.router.navigate(['player-profile']);
  }

  canViewUsers(): boolean {
    return this.authenticationService.hasAnyPermission([
      PermissionsEnum.VIEW_USER,
      PermissionsEnum.CREATE_USER
    ]);
  }

  canViewWig(): boolean {
    return this.authenticationService.hasAnyPermission([
      PermissionsEnum.VIEW_WIG,
      PermissionsEnum.CREATE_WIG
    ]);
  }

  // showNotifications(): void {
  //   this.bsModalService.show(NotificationToastComponent, {
  //     class: 'modal-dialog-centered'
  //   });
  // }

  toDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  toUsers(): void {
    this.router.navigate(['/users']);
  }

  toWig(): void {
    this.router.navigate(['/wig']);
  }

  toMasterRecord(): void {
    this.router.navigate(['/master-record']);
  }

  // markNotificationAsRead(id: number, type: AppNotificationDto.TypeEnum): void {
  //   this.notificationControllerService.updateNotificationRecipientStatus({ id: id }).subscribe(
  //     (res) => {
  //       if (type == 'CADENCE_REMINDER') {
  //         // this.router.navigate(['/scoreboard']);
  //         this.getPendingNotifications();
  //       }
  //       if (type == 'CADENCE_SETUP') {
  //         // this.router.navigate(['/scoreboard']);
  //         this.getPendingNotifications();
  //       }
  //       if (type == 'CADENCE_SCHEDULE_UPDATE') {
  //         // this.router.navigate(['/scoreboard']);
  //         this.getPendingNotifications();
  //       }
  //       if (type == 'CADENCE_COMPLETION') {
  //         // this.router.navigate(['/scoreboard']);
  //         this.getPendingNotifications();
  //       }
  //       if (type == 'LEAD_MEASURE_BENCHMARK_REMINDER') {
  //         // this.router.navigate(['/scoreboard']);
  //         this.getPendingNotifications();
  //       }
  //       if (type == 'BLOCKER_ASSIGNMENT') {
  //         // this.router.navigate(['/scoreboard']);
  //         this.getPendingNotifications();
  //       }
  //       if (type == 'BLOCKER_RESOLUTION') {
  //         // this.router.navigate(['/scoreboard']);
  //         this.getPendingNotifications();
  //       }
  //       if (type == 'NEW_BLOCKER') {
  //         const assignBlockerModal: BsModalRef<AssignBlockerModalComponent> =
  //           this.modalService.show(AssignBlockerModalComponent, {
  //             initialState: {
  //               blockerId: item
  //             },
  //             keyboard: false,
  //             backdrop: 'static',
  //             class: ''
  //           });
  //         this.getPendingNotifications();
  //       }
  //       if (type == 'LEAD_MEASURE_ASSIGNMENT') {
  //         // this.router.navigate(['/leverage/suggestion/' +id])
  //         this.getPendingNotifications();
  //       }
  //       if (type == 'NEW_LEAD_MEASURE_SUGGESTION') {
  //         // this.router.navigate(['/leverage/suggestion/' +id])
  //         this.getPendingNotifications();
  //       }
  //     },
  //     (error: unknown) => {
  //       console.log('error reading notification', error);
  //     }
  //   );
  // }
  markNotificationAsRead(notification: AppNotificationDto): void {
    if (
      notification.type == 'BLOCKER_ASSIGNMENT' ||
      notification.type == 'BLOCKER_RESOLUTION' ||
      notification.type == 'NEW_BLOCKER'
    ) {
      const assignBlockerModal: BsModalRef<AssignBlockerModalComponent> = this.modalService.show(
        AssignBlockerModalComponent,
        {
          initialState: {
            blockerId: notification.entityId
          },
          keyboard: false,
          backdrop: 'static',
          class: ''
        }
      );
    }

    if (notification.type == 'NEW_LEAD_MEASURE_SUGGESTION') {
      const suggestionModal: BsModalRef<ReviewLeadMeasureSuggestionComponent> =
        this.modalService.show(ReviewLeadMeasureSuggestionComponent, {
          initialState: {
            leadMeasureId: notification.entityId
          },
          keyboard: false,
          backdrop: 'static',
          class: 'modal-lg'
        });
    }

    if (notification.type == 'CADENCE_REMINDER' || notification.type == 'CADENCE_COMPLETION') {
      this.cadenceController
        .getCadenceScheduleItemWigId({
          id: notification.entityId
        })
        .subscribe({
          next: (value) => {
            this.router.navigate([`wig/${value.id}/scoreboard`]);
          }
        });
    }

    this.notificationControllerService
      .updateNotificationRecipientStatus({ id: notification.id })
      .subscribe(
        (res) => {
          this.getPendingNotifications();
        },
        (error: unknown) => {
          console.log('error reading notification', error);
        }
      );
  }

  viewMore(notification: AppNotificationDto): void {
    if (
      notification.type == 'BLOCKER_ASSIGNMENT' ||
      notification.type == 'BLOCKER_RESOLUTION' ||
      notification.type == 'NEW_BLOCKER'
    ) {
      const assignBlockerModal: BsModalRef<AssignBlockerModalComponent> = this.modalService.show(
        AssignBlockerModalComponent,
        {
          initialState: {
            blockerId: notification.entityId
          },
          keyboard: false,
          backdrop: 'static',
          class: ''
        }
      );
    }

    if (notification.type == 'NEW_LEAD_MEASURE_SUGGESTION') {
      const suggestionModal: BsModalRef<ReviewLeadMeasureSuggestionComponent> =
        this.modalService.show(ReviewLeadMeasureSuggestionComponent, {
          initialState: {
            leadMeasureId: notification.entityId
          },
          keyboard: false,
          backdrop: 'static',
          class: 'modal-lg'
        });
    }
    if (
      notification.type == 'LEAD_MEASURE_BENCHMARK_REMINDER' ||
      notification.type == 'LEAD_MEASURE_ASSIGNMENT'
    ) {
      console.log('Is cadence');
    }

    if (notification.type == 'CADENCE_REMINDER' || notification.type == 'CADENCE_COMPLETION') {
      this.cadenceController
        .getCadenceScheduleItemWigId({
          id: notification.entityId
        })
        .subscribe({
          next: (value) => {
            this.router.navigate([`wig/${value.id}/scoreboard`]);
          }
        });
    }

    this.notificationControllerService
      .updateNotificationRecipientStatus({ id: notification.id })
      .subscribe(
        (res) => {
          this.getPendingNotifications();
        },
        (error: unknown) => {
          console.log('error reading notification', error);
        }
      );
  }

  readMore(ref: HTMLParagraphElement): void {
    if (ref.classList.contains('text-truncate')) ref.classList.remove('text-truncate');
    else ref.classList.add('text-truncate');
  }

  getText(ref: HTMLParagraphElement): string {
    if (ref.classList.contains('text-truncate')) return 'Read more';
    else return 'Show less';
  }

  getImageDataURI(): void {
    this._loadingImage = true;

    this.profileController
      .getUserProfile()
      .subscribe({
        next: (value: PlayerProfilePojo) => {
          this.fileController
            .getDataURI({ id: value.profileImage?.id })
            .subscribe({
              next: (value) => {
                this._profilePicture = value.code;
              }
            })
            .add(() => (this._loadingImage = false));
        }
      })
      .add(() => {
        this._loadingImage = false;
      });
  }
}
