import { Component, Input } from '@angular/core';

@Component({
  selector: 'lottie-in-full-table',
  templateUrl: './lottie-in-full-table.component.html',
  styleUrls: ['./lottie-in-full-table.component.css']
})
export class LottieInFullTableComponent {
  @Input() path: string;
  @Input() message: string;
  @Input() classForText: string;
}
