<div>
  <div class="card rounded-0 p-3">
    <div class="d-flex justify-content-between align-items-center">
      <p class="fs-20 fw-bold text-primary pb-3">Players</p>
      <div>
        <button
          (click)="viewUsersWithoutWig()"
          class="p-2 position-relative p-2 btn btn-outline-primary"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span class="iconify" data-icon="material-symbols:person"></span>
          Players without Wig
          <span
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
          >
            {{ usersWithoutWig?.length }}
            <!--    <span class="visually-hidden">Users without WIG</span>--></span
          >
        </button>

        <user-without-wig-card [showUsers]="hideUsersWithoutAssignedWig"></user-without-wig-card>
      </div>
    </div>
  </div>

  <div>
    <div class="scrollable-container">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="fs-12 text-muted">SN</th>
            <th scope="col" class="fs-12 text-muted">Player Name</th>
            <th scope="col" class="fs-12 text-muted">Assigned Lag Measures</th>
            <th scope="col" class="fs-12 text-muted">Assigned Lead Measures</th>
            <th scope="col" class="fs-12 text-muted">Entries Done</th>
            <th scope="col" class="text-danger fs-12">Entries Missed</th>
          </tr>
        </thead>
        <ng-container>
          <tbody>
            <tr *ngFor="let summary of searchManager?.list; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td class="small">
                <div
                  style="display: inline-block"
                  class="fw-bold pointer"
                  [ngbPopover]="playerDetailsPopUp"
                  triggers="click:click"
                  [autoClose]="true"
                  placement="right"
                  (click)="updateSelectedId(summary.playerId)"
                >
                  {{ summary.playerName }}
                </div>
                <p class="small opacity-75">
                  <span class="text-danger"> {{ summary.entriesDone }} </span> entries done |
                  <span class="text-danger">{{ summary.entriesMissed }} </span> entries missed
                </p>
              </td>
              <td>
                <div class="justify-content-between align-items-end d-flex col-6">
                  <div>
                    <button
                      type="button"
                      (click)="gotoAssignedLagMeasures(summary.playerId, summary.playerName)"
                      class="btn border-0 p-1 position-relative"
                    >
                      <span class="iconify fs-5" data-icon="ph:flag-bold"></span>
                      <span
                        class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                      >
                        {{ summary.assignedLagMeasures }} lag
                        <span class="visually-hidden">unread messages</span>
                      </span>
                    </button>
                  </div>
                  <!--                      <div>-->
                  <!--                        <span data-icon="ph:info-light" class="iconify fs-5"></span>-->
                  <!--                      </div>-->
                </div>
              </td>
              <td>
                <div class="justify-content-between align-items-end d-flex col-6">
                  <div>
                    <button
                      type="button"
                      (click)="gotoAssignedLeadMeasures(summary.playerId, summary.playerName)"
                      class="btn border-0 p-1 position-relative"
                      data-bs-toggle="modal"
                      data-bs-target="#assignedLead"
                    >
                      <span
                        class="iconify fs-5"
                        data-icon="material-symbols:leaderboard-outline-rounded"
                      ></span>
                      <span
                        class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                      >
                        {{ summary.assignedLeadMeasures }} lead
                        <span class="visually-hidden">unread messages</span>
                      </span>
                    </button>
                  </div>
                  <!--                      <div>-->
                  <!--                        <span data-icon="ph:info-light" class="iconify fs-5"></span>-->
                  <!--                      </div>-->
                </div>
              </td>
              <td class="small text-muted">{{ summary.entriesDone }} done</td>
              <td class="small">
                <p class="text-muted">{{ summary.entriesMissed }} missed</p>
              </td>
            </tr>
          </tbody>
        </ng-container>
        <ng-container *ngIf="searchManager?.list.length === 0">
          <tbody>
            <tr>
              <td colspan="8">
                <div class="d-flex justify-content-center gap-2 my-5 py-5">
                  <h3>No Records</h3>
                </div>
              </td>
            </tr>
          </tbody>
        </ng-container>
      </table>
      <!--            <div *ngIf="searchManager.list.length > 0" class="row py-2">-->
      <!--              <div class="col-12 d-flex justify-content-end">-->
      <!--                <pagination-->
      <!--                  [(ngModel)]="searchManager.page"-->
      <!--                  [boundaryLinks]="true"-->
      <!--                  [itemsPerPage]="searchManager.itemsPerPage"-->
      <!--                  [maxSize]="maxPageSize"-->
      <!--                  [rotate]="true"-->
      <!--                  [totalItems]="searchManager.totalCount"-->
      <!--                  firstText="&laquo;"-->
      <!--                  lastText="&raquo;"-->
      <!--                  nextText="&rsaquo;"-->
      <!--                  previousText="&lsaquo;"-->
      <!--                ></pagination>-->
      <!--              </div>-->
      <!--            </div>-->
    </div>
  </div>
</div>

<!--<div-->
<!--  class="modal fade"-->
<!--  id="assignedLead"-->
<!--  tabindex="-1"-->
<!--  aria-labelledby="exampleModalLabel"-->
<!--  aria-hidden="true"-->
<!--&gt;-->
<!--  <div class="modal-dialog modal-dialog-centered modal-lg">-->
<!--    <div class="modal-content">-->
<!--      <div class="modal-header">-->
<!--        <h1 class="modal-title fs-5" id="exampleModalLabel">Lead Measures : Ogechi Azurunwa</h1>-->
<!--        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
<!--      </div>-->
<!--      <div class="modal-body">-->
<!--        <div>-->
<!--          <div class="table-responsive">-->
<!--            <table class="table">-->
<!--              <thead>-->
<!--              <tr>-->
<!--                <th scope="col" class="fs-12 text-muted">Lag Measures</th>-->
<!--                <th scope="col" class="fs-12 text-muted">Lead Measures</th>-->
<!--                <th scope="col" class="fs-12 text-muted">Lead Measure Score</th>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr>-->
<!--                <td class="fs-12 text-muted">Implement Dashboard</td>-->
<!--                <td class="fs-12 text-muted">Write 20 lines of code everyday</td>-->
<!--                <td class="fs-12 text-muted">20/98</td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<ng-template #playerDetailsPopUp style="z-index: 999">


  <player-profile-card [playerId]="this.selectPlayerId"> </player-profile-card>
</ng-template>
