import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  LeadMeasureSearchPojo,
  LeverageControllerService
} from '../../../../../../../sdk/ecadence-sdk';
import { XAlertMessage } from '../../../../../shared/components/alert-message/alert-message.component';
import { FormConstants } from '../../../../../shared/constants/form-constants';
import { NotBlankValidator } from '../../../../../shared/validators/not-blank.validator';
import { ConfirmDialogComponent } from '../../../../../shared/components/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'review-lead-measure-suggestion-modal',
  templateUrl: './review-lead-measure-suggestion-modal.component.html',
  styleUrls: ['./review-lead-measure-suggestion-modal.component.css']
})
export class ReviewLeadMeasureSuggestionComponent implements OnInit {
  _leadMeasureSearchPojo: LeadMeasureSearchPojo;

  _isApproving = false;
  _isDisapproving = false;

  xAlertMessage: XAlertMessage = {
    message: undefined,
    messageType: 'info'
  };

  _form: FormGroup;
  _showReview = true;

  _leadMeasureId: number;
  _loading = false;

  @Input()
  set leadMeasureId(value: number) {
    this._leadMeasureId = value;
    this._loading = true;
    this.leadController
      .searchLeadMeasures({
        leadMeasureSearchFilter: {
          leadMeasureId: value
        }
      })
      .subscribe({
        next: (value) => {
          if (!value.empty && value.results?.length > 0) {
            this.computeLeadMeasurePojo(value.results[0]);
          }
        },
        error: (err: unknown) => {
          console.log('error');
        }
      })
      .add(() => {
        this._loading = false;
      });
  }

  @Input()
  set leadMeasureSearchPojo(value: LeadMeasureSearchPojo) {
    this.computeLeadMeasurePojo(value);
  }

  ngOnInit(): void {
    this._form = this.formBuilder.group({
      reason: [
        '',
        [
          Validators.required,
          NotBlankValidator.validate,
          Validators.minLength(FormConstants.NAME_MINIMUM_LENGTH),
          Validators.maxLength(FormConstants.SHORT_NOTE_MAXIMUM_LENGTH)
        ]
      ]
    });
  }

  constructor(
    private modalRef: BsModalRef,
    private leadController: LeverageControllerService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder
  ) {}

  disapprove(): void {
    this._form.markAllAsTouched();

    if (this._form.invalid) {
      return;
    }
    this._isDisapproving = true;
    this.leadController
      .disapproveLeadMeasureSuggestion({
        id: this._leadMeasureSearchPojo.id,
        reasonForDisapproval: this._form.get('reason').value
      })
      .subscribe({
        next: () => {
          this._leadMeasureSearchPojo.reviewStatus = 'DISAPPROVED';
          this._leadMeasureSearchPojo.reviewNote = this._form.get('reason').value;
          this.xAlertMessage.messageType = 'warning';
          this.xAlertMessage.message = 'Lead measure suggestion disapproved';
        },
        error: (err: unknown) => {
          this.xAlertMessage.messageType = 'danger';
          this.xAlertMessage.message =
            err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
              ? err.error.message
              : 'Unable to disapprove lead measure suggestion';
        }
      })
      .add(() => {
        this._isDisapproving = false;
      });
  }

  approve(): void {
    this._showReview = false;
    this._isApproving = true;
    const message = 'Are you sure you want to approve this lead measure suggestion?';
    const bsModalRef2 = this.modalService.show(ConfirmDialogComponent, {
      initialState: {
        body: message,
        positiveButtonText: 'Yes',
        negativeButtonText: 'No'
      },
      class: 'modal-md modal-dialog-centered',
      keyboard: false,
      backdrop: 'static'
    });

    bsModalRef2.content.cancel.subscribe((result: any) => {
      this._showReview = true;
      this._isApproving = false;
    });

    bsModalRef2.content.confirm.subscribe((result: any) => {
      bsModalRef2.hide();
      this._showReview = true;
      this.leadController
        .approveLeadMeasureSuggestion({
          id: this._leadMeasureSearchPojo.id
        })
        .subscribe({
          next: () => {
            this._leadMeasureSearchPojo.reviewStatus = 'APPROVED';
            this.xAlertMessage.messageType = 'success';
            this.xAlertMessage.message = 'Lead measure suggestion approved';
          },
          error: (err: unknown) => {
            this.xAlertMessage.messageType = 'danger';
            this.xAlertMessage.message =
              err instanceof HttpErrorResponse && err.error?.message && err.error.code !== 500
                ? err.error.message
                : 'Unable to approve lead measure suggestion';
          }
        })
        .add(() => {
          this._isApproving = false;
        });
    });
  }

  closeModal(): void {
    this.modalRef.hide();
  }

  computeLeadMeasurePojo(value: LeadMeasureSearchPojo): void {
    this._leadMeasureSearchPojo = value;
  }

  protected readonly FormConstants = FormConstants;
}
