import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ScoreBoardDataItem } from '../scoreboard.component';
import { WigControllerService, WigKpiPojo } from '../../../../../../sdk/ecadence-sdk';
import { ConfirmDialogComponent } from '../../../../shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { HelperService } from '../../../../services/helper.service';

@Component({
  selector: 'scoreboard-status-component',
  templateUrl: './scoreboard-status.component.html',
  styleUrls: ['./scoreboard-status.component.css']
})
export class ScoreboardStatusComponent {
  @Input() scoreboardDataItem: ScoreBoardDataItem;

  constructor(
    private wigController: WigControllerService,
    private modalService: BsModalService,
    public helperService: HelperService
  ) {}

  markKpiAsDone(k: WigKpiPojo): void {
    const ref = this.modalService.show(ConfirmDialogComponent, {
      initialState: {
        body: 'Are you sure you want to mark this KPI as done? This cannot be reversed.'
      }
    });

    ref.content.confirm.subscribe((v) => {
      this.wigController.markKpiAsDone({ wigId: k.lagId, kpiId: k.lagId }).subscribe(
        (v) => {
          window.location.reload();
        },
        (e: unknown) => {
          // this.show.error('An error occurred, please try again later.');
        }
      );
    });
  }

  protected readonly Number = Number;
}
