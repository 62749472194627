<div>
  <!--  <span class="iconify fs-22 text-primary" data-icon="fluent:code-block-16-filled"></span>-->

  <span class="dropdown-menu px-2 overflow-y-scroll" style="max-height: 300px">
    <div *ngIf="scoreboardDataItem?.leadMeasureBenchmark?.length > 0; else empty">
      <div
        class="card p-2 overflow-y-auto mb-3"
        style="width: 18rem"
        *ngFor="let benchmark of scoreboardDataItem?.leadMeasureBenchmark; let i = index"
      >
        <!--        <div class="card-header p-0 border-bottom-0 fw-bold">User</div>-->

        <div class="rounded-3 border-1">
          <div class="d-flex flex-column justify-content-between p-2 fs-12">
            <p class="fw-bold fs-12 text-muted mb-2">Lag Measure Benchmark</p>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <p class="mb-2 fs-12">
                  Lag: <span>{{ benchmark['description'] }}</span>
                </p>
                <p class="fs-12">
                  Benchmark Score: <span>{{ benchmark['benchMarkScore'] }}%</span>
                </p>
              </div>

              <div class="">
                <p class="fw-bold mb-0 fs-12 me-2 mb-1">Time Left:</p>

                <div class="d-flex justify-content-between">
                  <div class="d-flex justify-content-end">
                    <div class="text-end">
                      <p class="mb-0 fw-bold text-primary fs-14">
                        {{ (_countDown | arrayreload : i)?.days }}
                      </p>
                      <p class="mb-0 fs-10 opacity-75">days</p>
                    </div>
                    <div class="text-end mx-1">
                      <p class="mb-0 text-muted fw-bold fs-14">
                        {{ (_countDown | arrayreload : i)?.hours }}
                      </p>
                      <p class="mb-0 fs-12 opacity-75">hours</p>
                    </div>
                    <div>
                      <span class="fw-bold">:</span>
                    </div>
                    <div class="text-end mx-1">
                      <p class="mb-0 text-muted fw-bold fs-14">
                        {{ (_countDown | arrayreload : i)?.minutes }}
                      </p>
                      <p class="mb-0 fs-12 opacity-75">minutes</p>
                    </div>

                    <div>
                      <span class="fw-bold">:</span>
                    </div>
                    <div class="text-end">
                      <p class="mb-0 text-muted fw-bold fs-14">
                        {{ (_countDown | arrayreload : i)?.seconds }}
                      </p>
                      <p class="mb-0 fs-12 opacity-75">Seconds</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #empty>
      <span class="text-center fs-12 text-muted">No upcoming deadline</span>
    </ng-template>
  </span>
</div>
