import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import {
  ScoreboardControllerService,
  UserWithoutWigPojo,
  Wig,
  WigPojo,
  WigScoreStatisticsPojo
} from '../../../../../../sdk/ecadence-sdk';
import { ScoreBoardDataItem } from '../scoreboard.component';

@Component({
  selector: 'scoreboard-header-component',
  templateUrl: './scoreboard-header.component.html',
  styleUrls: ['./scoreboard-header.component.css']
})
export class ScoreboardHeaderComponent implements OnChanges, OnInit {
  usersWithoutWig: UserWithoutWigPojo[];
  timerObject: TimerObject;
  @Input() scoreboardDataItem: ScoreBoardDataItem;
  @Input() wigId: number;
  scoreboardData: ScoreBoardDataItem;
  wigScoreStatistics: WigScoreStatisticsPojo;
  formattedDate: Date;
  isLocal: boolean;
  hideUsersWithoutAssignedWig = false;
  hideUpcomingDeadlines: boolean;
  @Input() isCadence: boolean;
  @Input() cadenceEventId: number;
  @Input() cadenceTimer: number;

  _isLoading = true;

  @Output() createCommitmentEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private bsModalService: BsModalService,
    private scoreboardControllerService: ScoreboardControllerService,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.scoreboardDataItem = changes['scoreboardDataItem']?.currentValue;
    if (this.scoreboardDataItem) {
      this.countdown(this.scoreboardDataItem.wigPojo?.dueDate);
      this.formatScoreboardLastUpdate(
        this.scoreboardDataItem?.wigDefinition?.scoreboardLastUpdatedAt
      );
      this._isLoading = false;
    }
  }

  countdown(targetDate: string): void {
    const targetTime = new Date(targetDate).getTime();

    const int = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeRemaining: number = targetTime - currentTime;
      const days: number = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours: number = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes: number = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds: number = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      this.timerObject = {
        days,
        hours,
        minutes,
        seconds
      };
    }, 1000);
  }

  private formatScoreboardLastUpdate(date: string): void {
    this.formattedDate = new Date(date);
  }

  loadUpcomingDeadlines(): void {
    this.hideUpcomingDeadlines = !this.hideUpcomingDeadlines;
  }

  viewUsersWithoutWig(): void {
    this.hideUsersWithoutAssignedWig = !this.hideUsersWithoutAssignedWig;
  }

  ngOnInit(): void {
    this.getUsersWithoutAssignedWigs();
    this.getUnassignedBlockers();
  }

  getUsersWithoutAssignedWigs(): void {
    this.scoreboardControllerService.getUsersWithoutAssignedWig().subscribe(
      (res) => {
        this.usersWithoutWig = res;
      },
      (error: unknown) => {
        console.log('error ', error);
      }
    );
  }

  getUnassignedBlockers(): void {
    this.scoreboardControllerService.getWigScoreStatistics({ wigId: this.wigId }).subscribe(
      (res) => {
        this.wigScoreStatistics = res;
        console.log('blockers ===> {}', res);
      },
      (error: unknown) => {
        console.log('error ===>', error);
      }
    );
  }

  gotoUnassignedBlocker(): void {
    this.router.navigate(['/leverages/blockers'], {
      queryParams: { wigId: this.wigId, blockerStatus: 'PENDING' }
    });
  }

  cadenceStatus(event: any): void {
    this.isCadence = false;
  }

  protected readonly Number = Number;

  showCountDown(): boolean {
    if (!this.scoreboardDataItem?.wigPojo) return false;
    if (this.scoreboardDataItem?.wigPojo.score == 100) return false;
    return new Date(this.scoreboardDataItem?.wigPojo.startDate) < new Date();
  }
}

interface TimerObject {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
