<div class="d-flex justify-content-center align-items-center">
  <tr>
    <td colspan="100%">
      <ng-lottie
        containerClass="mx-auto"
        [options]="{ path: path, autoplay: true, loop: true }"
        width="150px"
        height="150px"
      ></ng-lottie>
      <div class="d-flex justify-content-center align-items-center" [ngClass]="classForText">{{ message }}</div>
    </td>
  </tr>
</div>
