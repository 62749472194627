import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ScoreboardComponent } from './scoreboard.component';

const routes: Routes = [
  {
    path: '',
    component: ScoreboardComponent,
    pathMatch: 'full',
    // canActivate: [LoggedInGuard, SubscriptionGuard],
    data: {
      isExtranet: false
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScoreboardRoutingModule {}
