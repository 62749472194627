<div class="bg-main ">
  <div class="container">
    <div class="row p-5 vh-100 ">
      <div class="d-flex justify-content-start align-items-center">
        <div class="col-md-6">
        <p class="text-light fw-bold forbidden-fs " style=" line-height: 140% !important;"> {{code}}</p>
        <p class="paragraph text-light fw-semibold">
         Message:
        </p>
          <p class=" text-light mt-3">
           Oops! The page you are looking for could not be found.
          </p>
          <p class=" text-light mt-5 fs-12">
            We apologize, but it appears that the page you requested is missing or no longer available. This could be due to a variety of reasons, including a mistyped URL, a removed page, or an outdated link.
          </p>

          <button (click)="takeMeBack()" class="btn btn-light text-primary px-md-4 mt-5">
            Take me Back
          </button>
        </div></div>
    </div>

  </div>


</div>

