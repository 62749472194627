import { Component, HostListener, OnInit } from '@angular/core';
import { RouterModule, ExtraOptions, Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  ngOnInit(): void {
    const routerOptions: ExtraOptions = {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    };
  }

  login(): void {
    this.authenticationService.login({
      // It should go to /accounts
      redirectUri: `${window.location.origin}/accounts`
    });
  }

  isScrolled = false;
  logoSrc = '/assets/img/logo.svg'; // Default logo source

  @HostListener('window:scroll')
  onWindowScroll(): void {
    this.isScrolled = window.scrollY > 0;
    this.logoSrc = this.isScrolled ? '/assets/img/logo_dark.svg' : ' /assets/img/logo.svg';
  }
}
