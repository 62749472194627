<div class="card rounded-0 p-0">
  <div class="border-bottom p-3 d-flex justify-content-between align-items-center" style="background-color: #35016c">
    <p class="fs-14 text-light">Team’s Commitments</p>
    <div>
      <a class="dropdown-item text-light fs-13 pointer" (click)="addCommitment()">
        <span class="iconify text-white" data-icon="carbon-add"></span>
        Add Commitment
      </a>
    </div>
  </div>


  <div class="p-3" style="max-height: 450px; overflow-y: scroll;">
    <ng-container *ngFor="let c of teamCommitments; index as i">
      <commitment-card
        [commitment]="c"
      >
      </commitment-card>
    </ng-container>
  </div>


</div>

