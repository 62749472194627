<div class="card overview-card h-100">
  <div class="d-flex justify-content-between align-items-center mt-2 mb-4">
    <div class="d-flex">
      <div
        class="p-2 position-relative"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style="background: #3285f834; border-radius: 3.28px"
        (click)="viewBlockers()"
      >
        <span class="iconify fs-22 text-primary" data-icon="fluent:code-block-16-filled"></span>

        <span
          class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
        >
          {{ _wigSummaryDto.blockerCount }}<span class="visually-hidden">unread messages</span>
        </span>
        <span class="dropdown-menu"> </span>
      </div>
    </div>

    <div>
      <button (click)="selectedWig(_wigSummaryDto.wigId)" class="mini-button px-4 py-1">
        View WIG Profile
      </button>
    </div>
  </div>
  <div class="d-flex justify-content-between align-items-center mt-2 mb-4 fs-15 fw-medium">
    <div class="">
      WIG Score: <span class="fw-normal">{{ _wigSummaryDto.progress | number : '1.2-2' }}% </span>
    </div>
    <div class="">
      Start Date: <span class="fw-normal">{{ _wigSummaryDto.startAt | date }}</span>
    </div>
  </div>
  <div   data-bs-toggle="offcanvas"
         data-bs-target="#offcanvasBottom"
         aria-controls="offcanvasBottom" class="pointer">
    <h5 class="limit-to-25-chars" style="line-height: 33px" [tooltip]="_wigSummaryDto.wigName">
      {{ _wigSummaryDto.wigName }}
    </h5>
  </div>

  <div class="d-flex justify-content-between align-items-center my-2 fs-15 fw-medium">
    <div class="my-2">
      Lags: <span class="fw-normal">{{ _wigSummaryDto.lagPojos.length }}</span>
    </div>
    <div class="my-2">
      Players: <span class="fw-normal">{{ _wigSummaryDto.players.length }}</span>
    </div>
  </div>
  <div class="d-flex mt-2 dash-dotted">
    <div class="fs-15 fw-medium">Time Left:</div>
    <div class="ms-md-1 mt-md-0">
      <div class="d-flex align-items-start ms-1">
        <div>
          <div class="time-stamp-dashboard mb-1" style="color: #186de2">
            {{_timerObject?.['days']}}
          </div>
          <div style="text-align: right; font-size: 10px">Days</div>
        </div>
        <div class="ms-4">
          <div class="time-stamp-dashboard mb-1">{{_timerObject?.['hours']}}</div>
          <div style="text-align: right; font-size: 10px">Hr</div>
        </div>
        <div class="time-stamp-dashboard ms-2">:</div>
        <div class="ms-4">
          <div class="time-stamp-dashboard mb-1">{{_timerObject?.['minutes']}}</div>
          <div style="text-align: right; font-size: 10px">Min</div>
        </div>
        <div class="time-stamp-dashboard ms-2">:</div>
        <div class="ms-4">
          <div class="time-stamp-dashboard mb-1">{{_timerObject?.['seconds']}}</div>
          <div style="text-align: right; font-size: 10px">Secs</div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-2 mt-4 d-flex align-items-center justify-content-between">
    <button
      style=""
      class="mini-button px-2 py-1"
      (click)="addBlocker()"
      [disabled]="chechUserPermissions('CREATE_BLOCKER') === false"
    >
      Add blocker
    </button>
    <button
      class="mini-button px-2 py-1"
      (click)="suggestLeadMeasure()"
      [disabled]="chechUserPermissions('REQUEST_LEAD_MEASURE') === false"
    >
      Suggest Lead Measure
    </button>
  </div>
</div>
