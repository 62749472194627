import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { User } from '../../../models/user/user.model';
import { Account } from '../../../models/account/account.model';
import { PortalAccount, UserControllerService } from '../../../../../sdk/ecadence-sdk';

@Component({
  selector: 'portal-account',
  templateUrl: './portal-account.component.html',
  styleUrls: ['./portal-account.component.css']
})
export class PortalAccountComponent implements OnInit {
  userAccounts: PortalAccount[] = [];
  user: User;
  numberOfAccounts: number;
  fullName: string;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserControllerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getUserAccounts();
    this.authenticationService.getUser().subscribe((value) => {
      this.user = value;
    });
    this.fullName = this.user?.firstName + ' ' + this.user?.lastName;
  }

  getUserAccounts(): void {
    this.userService.getUserAccounts().subscribe((v) => {
      this.userAccounts = v;
      this.numberOfAccounts = this.userAccounts.length;

      if (this.userAccounts.length == 1) {
        this.router.navigate(['/dashboard']);
      }
    });
  }

  selectAccount(account: PortalAccount): void {
    const userAccount = this.user.accounts.find((value) => value.accountCode == account.code);
    this.authenticationService.setCurrentAccount(userAccount);
    this.router.navigate(['/dashboard']);
    //this.router.navigateByUrl('/dashboard');
  }
}
