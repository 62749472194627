import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder } from '@angular/forms';
import moment from 'moment';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { AddCommitmentComponent } from '../add-commitment/add-commitment.component';
import {
  CommitmentControllerService,
  CommitmentDto,
  CommitmentSearchFilter,
  LeadMeasureSearchPojo,
  LeverageControllerService,
  QueryResultsLeadMeasureSearchPojo,
  ScoreboardControllerService,
  TeamWigCommitmentPojo,
  WigPojo
} from '../../../../../../sdk/ecadence-sdk';
import { Commitment } from '../../../../models/cadence/commitment.model';
import { removeUndefinedOrNullFields } from '../../../../shared/utils/utils';
import { SuccessDialogComponent } from '../../../../shared/components/success-dialog/success-dialog.component';
import { ConfirmDialogComponent } from '../../../../shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { AuthenticationService } from '../../../../services/authentication.service';
import { User } from '../../../../models/user/user.model';
import CompletionStatusEnum = CommitmentDto.CompletionStatusEnum;

@Component({
  selector: 'recent-commitment-holder',
  templateUrl: './recent-commitment-holder.component.html',
  styleUrls: ['./recent-commitment-holder.component.css']
})
export class RecentCommitmentHolderComponent implements OnInit {
  @Input()
  wig: WigPojo;
  teamCommitments: TeamWigCommitmentPojo[];
  user: User;

  constructor(
    private commitmentService: CommitmentControllerService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private authenticationService: AuthenticationService,
    private scoreboardControllerService: ScoreboardControllerService,
    private laverageControllerService: LeverageControllerService
  ) {}

  ngOnInit(): void {
    this.getScoreboardTeamCommitment();
    this.authenticationService.getUser().subscribe((v) => (this.user = v));
  }

  getScoreboardTeamCommitment(): void {
    this.scoreboardControllerService
      .getTeamWigCommitments({ wigId: this.wig.id })
      .subscribe((res) => {
        this.teamCommitments = res;
        // this.populateCommitment();
      });
  }
  addCommitment(): void {
    const createLeadMeasureModal: BsModalRef<AddCommitmentComponent> = this.modalService.show(
      AddCommitmentComponent,
      {
        initialState: {
          wigId: this.wig.id
        },
        keyboard: false,
        backdrop: 'static',
        class: 'offcanvas offcanvas-end show'
      }
    );

    createLeadMeasureModal.content.commitmentAddedEvent.subscribe((v) => {
      // const newCom = v.map((v): Commitment => {
      //   let x: TeamWigCommitmentPojo = {
      //     lagMeasure: v.lagMeasureName,
      //     leadMeasure: v.leadmeasureName,
      //     leadMeasureScore: v.leadMeasureScore,
      //     lagMeasureScore: '',
      //     commitmentCompletionStatus: CompletionStatusEnum.NOT_COMPLETED,
      //     createdAt: new Date().toDateString(),
      //     commitmentId: 0,
      //     dueDate: new Date(v.dueAt).toISOString(),
      //     leadMeasureAction: v.commitments,
      //     player: this.user.firstName + ' ' + this.user.lastName,
      //     targetScore: v.playerScore
      //   };
      //   return x;
      // });
      // this.commitments.unshift(...newCom);
      this.getScoreboardTeamCommitment();
    });
  }
  loading = false;
  stillLoading = false;
  cardView = true;
  form = this.fb.group({
    limit: [],
    createdAfter: [],
    createdBefore: []
  });
  pageSize = 10;
  queryResults: QueryResultsLeadMeasureSearchPojo;
  filterData? = {};

  setCardView(cardView: boolean): void {
    this.cardView = cardView;
  }

  getCommitments(): void {
    this.search(this.getFilter());
  }

  getFilter(limit?: number): CommitmentSearchFilter {
    const data = this.form.getRawValue();
    const filter: CommitmentSearchFilter = { ...data };
    filter.limit = limit || 10;
    filter.createdAfter = data?.createdAfter
      ? moment(data.createdAfter).format('DD-MM-YYYY')
      : undefined;
    filter.createdBefore = data.createdBefore
      ? moment(data.createdBefore).format('DD-MM-YYYY')
      : undefined;
    return removeUndefinedOrNullFields(filter);
  }

  onPageChange(event: PageChangedEvent): void {
    this.form.get('limit').setValue(this.pageSize);
    const filter = this.getFilter(this.pageSize);
    filter.offset = (event.page - 1) * this.pageSize;
    this.search(filter);
  }

  private search(filter: CommitmentSearchFilter): void {
    this.loading = true;
    this.commitmentService.searchCommitments({ commitmentSearchFilter: filter }).subscribe({
      next: (data) => {
        this.queryResults = data;
        this.teamCommitments = data.results;
        this.loading = false;
        this.getFilterParamsForView(filter);
      },
      complete: () => {
        /**/
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  private getFilterParamsForView(filter: CommitmentSearchFilter): void {
    this.filterData = filter;
    Object.keys(this.filterData).forEach((v) => {
      if (v == 'limit' || v == 'offset') {
        delete this.filterData![v];
      }
      if (!this.filterData[v]) {
        delete this.filterData[v];
      }
    });
  }

  // populateCommitment(): void {
  //   this.teamCommitments.map((commitment) => {
  //     this.commitments.push({
  //       player: commitment.player,
  //       commitmentId: commitment.commitmentId,
  //       commitmentCompletionStatus: commitment.commitmentCompletionStatus,
  //       lagMeasure: commitment.lagMeasure,
  //       leadMeasure: commitment.leadMeasure,
  //       leadMeasureScore: commitment.leadMeasureScore,
  //       lagMeasureScore: commitment.lagMeasureScore,
  //       targetScore: commitment.targetScore,
  //       dueDate: commitment.dueDate,
  //       dueTime: commitment.dueDate,
  //       leadMeasureAction: commitment.leadMeasureAction,
  //       createdAt: commitment.createdAt
  //     });
  //   });
  // }

  markCommitmentAsDone(commitmentId: number): void {
    this.laverageControllerService.markCommitmentAsDone({ id: commitmentId }).subscribe(
      (res) => {
        console.log('done');
      },
      (error: unknown) => {
        console.log('error', error);
      }
    );
  }

  markCommitmentAsNotdone(commitmentId: number): void {
    this.laverageControllerService.markCommitmentAsNotDone({ id: commitmentId }).subscribe(
      (res) => {
        console.log('done marking commitment');
      },
      (error: unknown) => {
        console.log('error', error);
      }
    );
  }

  showSuccessModal(message: string): void {
    const bsModalRef1 = this.modalService.show(SuccessDialogComponent, {
      initialState: {
        title: 'Success',
        message: message,
        onClose: () => {
          this.modalService.hide();
        }
      },
      class: 'modal-sm modal-dialog-centered',
      keyboard: false,
      backdrop: 'static'
    });
    bsModalRef1.content.closeEvent.subscribe((result: any) => {
      console.log('result');
    });
  }

  actionConfirmationModal(message: string, action: string, commitmentId: number): void {
    const bsModalRef2 = this.modalService.show(ConfirmDialogComponent, {
      initialState: {
        body: message,
        positiveButtonText: 'Yes',
        negativeButtonText: 'No'
      },
      class: 'modal-sm modal-dialog-centered',
      keyboard: false,
      backdrop: 'static'
    });
    bsModalRef2.content.confirm.subscribe((result: any) => {
      bsModalRef2.hide();
      if (action === 'markCommitmentAsDone') {
        this.markCommitmentAsDone(commitmentId);
      } else if (action === 'markCommitmentAsNotdone') {
        this.markCommitmentAsNotdone(commitmentId);
      }
    });
  }
}
